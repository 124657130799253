import React, { useEffect, useState } from 'react';

import { Avatar, Button, Divider, List, ListItem, ListItemAvatar, ListItemText, Paper, Typography } from '@mui/material';

import ArticleIcon from '@mui/icons-material/Article';

import { obtenerUltimosAcuerdos } from '../../actions/ui/ultimosAcuerdosActions';

const xmlToJson = (xml) => {
	
    // Create the return object
    var obj = {};

    if (xml.nodeType === 1) { // element
        // do attributes
        if (xml.attributes.length > 0) {
        obj["@attributes"] = {};
            for (var j = 0; j < xml.attributes.length; j++) {
                var attribute = xml.attributes.item(j);
                obj["@attributes"][attribute.nodeName] = attribute.nodeValue;
            }
        }
    } else if (xml.nodeType === 3) { // text
        obj = xml.nodeValue;
    }

    // do children
    if (xml.hasChildNodes()) {
        for(var i = 0; i < xml.childNodes.length; i++) {
            var item = xml.childNodes.item(i);
            var nodeName = item.nodeName;
            if (typeof(obj[nodeName]) == "undefined") {
                obj[nodeName] = xmlToJson(item);
            } else {
                if (typeof(obj[nodeName].push) == "undefined") {
                    var old = obj[nodeName];
                    obj[nodeName] = [];
                    obj[nodeName].push(old);
                }
                obj[nodeName].push(xmlToJson(item));
            }
        }
    }
    return obj;
};

export const UltimosAcuerdos = () => {

    const [data, setData] = useState( [] );
    
    useEffect(() => {
      
        async function Obtener(){

            await obtenerUltimosAcuerdos().then( response => {

                if( response.status === 200 ){

                    if( response.data ){

                        const parser = new DOMParser();
                        const xmlDoc = parser.parseFromString(response.data, "text/xml");

                        if( xmlDoc ){

                            const dataXml = xmlToJson( xmlDoc );

                            if( dataXml ){

                                const { item } = dataXml.rss.channel;

                                if( item.length !== 0 ){

                                    let newArray = [];

                                    for (let index = 0; index < 10; index++) {
                                        newArray.push( item[index] );
                                        
                                    }

                                    setData( newArray );
                                }

                            }

                        }

                    }
                }

            });
        }

        Obtener();

    }, [ ])
    
    return (
        <Paper>
            <Typography fontSize={22} variant='h5' sx={{ ml: 2, pt: 2 }}>
                Acuerdos del Consejo
            </Typography>
            
            <List key={0} sx={{ width: '100%', borderRadius: 3 }}>
            {
                data.map( ( element, i ) => (

                    <div key={ i }>

                        <ListItem sx={{ pt: '13px', pb: '13px' }} >
                            <ListItemAvatar>
                                <Avatar>
                                    <Button
                                        href={ JSON.stringify(element.link['#text']).replaceAll('"', '') }
                                        target='_blank'
                                        variant='text'
                                    >
                                        <ArticleIcon color='primary' />
                                    </Button>
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={ 
                                <Typography variant='body2' sx={{ fontSize: 14 }}> 
                                    { JSON.stringify(element.title['#text']).replaceAll('"', '') } 
                                </Typography> } 
                            />
                        </ListItem>

                        <Divider />

                    </div>

                ))
            }
            </List>
        
        </Paper>
    )

}