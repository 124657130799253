import React, { useState } from 'react'

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, Radio, RadioGroup, TextField, Typography } from '@mui/material'

import { LoadingButton } from '@mui/lab';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import CheckIcon from '@mui/icons-material/Check';
import SendIcon from '@mui/icons-material/Send';

import { CambiarContrasena, EnviarNipCambioContrasena, ValidarNipRecuperacion } from '../../actions/auth/authActions';

export const ModalActualizarContrasena = ({ open, setOpen, setModal, usuario }) => {

    const [viewContrasena, setViewContrasena] = useState( false );
    const [viewConfirmarContrasena, setViewConfirmarContrasena] = useState( false );

    const [contrasena, setContrasena] = useState( '' );
    const [confirmarContrasena, setConfirmarContrasena] = useState( '' );
    
    const [activeStep, setActiveStep] = useState( 0 );
    const [loadingNext, setLoadingNext] = useState( false );
    const [tipoRecuperacion, setTipoRecuperacion] = useState( 0 );
    const [codigoVerificacion, setCodigoVerificacion] = useState( '' );
    const [token, setToken] = useState( '' );

    const handleChangeViewContrasena = () => setViewContrasena( (prev) => !prev );
    const handleChangeViewConfirmarContrasena = () => setViewConfirmarContrasena( (prev) => !prev );

    const validateFields = () => {

        let valid = true;

        if ( ( confirmarContrasena !== '' || contrasena !== '' ) && ( contrasena !== confirmarContrasena )) {
            valid = false;

            setModal({
                Open: true,
                msg: 'las contraseñas no coinciden',
                type: 'error'
            });
        }

        return valid;
    }

    const handleClose = () => {
     
        setActiveStep( 0 );
        setToken( '' );
        setTipoRecuperacion( 0 );
        setLoadingNext( false );
        setCodigoVerificacion( '' );

        setContrasena( '' );
        setConfirmarContrasena( '' );
        setViewContrasena( false );
        setViewConfirmarContrasena( false );

        setOpen( false );
    }

    const handleEnviarNipMetodoRecuperacion = () => {

        const params = {
            correoElectronico: tipoRecuperacion === 1 ? usuario?.correoElectronico : '',
            telefonoCelular: tipoRecuperacion === 2 ? usuario?.telefonoCelular : '',            
            idTipoRecuperacion: tipoRecuperacion
        }

        setLoadingNext( true );

        EnviarNipCambioContrasena( params ).then( response => {

            if( response ){

                if( response.status === 200 ){

                    const { success, token } = response.data;

                    if( success ){

                        setTimeout(() => {
           
                            setToken( token );
                            setLoadingNext( false );
                            setActiveStep( (prevStep) => prevStep + 1);
        
                       }, 1200);

                   }
                   else {
                        setTimeout(() => {    
           
                            setLoadingNext( false );
               
                        }, 1200);
                   }

                }

            }

        })
    }

    const handleValidarNipRecuperacion = () => {      

        const params = {
            nip: codigoVerificacion,
            token: token,
        }

        setLoadingNext( true );

        ValidarNipRecuperacion( params ).then( response => {

            if( response ){

                if( response.status === 200 ){

                    const { success, message } = response.data;

                    if( success ){

                        setTimeout(() => {
           
                           setLoadingNext( false );
                           setActiveStep( (prevStep) => prevStep + 1);
        
                       }, 1200);

                   }
                   else {

                       setTimeout(() => {   

                            setModal({
                                Open: true,
                                msg: message,
                                type: 'error'
                            });
           
                           setLoadingNext( false );
               
                       }, 1200);

                   }

                }

            }

        })
    }

    const handleCambiarContrasena = async () => {

        const valid = validateFields();
        if( !valid ){
            return false;
        }

        setLoadingNext( true );

        await CambiarContrasena( { contrasena, token } ).then( response => {

            if( response ){

                if( response.status === 200 ){

                    const { success, msg } = response.data;

                    if( success ){

                        setTimeout(() => {

                            setModal({
                                Open: true,
                                msg: msg,
                                type: 'success'
                            });
           
                           setLoadingNext( false );
                           handleClose();
        
                       }, 1200);

                   }
                   else {

                       setTimeout(() => {

                            setModal({
                                Open: true,
                                msg: msg,
                                type: 'error'
                            });
                   
                            setLoadingNext( false );
               
                       }, 1200);

                   }

                }

            }

        })
    }

    return (
        <Dialog open={ open } onClose={ () => { } } maxWidth='sm' fullWidth >

            <DialogTitle>
                Cambiar contraseña
            </DialogTitle>

            <DialogContent>

                <Grid container spacing={3} >                 

                    {                       
                        activeStep === 0
                        ?
                        (
                            <>
                                <Grid item md={12} xs={12} sx={{ textAlign: 'center' }}>

                                    <Typography variant='h6' sx={{ fontSize: 16, fontWeight: 'bold' }}>
                                        Para cambiar la contraseña seleccione algun método que aparece a continuación:
                                    </Typography>

                                </Grid>

                                <Grid item md={12} xs={12} >

                                    <FormControl>

                                        <RadioGroup>

                                            <FormControlLabel control={<Radio size='small' checked={ tipoRecuperacion === 1 } onChange={ () => setTipoRecuperacion( 1 ) } /> } label={ 
                                                <Typography variant='body2'> 
                                                    Se enviar un código de verificación al correo electrónico <br /> <strong> { usuario?.correoElectronico } </strong>
                                                </Typography> 
                                            } />

                                            {
                                                ( usuario?.telefonoCelular )
                                                &&                                                
                                                <FormControlLabel sx={{ mt: 2 }}  control={ <Radio size='small' checked={ tipoRecuperacion === 2 } onChange={ () => setTipoRecuperacion( 2 ) } />} label={ 
                                                    <Typography variant='body2'> 
                                                        Se enviar un código de verificación vía mensaje de texto al número celular <br /> <strong> { usuario?.telefonoCelular } </strong>
                                                    </Typography> 
                                                } />
                                            }
                                            
                                        </RadioGroup>

                                    </FormControl>

                                </Grid>

                                <Grid item md={12} xs={12} sx={{ textAlign: 'center' }}>

                                    <LoadingButton
                                        variant='contained'
                                        loading={ loadingNext }
                                        onClick={ handleEnviarNipMetodoRecuperacion }
                                        startIcon={ <SendIcon /> }
                                        disabled={ tipoRecuperacion === 0 }
                                    >
                                        Enviar código de verificación
                                    </LoadingButton>

                                </Grid>
                            </>
                        )
                    :
                        activeStep === 1
                        ?
                        (
                            <>
                                <Grid item md={12} xs={12} sx={{ textAlign: 'center' }}>

                                    <Typography variant='h6' sx={{ fontSize: 16, fontWeight: 'bold' }}>
                                        Ingrese el codigo de verificación
                                    </Typography>

                                </Grid>

                                <Grid item md={12} xs={12} display='flex' flexDirection='column' alignItems='center'>

                                    <TextField 
                                        name='codigoVerificacion'
                                        label=''
                                        type='text'
                                        value={ codigoVerificacion }
                                        onChange={ ( e ) => setCodigoVerificacion( e.target.value ) }
                                        inputProps={{ style: { textAlign: 'center', fontSize: '1.5em' }}}
                                    />

                                </Grid>

                                <Grid item md={12} xs={12} sx={{ textAlign: 'center' }}>

                                    <LoadingButton
                                        variant='contained'
                                        loading={ loadingNext }
                                        onClick={ handleValidarNipRecuperacion }
                                        startIcon={ <CheckIcon /> }
                                        disabled={ codigoVerificacion === '' }
                                    >
                                        Validar código de verificación
                                    </LoadingButton>

                                </Grid>
                            </>
                        )
                    :
                        activeStep === 2
                        ?
                        (
                            <>
                                <Grid item md={12} xs={12} sx={{ textAlign: 'center' }}>

                                    <Typography variant='h6' sx={{ fontSize: 16, fontWeight: 'bold' }}>
                                        Ingrese la nueva contraseña
                                    </Typography>

                                </Grid>

                                <Grid item xs={6} sm={6} > 

                                    <FormControl variant="outlined" fullWidth >
                                        <InputLabel>Contraseña</InputLabel>
                                        <OutlinedInput
                                            label="Contraseña"
                                            name="contrasena"
                                            type={ viewContrasena ? 'text' : 'password'}
                                            value={ contrasena }
                                            onChange={ ( e ) => setContrasena( e.target.value ) }
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton onClick={ handleChangeViewContrasena } edge="end" >
                                                        {
                                                            viewContrasena
                                                            ?
                                                            <VisibilityOffIcon />
                                                            :
                                                            <VisibilityIcon />
                                                        }                                                    
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                    </FormControl>  

                                </Grid>                  

                                <Grid item md={6} xs={12} >

                                    <FormControl variant="outlined" fullWidth>
                                        <InputLabel>Confirmar contraseña</InputLabel>
                                        <OutlinedInput
                                            label="Confirmar contraseña"
                                            name="confirmarContrasena"
                                            type={ viewConfirmarContrasena ? 'text' : 'password'}
                                            value={ confirmarContrasena }
                                            onChange={ ( e ) => setConfirmarContrasena( e.target.value ) }
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton onClick={ handleChangeViewConfirmarContrasena } edge="end" >
                                                        {
                                                            viewConfirmarContrasena
                                                            ?
                                                                <VisibilityOffIcon />
                                                            :
                                                                <VisibilityIcon />
                                                        }
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                    </FormControl>

                                </Grid>

                                <Grid item md={12} xs={12} sx={{ textAlign: 'center' }}>

                                    <LoadingButton
                                        variant='contained'
                                        loading={ loadingNext }
                                        onClick={ handleCambiarContrasena }
                                        startIcon={ <CheckIcon /> }
                                        disabled={ contrasena === '' || confirmarContrasena === '' }
                                    >
                                        Guardar nueva contraseña
                                    </LoadingButton>

                                </Grid>
                            </>
                        )
                    :
                        <>
                            <Grid container spacing={1} sx={{ mt: '1em' }} >

                                <Grid item md={12} xs={12} sx={{ textAlign: 'center' }}>

                                    <CheckIcon sx={{ fontSize: '5em' }} color='success' />

                                </Grid>

                                <Grid item md={12} xs={12} sx={{ textAlign: 'center' }}>

                                    <Typography variant='body2' sx={{ fontSize: 16, fontWeight: 'bold' }}>
                                        Se actualizó correctamente la contraseña
                                    </Typography>

                                </Grid>

                            </Grid>
                        </>
                    }

                </Grid>

            </DialogContent>

            <DialogActions>
                <Button onClick={ handleClose }> Cancelar </Button>             
            </DialogActions>

        </Dialog>
    )

}
