import React, { useState, useEffect } from 'react'

import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField, Typography } from '@mui/material'

import { LoadingButton } from '@mui/lab';

import SaveIcon from '@mui/icons-material/Save';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import { ActualizarDatosCuenta } from '../../actions/admin/usuarioActions';

export const ModalActualizarDatosCuenta = ({ open, setOpen, setModal, usuario, setUsuario }) => {

    const [viewContrasena, setViewContrasena] = useState( false );
    const [viewConfirmarContrasena, setViewConfirmarContrasena] = useState( false );

    const [correoElectronico, setCorreoElectronico] = useState( '' );
    const [confirmarCorreoElectronico, setconfirmarCorreoElectronico] = useState( '' );
    const [contrasena, setContrasena] = useState( '' );
    const [confirmarContrasena, setConfirmarContrasena] = useState( '' );

    const [errores, setErrors] = useState( {} )
    const [openLoading, setOpenLoading] = useState( false );
    
    const handleChangeViewContrasena = () => setViewContrasena( (prev) => !prev );
    const handleChangeViewConfirmarContrasena = () => setViewConfirmarContrasena( (prev) => !prev );

    const validateFields = () => {

        let valid = true;
        let errores = {};

        const validCorreoElectronico = /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test( correoElectronico );
        const validConfirmarCorreoElectronico = /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test( confirmarCorreoElectronico );

        if ( correoElectronico === '' ) {
            valid = false
            errores.correoElectronico = 'Debe escribir el correo electrónico'
        }
        else if ( !validCorreoElectronico ) {
            valid = false
            errores.correoElectronico = 'El correo electrónico no es valido'
        }
        else if ( !validConfirmarCorreoElectronico ) {
            valid = false
            errores.correoElectronico = 'El correo electrónico no es valido'
        }
        else if ( confirmarCorreoElectronico === '' ) {
            valid = false
            errores.correoElectronico = 'Debe confirmar el correo electrónico'
        }
        else if ( correoElectronico !== confirmarCorreoElectronico ) {
            valid = false
            errores.correoElectronico = 'Los correos electrónicos no coinciden'
        }
        else if ( ( confirmarContrasena !== '' || contrasena !== '' ) && ( contrasena !== confirmarContrasena )) {
            valid = false
            errores.contrasena = 'las contraseñas no coinciden'
        }

        setErrors( errores );

        return valid;
    }

    const handleActualizarDatosCuenta = async () => {
        
        const valid = validateFields();

        if ( !valid ){
            return false
        }

        setOpenLoading( true );

        const params ={
            correoElectronico: correoElectronico,
            contrasena: contrasena,
        }

        await ActualizarDatosCuenta( params ).then(response => {

            if( response ){
            
                if( response.status === 200 ){

                    const { success, message } = response.data;

                    setTimeout(() => {                    

                        if( success ){

                            setModal({
                                Open: true,
                                msg: message,
                                type: 'success'
                            });

                            setUsuario({
                                ...usuario,
                                correoElectronico
                            });

                            handleClose();

                            setOpenLoading( false );
                        }
                        else {               
                                
                            setModal({
                                Open: true,
                                msg: message,
                                type: 'error'
                            });

                            setOpenLoading( false );                        
                        }

                    }, 1000);

                }

            }
        })
  
    }

    const handleClose = () => {

        setCorreoElectronico( '' ); 
        setconfirmarCorreoElectronico( '' ); 
        setContrasena( '' );
        setConfirmarContrasena( '' );
        setViewContrasena( false );
        setViewConfirmarContrasena( false );
        setErrors( {} );

        setOpen( false );
    }

    useEffect(() => {

        setCorreoElectronico( usuario?.correoElectronico ?? '' ); 
        setconfirmarCorreoElectronico( usuario?.correoElectronico ?? '' ); 

    }, [ open, usuario ])

    return (
        <Dialog open={ open } onClose={ () => { } } maxWidth='md' fullWidth >

            <DialogTitle>
                Actualizar datos de la cuenta
            </DialogTitle>

            <DialogContent>

                <Grid container spacing={3} sx={{ mt: 1 }}>

                    <Grid item md={6} xs={12} >

                        <TextField
                            fullWidth
                            label="Correo electrónico"
                            name="correoElectronico"
                            variant="outlined"
                            autoComplete='off'
                            value={ correoElectronico }
                            onChange={ (e) => { setCorreoElectronico( e.target.value ) }}
                            required
                        />

                    </Grid>

                    <Grid item md={6} xs={12} >

                        <TextField
                            fullWidth
                            label="Confirmar correo electrónico"
                            name="confirmarCorreoElectronico"
                            variant="outlined"
                            autoComplete='off'
                            value={ confirmarCorreoElectronico }
                            onChange={ (e) => { setconfirmarCorreoElectronico(e.target.value) } }
                            required
                        />

                    </Grid>

                    {
                        errores.correoElectronico
                        &&
                        <Grid item md={12} xs={12} >
                            <Alert severity='warning' variant='filled'>
                                { errores.correoElectronico }
                            </Alert>
                        </Grid>
                    }

                    <Grid item md={12} xs={12} sx={{ mt: 1 }}>
                        <Typography variant='body2' sx={{ fontWeight: 'bold' }}>
                            Para cambiar el correo electrónico es necesario ingresar su contraseña actual.
                        </Typography>
                    </Grid>

                    <Grid item md={6} xs={12} >

                        <FormControl variant="outlined" fullWidth>
                            <InputLabel>Contraseña</InputLabel>
                            <OutlinedInput
                                label="Contraseña"
                                name="contrasena"
                                type={ viewContrasena ? 'text' : 'password'}
                                value={ contrasena }
                                onChange={ (e) => { setContrasena( e.target.value ) }}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton onClick={ handleChangeViewContrasena } edge="end" >
                                            {
                                                viewContrasena
                                                ?
                                                    <VisibilityOffIcon />
                                                :
                                                    <VisibilityIcon />
                                            }
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>

                    </Grid>

                    <Grid item md={6} xs={12} >

                        <FormControl variant="outlined" fullWidth>
                            <InputLabel>Confirmar contraseña</InputLabel>
                            <OutlinedInput
                                label="Confirmar contraseña"
                                name="confirmarContrasena"
                                type={ viewConfirmarContrasena ? 'text' : 'password'}
                                value={ confirmarContrasena }
                                onChange={ (e) => { setConfirmarContrasena( e.target.value) } }
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton onClick={ handleChangeViewConfirmarContrasena } edge="end" >
                                            {
                                                viewConfirmarContrasena
                                                ?
                                                    <VisibilityOffIcon />
                                                :
                                                    <VisibilityIcon />
                                            }
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>

                    </Grid>

                    {
                        errores.contrasena
                        &&
                        <Grid item md={12} xs={12} >
                            <Alert severity='warning' variant='filled'>
                                { errores.contrasena }
                            </Alert>
                        </Grid>
                    }

                </Grid>

            </DialogContent>

            <DialogActions>
                <Button onClick={ handleClose }> Cancelar </Button>
                <LoadingButton 
                    onClick={ handleActualizarDatosCuenta } 
                    variant='contained' 
                    startIcon={ <SaveIcon />} 
                    loading={ openLoading }
                    disabled={ contrasena === '' || confirmarContrasena === '' }
                > 
                    Guardar 
                </LoadingButton>
            </DialogActions>

        </Dialog>
    )

}
