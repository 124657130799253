import httpCliente from '../../services/httpClient';
import httpClienteToken from '../../services/httpClientToken';

export const ObtenerCodigosPostales = ( id ) => {

    return new Promise( (resolve, eject) => {

        httpCliente.get(`/Api/Asentamiento/ObtenerCodigosPostales/${ id }`)
        .then( response => {
            if( response.status === 200 ){
                resolve( response );
            }
        })
        .catch( (error) => {
            resolve( error.response );
        });

    });

}

export const ObtenerAsentamientos = ( param ) => {

    return new Promise( (resolve, eject) => {

        httpCliente.post('/Api/Asentamiento/ObtenerAsentamientos/', param)
        .then( response => {
            if( response.status === 200 ){
                resolve( response );
            }
        })
        .catch( (error) => {
            resolve( error.response );
        });       

    });

}

export const Asentamientos = ( ) => {

    return new Promise( (resolve, eject) => {

        const data = JSON.parse(window.localStorage.getItem('data'));

        if( data ){
            const { token } = data;

            httpClienteToken.get('/Api/Asentamiento/', token)
            .then( response => {
                if( response.status === 200 ){
                    resolve( response );
                }
            })
            .catch( (error) => {
                resolve( error.response );
            });
        }

    });

}

export const ActualizarAsentamiento = ( params ) => {

    return new Promise( (resolve, eject) => {

        const data = JSON.parse(window.localStorage.getItem('data'));

        if( data ){
            const { token } = data;

            httpClienteToken.post('/Api/Asentamiento/Actualizar', params, token)
            .then( response => {
                if( response.status === 200 ){
                    resolve( response );
                }
            })
            .catch( (error) => {
                resolve( error.response );
            });
        }

    });

}

export const AgregarAsentamiento = ( params ) => {

    return new Promise( (resolve, eject) => {

        const data = JSON.parse(window.localStorage.getItem('data'));

        if( data ){
            const { token } = data;

            httpClienteToken.post('/Api/Asentamiento/Agregar', params, token)
            .then( response => {
                if( response.status === 200 ){
                    resolve( response );
                }
            })
            .catch( (error) => {
                resolve( error.response );
            });
        }

    });

}