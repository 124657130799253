import React, { useEffect, useRef, useState } from 'react';

import { useSelector } from 'react-redux';

import { Alert, Box, Button, Card, CardContent, CardHeader, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, Grid, InputLabel, LinearProgress, MenuItem, Select, Snackbar, TextField, Typography } from '@mui/material';

import { LoadingButton } from '@mui/lab';

import SaveIcon from '@mui/icons-material/Save';
import SendIcon from '@mui/icons-material/Send';

import Dropzone from 'react-dropzone-uploader';
import 'react-dropzone-uploader/dist/styles.css';

import AuthCode from 'react-auth-code-input';
import '../../css/InputCodeVerification.css';

import { usePermisos } from '../../hooks/usePermisos';

import { AgregarPromocion, ValidarExpediente } from '../../actions/buzon/promocionActions.js';
import { ObtenerJuzgadosDistritoMateria } from '../../actions/catalogos/juzgadoActions';
import { ObtenerMateriaDistritos } from '../../actions/catalogos/materiaDistritoActions';
import { ObtenerOficialiaMateria } from '../../actions/catalogos/oficialiaMateriaActions';
import { ObtenerTiposAudiencia } from '../../actions/catalogos/tipoAudienciaActions';
import { ObtenerTipoJuicioMaterias } from '../../actions/catalogos/tipoJuicioActions';
import { ObtenerTipoTramiteMaterias } from '../../actions/catalogos/tipoTramiteMateriasActions';
import { ObtenerTipoTramiteUsuarios } from '../../actions/catalogos/tipoTramiteUsuarioActions';


export const RealizarEnvioScreen = () => {

    const { tipoUsuario } = usePermisos();
    const { tokenUnicoActivado } = useSelector( state => state.auth );

    const AuthInputRef = useRef( null );
    const refDropzoneEscrito = useRef( null );
    const refDropzoneAnexos = useRef( null );
    
    const [errors, setErrors] = useState({ });

    const [tipoTramite, setTipoTramite] = useState( 0 );
    const [tipoTramiteArray, setTipoTramiteArray] = useState( [ ] );

    const [distritoJudicial, setDistritoJudicial] = useState( 0 );
    const [distritoJudicialArray, setDistritoJudicialArray] = useState( [ ] );

    const [juzgado, setJuzgado] = useState( 0 );
    const [juzgadoArray, setJuzgadoArray] = useState( [ ] );

    const [tipoJuicio, setTipoJuicio] = useState( 0 );
    const [tipoJuicioArray, setTipoJuicioArray] = useState( [ ] );

    const [tipoAudiencia, setTipoAudiencia] = useState( 0 );
    const [tipoAudienciaArray, setTipoAudienciaArray] = useState( [ ] );

    const [materia, setMateria] = useState( 0 );
    const [materiaArray, setMateriaArray] = useState( [ ] );

    const [oficialia, setOficialia] = useState( 0 );
    const [oficialiaMateriaArray, setOficialiaMateriaArray] = useState( [ ] );

    const [imputado, setImputado] = useState( '' );
    const [acusado, setAcusado] = useState( '' );
    const [referencia, setReferencia] = useState( '' );
    const [juicio, setJuicio] = useState( '' );
    
    const [codigo, setCodigo] = useState( '' );
    const [tokenUnico, setTokenUnico] = useState( '' );
    
    const [escrito, setEscrito] = useState( null );
    const [acuseEnvio, setAcuseEnvio] = useState( '' );      
    const anexos = useRef( [] );

    const [loadingSave, setLoadingSave] = useState( false );
    const [loadingRegistrar, setLoadingRegistrar] = useState( false );

    const [openModal, setOpenModal] = useState( false );

    const [openAcuse, setOpenAcuse] = useState( false );

    const [alert, setAlert] = useState( {} );

    const handleChangeEscrito = ({ meta, file }, status) => { 
        if( status === "done" ){ 
            setEscrito( file );
        } 
        else if( status === "removed" ){
            setEscrito( null );
        }
    }
  
    const handleChangeAnexos = ( { file }, status ) => { 

        if( status === "done" ){ 
            anexos.current.push( file );
        }
        else if( status === "removed" ){
            anexos.current = anexos.current.filter((elem) => elem.name !== file.name && elem.lastModified !== file.lastModified );
        }

    }

    const handleCloseModal = () => {
        
        setOpenModal( false );
        setErrors( { } );

        AuthInputRef.current?.clear();
    }

    const SolicitarCodigo = async () => {
        
        const valid = validateFiels();

        if( !valid ){
            return false;
        }

        setLoadingSave( true );

        let validateExpediente = false;

        if( tipoTramite === 9 && juzgado === 86){

            if( referencia.indexOf('/') !== -1 ){
                
                const anioExpediente = parseInt( referencia.substring( referencia.indexOf('/') + 1 ) ?? '0' );
                if (anioExpediente >= 2024)
                {
                    validateExpediente = true;
                }
            }            

        }
        else {
            validateExpediente = true;
        }

        if( tipoTramite === 9 && materia === 7 && validateExpediente){

            await ValidarExpediente({ numeroExpediente: referencia, idJuzgado: juzgado }).then( response => {

                if( response ){

                    if( response.status === 200){

                        const { success, message } = response.data;

                        if( success ){

                            setTimeout(() => {
                                    
                                setOpenModal( true );
                                setLoadingSave( false );

                            }, 1000);

                        }
                        else{
                                
                            setTimeout(() => {
                            
                                setAlert( {
                                    open: true,
                                    msg: message,
                                    type: 'error'
                                } ); 

                                setLoadingSave( false );

                            }, 1000);  

                        }

                    }
                    else{
                                
                        setTimeout(() => {
                        
                            setAlert( {
                                open: true,
                                msg: 'Ocurrio un error, contacte con el administrador del sistema',
                                type: 'error'
                            } ); 

                            setLoadingSave( false );

                        }, 1000);  

                    }

                }
                else{
                                
                    setTimeout(() => {
                    
                        setAlert( {
                            open: true,
                            msg: 'Ocurrio un error, contacte con el administrador del sistema',
                            type: 'error'
                        } ); 

                        setLoadingSave( false );

                    }, 1000);  

                }

            });

        }
        else{

            setTimeout(() => {
                                        
                setOpenModal( true );
                setLoadingSave( false );

            }, 1000);

        }
        
    }

    const handleGuardar = async () => {

        setLoadingRegistrar( true );   

        let formData = new FormData();
		    formData.append('IdTipoTramite', tipoTramite);  
            formData.append('Referencia', referencia);  
            formData.append('IdDistritoJudicial', distritoJudicial);  
            formData.append('IdOficialia', oficialia);  
            formData.append('IdJuzgado', juzgado);  
            formData.append('IdMateria', materia);  
            formData.append('IdTipoJuicio', tipoJuicio);  
            formData.append('IdTipoAudiencia', tipoAudiencia);  
            formData.append('OtroTipoJuicio', juicio);  
            formData.append('Imputado', imputado);  
            formData.append('Acusado', acusado); 
            formData.append('Escrito', escrito); 
            formData.append('Nip', codigo); 
            formData.append('TokenUnico', tokenUnico); 
           
            for (let i = 0 ; i < anexos.current.length ; i++) {
                formData.append('Anexos', anexos.current[i]); 
            }

        await AgregarPromocion( formData ).then( response => {

            if( response ){

                if( response.status === 200){

                    if( response.data ){

                        const { success, message, url } = response.data;

                        if( success ){

                            setTimeout(() => {

                                handleCloseModal();

                                handleClearPromocion();

                                setLoadingRegistrar( false );

                                setAlert( {
                                    open: true,
                                    msg: message,
                                    type: 'success'
                                } ); 

                                setAcuseEnvio(url) ;
                                setOpenAcuse(true) ;

                            }, 1000);                                
                            
                        }
                        else{
                            
                            setTimeout(() => {

                                setErrors( { } );

                                let errores = {};
                            
                                errores.codigoInvalido = message;
                                
                                setErrors( errores ) ;

                                setLoadingRegistrar( false );

                            }, 1000);  

                        }
                        
                    } 

                }
                else{
                            
                    setTimeout(() => {

                        setErrors( { } );

                        let errores = {};
                    
                        errores.codigoInvalido = 'Ocurrio un error, vuelva a firmar y enviar su trámite.';
                        
                        setErrors( errores ) ;

                        setLoadingRegistrar( false );

                    }, 1000);  

                }

            }
            
        });

    }

    const validateFiels = () => {
        
        setErrors( { } );

        let valid = true;
        let errores = {};
        
        if( tipoTramite === 0 ){
            valid = false;
            errores.tipoTramite = 'Selecciona un tipo de trámite';
        }  

        if( distritoJudicial === 0 ){
            valid = false;
            errores.distritoJudicial = 'Selecciona un distrito judicial';
        }  

        if( materia === 0 ){
            valid = false;
            errores.materia = 'Selecciona una materia';
        }  

        if( (tipoTramite === 1 || tipoTramite === 6  )  ){ /* Tramites que se asignan a una Oficialia */
            if(oficialia === 0){
                valid = false;
                errores.oficialia = 'Selecciona la Oficialía';
            }
            if(tipoJuicio === 0){
                valid = false ;
                errores.tipoJuicio = 'Selecciona un tipo de juicio'
            }
            if(( tipoJuicio === 171 || tipoJuicio === 172 || tipoJuicio === 173 || tipoJuicio === 174 || tipoJuicio === 175 ) && juicio === ''){ /* tipo de juicio OTRO */
                valid = false ;
                errores.juicio = 'Escribe el tipo de juicio'
            }
        }

        if( (tipoTramite === 7 || tipoTramite === 8 || tipoTramite === 9 || tipoTramite === 10 || tipoTramite === 11 || tipoTramite === 12 )  && juzgado === 0 ){ /* Tramites que se asignan a un Juzgado */
            valid = false;
            errores.juzgado = 'Selecciona el juzgado';
        }

        if( ( tipoTramite === 8 || tipoTramite === 4 ) && referencia === '' ){ 
            valid = false;
            errores.referencia = 'Selecciona el número de exhorto';
        }

        if( ( tipoTramite === 9 || tipoTramite === 5 )  && referencia === '' ){ 
            valid = false;
            errores.referencia = 'Selecciona el número de expediente';
        }

        if( tipoTramite === 10  && referencia === '' ){ 
            valid = false;
            errores.referencia = 'Selecciona el número de folio';
        }

        if( tipoTramite === 12 ){ /* Solicitud de Audiencia (Penal) */
            if( tipoAudiencia === 0){
                valid = false;
                errores.tipoAudiencia = 'Selecciona un tipo de audiencia';
            }
            if( referencia === ''){
                valid = false;
                errores.numeroCausa = 'Escribe el número de causa';
            }
            if( imputado === ''){
                valid = false;
                errores.imputado = 'Escribe el nombre del imputado';
            }     
        }    

        if( tipoTramite === 3 ){
            if( referencia === ''){
                valid = false;
                errores.numeroCausa = 'Escribe el número de causa';
            }
        }

        if( tipoTramite === 11 ){ /* Promoción a Tramite (Penal) */
            if( referencia === ''){
                valid = false;
                errores.numeroCausa = 'Escribe el número de causa';
            }
            if( imputado === ''){
                valid = false;
                errores.imputado = 'Escribe el nombre del imputado';
            }     
        }  

        if( tipoTramite === 7 ){ /* Promoción a Causa (Penal) */
            if( referencia === ''){
                valid = false;
                errores.numeroCausaPenal = 'Escribe el número de causa penal';
            }
            if( acusado === ''){
                valid = false;
                errores.acusado = 'Escribe el nombre del acusado';
            }     
        } 

        setErrors( errores );

        return valid;
    }
    
    const handleClear = () => {
      
        setMateria( 0 );
        setDistritoJudicial( 0 );

        setTipoJuicio( 0 );
        setJuicio( '' );

        setOficialia( 0 );
        setJuzgado( 0 );

        setTipoAudiencia( 0 );
        setImputado( '' );
        setAcusado( '' );
        setReferencia( '' );
    }

    const handleClearPromocion = () => {
      
        setTipoTramite( 0 );
        setMateria( 0 );
        setDistritoJudicial( 0 );

        setTipoJuicio( 0 );
        setJuicio( '' );

        setOficialia( 0 );
        setJuzgado( 0 );

        setTipoAudiencia( 0 );
        setImputado( '' );
        setAcusado( '' );
        setReferencia( '' );
        setEscrito(null);
        
        anexos.current = [];
        refDropzoneEscrito.current.files = [];
        refDropzoneAnexos.current.files = [];
    }

    useEffect(() => {
        
        async function Obtener(){
            
            await ObtenerTipoTramiteUsuarios( tipoUsuario ).then(response => {
                
                if( response ){

                    if( response.status === 200 ){
                        setTipoTramiteArray( response.data );
                    }
                }
                
            });

        }

        Obtener();

    }, [ tipoUsuario ]);

    useEffect(() => {
        
        async function Obtener(){

            setMateria( 0 );
            setMateriaArray( [] );
            
            await ObtenerTipoTramiteMaterias( tipoTramite ).then(response => {
                
                if( response ){

                    if( response.status === 200 ){
                        setMateriaArray( response.data );
                    }

                }
                
            });

        }

        Obtener();

    }, [ tipoTramite ]);
    
    useEffect(() => {
        
        async function Obtener(){

            setDistritoJudicial( 0 );
            setDistritoJudicialArray( [] );
            
            await ObtenerMateriaDistritos( { idMateria: materia, idTipoTramite: tipoTramite } ).then(response => {
                
                if( response ){

                    if( response.status === 200 ){
                        setDistritoJudicialArray( response.data );
                    }

                }
                
            });

        }

        Obtener();

    }, [ tipoTramite, materia ]);

    useEffect(() => {
        
        async function Obtener(){

            setOficialiaMateriaArray( [] );
            
            await ObtenerOficialiaMateria({ idMateria: materia, idDistritoJudicial : distritoJudicial }).then(response => {
                
                if( response ){

                    if( response.status === 200 ){
                        setOficialiaMateriaArray( response.data );
                    }

                }
                
            });

        }

        Obtener();

    }, [ distritoJudicial, materia ]);

    useEffect(() => {
        
        async function Obtener(){

            setJuzgadoArray( [] );
            
            await ObtenerJuzgadosDistritoMateria({ idMateria: materia, idDistritoJudicial : distritoJudicial, idTipoTramite: tipoTramite })
            .then(response => {
                
                if( response ){

                    if( response.status === 200 ){
                        setJuzgadoArray( response.data );
                    }

                }
                
            });

        }

        if( tipoTramite !== 0 && distritoJudicial !== 0 && materia !== 0 ){
            Obtener();
        }
        

    }, [ tipoTramite, distritoJudicial, materia ]);

    useEffect(() => {
        
        async function Obtener(){
            
            await ObtenerTipoJuicioMaterias(materia).then(response => {
                
                if( response ){

                    if( response.status === 200 ){

                        setTipoJuicioArray( response.data );
                    }

                }
                
            });

        }

        Obtener();

    }, [ materia ]);

    useEffect(() => {
        
        async function Obtener(){
            
            await ObtenerTiposAudiencia().then(response => {
                
                if( response ){

                    if( response.status === 200 ){
                        setTipoAudienciaArray( response.data );
                    }

                }
                
            });

        }

        Obtener();

    }, [ ]);

    return (
        <>

            {
                alert.msg && (
                    <Snackbar open={ alert.open } anchorOrigin={{ vertical: 'bottom',horizontal: 'right' }} autoHideDuration={ 4000 } onClose={ () => setAlert( { open:false }) }>
                        <Alert onClose={ () => setAlert( { open:false }) } variant= "filled" severity={ alert.type } sx={{ width: '100%' }}  >
                            { alert.msg }
                        </Alert>
                    </Snackbar>
                )
            }

            <Grid container spacing={3} sx={{ mt: 1 }}>

                <Grid item md={4} xs={12} >   

                    <FormControl fullWidth>

                        <InputLabel id='select-tipoTramite-label'>Tipo de trámite</InputLabel>

                        <Select
                            labelId='select-tipoTramite-label'
                            id='select-tipoTramite'                                            
                            label='Tipo de Trámite'
                            name='tipoTramite'
                            value={ tipoTramite }                                           
                            onChange={ ( e ) => { setTipoTramite( e.target.value); handleClear(); } }
                        >
                            <MenuItem value={0}>Seleccione una opción</MenuItem>
                            {
                                tipoTramiteArray.map( (elem) => (
                                    <MenuItem key={ elem.idTipoTramite} value={ elem.idTipoTramite}> {elem.descripcion} </MenuItem>
                                ))
                            }
                            
                        </Select>               

                    </FormControl>

                    {   
                        errors.tipoTramite && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.tipoTramite } </Alert> )
                    }

                </Grid>  

                <Grid item md={4} xs={12} >   
                 
                    <FormControl fullWidth>

                        <InputLabel id='select-materia-label'>Materia</InputLabel>

                        <Select
                            labelId='select-materia-label'
                            id='select-materia'                                            
                            label='Materia'
                            name='materia'
                            value={ materia }                                           
                            onChange={ ( e ) => { setMateria( e.target.value ); setTipoJuicio( 0 ); setDistritoJudicial(0); setJuzgado(0); setOficialia(0); } }
                            disabled={ tipoTramite === 0 }
                        >
                        <MenuItem value={0}>Seleccione una opción</MenuItem>
                        {
                            materiaArray.map( (elem) => (
                                <MenuItem key={ elem.idMateria} value={ elem.idMateria}> {elem.descripcion} </MenuItem>
                            ))
                        }    
                            
                        </Select>               
                        
                    </FormControl>

                    {   
                        errors.materia
                        &&  
                            <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.materia } </Alert> 
                    }

                </Grid>
    
                <Grid item md={4} xs={12} >   
                    
                    <FormControl fullWidth >

                        <InputLabel id='select-distritoJudicial-label'>Distrito judicial</InputLabel>

                        <Select
                            labelId='select-distritoJudicial-label'
                            id='select-distritoJudicial'                                            
                            label='Distrito judicial'
                            name='distritoJudicial'
                            value={ distritoJudicial }                                           
                            onChange={ ( e ) => { setDistritoJudicial( e.target.value); setOficialia( 0 ); setJuzgado( 0 ); } }
                            disabled={ tipoTramite === 0 }
                        >
                            <MenuItem value={0}>Seleccione una opción</MenuItem>
                            {
                                distritoJudicialArray.map( (elem) => (
                                    <MenuItem key={ elem.idDistritoJudicial} value={ elem.idDistritoJudicial}> {elem.descripcion} </MenuItem>
                                ))
                            }
                            
                        </Select>               

                    </FormControl>
                    
                    {   
                        errors.distritoJudicial
                        &&
                            <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.distritoJudicial } </Alert>
                    }

                </Grid>  

                {
                    ( tipoTramite === 4 || tipoTramite === 5 || tipoTramite === 8 || tipoTramite === 9 ) 
                    &&
                        <Grid item md={6} xs={12} >   

                            <TextField 
                                name='numeroExpediente'
                                label={ ( tipoTramite === 8 || tipoTramite === 4 ) ? 'Número de exhorto' : 'Número de expediente'}
                                value={referencia}
                                onChange={ (e) => { setReferencia(e.target.value) } }
                                fullWidth
                                inputProps={{ maxLength: 9 }}
                                autoComplete='off'
                            />

                            {   
                                errors.referencia
                                &&
                                    <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.referencia } </Alert>
                            }

                        </Grid>  
                }

                {
                    ( tipoTramite === 10 ) 
                    &&
                        <Grid item md={6} xs={12} >   

                            <TextField 
                                name='numeroFolio'
                                label='Número de folio'
                                value={referencia}
                                onChange={ (e) => { setReferencia(e.target.value) } }
                                fullWidth
                                inputProps={{ maxLength: 9 }}
                                autoComplete='off'
                            />

                            {   
                                errors.referencia
                                &&
                                    <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.referencia } </Alert>
                            }

                        </Grid>    
                }
            
                {
                    ( ( tipoTramite === 1 || tipoTramite === 6 ) && ( materia === 1 || materia === 2 || materia === 3 || materia === 4 || materia === 5 || materia === 7 ) )
                    &&    
                    <Grid item md={6} xs={12} >   

                        <FormControl fullWidth>

                            <InputLabel id='select-tipoJuicio-label'>Tipo de juicio</InputLabel>

                            <Select
                                labelId='select-tipoJuicio-label'
                                id='select-tipoJuicio'                                            
                                label='Tipo de juicio'
                                name='tipoJuicio'
                                value={ tipoJuicio }                                           
                                onChange={ ( e ) => { setTipoJuicio(e.target.value); setJuicio( '' );  } }
                            >
                                <MenuItem value={0}>Seleccione una opción</MenuItem>
                                {
                                    tipoJuicioArray.map( (elem) => (
                                        <MenuItem key={ elem.idTipoJuicio } value={elem.idTipoJuicio}> {elem.descripcion} </MenuItem>
                                    )
                                    )
                                }
                                
                            </Select>         
                            
                            {   
                                errors.tipoJuicio 
                                && 
                                ( 
                                    <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.tipoJuicio } </Alert> 
                                )
                            } 

                        </FormControl>
                    
                    </Grid>  
                }
                
                { 
                    ( ( tipoJuicio === 171 || tipoJuicio === 172 || tipoJuicio === 173 || tipoJuicio === 174 || tipoJuicio === 175 ) )
                    && 
                    <Grid item md={6} xs={12} >  

                        <TextField 
                            name='descripcionJuicio'
                            label='Descripción del juicio'
                            value={juicio}
                            onChange={ (e) => { setJuicio(e.target.value)} }
                            fullWidth
                        />

                        {   
                            errors.juicio 
                            && 
                            ( 
                                <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.juicio } </Alert> 
                            )
                        } 

                    </Grid>
                }
            
                {
                    ( (tipoTramite === 1 || tipoTramite === 6) && distritoJudicial !== 0 ) 
                    &&   
                    <Grid item md={12} xs={12} >   

                        <FormControl fullWidth>

                            <InputLabel id='select-oficialia-label'>Oficialia</InputLabel>

                            <Select
                                labelId='select-oficialia-label'
                                id='select-oficialia'                                            
                                label='Oficialia'
                                name='oficialia'
                                value={ oficialia }                                           
                                onChange={ ( e ) => { setOficialia(e.target.value) } }
                            >
                                <MenuItem value={0}>Seleccione una opción</MenuItem>
                                {
                                    oficialiaMateriaArray.map( (elem) => (
                                        <MenuItem key={ elem.idOficialia} value={ elem.idOficialia}> {elem.descripcion} </MenuItem>
                                    ))
                                }

                            </Select>              

                            {   
                                errors.oficialia 
                                && 
                                ( 
                                    <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.oficialia } </Alert> 
                                )
                            } 

                        </FormControl>

                    </Grid>  
                }
                      
                {
                    ( tipoTramite !== 1 && tipoTramite !== 6 && distritoJudicial !== 0 ) 
                    &&   
                    <Grid item md={12} xs={12} > 

                        <FormControl fullWidth>

                            <InputLabel id='select-juzgado-label'>Juzgado</InputLabel>

                            <Select
                                labelId='select-juzgado-label'
                                id='select-juzgado'                                            
                                label='Juzgado'
                                name='juzgado'
                                value={ juzgado }                                           
                                onChange={ (e ) => { setJuzgado(e.target.value) } }
                            >
                                <MenuItem value={0}>Seleccione una opción</MenuItem>
                                {
                                    juzgadoArray.map( (elem) => (
                                        <MenuItem key={ elem.idJuzgado} value={ elem.idJuzgado}> {elem.descripcion} </MenuItem>
                                    ))
                                }
                                
                            </Select>               

                        </FormControl>

                        { 
                            errors.juzgado 
                            && 
                            (
                                <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.juzgado } </Alert>
                            ) 
                        }

                    </Grid>
                }

                {
                    ( tipoTramite === 12 && juzgado !== 0 ) 
                    &&
                        <Grid item md={12} xs={12} >   

                            <FormControl fullWidth>

                                <InputLabel id='select-tipoAudiencia-label'>Tipo de audiencia</InputLabel>

                                <Select
                                    labelId='select-tipoAudiencia-label'
                                    id='select-tipoAudiencia'                                            
                                    label='Tipo de audiencia'
                                    name='tipoAudiencia'
                                    value={ tipoAudiencia }                                           
                                    onChange={ ( e ) => { setTipoAudiencia(e.target.value) } }
                                >
                                    <MenuItem value={0}>Seleccione una opción</MenuItem>
                                    {
                                        tipoAudienciaArray.map( (elem) => (
                                            <MenuItem key={elem.idTipoAudiencia} value={elem.idTipoAudiencia} >{ elem.descripcion }</MenuItem>
                                        ))
                                    }
                                    
                                </Select>               

                            </FormControl>

                            { 
                                errors.tipoAudiencia 
                                && 
                                (
                                    <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.tipoAudiencia } </Alert>
                                ) 
                            }

                        </Grid>  
                }

                {
                    ( ( tipoTramite === 11 || tipoTramite === 12 ) && juzgado !== 0  ) 
                    &&
                        <Grid item md={4} xs={12} >   

                            <TextField 
                                name='numerocausa'
                                label='Número único de causa'
                                value={ referencia }
                                onChange={ ( e ) => { setReferencia( e.target.value ) } }
                                fullWidth
                            />

                            { 
                                errors.numeroCausa 
                                && 
                                (
                                    <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.numeroCausa } </Alert>
                                ) 
                            }

                        </Grid>  
                }

                {
                    ( ( tipoTramite === 11 || tipoTramite === 12 ) && juzgado !== 0  ) 
                    &&
                        <Grid item md={4} xs={12} >   

                            <TextField 
                                name='nombreImputado'
                                label='Nombre del imputado'
                                value={imputado}
                                onChange={ (e) => { setImputado(e.target.value)  } }
                                fullWidth
                            />

                            { 
                                errors.imputado 
                                && 
                                (
                                    <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.imputado } </Alert>
                                ) 
                            }

                        </Grid>  
                }

                {
                    ( ( tipoTramite === 7 || tipoTramite === 3 ) && juzgado !== 0  ) 
                    &&
                        <Grid item md={4} xs={12} >   

                            <TextField 
                                name='numerocausapenal'
                                label='Número de causa penal'
                                value={ referencia }
                                onChange={ ( e ) => { setReferencia( e.target.value ) } }
                                fullWidth
                            />

                            { 
                                errors.numeroCausaPenal 
                                && 
                                (
                                    <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.numeroCausaPenal } </Alert>
                                ) 
                            }

                        </Grid>  
                }

                {
                    ( tipoTramite === 7 && juzgado !== 0 ) 
                    &&   
                        <Grid item md={4} xs={12} >   

                            <TextField 
                                name='nombreAcusado'
                                label='Nombre del acusado'
                                value={acusado}
                                onChange={ (e) => { setAcusado(e.target.value) } }
                                fullWidth
                            />

                            { 
                                errors.acusado 
                                && 
                                (
                                    <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.acusado } </Alert>
                                ) 
                            }

                        </Grid>  
                }
            
                {
                    ( ( tipoTramite === 1 || tipoTramite === 6 ) && oficialia !== 0 )
                    &&  
                    <Grid item xs={12} >  

                        <Card>

                            <CardHeader sx={{ backgroundColor: (theme) => theme.palette.primary.main }} subheader={ 
                                <Typography variant='subtitle1' sx={{ fontSize: 16, color: 'white' }}> Nota Importante: </Typography> 
                            } />

                            <CardContent>
                                
                                <Typography sx={{ textAlign: 'justify' }} color="text.primary">
                                    Se deberá presentar la demanda en físico en la oficialía de partes correspondiente al distrito judicial y la materia en un término no mayor a 3 días hábiles.
                                </Typography>

                            </CardContent>

                        </Card>

                    </Grid>
                }

                <Grid item md={12} xs={12} sx={{ mt: 5 }}>

                    <Grid container spacing={3}>

                        <Grid item md={6} xs={12}>

                            <Grid container spacing={3}>

                                <Grid item md={12} xs={12} >

                                    <Typography variant='body2' sx={{ fontWeight: 'bold', fontSize: 22, mb: 1 }}>
                                        Cargar escrito
                                    </Typography>

                                    <Typography variant='body2' sx={{ fontSize: 14, mb: 1 }}>
                                        Para el correcto procesamiento, la promoción deberá estar en formato .PDF y no podrá pesar más de 64mb.
                                    </Typography>

                                    <Divider />

                                </Grid>

                                <Grid item md={12} xs={12} >

                                    <Dropzone
                                        onChangeStatus={ handleChangeEscrito }
                                        ref={ ( ref ) =>  refDropzoneEscrito.current = ref }
                                        accept="application/pdf"
                                        maxSizeBytes={64000000}
                                        inputContent={ 
                                            <Typography key={0} variant='body2' sx={{ textAlign: 'center' }}>
                                                <strong style={{ fontSize: 18 }}>Añadir escrito.</strong>
                                            </Typography> 
                                        }
                                        maxFiles={1}
                                        SubmitButtonComponent={null}
                                        styles={{
                                            dropzone: { border: '1px dashed #252B50' },
                                            inputLabel:{ color: '#252B50', fontSize: 14, padding: '15px' }
                                        }}
                                    />

                                </Grid>

                            </Grid>

                        </Grid>

                        <Grid item md={6} xs={12}>

                            <Grid container spacing={3}>
            
                                <Grid item md={12} xs={12} >

                                    <Typography variant='body2' sx={{ fontWeight: 'bold', fontSize: 22, mb: 1 }}>
                                        Anexo(s)
                                    </Typography>

                                    <Typography variant='body2' sx={{ fontSize: 14, mb: 1 }}>
                                        Los anexos deberán cargarse en formato .PDF de manera independiente y no podrán pesar más de 64mb en conjunto.
                                    </Typography>

                                    <Divider />

                                </Grid>

                                <Grid item md={12} xs={12} >

                                    <Dropzone
                                        ref={ ( ref ) =>  refDropzoneAnexos.current = ref }
                                        onChangeStatus={ handleChangeAnexos }
                                        accept="application/pdf"
                                        maxSizeBytes={64000000}
                                        inputContent={ 
                                            <Typography key={0} variant='body2' sx={{ textAlign: 'center' }}>
                                                <strong style={{ fontSize: 18 }}>Añadir anexos.</strong>
                                            </Typography> 
                                        }
                                        styles={{
                                            dropzone: { border: '1px dashed #252B50' },
                                            inputLabel:{ color: '#252B50', fontSize: 14, padding: '15px' }
                                        }}
                                    />

                                </Grid>

                            </Grid>

                        </Grid>
                    
                    </Grid>
                
                </Grid>
        
                <Grid item md={12} xs={12} display='flex' flexDirection='column' alignItems='center' sx={{ mt: 5 }} >   

                    <LoadingButton
                        variant='contained'
                        onClick={  SolicitarCodigo }
                        color='primary'
                        startIcon={ <SendIcon /> }
                        disabled={ tipoTramite === 0 || escrito === null }
                        loading={ loadingSave }
                    >
                        Firmar y enviar
                    </LoadingButton>

                </Grid>  

            </Grid>

            <Dialog open={ openModal } maxWidth='sm' fullWidth={true}>       
                        
                <DialogContent>

                    <Box sx={{ ml: 2, mr: 2, mb: 5, mt: 2, textAlign: 'center' }} >

                        <Typography variant='h6' sx={{ mb: 5, fontWeight: 'bold', }}> 
                            {  
                                tokenUnicoActivado
                                ?
                                    'Ingrese su token único configurado desde la App Poder en Líne@'
                                :
                                    'Ingrese el token dinámico generado desde la App Token'                        
                            }
                            
                        </Typography> 

                        {  
                                tokenUnicoActivado
                            ?
                                <AuthCode 
                                    ref={ AuthInputRef }
                                    inputClassName='input' 
                                    isPassword={ false }
                                    length={4} 
                                    onChange={ ( value ) => setTokenUnico( value ) } 
                                />        
                            :
                                <AuthCode 
                                    ref={ AuthInputRef }
                                    inputClassName='input' 
                                    isPassword={ false }
                                    length={6} 
                                    onChange={ ( value ) => setCodigo( value ) } 
                                />                 
                        }     

                        {
                            errors.codigoInvalido 
                            && 
                            (
                                <Alert color='warning' severity="warning" variant="filled" sx={{ mt: 5 }} > { errors.codigoInvalido } </Alert>
                            ) 
                        }

                    </Box>

                </DialogContent>

                <DialogActions>
                
                    <Button onClick={ handleCloseModal } disabled={ loadingRegistrar } > Cancelar </Button>
                    <LoadingButton 
                        onClick={ handleGuardar } 
                        variant='contained' 
                        startIcon={ <SaveIcon /> } 
                        disabled={ 
                            tokenUnicoActivado
                            ?
                                tokenUnico === '' || tokenUnico.length < 4
                            :                            
                                codigo === '' || codigo.length < 6 
                        }
                        loading={ loadingRegistrar }
                    > 
                        Verificar token 
                    </LoadingButton>
                
                </DialogActions>

                <LinearProgress sx={{ display: loadingRegistrar ? 'flex' : 'none' }} />

            </Dialog>

            <Dialog 
                open={ openAcuse } 
                onClose={ () => {} } 
                fullWidth 
                maxWidth="md" 
            >

                <DialogTitle>
                    Acuse de Envío
                </DialogTitle>
                
                <DialogContent>
                    
                    <Grid container spacing={3} >                                                
                    
                            <Grid item md={12} xs={12} sx={{ display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
                                
                                <iframe title='acuseEnvio' src={ acuseEnvio } height="500px" width="100%"></iframe>

                            </Grid>
                            
                        </Grid>
                    
                </DialogContent>

                <DialogActions>

                    <Button color="primary" onClick={ () => { setOpenAcuse( false ); } }>
                        Cerrar
                    </Button>

                </DialogActions>                

            </Dialog> 

        </>
    );

}
