import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { Alert, Box, Button, CircularProgress, Container, Dialog, DialogActions, DialogContent, Grid, IconButton, LinearProgress, Paper, Switch, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, 
  TableRow, TextField, Toolbar, Typography 
} from '@mui/material';

import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import UndoIcon from '@mui/icons-material/Undo';

import { NavbarInicio } from '../ui/NavbarInicio';

import { usePages } from '../../hooks/usePages';

import { TablePaginationActions } from '../../helpers/TablePaginationActions';

import { ActualizarTipoAsentamiento, AgregarTipoAsentamiento, TiposAsentamientos } from '../../actions/catalogos/tipoAsentamientoActions';

import { usePermisos } from '../../hooks/usePermisos';

import { NotFoundScreen } from '../auth/NotFoundScreen';
   
export const TipoAsentamientoScreen = ( ) => {

    const { tipoUsuario, loading } = usePermisos();

    const navigate = useNavigate();

    const [errors, setErrors] = useState({ });

    const [rows, setRows] = useState( [ ] );

    const [descripcion, setDescripcion] = useState( '' );
    const [idReferencia, setIdReferencia] = useState( '' );
    const [opcion, setOpcion] = useState( '' );

    const [loadingTable, setLoadingTable] = useState( true );
    const [loadingSave, setLoadingSave] = useState( false );
    const [openModal, setOpenModal] = useState( false );
  
    const [page, rowsPerPage, handleChangePage, handleChangeRowsPerPage] = usePages();
  
    const handleChangeSwitch = ( id, value ) => {

        const newRows = rows.map( (row) => { if(row.idTipoAsentamiento === id){ row.activo = value; return row; } else{ return row; } } );
        setRows( newRows );        

        const tipoAsentamiento = rows.filter( function(row){ return row.idTipoAsentamiento === id; } );
        cambiarEstatus( tipoAsentamiento[0] );
    }

    const handleOpenModal = () => {
        setOpenModal(true);
    }

    const handleCloseModal = () => {
        setOpenModal(false);
        setErrors( { } );
    }

    const cambiarEstatus = async ( { idTipoAsentamiento, clave, descripcion, activo } ) => {

        const params = {
            idTipoAsentamiento,
            descripcion,
            activo,
            clave,
        };

        await ActualizarTipoAsentamiento( params );
    }

    const AgregarRegistro = () => {

        setDescripcion( '' );

        setOpcion( 'agregar' );
        setIdReferencia( 0 );

        handleOpenModal();
    }

    const EditarRegistro = ( id, descripcion ) => {
        
        setDescripcion( descripcion );

        setOpcion( 'editar' );
        setIdReferencia( id );

        handleOpenModal();
    }

    const Guardar = async () => {

        const valid = validateFiels();

        if( !valid ){
            return false;
        }

        setLoadingSave( true );

        if( opcion === 'agregar' ){
            const params = {
                idTipoAsentamiento: 0,
                descripcion: descripcion.trim(),
                activo: true,
                clave: '',
            };

            await AgregarTipoAsentamiento( params ).then( response => {
                if( response ){
                    if( response.status === 200){
                        if( response.data ){

                            setTimeout(() => {
                                
                                setRows([ 
                                    ...rows,  
                                    {
                                        idTipoAsentamiento: response.data.idTipoAsentamiento,
                                        descripcion: descripcion,
                                        activo: true
                                    }
                                ]);  
    
                                handleCloseModal();

                                setLoadingSave( false );

                            }, 1000);
                            
                        } 
                    }
                }
            });
        }

        if( opcion === 'editar' ){
            const tipoAsentamiento = rows.filter( function(row){ return row.idTipoAsentamiento === idReferencia; } )[0];

            const params = {
                idTipoAsentamiento: idReferencia,
                descripcion: descripcion.trim(),
                activo: tipoAsentamiento.activo,
                clave: tipoAsentamiento.clave,
            };
    
            await ActualizarTipoAsentamiento( params ).then( response => {
                if( response ){
                    if( response.status === 200){
                        
                        setTimeout(() => {

                            const newRows = rows.map( (row) => { 
                                if(row.idTipoAsentamiento === idReferencia){ 
                                    row.descripcion = descripcion; 
                                    return row; 
                                } 
                                else{ 
                                    return row; 
                                } 
                            });

                            setRows( newRows ); 
                            
                            handleCloseModal();

                            setLoadingSave( false );

                        }, 1000);

                    }
                }                
            });
        }
    }

    const validateFiels = () => {
        
        setErrors( { } );

        let valid = true;
        let errores = {};

        if( descripcion.trim() === '' ){
            valid = false;
            errores.descripcion = 'Debes escribir la descripción';
        }        
        
        setErrors( errores );
        return valid;
    } 

    useEffect(() => {
        
        async function Obtener(){
            
            await TiposAsentamientos().then(response => {
                
                if( response ){
                    
                    if( response.status === 200 ){

                        setTimeout(() => {
                            
                            setLoadingTable( false );
                            setRows( response.data );
                            
                        }, 1000);
                        
                    }

                }
                
            });

        }

        Obtener();

    }, [ ]);

    return (
        <>
            <NavbarInicio />

            {
                ( !loading && tipoUsuario !== 3 )
                &&
                <NotFoundScreen />
            }

            {
                ( !loading && tipoUsuario === 3  )
                &&   
                <>

                    <Container maxWidth='xl' sx={{ mt: { md: '13em', xs: '11em' } }}>

                        <Box sx={{ pb: '2em', pl: { md: 10, xs: 1 }, pr: { md: 10, xs: 1 }, pt: 2 }} component={ Paper } >	

                            <Container maxWidth='lg'>

                                <Grid container spacing={3} sx={{ mt: 1 }} >  
                                
                                    <Grid item md={6} xs={6} sx={{ textAlign: 'left' }}> 
                                        <Button type="button" startIcon={ <UndoIcon /> } variant='contained' color='inherit' sx={{ mt: 1 }} onClick={ () => navigate('/administracion/') }> 
                                            Regresar a los catálogos
                                        </Button> 
                                    </Grid>

                                    <Grid item md={6} xs={6} sx={{ textAlign: 'right' }}> 
                                        <Button type="button" startIcon={ <AddCircleOutlineRoundedIcon /> } variant="contained" sx={{ mt: 1 }} onClick={ AgregarRegistro }> 
                                            Agregar
                                        </Button> 
                                    </Grid>

                                </Grid>

                                <Grid container spacing={3} sx={{ mt: 1 }} >  
                                    
                                    <Grid item xs={12} md={12}> 

                                        <Toolbar sx={{ pl: { sm: 2 }, pr: { xs: 1, sm: 1 } }} >
                                            <Typography sx={{ flex: '1 1 100%' }} variant="h6" component="div" >
                                                Tipo asentamiento
                                            </Typography>
                                        </Toolbar>

                                        <TableContainer>
                                            <Table sx={{ minWidth: 500 }} >
                                                <TableHead>
                                                    <TableRow>        
                                                        <TableCell style={{ width:'10%' }}> ID </TableCell>
                                                        <TableCell style={{ width:'70%' }}> DESCRIPCIÓN </TableCell>
                                                        <TableCell style={{ width:'10%' }}> HABILITADO </TableCell>
                                                        <TableCell style={{ width:'10%' }}> EDITAR </TableCell>
                                                    </TableRow>
                                                </TableHead>

                                                <TableBody>
                                                {
                                                (rowsPerPage > 0
                                                    ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                    : rows
                                                ).map((row) => (
                                                    <TableRow key={ row.idTipoAsentamiento} >
                                                    
                                                        <TableCell style={{ fontWeight: 'bold' }}>
                                                            { row.idTipoAsentamiento }
                                                        </TableCell>

                                                        <TableCell>
                                                            { row.descripcion }
                                                        </TableCell>
                                                        
                                                        <TableCell>
                                                            {
                                                                row.activo 
                                                                ? 
                                                                (
                                                                    <Switch 
                                                                        inputProps={ { 'aria-label': 'Switch demo'} } 
                                                                        checked={ row.activo } 
                                                                        onChange={ ( e ) => handleChangeSwitch( row.idTipoAsentamiento, e.target.checked) }
                                                                    />
                                                                ) 
                                                                :                                           
                                                                ( 
                                                                    <Switch 
                                                                        inputProps={ { 'aria-label': 'Switch demo'} } 
                                                                        checked={ row.activo } 
                                                                        onChange={ ( e ) => handleChangeSwitch( row.idTipoAsentamiento, e.target.checked) }
                                                                    />
                                                                )
                                                            }
                                                        </TableCell>

                                                        <TableCell>
                                                            <IconButton 
                                                                    color="primary" 
                                                                    aria-label="edit" 
                                                                    component="span" 
                                                                    onClick={ () => EditarRegistro( row.idTipoAsentamiento, row.descripcion ) }
                                                                >                                                        
                                                                <EditIcon />
                                                            </IconButton>  
                                                        </TableCell>

                                                    </TableRow>
                                                ))}

                                                { 
                                                    rows.length === 0 && (
                                                        <TableRow style={{ height: 53 }}>

                                                            <TableCell colSpan={10} align="center"> 
                                                                {
                                                                    loadingTable
                                                                    ?
                                                                        <CircularProgress size={40} sx={{ mt: 2, mb: 2 }} />
                                                                    :
                                                                        ''
                                                                }
                                                                {
                                                                    !loadingTable && rows.length === 0
                                                                    ?
                                                                        'No se encontraron usuarios'
                                                                    :
                                                                        ''
                                                                }
                                                            </TableCell>

                                                        </TableRow>
                                                    )
                                                }

                                            </TableBody>

                                                <TableFooter>
                                                    <TableRow>
                                                        <TablePagination
                                                            rowsPerPageOptions={[ 5, 10, 15, 20, 50, 100]}
                                                            colSpan={4}
                                                            count={rows.length}                                        
                                                            rowsPerPage={rowsPerPage}
                                                            page={page}                                                                                
                                                            onPageChange={ handleChangePage }
                                                            onRowsPerPageChange={ handleChangeRowsPerPage }
                                                            ActionsComponent={ TablePaginationActions }

                                                            labelRowsPerPage="Filas por página"                                            
                                                            labelDisplayedRows={
                                                                ({ from, to, count }) => {
                                                                return '' + from + '-' + to + ' de ' + count
                                                                }
                                                            }  
                                                        />
                                                    </TableRow>
                                                </TableFooter>
                                            </Table>
                                        </TableContainer>
                                        

                                    </Grid>

                                </Grid>

                            </Container>

                        </Box>

                    </Container>

                    <Dialog open={ openModal } maxWidth='sm' fullWidth={true}>       
                    
                        <DialogContent>

                            <TextField
                                sx={{ mt: 1 }}
                                label='Descripción'
                                variant="outlined"      
                                autoFocus                          
                                multiline
                                rows={4}                                        
                                fullWidth
                                value={ descripcion }
                                onChange={ ( e ) => { setDescripcion( e.target.value ) } }
                            />

                            {   
                                errors.descripcion && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.descripcion } </Alert> )
                            }

                        </DialogContent>

                        <DialogActions>
                            <Button onClick={ handleCloseModal }> Cancelar </Button>
                            <Button onClick={ Guardar } variant='contained' startIcon={ <SaveIcon /> } > Guardar </Button>
                        </DialogActions>

                        <LinearProgress sx={{ display: loadingSave ? 'flex' : 'none' }} />

                    </Dialog>

                </>

            }

        </>
    )
}