import httpClient from "../../services/httpClient";

export const obtenerUltimosAcuerdos = () => {

    return new Promise( (resolve, eject) => {

        httpClient.get('https://www.pjecz.gob.mx/feeds/acuerdos-del-consejo.rss.xml')
        .then( response => {
            if( response.status === 200 ){
                resolve( response );
            }
        })
        .catch( (error) => {
            resolve( error.response );
        });

    });

}