import React, { useState } from 'react';

import { Box, Tab, Tabs } from '@mui/material';

import PropTypes from 'prop-types';

import { BoletasEnviadasScreen } from './BoletasEnviadasScreen';
import { BusquedaScreen } from './BusquedaScreen';

function TabPanel( props ){
    
    const { children, value, index, ...other } = props;
  
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};
  
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export const TabsUsuarioInternoScreen = () => {

    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', mt: 2 }}>
            
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" variant='scrollable' scrollButtons='auto' allowScrollButtonsMobile >
                    
                    <Tab label="Boletas enviadas" {...a11yProps(0)} />
                    <Tab label="Busqueda" {...a11yProps(1)} />

                </Tabs>

            </Box>

            <TabPanel value={value} index={0}>

                <BoletasEnviadasScreen />

            </TabPanel>

            <TabPanel value={value} index={1}>

                <BusquedaScreen />

            </TabPanel>
        </>
    )

}
