import React, { useState } from 'react';

import { Box, Container, Paper, Tab, Tabs } from '@mui/material';

import PropTypes from 'prop-types';

import { NavbarInicio } from '../ui/NavbarInicio';

import { SolicitudesPendientesScreen } from './SolicitudesPendientesSreen';
import { BusquedaScreen } from './BusquedaScreen';
import { NotFoundScreen } from '../auth/NotFoundScreen';

import { usePermisos } from '../../hooks/usePermisos';

function TabPanel( props ){
    
    const { children, value, index, ...other } = props;
  
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};
  
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export const SolicitudesScreen = () => {
   
    const { tipoUsuario, loading } = usePermisos();

    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            <NavbarInicio />

            {
                ( !loading && tipoUsuario !== 3 )
                &&
                <NotFoundScreen />
            }

            {
                ( !loading && tipoUsuario === 3  )
                &&     

                <Container maxWidth='xl' sx={{ mt: { md: '10em', xs: '8em' }, mb: 5 }}>

                    <Box sx={{ pb: 2, pl: { md: 8, xs: 1 }, pr: { md: 8, xs: 1 }, pt: 2 }} component={ Paper } >	

                        <Box sx={{ borderBottom: 1, borderColor: 'divider', mt: 2 }}>
                        
                            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" variant='scrollable' scrollButtons='auto' allowScrollButtonsMobile >
                                
                                <Tab label="Solicitudes pendientes" {...a11yProps(0)} />
                                <Tab label="Busqueda" {...a11yProps(1)} />

                            </Tabs>

                        </Box>

                        <TabPanel value={value} index={0}>

                            <SolicitudesPendientesScreen />

                        </TabPanel>

                        <TabPanel value={value} index={1}>

                            <BusquedaScreen />

                        </TabPanel>

                    </Box>

                </Container>

            }

        </>
    )

}
