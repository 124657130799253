import React, { useState, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Grid, IconButton, InputBase, List, ListItem, ListItemAvatar, ListItemText, ListSubheader, Paper, Tab, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, Tabs, Typography } from '@mui/material';

import { LoadingButton } from '@mui/lab';

import ArtTrackIcon from '@mui/icons-material/ArtTrack';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import InboxIcon from '@mui/icons-material/Inbox';
import SourceIcon from '@mui/icons-material/Source';
import SearchIcon from '@mui/icons-material/Search';

import { usePages } from '../../hooks/usePages';

import { TablePaginationActions } from '../../helpers/TablePaginationActions';

import { ArchivarCausaPenal, ArchivarExpediente, BuscarExpedientes, ObtenerMisExpedientes, VerMisCausasPenales, VerMisExpedientes } from '../../actions/expediente/expedienteVirtualActions';

function TabPanel( props ) {

    const { children, value, index, ...other } = props;
  
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
            style={{ width: '100%', overflowY: 'scroll', height: 'calc(80vh - 150px)' }}
        >
            { value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

export const MisExpedientesScreen = () => {

    const navigate = useNavigate();

    const [page, rowsPerPage, handleChangePage, handleChangeRowsPerPage, resetPage] = usePages( 5 );

    const [open, setOpen] = useState( false );
    const [openExpedientes, setOpenExpedientes] = useState( false );
    const [openCausaPenal, setOpenCausaPenal] = useState( false );
    const [openResultados, setOpenResultados] = useState( false );

    const [loading, setLoading] = useState( true );
    const [loadingDataTable, setLoadingDataTable] = useState( true );
    const [loadingResultados, setLoadingResultados] = useState( false );
    
    const [data, setData] = useState( [] );
    const [dataTable, setDataTable] = useState( [] );
    const [resultados, setResultados] = useState( [] );

    const [value, setValue] = useState(0);

    const [recordsCount, setRecordsCount] = useState(0);
    const [filtroAplicado, setFiltroAplicado] = useState(false);

    const [referencia, setReferencia] = useState('');

    const [penal, setPenal] = useState( false );

    const [refId, setRefId] = useState( '' );

    const [filtroAplicadoPenal, setFiltroAplicadoPenal] = useState(false);

    const [reload, setReload] = useState(false);

    const [loadingArchivar, setLoadingArchivar] = useState(false);

    const [buscarExpediente, setBuscarExpediente] = useState( '' );
    const [sinResultados, setSinResultados] = useState( '' );

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleViewData = ( idJuzgado, esPenal ) => {
       
        setReferencia( idJuzgado );
        setPenal( esPenal );

        if( esPenal ){
            setFiltroAplicadoPenal( true );
        }
        else{
            setFiltroAplicado( true ); 
        }
        
        setOpenExpedientes( true );  

    }

    const handleClose = () => {
        
        setDataTable( [] );
        setReferencia( '' );
        setPenal( false );

        setFiltroAplicado( false );
        setFiltroAplicadoPenal( false );

        setOpenExpedientes( false );
        setOpen( false );
        setOpenCausaPenal( false );

        resetPage();

    }

    const handleArchivarExpediente = async () => {

        setLoadingArchivar( true );

        await ArchivarExpediente({ idExpedienteAsignado : refId, archivar: true }).then( response => {

            if( response ){

                if( response.status === 200 ){

                    const { success } = response.data;

                    if( success ){

                        setTimeout(() => {
                            
                            setReload( true );
                            handleClose();

                            setLoadingArchivar( false );

                        }, 1000);                        
                        
                    }

                }

            }

        });

    }
    
    const handleArchivarCausaPenal = async () => {

        setLoadingArchivar( true );

        await ArchivarCausaPenal({ idCausaPenalAsignada : refId, archivar: true }).then( response => {

            if( response ){

                if( response.status === 200 ){

                    const { success } = response.data;

                    if( success ){

                        setTimeout(() => {
                            
                            setReload( true );
                            handleClose();

                            setLoadingArchivar( false );

                        }, 1000);    

                    }

                }

            }

        });
        
    }

    const handleBuscarExpedientes = async () => {

        setLoadingResultados( true );

        await BuscarExpedientes({ numeroExpediente: buscarExpediente }).then( response => {

            if( response ){

                if( response.status === 200 ){

                    setTimeout(() => {

                        if( response?.data?.length !== 0 ){

                            setBuscarExpediente( '' );
                            setSinResultados( '' );
                            setResultados( response?.data ?? [] );
                            setOpenResultados( true );

                        }
                        else {
                            setBuscarExpediente( '' );
                            setSinResultados( 'No se encontraron resultados' );
                        }

                        setLoadingResultados( false );

                    }, 700);    

                }
                else {

                    setTimeout(() => {

                        setBuscarExpediente( '' );
                        setResultados( [] );
                        setSinResultados( 'No se encontraron resultados' );
                        setLoadingResultados( false );

                    }, 700);    

                }

            }

        });
        
    }

    useEffect(() => {
      
        async function Obtener(){    
            
            setLoading( true );

            await ObtenerMisExpedientes().then( response => {

                if( response ){

                    if( response.status === 200 ){      
                        
                        setTimeout(() => {
                        
                            setLoading( false );
                            setData( response.data );

                        }, 1000);                        
                        
                    }
                    else{
                        
                        setTimeout(() => {
                            
                            setLoading( false );

                        }, 1000);
                        
                    }
                }
                else{

                    setTimeout(() => {
                            
                        setLoading( false );

                    }, 1000);

                }
            })

        }     
        
        Obtener();  

    }, [ ])    

    useEffect(() => {
      
        async function Obtener(){    
            
            setLoading( true );

            await ObtenerMisExpedientes().then( response => {

                if( response ){

                    if( response.status === 200 ){      
                        
                        setTimeout(() => {
                        
                            setLoading( false );
                            setData( response.data );
                            setReload( false );

                        }, 1000);                        
                        
                    }
                    else{
                        
                        setTimeout(() => {
                            
                            setLoading( false );

                        }, 1000);
                        
                    }
                }
                else{

                    setTimeout(() => {
                            
                        setLoading( false );

                    }, 1000);

                }
            })

        }     
        
        if( reload ){
            Obtener();  
        }

    }, [ reload ]) 

    useEffect(() => {
      
        async function Obtener(){  
            
            setLoadingDataTable( true );
            setDataTable( [] );

            await VerMisExpedientes({ idJuzgado: referencia, page, rowsPerPage }).then( response => {

                if( response ){

                    if( response.status === 200 ){      
                        
                        setTimeout(() => {
                                      
                            setRecordsCount( response?.data?.recordsCount ?? 0 );
                            setDataTable( response?.data?.records ?? [] );
                            setLoadingDataTable( false );

                        }, 1000);                        
                        
                    }
                    else{
                        
                        setTimeout(() => {
                            
                            setLoadingDataTable( false );

                        }, 1000);
                        
                    }
                }
                else{

                    setTimeout(() => {
                            
                        setLoadingDataTable( false );

                    }, 1000);

                }
            })

        }     
        
        if( filtroAplicado ){
            Obtener();  
        }

    }, [ page, rowsPerPage, referencia, filtroAplicado ])  
    
    useEffect(() => {
      
        async function Obtener(){  
            
            setLoadingDataTable( true );
            setDataTable( [] );

            await VerMisCausasPenales({ idJuzgado: referencia, page, rowsPerPage }).then( response => {

                if( response ){

                    if( response.status === 200 ){      
                        
                        setTimeout(() => {
                                      
                            setRecordsCount( response?.data?.recordsCount ?? 0 );
                            setDataTable( response?.data?.records ?? [] );
                            setLoadingDataTable( false );

                        }, 1000);                        
                        
                    }
                    else{
                        
                        setTimeout(() => {
                            
                            setLoadingDataTable( false );

                        }, 1000);
                        
                    }
                }
                else{

                    setTimeout(() => {
                            
                        setLoadingDataTable( false );

                    }, 1000);

                }
            })

        }     
        
        if( filtroAplicadoPenal ){
            Obtener();  
        }

    }, [ page, rowsPerPage, referencia, filtroAplicadoPenal ])  
    
    return (
        <>
        
            <Box>

                <Grid container spacing={3}>

                    <Grid item md={4} xs={12} sx={{ textAlign: { xs: 'center', md: 'right'} }}>

                        <Paper
                            component="form"
                            sx={{ p: '6px', display: 'flex', alignItems: 'center' }}
                        >
                            <InputBase
                                sx={{ ml: 2, flex: 1 }}
                                placeholder="Buscar por número de expediente"
                                inputProps={{ 'aria-label': 'Buscar por número de expediente' }}
                                value={ buscarExpediente }
                                onChange={ (e) => setBuscarExpediente( e.target.value ) }
                                onKeyPress={ e => e.key === 'Enter' && e.preventDefault() }
                            />

                            <LoadingButton 
                                size='small'
                                variant='contained'
                                startIcon={ <SearchIcon /> }                                
                                loading={ loadingResultados }
                                disabled={ buscarExpediente === '' }
                                onClick={ () => handleBuscarExpedientes() }
                            >
                                Buscar                                
                            </LoadingButton>                    
                        </Paper>

                        <Typography variant='body2' color='white' textAlign='center' sx={{ mt: 2 }} >
                            { sinResultados }
                        </Typography>
                       
                    </Grid>
                    
                    <Grid item md={8} xs={12} sx={{ textAlign: { xs: 'center', md: 'right'} }}>

                        <Button
                            variant='contained'
                            sx={{ backgroundColor: '#fff' }}
                            color='inherit'
                            startIcon={ <Inventory2Icon /> }
                            onClick={ () => navigate('/expedienteVirtual/archivados/') }
                        >
                            Expedientes archivados
                        </Button>

                    </Grid>

                    <Grid item xs={12}>
                    
                        <Box sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex' }}>

                            <Tabs
                                orientation="vertical"
                                variant="scrollable"
                                allowScrollButtonsMobile
                                value={ value }
                                onChange={ handleChange }
                                aria-label="Vertical tabs example"
                                sx={{ borderRight: 1, borderColor: 'divider' }}
                            >                        

                                {
                                    loading 
                                    ?
                                       null
                                    :
                                        data?.map( ( elem, i ) => (
                                            <Tab 
                                                key={ elem.idDistritoJudicial } 
                                                label={`Distrito Judicial de ${ elem.distritoJudicial } (${ elem?.totalExpedientes })`}                                             
                                                sx={{ mt: 3, mb: 3 }} 
                                                {...a11yProps( i ) } 
                                            />
                                        ))
                                }

                            </Tabs>

                            {
                                loading 
                                ?
                                    <Grid container spacing={3} sx={{ justifyContent: 'center' }}>
                                        <CircularProgress size={40} sx={{ mt: 15, mb: 15 }} />
                                    </Grid>
                                :
                                    data?.map( ( elem, i ) => (
                                        <TabPanel key={ i } value={value} index={ i } >

                                            <Grid container spacing={3} sx={{ mt: 1 }}>

                                                {
                                                    elem?.materias?.map( ( item ) => (

                                                        <Grid key={ item?.idMateria } item xs={12}>   

                                                            <List
                                                                subheader={
                                                                    <ListSubheader component="div">
                                                                        { item?.materia } ({ item?.totalExpedientes })
                                                                    </ListSubheader>
                                                                }
                                                            >        

                                                                {
                                                                    item?.juzgados?.map( ( data ) => (
                                                                        <ListItem
                                                                            key={ data?.idJuzgado }
                                                                            secondaryAction={
                                                                                <Button 
                                                                                    edge="end" 
                                                                                    color='primary' 
                                                                                    variant='contained'
                                                                                    onClick={ () => handleViewData( data?.idJuzgado, data?.penal ) }
                                                                                >
                                                                                    <SourceIcon />
                                                                                </Button>
                                                                            }
                                                                        >
                                                                            <ListItemAvatar>
                                                                                <InboxIcon />
                                                                            </ListItemAvatar>
                                                                            <ListItemText>
                                                                                <Typography variant='subtitle2' sx={{ fontSize: 16, fontWeight: 'bold' }}>
                                                                                    { data?.juzgado } ({ data?.totalExpedientes })
                                                                                </Typography>
                                                                            </ListItemText>
                                                                        </ListItem>
                                                                    ))
                                                                }                                                       

                                                            </List>

                                                            <Divider />

                                                        </Grid>

                                                    ))
                                                }
                                                
                                            </Grid>

                                        </TabPanel>
                                    ))
                            }
                            
                        </Box>

                    </Grid>

                </Grid>

            </Box>

            <Dialog
                open={ open }
                onClose={ () => { } }
            >
                <DialogTitle>
                    Confirmación
                </DialogTitle>
               
                <DialogContent>

                    <DialogContentText sx={{ color: 'black' }}>
                        ¿Desea archivar el expediente seleccionado?
                    </DialogContentText>

                </DialogContent>

                <DialogActions>
                    <Button onClick={ () => { setOpen( false ) } }>Cancelar</Button>
                    <LoadingButton variant='contained' color='primary' onClick={ handleArchivarExpediente } loading={ loadingArchivar } >
                        Aceptar
                    </LoadingButton>
                </DialogActions>

            </Dialog>

            <Dialog
                open={ openCausaPenal }
                onClose={ () => { } }
            >
                <DialogTitle>
                    Confirmación
                </DialogTitle>
               
                <DialogContent>

                    <DialogContentText sx={{ color: 'black' }}>
                        ¿Desea archivar la causa seleccionada?
                    </DialogContentText>

                </DialogContent>

                <DialogActions>
                    <Button onClick={ () => { setOpenCausaPenal( false ) } }>Cancelar</Button>
                    <LoadingButton variant='contained' color='primary' onClick={ handleArchivarCausaPenal } loading={ loadingArchivar } >
                        Aceptar
                    </LoadingButton>
                </DialogActions>

            </Dialog>

            <Dialog
                open={ openExpedientes }
                onClose={ () => { } }
                maxWidth='lg'
                fullWidth
            >  
                <DialogContent>

                    {
                        loadingDataTable 
                        ?
                            <Grid container spacing={3} sx={{ justifyContent: 'center' }}>
                                <CircularProgress size={40} sx={{ mt: 15, mb: 15 }} />
                            </Grid>
                        :
                        <Grid container spacing={3} sx={{ justifyContent: 'center' }}>

                            <Grid item xs={12}>

                                {
                                    penal
                                    ?
                                        <TableContainer>

                                            <Table>
            
                                                <TableHead>
            
                                                    <TableRow>
            
                                                        <TableCell style={{ width: '5%' }}> CARPETA DE INVESTIGACIÓN </TableCell>
                                                        <TableCell style={{ width: '10%' }}> NÚMERO DE CAUSA </TableCell>
                                                        <TableCell style={{ width: '20%' }}> IMPUTADO(S) </TableCell>
                                                        <TableCell style={{ width: '20%' }}> VICTIMA(S)/OFENDIDO(S) </TableCell>
                                                        <TableCell style={{ width:'1%', textAlign:'center' }}> VER </TableCell>
                                                        <TableCell style={{ width:'1%', textAlign:'center' }}> ARCHIVAR </TableCell>
                                                        <TableCell style={{ width:'1%', textAlign:'center' }}> </TableCell>
            
                                                    </TableRow>
            
                                                </TableHead>
            
                                                <TableBody>
            
                                                    {
                                                        dataTable.map((row) => (
            
                                                            <TableRow key={ row.idCausaPenal } >
            
                                                                <TableCell> { row.nuc } </TableCell>
                                                                <TableCell> { row.nic } </TableCell>
                                                                <TableCell> { row.imputado } </TableCell>
                                                                <TableCell> { row.victima_Ofendido } </TableCell>

                                                                <TableCell style={{ width:'1%', textAlign:'center' }}>
                                                                    <IconButton
                                                                        href={`/expedienteVirtual/DetallePenal/${ row.idCausaPenal }`}
                                                                        target='_blank'
                                                                    >
                                                                        <ArtTrackIcon color='primary' fontSize='large' />
                                                                    </IconButton>
                                                                </TableCell>
                                                                
                                                                <TableCell style={{ width:'1%', textAlign:'center' }}>
                                                                    <IconButton 
                                                                        color='primary'
                                                                        onClick={ () => { setRefId( row?.idCausaPenalAsignada ); setOpenCausaPenal( true ); } } 
                                                                    >
                                                                        <BookmarkIcon />
                                                                    </IconButton>
                                                                </TableCell>                                                               
            
                                                            </TableRow>
            
                                                        ))
                                                    }

                                                </TableBody>
            
                                                <TableFooter>
            
                                                    <TableRow>
            
                                                        <TableCell colSpan={1}>
                                                            <Typography variant='body2' color='black' sx={{ fontWeight: 'bold' }}> Causas: {recordsCount} </Typography>
                                                        </TableCell>
            
                                                        <TablePagination
                                                            rowsPerPageOptions={[5, 10, 15, 20]}
                                                            colSpan={10}
                                                            count={recordsCount}
                                                            rowsPerPage={rowsPerPage}
                                                            page={page}
                                                            onPageChange={handleChangePage}
                                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                                            ActionsComponent={TablePaginationActions}
            
                                                            labelRowsPerPage="Filas por página"
                                                            labelDisplayedRows={
                                                                ({ from, to, count }) => {
                                                                    return '' + from + '-' + to + ' de ' + count
                                                                }
                                                            }
                                                        />
            
                                                    </TableRow>
            
                                                </TableFooter>
            
                                            </Table>
            
                                        </TableContainer>
                                    :
                                        <TableContainer>

                                            <Table>
                                                
                                                <TableHead>

                                                    <TableRow>        

                                                        <TableCell style={{ width:'10%' }}> FOLIO </TableCell>
                                                        <TableCell style={{ width:'10%' }}> EXPEDIENTE </TableCell>
                                                        <TableCell style={{ width:'45%', textAlign:'center' }}> PARTES (ACTOR vs DEMANDADO) </TableCell>
                                                        <TableCell style={{ width:'1%', textAlign:'center' }}> VER </TableCell>
                                                        <TableCell style={{ width:'1%', textAlign:'center' }}> ARCHIVAR </TableCell>
                                                        <TableCell style={{ width:'1%', textAlign:'center' }}> </TableCell>

                                                    </TableRow>

                                                </TableHead>

                                                <TableBody>

                                                    {
                                                        dataTable?.map( ( row ) => (                                                                                

                                                            <TableRow key={ row.idExpediente }>        

                                                                <TableCell style={{ width:'10%' }}> { row.folio !== '0' ? row.folio : '' } </TableCell>
                                                                
                                                                <TableCell style={{ width:'10%' }}> { row.numeroExpediente } </TableCell>
                                                                
                                                                <TableCell style={{ width:'35%', textAlign:'center' }}> 
                                                                    { row.actor } 
                                                                    { row.demandado ? <> <br /> <strong>vs</strong> <br /> </> : '' }
                                                                    { row.demandado }
                                                                </TableCell>
                                                                
                                                                <TableCell style={{ width:'1%', textAlign:'center' }}>
                                                                    <IconButton
                                                                        href={`/expedienteVirtual/Detalle/${ row.idExpediente }`}
                                                                        target='_blank'
                                                                    >
                                                                        <ArtTrackIcon color='primary' fontSize='large' />
                                                                    </IconButton>
                                                                </TableCell>
                                                                
                                                                <TableCell style={{ width:'1%', textAlign:'center' }}>
                                                                    <IconButton 
                                                                        color='primary'
                                                                        onClick={ () => { setRefId( row?.idExpedienteAsignado ); setOpen( true ); } } 
                                                                    >
                                                                        <BookmarkIcon />
                                                                    </IconButton>
                                                                </TableCell>                                                               

                                                            </TableRow>

                                                        ))
                                                    }

                                                </TableBody>   

                                                <TableFooter>

                                                    <TableRow>     

                                                        <TableCell colSpan={1}>
                                                            <Typography variant='body2' color='black' sx={{ fontWeight: 'bold' }}> Expedientes { recordsCount } </Typography>
                                                        </TableCell>                                                                             

                                                        <TablePagination
                                                            rowsPerPageOptions={[ 5, 10, 20, 50, 100 ]}
                                                            colSpan={10}
                                                            count={ recordsCount }                                        
                                                            rowsPerPage={ rowsPerPage }
                                                            page={ page}                                                                                 
                                                            onPageChange={ handleChangePage }
                                                            onRowsPerPageChange={ handleChangeRowsPerPage }
                                                            ActionsComponent={ TablePaginationActions }

                                                            labelRowsPerPage="Filas por página"                                            
                                                            labelDisplayedRows={
                                                                ({ from, to, count }) => {
                                                                return '' + from + '-' + to + ' de ' + count
                                                                }
                                                            }  
                                                        />

                                                    </TableRow>

                                                </TableFooter>


                                            </Table>

                                        </TableContainer>  
                                }

                            </Grid>

                        </Grid>                    
                    }

                </DialogContent>

                <DialogActions>
                    <Button onClick={ handleClose }>Cerrar</Button>
                </DialogActions>

            </Dialog>

            <Dialog
                open={ openResultados }
                onClose={ () => { } }
                maxWidth='md'
                fullWidth
            >  
                <DialogContent>

                    {
                        loadingResultados 
                        ?
                            <Grid container spacing={3} sx={{ justifyContent: 'center' }}>
                                <CircularProgress size={40} sx={{ mt: 15, mb: 15 }} />
                            </Grid>
                        :
                        <Grid container spacing={3} sx={{ justifyContent: 'center' }}>

                            <Grid item xs={12}>
  
                                <TableContainer>

                                    <Table>

                                        <TableHead>

                                            <TableRow>        

                                                <TableCell sx={{ width:'15%', textAlign:'center' }}> EXPEDIENTE </TableCell>
                                                <TableCell sx={{ width:'50%' }}> JUZGADO </TableCell>
                                                <TableCell sx={{ width:'25%', textAlign:'center' }}> DISTRITO JUDICIAL </TableCell>
                                                <TableCell sx={{ width:'5%', textAlign:'center' }}> ESTATUS </TableCell>
                                                <TableCell sx={{ width:'5%', textAlign:'center' }}> VER </TableCell>

                                            </TableRow>

                                        </TableHead>

                                        <TableBody>
    
                                            {
                                                resultados.map( ( row, i) => (
    
                                                    <TableRow key={ i } >
    
                                                        <TableCell sx={{ textAlign: 'center' }}> { row?.referencia } </TableCell>
                                                        <TableCell> { row?.juzgado } </TableCell>
                                                        <TableCell sx={{ textAlign: 'center' }}> { row?.distritoJudicial } </TableCell>
                                                        <TableCell sx={{ textAlign: 'center', color: row?.estatus === 'Archivado' ? 'red' : 'black' }}> { row?.estatus } </TableCell>
                                                        
                                                        <TableCell style={{ width:'1%', textAlign:'center' }}>
                                                            <IconButton 
                                                                color='primary'
                                                                onClick={ () => {

                                                                    if( row?.penal ){
                                                                        navigate(`/expedienteVirtual/DetallePenal/${ row.id }`);
                                                                    }
                                                                    else{
                                                                        navigate(`/expedienteVirtual/Detalle/${ row.id }`)
                                                                    }
                                                                    
                                                                }}
                                                            >
                                                                <ArtTrackIcon fontSize='large' />
                                                            </IconButton>
                                                        </TableCell>                               
    
                                                    </TableRow>
    
                                                ))
                                            }

                                        </TableBody>
    
                                    </Table>
    
                                </TableContainer>

                            </Grid>

                        </Grid>                    
                    }

                </DialogContent>

                <DialogActions>
                    <Button onClick={ () => { setOpenResultados(); setResultados( [] ); } }>Cerrar</Button>
                </DialogActions>

            </Dialog>      

        </>
    )

}
