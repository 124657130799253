import React from 'react';

import { Navigate, Route, Routes } from 'react-router-dom';

import { AdministracionScreen } from '../components/administracion/AdministracionScreen';
import { AyudaScreen } from '../components/ayuda/AyudaScreen';
import { BoletasScreen } from '../components/boletas/BoletasScreen';
import { BuzonScreen } from '../components/buzon/BuzonScreen';
import { CitasScreen } from '../components/citas/CitasScreen';
import { NuevaCitaScreen } from '../components/citas/NuevaCitaScreen';
import { ArchivadosScreen } from '../components/expediente/ArchivadosScreen';
import { DetalleExpedienteScreen } from '../components/expediente/DetalleExpedienteScreen';
import { DetalleExpedientePenalScreen } from '../components/expediente/DetalleExpedientePenalScreen';
import { ExpedienteVirtualScreen } from '../components/expediente/ExpedienteVirtualScreen';
import { PerfilScreen } from '../components/perfil/PerfilScreen';
import { PoderEnLineaScreen } from '../components/PoderEnLineaScreen';
import { SolicitudesScreen } from '../components/sid/SolicitudesScreen';

import { NotFoundScreen } from '../components/auth/NotFoundScreen';
import { Navbar } from '../components/ui/Navbar';

import { AplicacionScreen } from '../components/catalogos/AplicacionScreen';
import { AsentamientoScreen } from '../components/catalogos/AsentamientoScreen';
import { CiudadScreen } from '../components/catalogos/CiudadScreen';
import { DistritoJudicialScreen } from '../components/catalogos/DistritoJudicialScreen';
import { EstadoScreen } from '../components/catalogos/EstadoScreen';
import { EstatusScreen } from '../components/catalogos/EstatusScreen';
import { JuzgadoScreen } from '../components/catalogos/JuzgadoScreen';
import { MateriaScreen } from '../components/catalogos/MateriaScreen';
import { MateriaDistritoScreen } from '../components/catalogos/MateriaDistritoScreen';
import { MunicipioScreen } from '../components/catalogos/MunicipioScreen';
import { OficialiaMateriaScreen } from '../components/catalogos/OficialiaMateriaScreen';
import { OficialiaScreen } from '../components/catalogos/OficialiaScreen';
import { PaisScreen } from '../components/catalogos/PaisScreen';
import { SexoScreen } from '../components/catalogos/SexoScreen';
import { TipoAsentamientoScreen } from '../components/catalogos/TipoAsentamientoScreen';
import { TipoAudienciaScreen } from '../components/catalogos/TipoAudienciaScreen';
import { TipoDiligenciaScreen } from '../components/catalogos/TipoDiligenciaScreen';
import { TipoDomicilioScreen } from '../components/catalogos/TipoDomicilioScreen';
import { TipoInstanciaScreen } from '../components/catalogos/TipoInstanciaScreen';
import { TipoJuicioScreen } from '../components/catalogos/TipoJuicioScreen';
import { TipoJuzgadoScreen } from '../components/catalogos/TipoJuzgadoScreen';
import { TipoMovimientoScreen } from '../components/catalogos/TipoMovimientoScreen';
import { TipoRecuperacionScreen } from '../components/catalogos/TipoRecuperacionScreen';
import { TipoSesionScreen } from '../components/catalogos/TipoSesionScreen';
import { TipoTramiteScreen } from '../components/catalogos/TipoTramiteScreen';
import { TipoTramiteUsuarioScreen } from '../components/catalogos/TipoTramiteUsuarioScreen';
import { TipoTramiteMateriaScreen } from '../components/catalogos/TipoTramiteMateriaScreen';
import { TipoUsuarioScreen } from '../components/catalogos/TipoUsuarioScreen';
import { ZonaScreen } from '../components/catalogos/ZonaScreen';
import { MotivoCancelacionScreen } from '../components/catalogos/MotivoCancelacionScreen';

export const PoderEnLineaRoutes = () => {

    return (
        <Routes>

            <Route path="/inicio/" element={ <PoderEnLineaScreen /> } />   

            <Route path="/administracion/" element={ <AdministracionScreen /> } />                   
            
            <Route path="/perfil/" element={ <PerfilScreen /> } />         

            <Route path="/ayuda/" element={ <AyudaScreen /> } />         

            <Route path="/citas/" element={ <CitasScreen /> } />      

            <Route path="/nuevaCita/" element={ <NuevaCitaScreen /> } />   

            <Route path="/buzon/" element={ <BuzonScreen /> } />          

            <Route path="/expedienteVirtual/" element={ <ExpedienteVirtualScreen /> } />  

            <Route path="/expedienteVirtual/Detalle/:Id" element={ <DetalleExpedienteScreen /> } />  

            <Route path="/expedienteVirtual/DetallePenal/:Id" element={ <DetalleExpedientePenalScreen /> } />         

            <Route path="/expedienteVirtual/archivados" element={ <ArchivadosScreen /> } />                                

            <Route path="/boletas/" element={ <BoletasScreen /> } />  

            <Route path="/solicitudes/" element={ <SolicitudesScreen /> } />  

            {/* Catálogos */}

            <Route path="/administracion/catalogo/Aplicacion/" element={ <AplicacionScreen /> } />  

            <Route path="/administracion/catalogo/Asentamiento/" element={ <AsentamientoScreen /> } />  

            <Route path="/administracion/catalogo/Ciudad/" element={ <CiudadScreen /> } />  

            <Route path="/administracion/catalogo/DistritoJudicial/" element={ <DistritoJudicialScreen /> } />  

            <Route path="/administracion/catalogo/Estado/" element={ <EstadoScreen /> } />  

            <Route path="/administracion/catalogo/Estatus/" element={ <EstatusScreen /> } /> 

            <Route path="/administracion/catalogo/Juzgado/" element={ <JuzgadoScreen /> } /> 

            <Route path="/administracion/catalogo/Materia/" element={ <MateriaScreen /> } />  

            <Route path="/administracion/catalogo/MateriaDistrito/" element={ <MateriaDistritoScreen /> } />  

            <Route path="/administracion/catalogo/Municipio/" element={ <MunicipioScreen /> } />  

            <Route path="/administracion/catalogo/Oficialia/" element={ <OficialiaScreen /> } />  

            <Route path="/administracion/catalogo/OficialiaMateria/" element={ <OficialiaMateriaScreen /> } />          

            <Route path="/administracion/catalogo/Pais/" element={ <PaisScreen /> } />  

            <Route path="/administracion/catalogo/Sexo/" element={ <SexoScreen /> } />  

            <Route path="/administracion/catalogo/TipoAsentamiento/" element={ <TipoAsentamientoScreen /> } />  

            <Route path="/administracion/catalogo/TipoAudiencia/" element={ <TipoAudienciaScreen /> } />  

            <Route path="/administracion/catalogo/TipoDiligencia/" element={ <TipoDiligenciaScreen /> } />  

            <Route path="/administracion/catalogo/TipoDomicilio/" element={ <TipoDomicilioScreen /> } />  

            <Route path="/administracion/catalogo/TipoInstancia/" element={ <TipoInstanciaScreen /> } />  

            <Route path="/administracion/catalogo/TipoJuicio/" element={ <TipoJuicioScreen /> } />  

            <Route path="/administracion/catalogo/TipoJuzgado/" element={ <TipoJuzgadoScreen /> } /> 

            <Route path="/administracion/catalogo/TipoMovimiento/" element={ <TipoMovimientoScreen /> } /> 

            <Route path="/administracion/catalogo/TipoSesion/" element={ <TipoSesionScreen /> } />  

            <Route path="/administracion/catalogo/TipoTramite/" element={ <TipoTramiteScreen /> } />  

            <Route path="/administracion/catalogo/TipoTramiteUsuario/" element={ <TipoTramiteUsuarioScreen /> } />

            <Route path="/administracion/catalogo/TipoTramiteMateria/" element={ <TipoTramiteMateriaScreen /> } />  

            <Route path="/administracion/catalogo/TipoUsuario/" element={ <TipoUsuarioScreen /> } /> 
            
            <Route path="/administracion/catalogo/Zona/" element={ <ZonaScreen /> } />          

            <Route path="/administracion/catalogo/TipoRecuperacion/" element={ <TipoRecuperacionScreen /> } />             

            <Route path="/administracion/catalogo/MotivoCancelacion/" element={ <MotivoCancelacionScreen /> } />           

            {/* Redirecciones */}

            <Route path="/" element={ <Navigate to="/inicio/" /> } />

            <Route path="*" element={ <> <Navbar /> <NotFoundScreen /> </> } />

        </Routes>
    )

}