import React from 'react';
import { Link } from 'react-router-dom';

import { Box, Button, Container, Typography, Paper } from '@mui/material';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export const NotFoundScreen = () => {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', mt: '15em', color: 'white' }} >
        
            <Container maxWidth='md' component={ Paper } sx={{ pb: 5 }} >

                <Container component="main" sx={{ mt: 8, mb: 5 }} maxWidth="md">

                    <Typography variant="h4">
                        LO SENTIMOS, ESTA PÁGINA NO ESTÁ DISPONIBLE
                    </Typography>

                </Container>   

                <Container component="main" sx={{ mt: 2, mb: 2 }} maxWidth="md">
                    <Typography variant="h6">
                        Es posible que el enlace haya sido cambiado, eliminado, no exista ó no tenga los permisos necesarios.
                    </Typography>
                </Container> 

                <Container component="main" sx={{ mt: 5, textAlign: 'center' }} maxWidth="md">               
                    <Link to="/" style={{ textDecoration: 'none' }} > 
                        <Button variant="contained"  startIcon={ <ArrowBackIcon /> } >Regresar a la pagina de inicio</Button> 
                    </Link>  
                </Container> 

            </Container>

        </Box>
    )
}