import React, { useState, useEffect } from 'react';

import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, List, ListItem, ListItemIcon, ListItemText, Switch } from '@mui/material';

import { LoadingButton } from '@mui/lab';

import SaveIcon from '@mui/icons-material/Save';
import FolderCopyIcon from '@mui/icons-material/FolderCopy';
import MarkunreadMailboxIcon from '@mui/icons-material/MarkunreadMailbox';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';

import { ActualizarAplicaciones, DetalleAplicaciones } from '../../actions/admin/usuarioActions';

export const ModalAdminAplicaciones = ({ open, setOpen, referencia }) => {

    const [loading, setLoading] = useState( false );
    const [loadingSave, setLoadingSave] = useState( false );

    const [aplicaciones, setAplicaciones] = useState( [] );

    const handleChangeActiva = ( value, id ) => {

        const aplicacionesArray = aplicaciones.map( ( elem ) => {
            if( elem.id === id ){
                elem.activa = value;
            }
            return elem;
        });

        setAplicaciones( aplicacionesArray );

    }

    const handleGuardarAplicaciones = async() => {   

        setLoadingSave( true );

        const params = {
            id: referencia,
            aplicaciones,
        };

        await ActualizarAplicaciones( params ).then( response => {

            if( response ){

                if( response.data ){

                    setTimeout(() => {    

                        handleClose();

                        setLoadingSave( false );
                        
                    }, 500);

                }

            }

        });       

    }

    const handleClose = () => {
        
        setOpen( false );
        setAplicaciones( [] );
        
    }

    useEffect(() => {

        async function Obtener(){

            setLoading( true );

            await DetalleAplicaciones( referencia ).then( response => {

                if( response ){

                    if( response.status === 200 ){

                        setTimeout(() => {                          
                        
                            const aplicacionesArray = response?.data?.aplicaciones.map( ( elem ) => {
                                if( elem.id === 3 ){
                                    elem.icon = <MarkunreadMailboxIcon color='primary' />;
                                    elem.caption = 'Presentación electrónica de demandas y promociones que vayan dirigidas a los juzgados.';
                                }
                                else if( elem.id === 4 ){
                                    elem.icon = <FolderCopyIcon color='primary' />;
                                    elem.caption = 'Permite visualizar las resoluciones judiciales que conforman un expediente o carpeta administrativa.';
                                }
                                else if( elem.id === 7 ){
                                    elem.icon = <ForwardToInboxIcon color='primary' />;
                                    elem.caption = 'Permite solicitar diligencias actuariales sin la necesidad de trasladarse al juzgado respectivo.';
                                }
                                return elem;
                            });

                            setAplicaciones( aplicacionesArray );
                            
                            setLoading( false );

                        }, 700);                       

                    }

                }

            });

        }

        if( open && referencia ){
            Obtener();
        }

      
    }, [ open, referencia ])  

    return (
        <Dialog 
            open={ open } 
            onClose={ () => {} } 
            maxWidth='sm' 
            fullWidth
        >
                
            <DialogTitle>
                Actualizar aplicaciones
            </DialogTitle>
            
            <DialogContent>

                {
                    loading
                    ?   
                        <Grid container spacing={3} sx={{ justifyContent: 'center' }}>
                            <CircularProgress size={40} sx={{ mt: 5, mb: 5 }} />
                        </Grid>
                    :
            
                        <Grid container spacing={3} sx={{ pt: 1 }}> 

                            <Grid item md={12} xs={12}>

                                <List>

                                    {
                                        aplicaciones.map( ( elem ) => (

                                            <ListItem key={ elem.id }>
                                                <ListItemIcon>
                                                    { elem.icon }
                                                </ListItemIcon>
                                                <ListItemText primary={ elem.descripcion } secondary={ elem.caption } />
                                                
                                                <Switch checked={ elem.activa } onChange={ ( e ) => handleChangeActiva( e.target.checked, elem.id ) } edge='end' />
                                            </ListItem>

                                        ))
                                    }                                   

                                </List>

                            </Grid>
                        
                        </Grid>
                }
            
            </DialogContent>

            <DialogActions>
                <Button onClick={ handleClose }> Cancelar </Button>
                <LoadingButton onClick={ handleGuardarAplicaciones } variant='contained' startIcon={ <SaveIcon /> } loading={ loadingSave } > Guardar </LoadingButton>
            </DialogActions>

        </Dialog>
    )

}
