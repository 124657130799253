import React from 'react';

import { Box, Container, Paper } from '@mui/material';

import { NavbarInicio } from '../ui/NavbarInicio';

import { MisExpedientesScreen } from './MisExpedientesScreen';
import { TabsExpedientesAutorizadosScreen } from './TabsExpedientesAutorizadosScreen';
import { NotFoundScreen } from '../auth/NotFoundScreen';

import { CardMantenimiento } from '../ui/CardMantenimiento';

import { usePermisos } from '../../hooks/usePermisos';

export const ExpedienteVirtualScreen = () => {

    const { tipoUsuario, banderaExpedienteVirtual, banderaOnlineExpedienteVirtual, loading } = usePermisos();

    return (
        <>
            <NavbarInicio />           

            {
                ( !loading && !banderaExpedienteVirtual )
                &&
                <NotFoundScreen />
            }

            {
                ( !loading && banderaExpedienteVirtual )
                &&            
                <Container maxWidth='xl' sx={{ mt: { md: '10em', xs: '8em' } }}>
                    
                    {
                        banderaOnlineExpedienteVirtual
                        ?
                            <CardMantenimiento />
                        :
                        <>  
                            {
                                tipoUsuario === 1
                                &&
                                <Box sx={{ pb: '2em', pl: { md: 10, xs: 2 }, pr: { md: 10, xs: 2 }, pt: 2 }} component={ Paper } >	
                                    <TabsExpedientesAutorizadosScreen />
                                </Box>
                            }

                            {
                                ( tipoUsuario === 2 || tipoUsuario === 14 )
                                &&
                                <Box sx={{ pb: '2em', pl: { md: 10, xs: 2 }, pr: { md: 10, xs: 2 }, pt: 2 }}  >	
                                    <MisExpedientesScreen />
                                </Box>
                            }
                        </>
                    }

                </Container>
            }

        </>
    )
    
}
