export const catalogosData = [
    { 
        id: 1, 
        descripcion: 'Aplicación' , 
        path: '/administracion/catalogo/Aplicacion/'
    },
    { 
        id: 2, 
        descripcion: 'Asentamiento' , 
        path: '/administracion/catalogo/Asentamiento/'
    },
    { 
        id: 3, 
        descripcion: 'Ciudad' , 
        path: '/administracion/catalogo/Ciudad/'
    },
    { 
        id: 4, 
        descripcion: 'Distrito judicial' , 
        path: '/administracion/catalogo/DistritoJudicial/'
    },
    { 
        id: 5, 
        descripcion: 'Estado' , 
        path: '/administracion/catalogo/Estado/'
    },
    { 
        id: 6, 
        descripcion: 'Estatus' , 
        path: '/administracion/catalogo/Estatus/'
    },
    { 
        id: 7, 
        descripcion: 'Juzgado' , 
        path: '/administracion/catalogo/Juzgado/'
    },
    { 
        id: 8, 
        descripcion: 'Matería' , 
        path: '/administracion/catalogo/Materia/'
    },
    { 
        id: 9, 
        descripcion: 'Municipio' , 
        path: '/administracion/catalogo/Municipio/'
    },
    { 
        id: 10, 
        descripcion: 'Oficialía' , 
        path: '/administracion/catalogo/Oficialia/'
    },
    { 
        id: 11, 
        descripcion: 'Oficialía - Matería' , 
        path: '/administracion/catalogo/OficialiaMateria/'
    },
    { 
        id: 12, 
        descripcion: 'País' , 
        path: '/administracion/catalogo/Pais/'
    },
    { 
        id: 13, 
        descripcion: 'Sexo' , 
        path: '/administracion/catalogo/Sexo/'
    },
    { 
        id: 14, 
        descripcion: 'Tipo asentamiento' , 
        path: '/administracion/catalogo/TipoAsentamiento/'
    },
    { 
        id: 15, 
        descripcion: 'Tipo audiencia' , 
        path: '/administracion/catalogo/TipoAudiencia/'
    },
    { 
        id: 16, 
        descripcion: 'Tipo diligencia' , 
        path: '/administracion/catalogo/TipoDiligencia/'
    },
    { 
        id: 17, 
        descripcion: 'Tipo domicilio' , 
        path: '/administracion/catalogo/TipoDomicilio/'
    },
    { 
        id: 18, 
        descripcion: 'Tipo instancia' , 
        path: '/administracion/catalogo/TipoInstancia/'
    },
    { 
        id: 19, 
        descripcion: 'Tipo juicio' , 
        path: '/administracion/catalogo/TipoJuicio/'
    },
    { 
        id: 20, 
        descripcion: 'Tipo juzgado' , 
        path: '/administracion/catalogo/TipoJuzgado/'
    },
    { 
        id: 21, 
        descripcion: 'Tipo movimiento' , 
        path: '/administracion/catalogo/TipoMovimiento/'
    },
    { 
        id: 22, 
        descripcion: 'Tipo sesión' , 
        path: '/administracion/catalogo/TipoSesion/'
    },
    { 
        id: 23, 
        descripcion: 'Tipo trámite' , 
        path: '/administracion/catalogo/TipoTramite/'
    },
    { 
        id: 24, 
        descripcion: 'Tipo trámite - Usuario' , 
        path: '/administracion/catalogo/TipoTramiteUsuario/'
    },
    { 
        id: 25, 
        descripcion: 'Tipo trámite - Materia' , 
        path: '/administracion/catalogo/TipoTramiteMateria/'
    },
    { 
        id: 26, 
        descripcion: 'Tipo usuario' , 
        path: '/administracion/catalogo/TipoUsuario/'
    },
    { 
        id: 27, 
        descripcion: 'Zona' , 
        path: '/administracion/catalogo/Zona/'
    },
    { 
        id: 28, 
        descripcion: 'Tipo recuperación' , 
        path: '/administracion/catalogo/TipoRecuperacion/'
    },
    { 
        id: 29, 
        descripcion: 'Materia - Distrito' , 
        path: '/administracion/catalogo/MateriaDistrito/'
    },
    { 
        id: 30, 
        descripcion: 'Motivo de cancelación' , 
        path: '/administracion/catalogo/MotivoCancelacion/'
    },
]