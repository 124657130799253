import React, { useState } from 'react'

import PropTypes from 'prop-types';

import { Box, Container, Paper, Tab, Tabs } from '@mui/material'

import { NavbarInicio } from '../ui/NavbarInicio';
import { UsuariosScreen } from './UsuariosScreen';
import { ApiKeyScreen } from './ApiKeyScreen';
import { CatalogosScreen } from './CatalogosScreen';
import { NotFoundScreen } from '../auth/NotFoundScreen';

import { usePermisos } from '../../hooks/usePermisos';

function TabPanel( props ){
    
    const { children, value, index, ...other } = props;
  
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};
  
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export const AdministracionScreen = () => {

    const { tipoUsuario, loading } = usePermisos();

    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => setValue(newValue)

    return (
        <>
            <NavbarInicio />

            {
                ( !loading && tipoUsuario !== 3 )
                &&
                <NotFoundScreen />
            }

            {
                ( !loading && tipoUsuario === 3  )
                &&    

                <Container maxWidth='xl' sx={{ mt: { md: '10em', xs: '8em' }, mb: 5 }}>

                    <Box sx={{ pb: 2, pl: { md: 8, xs: 1 }, pr: { md: 8, xs: 1 }, pt: 2 }} component={ Paper } >	
            
                        <Tabs value={value} onChange={handleChange} textColor="inherit">
                        
                            <Tab label="Usuarios" {...a11yProps(0)} />
                            <Tab label="Catálogos" {...a11yProps(1)} />
                            <Tab label="Api key´s" {...a11yProps(2)} />

                        </Tabs>

                        <TabPanel value={value} index={0}> 

                            <UsuariosScreen />
                        
                        </TabPanel>
                        
                        <TabPanel value={value} index={1}> 

                            <CatalogosScreen />

                        </TabPanel>
                        
                        <TabPanel value={value} index={2}> 

                            <ApiKeyScreen />

                        </TabPanel>

                    </Box>

                </Container>

            }
        
        </>
    )
}
