import httpClientApiKey from "../../services/httpClientApiKey";
import httpClientToken from "../../services/httpClientToken";

import { login, logout, authError } from "../../store/slices/authSlice";

export const Login = ( params, setLoading ) => {

    return async ( dispatch ) => {

        await httpClientApiKey.post('/Api/Auth/Login', params)
        .then( response => {
        
            const { data } = response;

            if( data.success ){

                setTimeout(() => {
                
                    window.localStorage.setItem("data", JSON.stringify(data));

                    dispatch( login( data ) );

                    setLoading( false );

                }, 1000);
                
            }
            else {

                dispatch( authError( data.message ?? '' ) );

                setLoading( false );
            }
            
        })
        .catch( ( error ) => {

            if( error.response ){

                const { message } = error.response?.data;

                dispatch( authError( message ?? '' ) );
                
                setLoading( false );

            }            
            
        });   
          
    }
}

export const Logout = ( ) => {

    return async ( dispatch ) => {

        const data = JSON.parse(window.localStorage.getItem('data'));

        if( data ){
            const { token } = data;

            await httpClientToken.post('/Api/Auth/Logout', null, token)
            .then( response => {

                if( response.status === 200 ){

                    setTimeout(() => {
                        
                        localStorage.removeItem('data');
                        localStorage.removeItem('lastPath');
                        
                        dispatch( logout() ); 

                    }, 800);
                
                }
                
            })
            .catch( ( ) => { });
        }   
       
    }
}


export const ValidarSesion = () => {

    return new Promise( (resolve, eject) => {

        const data = JSON.parse(window.localStorage.getItem('data'));

        if( data ){
            const { token } = data;

            httpClientToken.post('/Api/Auth/ValidarSesion/', null, token)
            .then( response => {
                if( response.status === 200 ){
                    resolve( response );
                }
            })
            .catch( (error) => {
                resolve( error.response );
            });
        }

    });

}


export const RecuperarContrasena = ( params ) => {

    return new Promise( (resolve, eject) => {

        httpClientApiKey.post('/Api/Auth/RecuperarContrasena/', params)
        .then( response => {
            if( response.status === 200 ){
                resolve( response );
            }
        })
        .catch( (error) => {
            resolve( error.response );
        });        

    });

}

export const EnviarNipMetodoRecuperacion = ( params ) => {

    return new Promise( (resolve, eject) => {

        httpClientApiKey.post('/Api/Auth/EnviarNipMetodoRecuperacion/', params)
        .then( response => {
            if( response.status === 200 ){
                resolve( response );
            }
        })
        .catch( (error) => {
            resolve( error.response );
        });        

    });

}

export const ValidarNipRecuperacion = ( params ) => {

    return new Promise( (resolve, eject) => {

        httpClientApiKey.post('/Api/Auth/ValidarNipRecuperacion/', params)
        .then( response => {
            if( response.status === 200 ){
                resolve( response );
            }
        })
        .catch( (error) => {
            resolve( error.response );
        });        

    });

}

export const CambiarContrasena = ( params ) => {

    return new Promise( (resolve, eject) => {

        httpClientApiKey.post('/Api/Auth/CambiarContrasena/', params)
        .then( response => {
            if( response.status === 200 ){
                resolve( response );
            }
        })
        .catch( (error) => {
            resolve( error.response );
        });        

    });

}

export const EnviarNipCambioContrasena = ( params ) => {

    return new Promise( (resolve, eject) => {

        httpClientApiKey.post('/Api/Auth/EnviarNipCambioContrasena/', params)
        .then( response => {
            if( response.status === 200 ){
                resolve( response );
            }
        })
        .catch( (error) => {
            resolve( error.response );
        });        

    });

}


