import React, { useEffect, useRef, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { Alert, Box, Button, Chip, CircularProgress, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, InputLabel, MenuItem, MobileStepper, Paper, Select, Step, StepLabel, Stepper, TextField, Typography } from '@mui/material';

import { LoadingButton } from '@mui/lab';

import { CalendarPicker, LocalizationProvider } from '@mui/x-date-pickers'

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'

import { useTheme } from '@emotion/react';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import SendIcon from '@mui/icons-material/Send';
import CheckIcon from '@mui/icons-material/CheckCircle';
import DateRangeIcon from '@mui/icons-material/DateRange';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import CloseIcon from '@mui/icons-material/Close';

import moment from 'moment';
import 'moment/locale/es-mx';

import ReCAPTCHA  from 'react-google-recaptcha'

import { NavbarInicio } from '../ui/NavbarInicio';

import { usePermisos } from '../../hooks/usePermisos';

import { GetCitDiasDisponibles, GetDistritos, GetHorasDisponibles, GetOficinas, GetOficinaServicio, NewCita } from '../../actions/citas/CitCitasActions';

export const NuevaCitaScreen = () => {
    
    const navigate = useNavigate();

    const theme = useTheme();

    const maxSteps = 6;
    const nota = '';
    const notatemp = "";
    const tab = '\u00A0';

    let expnota = "";

    const { idUsuarioCitas } = usePermisos();

    const [activeStep, setActiveStep] = useState( 0 );
    
    const [openConfirmacion, setOpenConfirmacion] = useState( false );

    const [loadingBack, setLoadingBack] = useState( false );
    const [loadingNext, setLoadingNext] = useState( false );

    const [loadHoras, setLoadHoras] = useState( true );
    const [loadFechas, setLoadFechas] = useState( true);

    // Distritos
    const [distritoCita, setDistritoCita] = useState(0);
    const [distritosArray, setDistritosArray] = useState([]);

    // Oficinas
    const [oficinaCita, setOficinaCita] = useState(0);
    const [oficinasArray, setOficinasArray] = useState([]);

    //servicios
    const [tramiteCita, setTramiteCita] = useState(0);
    const [tramitesArray, setTramitesArray] = useState([]);

    //notas
    const [notas, setNotas] = useState(nota);
    const [notastemp, setNotastemp] = useState(notatemp);
    const [expedientesStatus, setExpedientesStatus] = useState(true);
    const [exp1, setExp1] = useState("");
    const [exp2, setExp2] = useState("");
    const [exp3, setExp3] = useState("");
    const [exp4, setExp4] = useState("");
    const [exp5, setExp5] = useState("");

    // fechas
    const [fechaCita, setFechaCita] = useState( moment( new Date() ) );
    const [isGetDate, setIsGetDate] = useState(false)
    const [fechasArray, setFechasArray] = useState([]);

    // horas
    const [horaCita, setHoraCita] = useState('');
    const [horasArray, setHorasArray] = useState([]);

    //codigo
    const [codigoA, setCodigoA] = useState('');

    // Mensaje error
    const [errorConfirm, setErrorConfirm] = useState('');
    const [errores, setErrors] = useState({});

    // variables de estado para captcha
    const [captchaValido, setCaptachaValido] = useState(null)

    // Referencia al checkbox 'recaptcha'
    const captcha = useRef(null)

    // Funcion de evento onChange
    const onChangeCaptcha = () => {

        if( captcha.current.getValue() ){
            setCaptachaValido(true)
        }
    }

    const validStepper = () => {

        let valid = true;
    
        let errores = {};

        if( activeStep === 0 ){

            if( distritoCita === 0 ) {
                valid = false;
                errores.distritoCita = 'Debes seleccionar un Distrito';
            }

            if( oficinaCita === 0 ){
                valid = false;
                errores.oficinaCita = 'Debes seleccionar una Oficina';
            }
    
        }
        else if( activeStep === 1 ){
    
            if( tramiteCita === 0 ){
                valid = false;
                errores.tramiteCita = 'Debes seleccionar una Trámite';
            }

            if( expedientesStatus ){
                  
                if( exp1.trim() !== "" ){ 

                    if( expnota.trim() !== "" ){ 
                        expnota = expnota + ", " + exp1 
                    }
                    else{ 
                        expnota = expnota + exp1 
                    }

                }
    
                if( exp2.trim() !== "" ){ 
                    
                    if( expnota.trim() !== "" ){ 
                        expnota = expnota + ", " + exp2 
                    }
                    else{ 
                        expnota = expnota + exp2 
                    }
                }
    
                if( exp3.trim() !== "" ){ 

                    if( expnota.trim() !== "" ){ 
                        expnota = expnota + ", " + exp3 
                    }
                    else{ 
                        expnota = expnota + exp3 
                    }
                }
    
                if( exp4.trim() !== "" ){ 
                    
                    if( expnota.trim() !== "" ){ 
                        expnota = expnota + ", " + exp4 
                    }
                    else{ 
                        expnota = expnota + exp4 
                    }

                }
    
                if( exp5.trim() !== "" ){ 

                    if( expnota.trim() !== "" ){ 
                        expnota = expnota + ", " + exp5 
                    }
                    else{ 
                        expnota = expnota + exp5
                    }
                }
                
                if( expnota.trim() === "" ){
                    valid = false;
                    errores.expnota = 'Falta llenar al menos un expediente';
                }

                setNotas(expnota)        
               
            }
            else{ 
                if( notastemp.trim() === "" ){
                    valid = false;
                    errores.notastemp = 'Falta llenar este campo.';
                }
                else{
                    expnota = notastemp;
                }

            }
            
            setNotas( expnota );
        }
        else if( activeStep === 2 ){

            if( horaCita === "" ){
                valid = false;
                errores.horaCita = 'Debes seleccionar una hora';
            }
        }

        setErrors( errores );

        return valid;
    }

    const handleNext = () => {

        const valid = validStepper();

        if ( !valid ){
            return false;
        }

        setLoadingNext( true );
        
        setTimeout(() => {
            
            setLoadingNext( false );
            setOpenConfirmacion( false );
            setActiveStep( ( prevStep ) => prevStep + 1 );

        }, 600);

    }

    const handleBack  = async () => {    

        setLoadingBack( true );        
        
        setTimeout(() => {

            setCaptachaValido( false );
            setLoadingBack( false );
            setActiveStep( ( prevStep ) => prevStep - 1 );
            setErrorConfirm( '' );

        }, 600);

    }

    const handleChangeTramiteCita = ( e ) => {
        
        if( e.target.value === 2 ){
            setExpedientesStatus( true );
        }
        else{
            setExpedientesStatus( false );
        }

        setTramiteCita( e.target.value );
    }

    // Fechas
    const disableDates = ( fechacalendario ) => {

        const diaDisponible = fechasArray.find( element => element.fecha === moment(fechacalendario).format("YYYY-MM-DD") );
       
        if( diaDisponible?.fecha === moment(fechacalendario).format("YYYY-MM-DD") ){
            return false
        }
        else{
            return true
        }

    }

     // Horas
    const handleClickSelected = ( horaSelected ) => {

        setHorasArray(

            horasArray.map( ( element ) => {

                if( element.horas_minutos === horaSelected ){
                    element.selected = !element.selected;
                }
                else{
                    element.selected = false;
                }

                return element;
            }

        ));

        setHoraCita(horaSelected)
    }

    // Confirmar cita
    const guardarInformacion = async (e) => {

        const params = {
            cit_cliente_id: idUsuarioCitas,
            oficina_id: oficinaCita,
            cit_servicio_id: tramiteCita,
            fecha: moment(fechaCita).format('YYYY-MM-DD'),
            hora_minuto: horaCita,
            notas: notas,
        }

        setLoadingNext( true );

        if(captchaValido){

            await NewCita( params ).then( response => {

                if( response.status === 200 ){

                    if( response.data.success ){

                        setCodigoA( response.data.codigo_asistencia );
                        handleNext();

                    }
                    else{
                        setErrorConfirm( response.data.message );
                        setLoadingNext( false );
                    }
                }
                    
            });

        }
        else{
            setCaptachaValido( false );
            setLoadingNext( false );
        }

    }    

    // Distritos
    useEffect(() => {
        
        async function Obtener(){

            await GetDistritos().then( response => {

                if( response.status === 200 ){
                    setDistritosArray( response.data.result.items );
                }

            });

        }

        Obtener();

    }, [ ])
    
    // Oficinas
    useEffect(() => {

        async function Obtener(){

            setOficinasArray( [] );
            setOficinaCita( 0 );

            if( distritoCita !== 0 ){

                await GetOficinas( distritoCita ).then( response => {

                    if( response.status === 200 ){
                        setOficinasArray( response.data.result.items );
                    }

                });
                
            }

        }
        
        Obtener()

    }, [ distritoCita ])

    // Tramites
    useEffect(() => {
        async function Obtener(){

            setTramitesArray( [] );
            setTramiteCita( 0 );
            
            await GetOficinaServicio( oficinaCita ).then( response => {

                if( response.status === 200 ){
                    setTramitesArray( response.data.result.items );
                }
            });
            
        }

        Obtener()

    }, [ oficinaCita ])
   
    useEffect(() => {

        async function fetchData(){
           
            setIsGetDate( false );
            setLoadFechas( true );

            const response = await GetCitDiasDisponibles();
            
            if(response.status === 200){

                setTimeout(() => {

                    setFechasArray( response.data.result.items );
                    setFechaCita( moment( response.data.result.items[0].fecha ) );
                    setIsGetDate( true );
                    setLoadFechas( false );

                }, 500);
            }
        }

        fetchData();

    }, [ ])

    useEffect(() => {

        async function Obtener(){
        
            setHorasArray( [] );
            setHoraCita( '' );
            setLoadHoras( true );

            const params = {
                oficina_id: oficinaCita, 
                fecha: moment(fechaCita).format('YYYY-MM-DD'),
                cit_servicio_id: tramiteCita,
            }

            if( isGetDate ){

                await GetHorasDisponibles( params ).then( response => {

                    if( response.status === 200 ){

                        const horasData = response.data.result.items;   
                    
                        if( horasData ){

                            setTimeout(() => {
                                
                                setHorasArray(
                                    horasData.map( ( element ) => {
                                        return {
                                            ...element,
                                            selected: false
                                        }
                                    }   
                                    
                                ))
                                setLoadHoras( false );

                            }, 1000)
                            
                        }
                        else{
                            setLoadHoras( false );
                        }

                    }
                }).catch(() => {

                    setLoadHoras( false );

                });

            }

        }

        Obtener();

    },[ oficinaCita, fechaCita, tramiteCita, isGetDate ])
    
    return (
        <>

            <NavbarInicio />

            <Container maxWidth='lg' sx={{ mt: { md: '13em', xs: '11em' } }}>
                
                <Box sx={{ pb: '2em' }} component={ Paper } >		

                    {
                        activeStep !== 4
                        &&
                        <Grid container spacing={3} sx={{ mt: '1em', pr: { xs: '1em', md: '1em' }, pl: { xs: '1em', md: '1em' } }}>

                            <Grid item md={12} xs={12} sx={{ textAlign: 'center' }} >

                                <Button
                                    variant='text'
                                    startIcon={ <DateRangeIcon /> }
                                    onClick={ () => navigate('/citas/') }
                                >
                                    Ver mis citas
                                </Button>

                            </Grid>

                        </Grid>
                    }

                    <Grid container spacing={3} sx={{ mt: '1em', pr: { xs: '1em', md: '1em' }, pl: { xs: '1em', md: '1em' }, display: { xs: 'none', md: 'block' } }} > 

                        <Grid item md={12} xs={12} >
                            
                            <Stepper activeStep={activeStep} alternativeLabel >
                                <Step>
                                    <StepLabel>Distrito y Oficinas</StepLabel>
                                </Step>
                                <Step>
                                    <StepLabel>Tipo de trámite</StepLabel>
                                </Step>
                                <Step>
                                    <StepLabel>Fecha y Hora</StepLabel>
                                </Step>
                                <Step>
                                    <StepLabel>Confirmación de cita</StepLabel>
                                </Step>
                            </Stepper>

                        </Grid>

                    </Grid>

                    {
                        activeStep === 0 ? (
                            <>                            
                                <Grid container spacing={3} sx={{ mt: '1em', pr: { xs: '1em', md: '2em' }, pl: { xs: '1em', md: '2em' } }} > 

                                    <Grid item md={12} xs={12} sx={{ display: { xs: 'flex', md: 'none' }, textAlign: 'center' }} >

                                        <Typography variant='h6'>
                                            Distrito y Oficinas
                                        </Typography>

                                    </Grid>
                                                
                                    <Grid item md={12} xs={12} >
                                       
                                        <FormControl fullWidth>

                                            <InputLabel id="select-distrito-label">Distrito</InputLabel>

                                            <Select
                                                labelId="select-distrito-label"
                                                id="distrito"                                            
                                                label="distrito"
                                                name="distrito"
                                                value={ distritoCita }                                
                                                onChange={ (e) => { setDistritoCita(e.target.value) } }
                                            >
                                                <MenuItem key={0} value={0}>Seleccione una opción</MenuItem>
                                                {
                                                    distritosArray.map( (elem) => (
                                                        <MenuItem key={elem.id} value={elem.id}>
                                                            {elem.nombre}
                                                        </MenuItem>
                                                    ))
                                                }
                                                
                                            </Select>

                                        </FormControl>

                                        {
                                            errores.distritoCita
                                            &&
                                            <Alert severity='warning' variant='filled' sx={{ mt: 1 }}>
                                                {errores.distritoCita}
                                            </Alert>

                                        }

                                    </Grid>

                                    <Grid item md={12} xs={12} >
                                       
                                        <FormControl fullWidth>

                                            <InputLabel id="select-oficina-label">Oficina</InputLabel>

                                            <Select
                                                labelId="select-oficina-label"
                                                id="oficina"                                            
                                                label="oficina"
                                                name="oficina"
                                                value={ oficinaCita }                                
                                                onChange={ (e) => { setOficinaCita(e.target.value) } }
                                                disabled={distritoCita === 0}
                                            >
                                                <MenuItem key={0} value={0}>Seleccione una opción</MenuItem>
                                                {
                                                    oficinasArray.map((element) =>
                                                        <MenuItem key={element.id} value={element.id}>
                                                            {element.descripcion}
                                                        </MenuItem>
                                                )}
                                            </Select>

                                        </FormControl>

                                        {
                                            errores.oficinaCita
                                            &&
                                            <Alert severity='warning' variant='filled' sx={{ mt: 1 }}>
                                                {errores.oficinaCita}
                                            </Alert>
                                        }

                                    </Grid>

                                    <Grid item md={12} xs={12} sx={{ textAlign: 'right', display: { xs: 'none', md: 'block' } }}>  
              
                                        <LoadingButton
                                            variant="contained" 
                                            onClick={ handleNext }
                                            endIcon={ <ArrowForwardIcon /> }
                                            loading={ loadingNext }
                                        > 
                                            Siguiente 
                                        </LoadingButton>

                                    </Grid>

                                </Grid>
                            </>
                        ) :
                        activeStep === 1 ? (
                            <>                            
                                <Grid container spacing={3} sx={{ mt: '1em', pr: { xs: '1em', md: '2em' }, pl: { xs: '1em', md: '2em' } }} > 

                                    <Grid item md={12} xs={12} sx={{ display: { xs: 'flex', md: 'none' }, textAlign: 'center' }} >

                                        <Typography variant='h6'>
                                            Tipo de trámite
                                        </Typography>

                                    </Grid>

                                    <Grid item md={6} xs={12} >
                                       
                                        <FormControl fullWidth>

                                            <InputLabel id="select-tipoTramite-label">Tipo de trámite</InputLabel>

                                            <Select
                                                labelId="select-tipoTramite-label"
                                                id="select-tipoTramite"                                            
                                                label="Tipo de trámite"
                                                name="tipoTramite"
                                                value={ tramiteCita }                                
                                                onChange={ (e) => { handleChangeTramiteCita(e) } }
                                            >
                                                <MenuItem key={0} value={0}>Seleccione una opción</MenuItem>
                                                {   
                                                    tramitesArray.map((elem) => (
                                                        <MenuItem key={elem.cit_servicio_id} value={elem.cit_servicio_id}>
                                                            {elem.cit_servicio_descripcion}
                                                        </MenuItem>
                                                    ))
                                                }
                                            </Select>

                                        </FormControl>

                                        {
                                            errores.tramiteCita
                                            &&
                                            <Alert severity='warning' variant='filled' sx={{ mt: 1 }}>
                                                {errores.tramiteCita}
                                            </Alert>

                                        }

                                    </Grid>

                                    <Grid item md={6} xs={12} >

                                        {
                                            tramiteCita === 2
                                            ?
                                                <Grid container spacing={3}>

                                                    <Grid item xs={6}>
                                                
                                                        <TextField
                                                            id='expediente1'
                                                            label="Expediente"
                                                            name='expediente1'
                                                            placeholder='352/2022'
                                                            variant="standard"
                                                            value={exp1}
                                                            onChange={ (e) => { setExp1(e.target.value) } }
                                                            sx={{ mb:2 }}
                                                            fullWidth
                                                        />

                                                    </Grid>

                                                    <Grid item xs={6}>

                                                        <TextField
                                                            id='expediente2'
                                                            label="Expediente"
                                                            name='expediente2'
                                                            variant="standard"
                                                            placeholder='353/2022'
                                                            value={exp2}
                                                            onChange={ (e) => { setExp2(e.target.value) } }
                                                            sx={{ mb:2 }}
                                                            fullWidth
                                                        />
                                                    
                                                    </Grid>

                                                    <Grid item xs={6}>

                                                        <TextField
                                                            id='expediente3'
                                                            label="Expediente"
                                                            name='expediente3'
                                                            variant="standard"
                                                            placeholder='354/2022'
                                                            value={exp3}
                                                            onChange={ (e) => { setExp3(e.target.value) } }
                                                            sx={{ mb:2 }}
                                                            fullWidth
                                                        />
                                                    
                                                    </Grid>

                                                    <Grid item xs={6}>
                                                    
                                                        <TextField
                                                            id='expediente4'
                                                            label="Expediente"
                                                            name='expediente4'
                                                            variant="standard"
                                                            placeholder='355/2022'
                                                            value={exp4}
                                                            onChange={ (e) => { setExp4(e.target.value) } }
                                                            sx={{ mb:2 }}
                                                            fullWidth
                                                        />

                                                    </Grid>

                                                    <Grid item xs={6}>
                                                    
                                                        <TextField
                                                            id='expediente5'
                                                            label="Expediente"
                                                            name='expediente5'
                                                            variant="standard"
                                                            placeholder='356/2022'
                                                            value={exp5}
                                                            onChange={ (e) => { setExp5(e.target.value) } }
                                                            fullWidth
                                                        /> 

                                                    </Grid>

                                                    {
                                                        errores.expnota
                                                        &&
                                                        <Grid item xs={12}>
                                                            <Alert severity='warning' variant='filled' sx={{ mt: 2 }}>
                                                                {errores.expnota}
                                                            </Alert>
                                                        </Grid>
                                                    }

                                                </Grid>
                                            :
                                                <Grid container spacing={1}>

                                                    <Grid item xs={12}>
                                                        
                                                        <TextField
                                                            disabled={ tramiteCita === 0 }
                                                            id="indicaciones_tramite"
                                                            label="Escriba las indicaciones del servicio o NINGUNO"
                                                            name="indicaciones_tramite"
                                                            multiline
                                                            placeholder="Escriba las indicaciones del servicio o NINGUNO"
                                                            rows={4}
                                                            value={notastemp}
                                                            fullWidth
                                                            onChange={(e) => { setNotastemp(e.target.value) }}

                                                        />

                                                    </Grid>

                                                    {
                                                        errores.notastemp
                                                        &&
                                                        <Grid item xs={12}>
                                                            <Alert severity='warning' variant='filled' sx={{ mt: 1 }}>
                                                                {errores.notastemp}
                                                            </Alert>
                                                        </Grid>
                                                    }

                                                </Grid>
                                        }

                                    </Grid>

                                    <Grid item md={12} xs={12} sx={{ mt: 1, display: { xs: 'none', md: 'block' } }}>  

                                        <Grid container spacing={3}>

                                            <Grid item md={6} xs={6} style={{ textAlign: 'left' }}>  

                                                <LoadingButton 
                                                    variant="text" 
                                                    color="inherit"
                                                    onClick={ handleBack }
                                                    startIcon={ <ArrowBackIcon /> }
                                                    loading={ loadingBack }
                                                > 
                                                    Regresar 
                                                </LoadingButton>

                                            </Grid>

                                            <Grid item md={6} xs={6} style={{ textAlign: 'right' }}>  

                                                <LoadingButton 
                                                    variant="contained" 
                                                    onClick={ handleNext }
                                                    endIcon={ <ArrowForwardIcon /> }
                                                    loading={ loadingNext }
                                                > 
                                                    Siguiente 
                                                </LoadingButton>

                                            </Grid>                                        

                                        </Grid>
                                    
                                    </Grid>

                                </Grid>
                            </>
                        ) :
                        activeStep === 2 ? (
                            <>                            
                                <Grid container spacing={3} sx={{ mt: '1em', pr: { xs: '1em', md: '2em' }, pl: { xs: '1em', md: '2em' } }} > 

                                    <Grid item md={12} xs={12} sx={{ display: { xs: 'flex', md: 'none' }, textAlign: 'center' }} >

                                        <Typography variant='h6'>
                                            Fecha y hora
                                        </Typography>

                                    </Grid>

                                    <Grid item md={6} xs={12} > 

                                        {
                                            loadFechas
                                            ?
                                                <Grid container direction="column" alignItems="center" justifyContent="center" style={{ marginTop: '25%' }}>
                                                    <CircularProgress size={50} />
                                                </Grid>
                                            :
                                                
                                                    
                                                <LocalizationProvider dateAdapter={ AdapterMoment } >
                                                    <CalendarPicker 
                                                        date={ fechaCita  }
                                                        onChange={ ( newDate ) => { setFechaCita( newDate ) } }
                                                        shouldDisableDate={ disableDates } 
                                                    />
                                                </LocalizationProvider>
                                        }
                                              
                                    </Grid>

                                    <Grid item md={6} xs={12} >  

                                        {
                                            loadHoras
                                            ?
                                                <Grid container direction="column" alignItems="center" justifyContent="center" style={{ marginTop: '25%' }}>
                                                    <CircularProgress size={50} />
                                                </Grid>  
                                            :
                                                <Grid container spacing={2} sx={{ p: 1, mt: 2 }}>  

                                                    {
                                                        horasArray.length !== 0
                                                        ?
                                                    
                                                            horasArray.map( (h) => (

                                                                
                                                                h.selected 
                                                                ?
                                                                    <Grid key={ h.horas_minutos } item md={2} xs={3} > 
                                                                        <Chip 
                                                                            key={ h.horas_minutos }
                                                                            label={ h.horas_minutos.slice(0,-3) }
                                                                            size='small'
                                                                            onClick={ () => { handleClickSelected(h.horas_minutos) } }
                                                                            color='primary'
                                                                            
                                                                        />

                                                                    </Grid>
                                                                :
                                                                    <Grid key={ h.horas_minutos } item md={2} xs={3} > 
                                                                        <Chip 
                                                                            key={ h.horas_minutos }
                                                                            label={ h.horas_minutos.slice(0,-3) }
                                                                            size='small'
                                                                            onClick={ () => { handleClickSelected(h.horas_minutos) } }
                                                                        />

                                                                    </Grid>
                                                                
                                                            ))
                                                            

                                                        :
                                                            <Grid item xs={12} sx={{ textAlign: 'center' }}>
                                                                <Typography variant="subtitle1" style={{ fontWeight: 'bold' }} >
                                                                    No se tiene horas disponibles en esta fecha
                                                                </Typography>        
                                                            </Grid>
                                                        
                                                        
                                                    } 

                                                </Grid>  
                                                   
                                        }        

                                                               
                                        {
                                            errores.horaCita
                                            &&
                                            <Alert severity='warning' variant='filled' sx={{ mt: 3 }}>
                                                {errores.horaCita}
                                            </Alert>

                                        }  

                                    </Grid>

                                    <Grid item md={12} xs={12} sx={{ mt: 1, display: { xs: 'none', md: 'block' } }}>  

                                        <Grid container spacing={3}>

                                            <Grid item md={6} xs={6} style={{ textAlign: 'left' }}>  

                                                <LoadingButton 
                                                    variant="text" 
                                                    color="inherit"
                                                    onClick={ handleBack }
                                                    startIcon={ <ArrowBackIcon /> }
                                                    loading={ loadingBack }
                                                > 
                                                    Regresar 
                                                </LoadingButton>

                                            </Grid>

                                            <Grid item md={6} xs={6} style={{ textAlign: 'right' }}>  

                                                <LoadingButton 
                                                    variant="contained" 
                                                    onClick={ handleNext }
                                                    endIcon={ <ArrowForwardIcon /> }
                                                    loading={ loadingNext }
                                                > 
                                                    Siguiente 
                                                </LoadingButton>

                                            </Grid>                                        

                                        </Grid>
                                    
                                    </Grid>

                                </Grid>
                            </>
                        ) :
                        activeStep === 3 ? (
                            <>                            
                                <Grid container spacing={3}  sx={{ mt: '1em', pr: { xs: '1em', md: '2em' }, pl: { xs: '1em', md: '2em' } }} > 

                                    <Grid item md={12} xs={12} sx={{ display: { xs: 'flex', md: 'none' }, textAlign: 'center' }} >

                                        <Typography variant='h6'>
                                            Confirmación de citas
                                        </Typography>

                                    </Grid>

                                    <Grid item md={12} xs={12} >

                                        <Grid container display='flex' flexDirection='column' alignItems='center'>

                                            <Grid item md={12} xs={12} sx={{ textAlign: 'center' }} >

                                                <Typography variant='subtitle1' sx={{ fontSize: 18, fontWeight:'bold' }}>
                                                    Revise que todos los datos sean correctos
                                                </Typography>

                                            </Grid>

                                            <Grid item md={12} xs={12} sx={{ mt: 2 }} >
                                            
                                                <Typography variant="body2" sx={{ mt: 2 }} >                                                            
                                                    <strong>Distrito:</strong>&nbsp; { distritosArray.find((e) => e.id === distritoCita)?.nombre ?? '' }
                                                </Typography>

                                                <Typography variant="body2" sx={{ mt: 2 }} >                                                            
                                                    <strong>Oficina:</strong>&nbsp; { oficinasArray.find((e) => e.id === oficinaCita)?.descripcion ?? '' }
                                                </Typography>

                                                <Typography variant="body2" sx={{ mt: 2 }} >                                                            
                                                    <strong>Servicio:</strong>&nbsp; { tramitesArray.find((e) => e.cit_servicio_id === tramiteCita)?.cit_servicio_descripcion ?? '' }
                                                </Typography>

                                                <Typography variant="body2" sx={{ mt: 2 }} >
                                                    <strong>Fecha:</strong>                                                             
                                                    &nbsp;{ fechasArray.find((e) =>  e.fecha ===  moment(new Date(fechaCita)).format('YYYY-MM-DD'))?.fecha ?? ''}
                                                    {tab}{tab}{tab}{tab} <strong>Hora:</strong> {horasArray.find((e) => e.horas_minutos === horaCita )?.horas_minutos.slice(0,-3) ?? ''}    
                                                </Typography>

                                                <Typography variant="body2" sx={{ mt: 2 }} >                                                            
                                                    <strong>Notas:</strong>&nbsp; { notas }
                                                </Typography>
                                                
                                            </Grid>

                                            <Grid item md={12} xs={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mt:3 }} >  

                                                <Typography component={'span'} variant={'body2'}>
                                                    <ReCAPTCHA
                                                        ref={captcha}
                                                        sitekey='6LdL-yMgAAAAAFaW2_5KwUlT5FXJjZYaPQd7fFbP'
                                                        onChange={onChangeCaptcha}
                                                    />
                                                    { ( captchaValido === false || captchaValido === null ) ? <Typography variant='body1'>Selecciona el captcha para continuar</Typography> : null }
                                                </Typography>
                                                

                                            </Grid>
                                            
                                        </Grid>

                                    </Grid>

                                    <Grid item md={12} xs={12} sx={{ mt: 1, textAlign: 'center'}}>
                                        {
                                            errorConfirm 
                                            &&
                                            <Typography sx={{ mt:1, color: '#BC0B0B', fontSize:18 }}>
                                                { errorConfirm }
                                            </Typography>
                                        }
                                    </Grid>

                                    <Grid item md={12} xs={12} sx={{ mt: 1, display: { xs: 'none', md: 'block' } }}>  

                                        <Grid container spacing={3}>

                                            <Grid item md={6} xs={6} style={{ textAlign: 'left' }}>  

                                                <LoadingButton 
                                                    variant="text" 
                                                    color="inherit"
                                                    onClick={ handleBack }
                                                    startIcon={ <ArrowBackIcon /> }
                                                    loading={ loadingBack }
                                                > 
                                                    Regresar 
                                                </LoadingButton>

                                            </Grid>

                                            <Grid item md={6} xs={6} style={{ textAlign: 'right' }}>  

                                                <Button 
                                                    variant="contained" 
                                                    onClick={ () => setOpenConfirmacion( true ) }
                                                    endIcon={ <EventAvailableIcon /> }
                                                    disabled={ captchaValido === false || captchaValido === null }
                                                > 
                                                    Confirmar cita
                                                </Button>

                                            </Grid>                                        

                                        </Grid>
                                    
                                    </Grid>

                                </Grid>
                            </>
                        ) :
                            <>
                                <Grid container spacing={3} sx={{ mt: '1em', pr: { xs: '1em', md: '2em' }, pl: { xs: '1em', md: '2em' } }} >

                                    <Grid item md={12} xs={12} sx={{ textAlign: 'center' }}>

                                        <CheckIcon sx={{ fontSize: '7em' }} color='success' />

                                    </Grid>

                                    <Grid item md={12} xs={12}>

                                        <Typography variant='h5' align='center' sx={{ color:"#002540", mt: 1, pl:5, pr:5, textTransform:'uppercase' }}>
                                            <b>Tu cita se agendo correctamente</b>
                                        </Typography>

                                        <Typography variant='h5' align='center' sx={{ mt: 2, pl:5, pr:5, color:"#002540" }}>
                                            <b>Código de asistencia</b>
                                        </Typography>
                                       
                                        <Typography align='center' sx={{ color:'#EB0000', mt: 1, fontSize:30 }}> { codigoA } </Typography>

                                        <Typography align='center' sx={{ color:'#002540', mt: 1, fontSize:14 }}> Muéstralo para marcar tu asistencia </Typography>
                                       
                                        <Box sx={{ mt: 3, ml: { md: 20, sm: 5, xs: 1 }, mr: { md: 20, sm: 5, xs: 1 } }}>
                                          
                                            <Typography align='center' variant='body2' sx={{  fontSize: 18, color:'#002540' }}>
                                                La confirmación de tu cita fue envíada a tu correo, la cual debes de mostrar junto con una identificación oficial al acudir a tu cita.
                                            </Typography>

                                            <Typography align='center' sx={{ mt:3, color:'#002540' }}>
                                                Agenda de forma responsable tus citas y haz un buen uso del sistema.
                                            </Typography>

                                            <Typography variant='subtitle1' align='center' sx={{ mt:3, fontWeight:500, color:'#B70000'}}>
                                                Puedes cancelar tu cita 24 horas antes de la fecha agendada. 
                                            </Typography>

                                        </Box>

                                    </Grid>

                                    <Grid item md={12} xs={12} sx={{ textAlign: 'center' }}>

                                        <Button
                                            variant='contained'
                                            startIcon={ <DateRangeIcon /> }
                                            onClick={ () => navigate('/citas/') }
                                        >
                                            Mis Citas
                                        </Button>

                                    </Grid>

                                </Grid>
                            </>
                    }

                    {
                        activeStep !== 4
                        &&
                        <Grid container spacing={3} sx={{ mt: '1em', pr: '1em', pl: '2em', display: { xs: 'block', md: 'none' } }} > 

                            <MobileStepper
                                variant={ null }
                                steps={ maxSteps }
                                position="static"
                                activeStep={ activeStep }
                                nextButton={
                                    <LoadingButton
                                        variant='contained'
                                        size="small"
                                        onClick={ () => activeStep === 3 ? setOpenConfirmacion( true ) : handleNext () }
                                        disabled={ ( activeStep === maxSteps ) || ( activeStep === 3 && ( captchaValido === false || captchaValido === null ) ) }
                                        loading={ activeStep !== 3 ? loadingNext : false }
                                        color={ activeStep === 5 ? 'success' : 'primary' }
                                    >
                                        { activeStep === 3 ? 'Confirmar' : 'Siguiente'}

                                        { theme.direction === 'rtl' ? (
                                            <KeyboardArrowLeftIcon /> 
                                        ) : (
                                            activeStep === 3 ? <EventAvailableIcon fontSize="small" /> : <KeyboardArrowRightIcon /> 
                                        )}
                                    </LoadingButton>
                                }
                                backButton={
                                    <LoadingButton 
                                        variant={ activeStep === 5 ? 'contained' : 'text'}
                                        size="small" 
                                        onClick={ handleBack }
                                        sx={{ display : activeStep === 0 ? 'none' : 'flex' }}
                                        loading={ loadingBack }
                                    >
                                        { theme.direction === 'rtl' ? (
                                            <KeyboardArrowRightIcon />
                                        ) : (
                                            activeStep === 5 ? <SendIcon fontSize="small" /> : <KeyboardArrowLeftIcon />
                                        )}
                                           Regresar
                                    </LoadingButton>
                                }
                            />

                        </Grid>
                    }

                </Box>

            </Container>

            <Dialog
                open={ openConfirmacion }
                onClose={ () => { } }
            >
                <DialogTitle>
                    Confirmación
                </DialogTitle>
            
                <DialogContent>

                    <DialogContentText sx={{ color: 'black' }}>
                        ¿Desea agendar la cita con la información registrada?
                    </DialogContentText>

                </DialogContent>

                <DialogActions>
                    <Button onClick={ () => { setOpenConfirmacion( false ); } } startIcon={ <CloseIcon /> }>Cancelar</Button>
                    <LoadingButton 
                        variant="contained" 
                        color="primary"
                        onClick={ guardarInformacion }
                        startIcon={ <CheckIcon /> }
                        loading={ loadingNext }
                    > 
                        Aceptar 
                    </LoadingButton>

                </DialogActions>

            </Dialog>
        
        </>
    )
}
