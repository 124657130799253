import React from 'react'

import { Box, Grid, Paper, Typography } from '@mui/material';

export const DownloadApp = () => {
    return (
        <Paper sx={{ backgroundColor: 'rgba(255, 255, 255, 1)', p: 1, mt: 3, mb: 4 }} >

            <Grid container spacing={3}>

                <Grid item xs={12} md={6}>

                    <Grid container spacing={3}>

                        <Grid item xs={12} md={12}>
                            
                            <Box display='flex' flexDirection='column' alignItems='center'> 

                                <Typography mt={4} variant='h6' fontSize={28}>Descarga nuestra app</Typography>

                                <Typography mt={1} variant='body2' textAlign={'center'} fontSize={16}>
                                    Está nueva aplicación concentra todas las herramientas de justicia digital.                                                
                                </Typography>
                        
                                <img 
                                    alt='logo app poder en linea'
                                    src={ process.env.PUBLIC_URL + '/assets/app-logo.png'}
                                    style={{ width: '22%', borderRadius: 15, marginTop: 15 }}
                                />

                            </Box>

                        </Grid>

                    </Grid>

                </Grid>

                <Grid item xs={12} md={6}>

                    <Grid container spacing={3}>

                        <Grid item xs={12} md={12}> 

                            <Box display='flex' flexDirection='column' alignItems='center' >     
                                    
                                <Grid container spacing={3}>                                                  
                                    
                                    <Grid item xs={6} md={6}>

                                        <Grid container spacing={3}> 

                                            <Grid item xs={12} md={12}>
                                                
                                                <Box display='flex' flexDirection='column' alignItems='center'> 

                                                    <a
                                                        href='https://play.google.com/store/apps/details?id=gob.pjecz.poder_en_linea_app'
                                                        target='_blank'
                                                        rel="noreferrer"
                                                    >
                                        
                                                        <img 
                                                            alt='google play'
                                                            src={ process.env.PUBLIC_URL + '/assets/googleplay.png'}
                                                            style={{ width: 180, marginTop: 15 }}                                        
                                                        />

                                                    </a>
                                                
                                                </Box>

                                            </Grid>

                                            <Grid item xs={12} md={12}>

                                                <Box display='flex' flexDirection='column' alignItems='center'> 
                                            
                                                    <img 
                                                        alt='qr google play'
                                                        src={ process.env.PUBLIC_URL + '/assets/qr-google-play.png'}     
                                                        style={{ width: 180 }}                                        
                                                    />

                                                </Box>

                                            </Grid> 

                                        </Grid>

                                    </Grid>
                                    
                                    <Grid item xs={6} md={6}>

                                        <Grid container spacing={3}>

                                            <Grid item xs={12} md={12}>

                                                <Box display='flex' flexDirection='column' alignItems='center'> 

                                                    <a                                                        
                                                        href='https://apps.apple.com/mx/app/poder-en-l%C3%ADne/id6475118346'
                                                        target='_blank'
                                                        rel="noreferrer"
                                                    >

                                                        <img 
                                                            alt='app store'
                                                            src={ process.env.PUBLIC_URL + '/assets/appstore.png'}     
                                                            style={{ width: 180, marginTop: 15 }}                                        
                                                        /> 

                                                    </a>
                                                    
                                                </Box>

                                            </Grid>

                                            <Grid item xs={12} md={12}>

                                                <Box display='flex' flexDirection='column' alignItems='center'> 

                                                    <img 
                                                        alt='qr app store'
                                                        src={ process.env.PUBLIC_URL + '/assets/qr-app-store.png'}     
                                                        style={{ width: 180 }}                                        
                                                    /> 
                                                    
                                                </Box>

                                            </Grid>

                                        </Grid>

                                    </Grid>                                                  

                                </Grid>    
                            
                            </Box>

                        </Grid>

                    </Grid>

                </Grid>

            </Grid>      

        </Paper>
    );
}
