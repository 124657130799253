import React from 'react';

import { Box, Container, Paper } from '@mui/material';

import { NavbarInicio } from '../ui/NavbarInicio';

import { NotFoundScreen } from '../auth/NotFoundScreen';
import { TabsUsuarioExternoScreen } from './TabsUsuarioExternoScreen';
import { TabsUsuarioInternoScreen } from './TabsUsuarioInternoScreen';

import { CardMantenimiento } from '../ui/CardMantenimiento';

import { usePermisos } from '../../hooks/usePermisos';

export const BuzonScreen = () => {

    const { tipoUsuario, banderaBuzon, banderaOnlineBuzon, loading } = usePermisos();

    return (
        <>

            <NavbarInicio />

            {
                ( !loading && !banderaBuzon )
                &&
                <NotFoundScreen />
            }

            {
                ( !loading && banderaBuzon )
                &&   

                <Container maxWidth='xl' sx={{ mt: { md: '10em', xs: '8em' } }}>

                    {
                        banderaOnlineBuzon
                        ?
                            <CardMantenimiento />
                        :
                        <>  

                            <Box sx={{ pb: '2em', pl: { md: 10, xs: 1 }, pr: { md: 10, xs: 1 }, pt: 2 }} component={ Paper } >	

                                {
                                    ( tipoUsuario === 1 || tipoUsuario === 5 )
                                    &&
                                    <TabsUsuarioInternoScreen />
                                }

                                {
                                    ( tipoUsuario === 2 || tipoUsuario === 14)
                                    &&
                                    <TabsUsuarioExternoScreen />
                                }

                            </Box>

                        </>
                    }
            
                </Container>
            
            }
        
        </>
    )

}
