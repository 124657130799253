import React from 'react';

import { Box, Container, Paper, Typography } from '@mui/material';

import { Navbar } from '../ui/Navbar';

export const AvisoPrivacidadScreen = () => {

    return (
        <>

            <Navbar />

            <Container maxWidth='lg' sx={{ mt: '8em' }}>
                
                <Box sx={{ textAlign: 'justify', p: '2em' }} component={ Paper } >		

                    <Typography variant='h4' sx={{ textAlign: 'center', mb: 2 }}>
                        Aviso de privacidad
                    </Typography>

                    <Typography variant='caption' sx={{ fontSize: 13 }} >
                        En los términos de lo dispuesto por los artículos 16, 20, 21 y 22 de la Ley de Protección de Datos Personales en Posesión de Sujetos Obligados del Estado de Coahuila, se emite el actual aviso de privacidad simplificado, en los siguientes términos:
                        <br/> <br/>
                    </Typography>

                    <Typography variant='caption' sx={{ fontWeight: 'bold', fontSize: 13 }}>
                        Las finalidades del tratamiento para las cuales se obtienen sus datos personales.
                        <br/> <br/>
                    </Typography>

                    <Typography variant='caption' sx={{ fontSize: 13 }}>
                        La Plataforma Poder en Línea (en adelante PPEL), recaba datos personales de los usuarios con el fin de brindar los servicios siguientes: 
                        
                        <br/> <br/>
                        <strong> a) </strong> envió de Demandas, <br/>
                        <strong> b) </strong> envió de Promociones, <br/>
                        <strong> c) </strong> citas en órganos jurisdiccionales para revisión de expedientes, tramitación de oficios, edictos y exhortos, <br/>
                        <strong> d) </strong> citas con actuarios y actuarias (civiles y familiares únicamente), <br/>
                        <strong> e) </strong> citas con el juzgador o juzgadora, <br/>
                        <strong> f) </strong> citas para expedición de copias simples o certificadas, <br/>
                        <strong> g) </strong> citas para devolución de documentos, <br/>
                        <strong> h) </strong> citas para entrega de cheques y certificados de depósitos, <br/>
                        <strong> i) </strong> Acceso a la versión digital de los expedientes mediante previa autorización, <br/>
                        <strong> j) </strong> Tramitación en línea de boletas de gestión actuarial para impulsar diligencias y <br/>
                        <strong> k) </strong> los demás que estén disponibles por parte de las autoridades en beneficio de la ciudadanía. <br/>
                        
                        <br/>
                        Razón por la cual los datos personales únicamente serán utilizados en el momento en el que se br/inden los servicios mencionados.
                        <br/> <br/>

                        <strong>Transferencias de datos personales.</strong>
                        
                        <br/> <br/>
                        Sus datos personales no podrán ser difundidos o transmitidos a terceros o al público en general, salvo que: 
                        <br/> <br/>
                        
                        <strong> a) </strong> medie su consentimiento expreso; <br/>
                        <strong> b) </strong> por disposición legal; o <br/>
                        <strong> c) </strong>  por ser indispensable para el ejercicio de alguna atribución por parte de esta u otra autoridad competente, 
                        incluyendo cualquier otro órgano jurisdiccional o área del Poder Judicial, en términos de los artículos 16 y 72 
                        de la Ley de Protección de Datos Personales en Posesión de Sujetos Obligados del Estado de Coahuila.
                        
                        <br/> <br/>

                        <strong>Mecanismos y medios disponibles para manifestar la negativa para el tratamiento de sus 
                        datos personales para finalidades y transferencias de datos personales.</strong> 
                        
                        <br/> <br/>
                        Usted por su propia cuenta o por medio de su representante, podrán solicitar el acceso, rectificación, cancelación u 
                        oposición al tratamiento de sus datos personales, conocidos como derechos ARCO. 
                        Para lo anterior, deberá comparecer personalmente o presentar la solicitud respectiva, por escrito, 
                        ante la Unidad de Atención a las Solicitudes de Acceso a la Información de la Secretaría Técnica y de Transparencia 
                        de la Presidencia del Tribunal Superior de Justicia del Estado. 
                        ubicada en Blvd. Venustiano Carranza número 2673, Colonia Santiago, en Saltillo, 
                        Coahuila de Zaragoza en un horario de atención de 8:30 a 16:30 horas de lunes a viernes.
                        
                        <br/> <br/>
                        El teléfono de contacto de la Unidad de Atención a las Solicitudes de Acceso a la Información Pública de la 
                        Secretaría Técnica y de Transparencia de la Presidencia del Tribunal Superior de Justicia del Estado, 
                        para cualquier duda, es el siguiente: 844 438 09 80 ext. 6808, el cual será atendido en un 
                        horario de lunes a viernes de 08:30 a 16:30 horas.
                        
                        <br/> <br/>
                        El Aviso de Privacidad Integral estará a su disposición en la página de internet siguiente: {' '}             
                        <a href="https://www.pjecz.gob.mx/consultas/poder-en-linea/aviso-de-privacidad.html" style={{ color:'#252B49' }}>
                            https://www.pjecz.gob.mx/consultas/poder-en-linea/aviso-de-privacidad.html
                        </a>
                        
                    </Typography>

                </Box>

            </Container>

        </>
    );

};