import React from 'react';

import { Navigate } from 'react-router-dom';

export const PublicRoute = ({ token, children }) => {

    const lastPath = window.localStorage.getItem("lastPath");

    return ( !Boolean( token ) )
        ? children
        : <Navigate to={ lastPath ? lastPath : "/inicio/" } />
        
}