import React, { useState, useEffect } from 'react';

import { Alert, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput } from '@mui/material';

import { LoadingButton } from '@mui/lab';

import SaveIcon from '@mui/icons-material/Save';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import { ActualizarUsuarioContrasena, DetalleContrasena } from '../../actions/admin/usuarioActions';

export const ModalAdminContrasena = ({ open, setOpen, referencia, setReferencia }) => {

    const [contrasena, setContrasena] = useState( '' );
    const [confirmarContrasena, setConfirmarContrasena] = useState( '' );

    const [viewContrasena, setViewContrasena] = useState( false );
    const [viewConfirmarContrasena, setViewConfirmarContrasena] = useState( false );

    const handleChangeViewContrasena = () => setViewContrasena( ( prev ) => !prev );
    const handleChangeViewConfirmarContrasena = () => setViewConfirmarContrasena( ( prev ) => !prev );

    const [errores, setErrors] = useState( {} )
    const [loading, setLoading] = useState( false );
    const [loadingSave, setLoadingSave] = useState( false );

    const validateFields = () => {

        let valid = true;
        let errores = {};

        if ( contrasena === '' ) {
            valid = false
            errores.contrasena = 'Debe escribir la contraseña';
        }
        else if ( confirmarContrasena === '' ) {
            valid = false
            errores.contrasena = 'Debe confirmar la contraseña';
        }
        else if ( ( confirmarContrasena !== '' || contrasena !== '' ) && ( contrasena !== confirmarContrasena )) {
            valid = false
            errores.contrasena = 'las contraseñas no coinciden';
        }

        setErrors( errores );

        return valid;
    }

    const handleClose = () => {

        setReferencia( '' );

        setContrasena( '' );
        setViewContrasena( false );

        setConfirmarContrasena( '' );
        setViewConfirmarContrasena( false );

        setOpen( false );

    }

    const handleActualizarContrasena = async () => {
        
        const valid = validateFields();

        if ( !valid ) {
            return false;
        }

        setLoadingSave( true );

        const params = { id: referencia, contrasena }

        await ActualizarUsuarioContrasena( params ).then( response => {

            if( response ){

                if( response.status === 200 ){

                    const { success }  = response.data;

                    setTimeout(() => {                      
                    
                        if( success ) {

                            setLoadingSave( false );  

                            handleClose();
                        }
                        else {       
                            
                            setLoadingSave( false );                             
                        }

                    }, 1000);
                }
            }
        })
    }

    useEffect(() => {

        async function Obtener(){

            setLoading( true );

            await DetalleContrasena( referencia ).then( response => {

                if( response ){

                    if( response.status === 200 ){

                        setTimeout(() => {                          
                        
                            setContrasena( response.data.contrasena ?? 0 );
                            setConfirmarContrasena( response.data.contrasena ?? 0 );
                            
                            setLoading( false );

                        }, 700);                       

                    }

                }

            });

        }

        if( open && referencia ){
            Obtener();
        }

      
    }, [ open, referencia ])  

    return (
        <Dialog 
            open={ open } 
            onClose={ () => {} } 
            maxWidth='xs' 
            fullWidth 
        >
                
            <DialogTitle>
                Actualizar contraseña
            </DialogTitle>
            
            <DialogContent>

                {
                    loading
                    ?   
                        <Grid container spacing={3} sx={{ justifyContent: 'center' }}>
                            <CircularProgress size={40} sx={{ mt: 5, mb: 5 }} />
                        </Grid>
                    :            
                        <Grid container spacing={3} sx={{ pt: 1 }}> 

                            <Grid item md={12} xs={12} >

                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel>Contraseña</InputLabel>
                                    <OutlinedInput
                                        label="Contraseña"
                                        name="contrasena"
                                        type={ viewContrasena ? 'text' : 'password'}                                            
                                        value={ contrasena }
                                        onChange={ (e) => { setContrasena( e.target.value ) }}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton onClick={ handleChangeViewContrasena } edge="end" >
                                                    {
                                                        viewContrasena
                                                        ?
                                                        <VisibilityOffIcon />
                                                        :
                                                        <VisibilityIcon />
                                                    }                                                    
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>

                            </Grid>    

                            <Grid item md={12} xs={12} >

                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel>Confirmar contraseña</InputLabel>
                                    <OutlinedInput
                                        label="Confirmar contraseña"
                                        name="confirmarContrasena"
                                        type={ viewConfirmarContrasena ? 'text' : 'password'}                                            
                                        value={ confirmarContrasena }
                                        onChange={ (e) => { setConfirmarContrasena( e.target.value) } }
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton onClick={ handleChangeViewConfirmarContrasena } edge="end" >
                                                    {
                                                        viewConfirmarContrasena
                                                        ?
                                                        <VisibilityOffIcon />
                                                        :
                                                        <VisibilityIcon />
                                                    }                                                    
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>

                            </Grid>    

                            {
                                errores.contrasena
                                &&
                                <Grid item md={12} xs={12} >
                                    <Alert severity='warning' variant='filled'>
                                        { errores.contrasena }
                                    </Alert>
                                </Grid>
                            }
                        
                        </Grid>
                }
            
            </DialogContent>

            <DialogActions>
                <Button onClick={ handleClose }> Cancelar </Button>
                <LoadingButton onClick={ handleActualizarContrasena } variant='contained' startIcon={ <SaveIcon /> } loading={ loadingSave } > Guardar </LoadingButton>
            </DialogActions>

        </Dialog>
    )

}
