import httpCliente from '../../services/httpClient';
import httpClienteToken from '../../services/httpClientToken';

export const ObtenerTipoJuicio = ( id ) => {

    return new Promise( (resolve, eject) => {

        httpCliente.get(`/Api/TipoJuicio/ObtenerTipoJuicio/${ id }`)
        .then( response => {
            if( response.status === 200 ){
                resolve( response );
            }
        })
        .catch( (error) => {
            resolve( error.response );
        });
        
    });

}


export const ObtenerTipoJuicioMaterias = ( id ) => {

    return new Promise( (resolve, eject) => {

        const data = JSON.parse(window.localStorage.getItem('data'));

        if(data){
            
            const { token } = data ;
            
            httpCliente.get(`/Api/TipoJuicio/ObtenerTipoJuicioMaterias/${ id }`, token)
            .then( response => {
                if( response.status === 200 ){
                    resolve( response );
                }
            })
            .catch( (error) => {
                resolve( error.response );
            });
        }
    });

}

export const TipoJuicio = ( ) => {

    return new Promise( (resolve, eject) => {

        const data = JSON.parse(window.localStorage.getItem('data'));

        if( data ){
            const { token } = data;

            httpClienteToken.get('/Api/TipoJuicio/', token)
            .then( response => {
                if( response.status === 200 ){
                    resolve( response );
                }
            })
            .catch( (error) => {
                resolve( error.response );
            });
        }

    });

}

export const ActualizarTipoJuicio = ( params ) => {

    return new Promise( (resolve, eject) => {

        const data = JSON.parse(window.localStorage.getItem('data'));

        if( data ){
            const { token } = data;

            httpClienteToken.post('/Api/TipoJuicio/Actualizar', params, token)
            .then( response => {
                if( response.status === 200 ){
                    resolve( response );
                }
            })
            .catch( (error) => {
                resolve( error.response );
            });
        }

    });

}

export const AgregarTipoJuicio = ( params ) => {

    return new Promise( (resolve, eject) => {

        const data = JSON.parse(window.localStorage.getItem('data'));

        if( data ){
            const { token } = data;

            httpClienteToken.post('/Api/TipoJuicio/Agregar', params, token)
            .then( response => {
                if( response.status === 200 ){
                    resolve( response );
                }
            })
            .catch( (error) => {
                resolve( error.response );
            });
        }

    });

}