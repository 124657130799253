import React from 'react';

import { Navigate, useLocation } from 'react-router-dom';

export const PrivateRoute = ({ token, children }) => {

    const { pathname, search } = useLocation();
    
    const lastPath = pathname + search;    
    localStorage.setItem('lastPath', lastPath );
        
    return ( Boolean( token ) )
        ? children
        : <Navigate to="/auth/login" />
}