import httpClienteToken from '../../services/httpClientToken';
import httpClientFormData from '../../services/httpClientFormData';

export const ObtenerSolicitudesPendientes = ( ) => {

    return new Promise( (resolve, eject) => {

        const data = JSON.parse(window.localStorage.getItem('data'));

        if( data ){
            const { token } = data;

            httpClienteToken.get('/Api/Solicitud/ObtenerSolicitudesPendientes/', token)
            .then( response => {
                if( response.status === 200 ){
                    resolve( response );
                }
            })
            .catch( (error) => {
                resolve( error.response );
            });
        }

    });

}

export const CancelarSolicitud = ( params ) => {

    return new Promise( (resolve, eject) => {

        const data = JSON.parse(window.localStorage.getItem('data'));

        if( data ){
            const { token } = data;

            httpClienteToken.post('/Api/Solicitud/CancelarSolicitud/', params, token)
            .then( response => {
                if( response.status === 200 ){
                    resolve( response );
                }
            })
            .catch( (error) => {
                resolve( error.response );
            });
        }

    });

}

export const CuentaExistente = ( params ) => {

    return new Promise( (resolve, eject) => {

        const data = JSON.parse(window.localStorage.getItem('data'));

        if( data ){
            const { token } = data;

            httpClienteToken.post('/Api/Solicitud/CuentaExistente/', params, token)
            .then( response => {
                if( response.status === 200 ){
                    resolve( response );
                }
            })
            .catch( (error) => {
                resolve( error.response );
            });
        }

    });

}

export const AceptarSolicitud = ( params ) => {

    return new Promise( (resolve, eject) => {

        const data = JSON.parse(window.localStorage.getItem('data'));

        if( data ){
            const { token } = data;

            httpClienteToken.post('/Api/Solicitud/AceptarSolicitud/', params, token)
            .then( response => {
                if( response.status === 200 ){
                    resolve( response );
                }
            })
            .catch( (error) => {
                resolve( error.response );
            });
        }

    });

}

export const BuscarSolicitudes = ( params ) => {

    return new Promise( (resolve, eject) => {

        const data = JSON.parse(window.localStorage.getItem('data'));

        if( data ){
            const { token } = data;

            httpClienteToken.post('/Api/Solicitud/BuscarSolicitudes/', params, token)
            .then( response => {
                if( response.status === 200 ){
                    resolve( response );
                }
            })
            .catch( (error) => {
                resolve( error.response );
            });
        }

    });

}

export const RegistrarCuenta = ( params ) => {

    return new Promise( (resolve, eject) => {     

        httpClientFormData.post('/Api/Solicitud/RegistrarCuenta/', params)
        .then( response => {
            if( response.status === 200 ){
                resolve( response );
            }
        })
        .catch( (error) => {
            resolve( error.response );
        });        

    });

}
export const EnviarCodigoVerificacion = ( params ) => {

    return new Promise( (resolve, eject) => {     

        httpClienteToken.post('/Api/Solicitud/EnviarCodigoVerificacion/', params)
        .then( response => {
            if( response.status === 200 ){
                resolve( response );
            }
        })
        .catch( (error) => {
            resolve( error.response );
        });        

    });

}
export const ReenviarCodigoVerificacion = ( params ) => {

    return new Promise( (resolve, eject) => {     

        httpClienteToken.post('/Api/Solicitud/ReenviarCodigoVerificacion/', params)
        .then( response => {
            if( response.status === 200 ){
                resolve( response );
            }
        })
        .catch( (error) => {
            resolve( error.response );
        });        

    });

}

export const VerificarCodigoRegistro = ( params ) => {

    return new Promise( (resolve, eject) => {     

        httpClienteToken.post('/Api/Solicitud/VerificarCodigoRegistro/', params)
        .then( response => {
            if( response.status === 200 ){
                resolve( response );
            }
        })
        .catch( (error) => {
            resolve( error.response );
        });        

    });

}