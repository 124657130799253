import React from 'react';

import { Navigate, Route, Routes } from 'react-router-dom';

import { LoginScreen } from '../components/auth/LoginScreen';
import { RegisterScreen } from '../components/auth/RegisterScreen';
import { ForgotPasswordScreen } from '../components/auth/ForgotPasswordScreen';

export const AuthRoutes = () => {

    return (
        <Routes>

            <Route path="login" element={ <LoginScreen /> } />    

            <Route path="register" element={ <RegisterScreen /> } />        

            <Route path="password" element={ <ForgotPasswordScreen /> } />                                      

            <Route path='/*' element={ <Navigate to="/auth/login" /> } />                          

        </Routes>
    )

}