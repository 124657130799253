import React from 'react';

import { Card, Typography } from '@mui/material';

export const CardMantenimiento = () => {

    return (
        <Card>           
                           
            <img 
                src={ process.env.PUBLIC_URL + '/assets/mantenimiento.png'} 
                alt="mantenimiento" 
                style={{                                        
                    marginTop: 50,
                    display: 'block',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    width: '40%',
                    borderRadius: '10px',
                }}
            />                
        
            <Typography variant='h3' textAlign='center'sx={{ mt: 5 }} >
                <strong>VENTANA DE MANTENIMIENTO</strong>
            </Typography>

            <Typography variant='h5' textAlign='center' sx={{ mt: 5, mb: 10 }} >  
                En este momento nos encontramos en mantenimiento y actualización para mejorar nuestro servicio.
                <br /><br />                                   
                De antemano agradecemos su compresión.
            </Typography>

        </Card>
    )

}
