import React, { useState, useEffect } from 'react';

import { Alert, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';

import { LoadingButton } from '@mui/lab';

import SaveIcon from '@mui/icons-material/Save';

import { ObtenerEstados } from '../../actions/catalogos/estadoActions';
import { ObtenerMunicipios } from '../../actions/catalogos/municipioActions';
import { ObtenerAsentamientos, ObtenerCodigosPostales } from '../../actions/catalogos/asentamientoActions';
import { ObtenerTipoDomicilios } from '../../actions/catalogos/tipoDomicilioActions';

import { ActualizarUsuarioDomicilio, DetalleDomicilio } from '../../actions/admin/usuarioActions';

export const ModalAdminDomicilio = ({ open, setOpen, referencia, setReferencia }) => {

    const [errores, setErrores] = useState( {} );
    const [openLoading, setOpenLoading] = useState( false );
    
    const [estado, setEstado] = useState( 0 );
    const [estadoArray, setEstadoArray] = useState( [] );
    
    const [municipio, setMunicipio] = useState( 0 );
    const [municipioArray, setMunicipioArray] = useState( [] );
    
    const [codigoPostal, setCodigoPostal] = useState( '0' );
    const [codigoPostalArray, setCodigoPostalArray] = useState( [] );
    
    const [asentamiento, setAsentamiento] = useState( 0 );
    const [asentamientoArray, setAsentamientoArray] = useState( [] );
    
    const [tipoDomicilio, setTipoDomicilio] = useState( 0 );
    const [tipoDomicilioArray, setTipoDomicilioArray] = useState( [] );
    
    const [calle, setCalle] = useState( '' );
    const [numero, setNumero] = useState( '' );

    const [usuario, setUsuario] = useState( {} );

    const [loading, setLoading] = useState( false );
    
    const validateFields = () => {

        let valid = true;
        let errores = {};

        if ( estado === 0 ) {
            valid = false
            errores.estado = 'Seleccione un estado'
        }

        if ( municipio === 0 ) {
            valid = false
            errores.municipio = 'Seleccione un municipio'
        }

        if ( codigoPostal === '0' ) {
            valid = false
            errores.codigoPostal = 'Seleccione un código Postal'
        }

        if ( tipoDomicilio === 0 ) {
            valid = false
            errores.tipoDomicilio = 'Seleccione un tipo de domicilio'
        }
        if ( asentamiento === 0 ) {
            valid = false
            errores.asentamiento = 'Seleccione un asentamiento'
        }

        if ( calle === '' ) {
            valid = false
            errores.calle = 'Escribir la calle o vialidad'
        }

        if ( numero === '' ) {
            valid = false
            errores.numero = 'Escribir el número'
        }

        setErrores( errores );

        return valid;
    }

    const handleActualizarDomicilio = async () => {
        
        const valid = validateFields();

        if ( !valid ) {
            return false;
        }

        setOpenLoading( true );

        const params = {
            id: referencia,
            IdAsentamiento : asentamiento,
            IdTipoDomicilio: tipoDomicilio,
            CalleVialidad: calle,
            Numero: numero,
            Referencia: '',
        }

        await ActualizarUsuarioDomicilio( params ).then( response => {

            if( response ){

                if( response.status === 200 ){

                    const { success, data }  = response.data;

                    setTimeout(() => {                      
                    
                        if( success ) {

                            setUsuario({
                                ...usuario,
                                ...data,
                            });

                            setOpenLoading( false );  

                            handleClose();
                        }
                        else {       
                            
                            setOpenLoading( false );                             
                        }

                    }, 1000);
                }
            }
        })
    }

    const handleClose = () => {
        
        setReferencia( '' );
        setNumero( '' );
        setCalle( '' );
        setTipoDomicilio( 0 );

        setAsentamientoArray( [] );
        setAsentamiento( 0 );

        setCodigoPostalArray( [] );
        setCodigoPostal( '0' );        

        setMunicipioArray( [] );
        setMunicipio( 0 );

        setEstado( 0 );

        setErrores( {} );
        
        setOpen( false );
    }

    useEffect(() => {

        async function Obtener() {

            await ObtenerEstados().then( response => {

                if( response ){

                    if ( response.status === 200 ) {
                    
                        if ( response.data ) {
                            setEstadoArray( response.data );
                        }

                    }

                }

            });

        }

        Obtener();

    }, [])

    useEffect(() => {

        async function Obtener() {

            setMunicipio( 0 );
            
            await ObtenerMunicipios( estado ).then( response => {

                if( response ){

                    if ( response.status === 200 ) {

                        if ( response.data ) {
                            setMunicipioArray( response.data );
                        }

                    }

                }

            });
        }

        Obtener();

    }, [ estado ])

    useEffect(() => {

        async function Obtener() { 

            setCodigoPostal( '0' )
           
            await ObtenerCodigosPostales( municipio ).then( response => {

                if( response ){

                    if ( response.status === 200 ) {
                    
                        if ( response.data ) {
                            setCodigoPostalArray( response.data );
                        }

                    }

                }               

            });
        }

        Obtener();

    }, [ municipio ])

    useEffect(() => {

        async function Obtener() {

            setAsentamiento( 0 ) 

            const param = {
                codigoPostal: codigoPostal,
                idMunicipio: municipio,
            };

            await ObtenerAsentamientos(param).then(response => {

                if( response ){
             
                    if ( response.status === 200 ) {

                        if ( response.data ) {
                            setAsentamientoArray( response.data );
                        }

                    }

                }

            });
        }

        Obtener();

    }, [ municipio, codigoPostal ])

    useEffect(() => {

        async function Obtener() {

            setTipoDomicilio( 0 );
            
            await ObtenerTipoDomicilios().then(response => {

                if( response ){

                    if (response.status === 200) {
                        
                        if (response.data) {
                            setTipoDomicilioArray(response.data);
                        }

                    }

                }

            });

        }

        Obtener();

    }, [])

    useEffect(() => {

        async function Obtener(){

            setLoading( true );

            await DetalleDomicilio( referencia ).then( response => {

                if( response ){

                    if( response.status === 200 ){

                        setTimeout(() => {                          
                        
                            setUsuario( response.data ?? {} );
                            
                            setLoading( false );

                        }, 700);                       

                    }

                }

            });

        }

        if( open && referencia ){
            Obtener();
        }

      
    }, [ open, referencia ])    

    // parte del codigo donde se asigna los valores del domicilio del usuario
    useEffect(() => {

        if( Object.keys( usuario ).length !== 0 && open ){

            setEstado( usuario.idEstado ?? 0 );

            setTipoDomicilio(usuario.idTipoDomicilio ?? 0 );
            setCalle( usuario.calleVialidad ?? '' );
            setNumero( usuario.numero === 0 ? '' : usuario.numero );
            
        }

    }, [ open, usuario ]); 

    useEffect(() => {
        
        if( Object.keys( usuario ).length !== 0  && open ){
        
            setMunicipio( 0 );

            if( municipioArray.length > 0 && usuario.idEstado === estado){

                const findMunicipio = municipioArray.find( ( elem ) => elem.idMunicipio === usuario.idMunicipio );

                if( findMunicipio ){
                    setMunicipio( usuario.idMunicipio ?? 0 );
                }
                
            }

        }


    }, [ open, usuario, estado, municipioArray ]);

    useEffect(() => {
        
        if( Object.keys( usuario ).length !== 0  && open ){
        
            setCodigoPostal( '0' );

            if( codigoPostalArray.length > 0 && usuario.idMunicipio === municipio){

                const findCodigoPostal = codigoPostalArray.find( ( elem ) => elem.codigoPostal === usuario.codigoPostal );

                if( findCodigoPostal ){
                    setCodigoPostal( usuario.codigoPostal ?? '0' );
                }

            }

        }

    }, [ open, usuario, municipio, codigoPostalArray ]);

    useEffect(() => {
        
        if( Object.keys( usuario ).length !== 0  && open ){
        
            setAsentamiento( 0 );

            if( asentamientoArray.length > 0 && usuario.codigoPostal === codigoPostal){

                const findAsentamiento = asentamientoArray.find( ( elem ) => elem.idAsentamiento === usuario.idAsentamiento );

                if( findAsentamiento ){
                    setAsentamiento( usuario.idAsentamiento ?? 0 );
                }               
            }

        }


    }, [ open, usuario, codigoPostal, asentamientoArray ]);
    
    return (
        <Dialog open={open} onClose={() => { }} maxWidth='sm' fullWidth >

            <DialogTitle>
                Editar domicilio
            </DialogTitle>

            <DialogContent>

                {
                    loading
                    ?   
                        <Grid container spacing={3} sx={{ justifyContent: 'center' }}>
                            <CircularProgress size={40} sx={{ mt: 5, mb: 5 }} />
                        </Grid>
                    :
                        <Grid container spacing={3} sx={{ pt: 1 }}>

                            <Grid item md={6} xs={12} >

                                <FormControl fullWidth>

                                    <InputLabel id="select-estado-label">Estado</InputLabel>

                                    <Select
                                        labelId="select-estado-label"
                                        id="select-estado"
                                        label="estado"
                                        name="estado"
                                        value={ estado }
                                        onChange={ (e) => setEstado( e.target.value ) }
                                    >
                                        <MenuItem key={0} value={0}>Seleccione una opción</MenuItem>
                                        {
                                            estadoArray.map( ( element ) => (
                                                <MenuItem key={ element.idEstado } value={ element.idEstado }> { element.descripcion } </MenuItem>
                                            ))
                                        }
                                    </Select>
                                    
                                </FormControl>

                                {
                                    errores.estado
                                    &&                          
                                    <Alert severity='warning' variant='filled' sx={{ mt: 1 }}>
                                        { errores.estado }
                                    </Alert>                          
                                }

                            </Grid>

                            <Grid item md={6} xs={12} >

                                <FormControl fullWidth>

                                    <InputLabel id="select-municipio-label">Municipio</InputLabel>

                                    <Select
                                        labelId="select-municipio-label"
                                        id="select-municipio"
                                        label="municipio"
                                        name="municipio"
                                        value={ municipio }
                                        onChange={ (e) => setMunicipio( e.target.value ) }
                                        disabled={ estado === 0 }
                                    >
                                        <MenuItem key={0} value={0}>Seleccione una opción</MenuItem>
                                        {
                                            municipioArray.map( ( element ) => (
                                                <MenuItem key={ element.idMunicipio } value={ element.idMunicipio } > { element.descripcion } </MenuItem>
                                            ))
                                        }
                                    </Select>
                            
                                </FormControl>

                                {
                                    errores.municipio
                                    &&                          
                                    <Alert severity='warning' variant='filled' sx={{ mt: 1 }}>
                                        { errores.municipio }
                                    </Alert>                          
                                }

                            </Grid>

                            <Grid item md={6} xs={12} >

                                <FormControl fullWidth>

                                    <InputLabel id="select-codigoPostal-label">Código Postal</InputLabel>

                                    <Select
                                        labelId="select-codigoPostal-label"
                                        id="select-codigoPostal"
                                        label="codigoPostal"
                                        name="codigoPostal"
                                        value={ codigoPostal }
                                        onChange={ (e) => setCodigoPostal( e.target.value ) }
                                        disabled={ municipio === 0 }
                                    >
                                        <MenuItem key={'0'} value={'0'}>Seleccione una opción</MenuItem>
                                        {
                                            codigoPostalArray.map( ( element ) => (
                                                <MenuItem key={ element.codigoPostal } value={ element.codigoPostal } > { element.codigoPostal } </MenuItem>
                                            ))
                                        }
                                    </Select>
                            
                                </FormControl>

                                {
                                    errores.codigoPostal
                                    &&                          
                                    <Alert severity='warning' variant='filled' sx={{ mt: 1 }}>
                                        { errores.codigoPostal }
                                    </Alert>                          
                                }

                            </Grid>

                            <Grid item md={6} xs={12} >

                                <FormControl fullWidth>

                                    <InputLabel id="select-asentamiento-label">Asentamiento</InputLabel>

                                    <Select
                                        labelId="select-asentamiento-label"
                                        id="select-asentamiento"
                                        label="asentamiento"
                                        name="asentamiento"
                                        value={ asentamiento }
                                        onChange={ (e) => setAsentamiento( e.target.value ) }
                                        disabled={ codigoPostal === 0 }
                                    >
                                        <MenuItem key={0} value={0}>Seleccione una opción</MenuItem>
                                        {
                                            asentamientoArray.map( ( element ) => (
                                                <MenuItem key={ element.idAsentamiento } value={ element.idAsentamiento } > { element.descripcion } </MenuItem>
                                            ))
                                        }
                                    </Select>
                                    
                                </FormControl>

                                {
                                    errores.asentamiento
                                    &&                          
                                    <Alert severity='warning' variant='filled' sx={{ mt: 1 }}>
                                        { errores.asentamiento }
                                    </Alert>                          
                                }

                            </Grid>

                            <Grid item md={12} xs={12} >

                                <FormControl fullWidth>

                                    <InputLabel id="select-tipoDomicilio-label">Tipo de Domicilio</InputLabel>

                                    <Select
                                        labelId="select-tipoDomicilio-label"
                                        id="select-tipoDomicilio"
                                        label="Tipo de Domicilio"
                                        name="tipoDomicilio"
                                        value={ tipoDomicilio }
                                        onChange={ (e) => setTipoDomicilio( e.target.value ) }

                                    >
                                        <MenuItem key={0} value={0}>Seleccione una opción</MenuItem>
                                        {
                                            tipoDomicilioArray.map( ( element ) => (
                                                <MenuItem key={ element.idTipoDomicilio } value={ element.idTipoDomicilio } > { element.descripcion } </MenuItem>
                                            ))
                                        }
                                    </Select>
                                    
                                </FormControl>

                                {
                                    errores.tipoDomicilio
                                    &&                          
                                    <Alert severity='warning' variant='filled' sx={{ mt: 1 }}>
                                        { errores.tipoDomicilio }
                                    </Alert>                          
                                }

                            </Grid>

                            <Grid item md={6} xs={12} >

                                <TextField
                                    fullWidth
                                    label="Calle o Vialidad"
                                    name="calleVialidad"
                                    variant="outlined"     
                                    autoComplete='off'                   
                                    value={ calle }
                                    onChange={ (e) => setCalle( e.target.value ) }
                                />
                                
                                {
                                    errores.calle
                                    &&                          
                                    <Alert severity='warning' variant='filled' sx={{ mt: 1 }}>
                                        { errores.calle }
                                    </Alert>
                                
                                }
                            </Grid>

                            <Grid item md={6} xs={12} >

                                <TextField
                                    fullWidth
                                    label="Número Int./ext."
                                    name="numero"
                                    variant="outlined"
                                    autoComplete='off'
                                    value={ numero }
                                    onChange={ (e) => setNumero( e.target.value ) }
                                />
                            
                                {
                                    errores.numero
                                    &&                          
                                    <Alert severity='warning' variant='filled' sx={{ mt: 1 }}>
                                        { errores.numero }
                                    </Alert>
                                
                                }
                            </Grid>

                        </Grid>
                }

            </DialogContent>

            <DialogActions>
                <Button onClick={ handleClose }> Cancelar </Button>
                <LoadingButton onClick={ handleActualizarDomicilio } variant='contained' startIcon={ <SaveIcon /> } loading={ openLoading } > Guardar </LoadingButton>
            </DialogActions>

        </Dialog>
    )

}
