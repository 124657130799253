
export const words = [
    'Roca', 
    'Papel', 
    'Tijeras',
    'Perro',
    'Gato',
    'Leon',
    'Pelo',
    'Raton',
    'Guitarra',
    'Salsa',
    'Mango',
    'Pera',
    'Manzana',
    'Oso',
    'Lobo',
    'Jaguar',
    'Cajon',
    'Chile',
    'Pilar',
    'Silla',
    'Mesa',
    'Impresora',
    'Camara',
    'Celular',
    'Pila',
    'Lapiz',
    'Television',
    'Teclado',
    'Laptop',
    'Borrador',
    'Lentes',
    'Cinta',
    'Cafe',
    'Vaso',
    'Caja',
    'Bateria',
    'Calendario',
    'Disco',
    'Reloj',
    'Cargador',
    'Pantalon',
    'Llave',
    'Escudo',
    'Espada',
    'Daga',
    'Fuego',
    'Agua',
    'Aire',
    'Tierra',
    'Foco',
    'Chocolate',
    'Refrigerador',
    'Carne',
    'Tinta',
    'Craneo',
    'Madera',
    'Arcilla',
    'Paja',
    'Ciudad',
    'Puente',
    'Camino',
    'Ladron',
    'Ejercito',
    'Mago',
    'Duende',
    'Guerrero',
    'Sacerdote',
    'Papa',
    'Calcetin',
    'Zapato',
    'Coca',
    'Pastilla',
    'Mano',
    'Dedo',
    'Ojo',
    'Diente',
    'Nariz',
    'Moneda',
    'Billete',
    'Billetera',
    'Pluma',
    'Cable',
    'Plato',
    'Mancha',
    'Pasta',
    'Cucharon',
    'Cuchillo',
    'Cuchara',
    'Cepillo',
    'Perilla',
    'Mono',
    'Cuerda',
    'Ventana',
    'Vidrio',
    'Espejo',
    'Piña',
    'Nube',
    'Tapa',
    'Manga',
    'Casa',
    'Bicho',
    'Miel',
    'Lampara',
    'Carro',
    'Camioneta',
    'Sabana',
    'Cama',
    'Camisa',
    'Hongo',
    'Mula',
];