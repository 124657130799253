import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { Alert, Box, Button, Card, CardActions, CardContent, CardHeader, CircularProgress, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Link, Tooltip, Typography } from '@mui/material';

import { LoadingButton } from '@mui/lab';

import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';

import moment from 'moment'

import { NavbarInicio } from '../ui/NavbarInicio';

import { usePermisos } from '../../hooks/usePermisos';

import { NotFoundScreen } from '../auth/NotFoundScreen';

import { DeleteCitas, GetCitCitasDisponibles, GetCitMisCitas, GetPollPendiente } from '../../actions/citas/CitCitasActions';

export const CitasScreen = () => {

    const navigate = useNavigate();

    const { banderaCitas, loading, idUsuarioCitas} = usePermisos();
    
    const [loadCitas, setLoadCitas] = useState( false );

    const [limitCit, setLimitCit] = useState(false);
    
    const [data, setData] = useState( [] );

    const [encuestaPend, setEncuestaPend] = useState("");

    const [open, setOpen] = useState( false );

    const [loadingAcept, setLoadingAcept] = useState( false );

    const [toDelete, setToDelete] = useState("");

    const format = ( inicio ) => moment( inicio ).format("YYYY-MM-DD HH:mm")

    const handleClickDelete = async () => {
     
        setLoadingAcept( true );

        const params = {
            id: toDelete,
            cit_cliente_id:idUsuarioCitas,
        }
        
        await DeleteCitas(params).then( response => {

            if( response ){

                if( response.status === 200 ){

                    if( response.data.success ){

                        setTimeout(() => {

                            const cancelCard = data.filter( data => data.id !== toDelete );

                            setData(cancelCard);

                            setToDelete( '' );

                            setOpen( false );
                            setLoadingAcept( false );
                            setLimitCit(false);
                
                        }, 500);

                    }
                    
                }
                        
            }
                
        })

    }

    useEffect(() => {
        
        async function fetchData(){

            setLoadCitas( true );

            await GetCitMisCitas(idUsuarioCitas).then(response => {

                if( response.status === 200 ){

                    setTimeout( () => {
                        
                        setData(response.data.result.items);
                        setLoadCitas(false);
                        
                    }, 1000);
                        
                }
                
            });
            
        }

        if( idUsuarioCitas !== 0 ){
            fetchData();
        }

    }, [ idUsuarioCitas ])

    useEffect(() => {

        async function fetchData(){

            await GetCitCitasDisponibles(idUsuarioCitas).then(response => {
                
                if( response.status === 200 ){
    
                    if( response.data.cantidad === 0 ){
                        setLimitCit(true);
                    }
                    else{
                        setLimitCit(false);
                    }
    
                    
                }

            })

        }

        if( idUsuarioCitas !== 0 ){
            fetchData();
        }

    },[ idUsuarioCitas ])

    useEffect(() => {

        async function fetchData(){

            await GetPollPendiente(idUsuarioCitas).then( response => {

                if( response ){
    
                    if( response.status === 200 ){

                        if( response.data.success ){

                            setEncuestaPend(response.data.url);

                        }

                    }
                    
                }
            
            })

        }

        if( idUsuarioCitas !== 0 ){
            fetchData();
        }

    },[ idUsuarioCitas ])

    return (
        <>  
            <NavbarInicio />

            {
                ( !loading && !banderaCitas ) && <NotFoundScreen />
            }

            {
                ( !loading && banderaCitas )
                ?            
                    <>
                        <Container maxWidth='lg' sx={{ mt: { md: '9em', xs: '8em' } }}>

                            <Box sx={{ pb: '2em' }} >	

                                <Grid container spacing={3} sx={{ mt: '1em', pr: { xs: '1em', md: '2em' }, pl: { xs: '1em', md: '2em' } }} > 

                                    <Grid item md={12} xs={12} >

                                        {
                                            encuestaPend 
                                            ?
                                                <Alert severity="info">
                                                    Tienes una encuesta pendiente, responde dando 
                                                    <Link style={{ color: '#002540', marginBottom:15, textDecoration:'none' ,textTransform:'uppercase', fontWeight:500 }} href={encuestaPend} target='_blank'> click aquí </Link>
                                                </Alert>
                                            :
                                                null
                                        }

                                    </Grid>

                                    <Grid item md={12} xs={12} sx={{ textAlign: 'right' }} >

                                        { 
                                            limitCit && <Alert severity='error' sx={{ mb:5 }}>Alcanzaste el límite de citas</Alert>
                                        }

                                        <Button
                                            variant='contained'
                                            sx={{ backgroundColor: '#fff', color: 'black' }}
                                            color='inherit'
                                            startIcon={ <AddIcon /> }
                                            onClick={ () => navigate('/nuevaCita/') }
                                            disabled={ limitCit }
                                        >
                                            Agregar cita
                                        </Button>

                                    </Grid>

                                    <Grid item md={12} xs={12} sx={{ m:1}}>

                                        <Grid container spacing={2} sx={{ p: 1, mt:3 }}>
                                          
                                            {
                                                loadCitas
                                                ?
                                                    <Grid container direction="column" alignItems="center" color='cyan' justifyContent="center" style={{ marginTop: '15%'}}>
                                                        <CircularProgress size={50} sx={{ color: 'white' }}  />
                                                    </Grid>  
                                                :
                                                    data.length !== 0
                                                    ?
                                                        data.map( (lista) => (

                                                            <Grid item lg={3} md={4} sm={6} xs={12} key={ lista.id } >

                                                                <Card align='center' key={ lista.id } sx={{ p: 1 }}>

                                                                    <CardHeader
                                                                        title={"Cita " + lista.id }
                                                                        titleTypographyProps={{
                                                                            fontSize: 25,
                                                                            fontWeight:500
                                                                        }}
                                                                    />

                                                                    <Typography  variant='subtitle1'>
                                                                        {format(lista.inicio)}
                                                                    </Typography>

                                                                    <CardContent component="div" style={{paddingTop:10, minHeight:325 }}>

                                                                        <Typography variant='subtitle2' style={{ fontWeight:'bold'}}>
                                                                            {lista.oficina_descripcion} <br/>
                                                                        </Typography>

                                                                        <Typography variant='subtitle2'>
                                                                            <br/>
                                                                            {lista.cit_servicio_descripcion}
                                                                        </Typography>

                                                                        <Typography variant='subtitle2'>
                                                                            <br/>
                                                                            {lista.estado}
                                                                        </Typography>

                                                                        <Box sx={{ mt:2, fontFamily:'Roboto'}}>
                                                                            <Tooltip title={lista.notas} arrow>
                                                                                {
                                                                                    lista.notas.length > 40 ? <Box>{lista.notas.substring(0,40) + '...'}</Box> : <Box>{lista.notas}</Box>
                                                                                }
                                                                                
                                                                            </Tooltip>
                                                                        </Box>

                                                                        <Typography sx={{ mt:3}}>
                                                                            Código asistencia<br/>
                                                                            <b style={{color:'#EB0000'}}>{lista.codigo_asistencia}</b>
                                                                        </Typography>

                                                                    </CardContent>

                                                                    <CardActions style={{float:'right'}}>
                                                                        {
                                                                            lista.puede_cancelarse 
                                                                            ? 
                                                                                <Button 
                                                                                    variant='contained'
                                                                                    color='error'
                                                                                    size='small'
                                                                                    startIcon={ <CloseIcon /> }
                                                                                    onClick={ () => { setToDelete(lista.id); setOpen( true ); }}
                                                                                >
                                                                                    Cancelar
                                                                                </Button>
                                                                            :
                                                                                <Box component='div' style={{ marginBottom:33}}></Box>
                                                                        }
                                                                        
                                                                    </CardActions>

                                                                </Card>

                                                            </Grid>

                                                        ))
                                                    :
                                                        data.length === 0 && !loadCitas
                                                        &&
                                                            <Grid item xs={12} sx={{ textAlign: 'center' }}>

                                                                <Typography variant='h5' sx={{ mt:15, color:'#F9F9F9', fontWeight:'bold' }}>
                                                                    No tienes citas agendadas
                                                                </Typography>        

                                                            </Grid>
                                            }

                                        </Grid> 
                                                             
                                    </Grid>
                                
                                </Grid>

                            </Box>
                    
                        </Container>

                        <Dialog
                            open={ open }
                            onClose={ () => { } }
                        >
                            <DialogTitle>
                                Confirmación
                            </DialogTitle>
                        
                            <DialogContent>

                                <DialogContentText sx={{ color: 'black' }}>
                                    ¿Desea cancelar la cita seleccionada?
                                </DialogContentText>

                            </DialogContent>

                            <DialogActions>
                                <Button onClick={ () => { setToDelete( '' ); setOpen( false ); } } startIcon={ <CloseIcon /> }>Cancelar</Button>
                                <LoadingButton 
                                    variant="contained" 
                                    color="primary"
                                    onClick={ handleClickDelete }
                                    startIcon={ <CheckIcon /> }
                                    loading={ loadingAcept }
                                > 
                                    Aceptar 
                                </LoadingButton>

                            </DialogActions>

                        </Dialog>
                    </>
                :
                    <Grid container direction="column" alignItems="center" color='cyan' justifyContent="center" style={{ marginTop: '15%'}}>
                        <CircularProgress size={50} sx={{ color: 'white' }}  />
                    </Grid>  

            }
        
        </>
    )
    
}
