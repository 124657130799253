import React, { useEffect, useRef } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { PrivateRoute } from './PrivateRoute';
import { PublicRoute } from './PublicRoute';

import { AuthRoutes} from './AuthRoutes';
import { PoderEnLineaRoutes } from './PoderEnLineaRoutes';

import { AvisoPrivacidadScreen } from '../components/auth/AvisoPrivacidadScreen';
import { TerminosScreen } from '../components/auth/TerminosScreen';

import { login } from '../store/slices/authSlice';

export const AppRouter = () => {

    const dispatch = useDispatch();

    const token = useRef();

    const { token: tokenRedux } = useSelector(state => state.auth);

    const data = window.localStorage.getItem("data");

    useEffect(() => {
        
        if( data ){

            dispatch( login( JSON.parse( data ) ) );

        } 
        else{            
            localStorage.removeItem('data');
            localStorage.removeItem('lastPath');
        }      

    }, [ data, dispatch ]); 

    if( data ){
        const dataJSON = JSON.parse( data );

        if( dataJSON ){
            token.current = dataJSON.token;
        }
    }
    else{
        token.current = tokenRedux;
    }

    return (
        <BrowserRouter>  

            <Routes>
                
                <Route path="/auth/*" element={ <PublicRoute token={ token.current } children={ <AuthRoutes /> } /> } />   

                <Route path="/*" element={ <PrivateRoute token={ token.current } children={ <PoderEnLineaRoutes /> } /> } />

                <Route path="/aviso/*" element={ <AvisoPrivacidadScreen /> } />   
                
                <Route path="/terminos/*" element={ <TerminosScreen /> } />     

            </Routes>
        
        </BrowserRouter>
    )

}