import React, { useEffect, useState } from 'react';

import { Button, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, TextField, Typography } from '@mui/material'

import { LoadingButton } from '@mui/lab';

import FileCopyIcon from '@mui/icons-material/FileCopy';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';

import moment from 'moment';

import { usePages } from '../../hooks/usePages';

import { TablePaginationActions } from '../../helpers/TablePaginationActions';
import { BuscarSolicitudes } from '../../actions/sid/solicitudActions';

export const BusquedaScreen = () => {
    
    const [rows, setRows] = useState( [] );

    const [page, rowsPerPage, handleChangePage, handleChangeRowsPerPage, resetPage] = usePages( 5 );

    const [fecha, setFecha] = useState( '' );
    const [solicitante, setSolicitante] = useState( '' );

    const [loadingSearch, setLoadingSearch] = useState( false );
    const [filtroAplicado, setFiltroAplicado] = useState( false );    

    const [loading, setLoading] = useState( true );
    const [openDocumento, setOpenDocumento] = useState( false );

    const [documento, setDocumento] = useState( '' );

    const [detalle, setDetalle] = useState( false );
    const [openDetalle, setOpenDetalle] = useState( false );

    const handleClearFiltro = () => {

        setFiltroAplicado( false ); 
        setSolicitante( '' );
        setFecha( '' );        
        resetPage();

    }

    const handleBuscarSolicitudes = async () => {

        setLoadingSearch( true );
        setFiltroAplicado( true );

        await BuscarSolicitudes({ fecha, solicitante }).then( response => {

            if( response ){

                if( response.status === 200 ){      
                    
                    setTimeout(() => {
                        
                        setRows( response.data ?? [] );                
                        setLoading( false );
                        setLoadingSearch( false );

                    }, 700);
                    
                }
                else{
                    setLoading( false );
                }
            }
            else{
                setLoading( false );
            }

        })

    }

    useEffect(() => {

        async function Obtener(){
            
            setRows( [ ] );    
            setLoading( true );            

            await BuscarSolicitudes({ fecha: moment( new Date() ).format('YYYY-MM-DD'), solicitante: '' }).then( response => {

                if( response ){

                    if( response.status === 200 ){      
                        
                        setTimeout(() => {
                            
                            setRows( response.data ?? [] );                
                            setLoading( false );

                        }, 700);
                        
                    }
                    else{
                        setLoading( false );
                    }
                }
                else{
                    setLoading( false );
                }

            })

        }         
      
        if( !filtroAplicado ){
            Obtener();          
        }
        
    }, [ filtroAplicado ])   
    
    return (
        
        <>
            <Grid container spacing={3} >

                <Grid item md={6} xs={12}>

                    <TextField 
                        name='solicitante'
                        label='Solicitante'
                        variant='standard'
                        type='text'
                        autoComplete='off'      
                        fullWidth
                        value={ solicitante }
                        onChange={ (e) => setSolicitante( e.target.value ) }
                        disabled={ filtroAplicado }
                    />

                </Grid>

                <Grid item md={6} xs={12} >  

                    <TextField                              
                        label='Fecha'
                        type='date'        
                        autoComplete='off'      
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}   
                        value={ fecha }
                        onChange={ (e) => setFecha( e.target.value ) }       
                        disabled={ filtroAplicado }                    
                    />

                </Grid>

                <Grid item md={12} xs={12} >  

                    <Grid container spacing={3} display='flex' flexDirection='revert' justifyContent='end' >

                        <Grid item md={4} xs={12}>

                            <LoadingButton
                                variant='contained'
                                startIcon={ filtroAplicado ? <ClearIcon /> : <SearchIcon /> }
                                fullWidth
                                disabled={ solicitante === '' && fecha === '' }
                                onClick={ () => { filtroAplicado ? handleClearFiltro() : handleBuscarSolicitudes() } }
                                loading={ loadingSearch }
                                color={ filtroAplicado ? 'inherit' : 'primary' }
                            >
                                { filtroAplicado ? 'limpiar filtro' : 'Buscar'}
                            </LoadingButton>

                        </Grid>

                    </Grid>

                </Grid>  

                <Grid item xs={12} md={12}> 
                
                    <TableContainer>

                        <Table>
                            
                            <TableHead>

                                <TableRow>        

                                    <TableCell style={{ width:'0.5%' }}></TableCell>
                                    <TableCell style={{ width:'10%' }}> FECHA </TableCell>
                                    <TableCell style={{ width:'25%' }}> SOLICITANTE </TableCell>
                                    <TableCell style={{ width:'15%' }}> PALABRA CLAVE </TableCell>
                                    <TableCell style={{ width:'5%' }}> IDENTIFICACIÓN </TableCell>
                                    <TableCell style={{ width:'5%' }}> CURP </TableCell>
                                    <TableCell style={{ width:'5%' }}> CÉDULA PROFESIONAL </TableCell>
                                    <TableCell style={{ width:'5%' }}> VIDEO </TableCell>
                                    <TableCell style={{ width:'1%', textAlign: 'center' }}>  </TableCell>

                                </TableRow>

                            </TableHead>

                            <TableBody>

                                {
                                    ( rowsPerPage > 0
                                        ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        : rows
                                    ).map( ( row, i) => (

                                        <TableRow key={ row.id } >

                                            <TableCell> { row.app && <PhoneAndroidIcon /> } </TableCell>
                                            
                                            <TableCell> { row.fecha ? moment( row.fecha ).format('DD-MM-YYYY hh:mm a') : '' } </TableCell>
                                          
                                            <TableCell sx={{ fontWeight: 'bold' }}> 
                                                <Button
                                                    variant='text'
                                                    onClick={ () => { setDetalle( row ); setOpenDetalle( true ); }}
                                                >
                                                    { row.solicitante } 
                                                </Button>
                                            </TableCell>

                                            <TableCell> { row.palabraClave } </TableCell>
                                            
                                            <TableCell sx={{ textAlign: 'center' }}>  

                                                <IconButton
                                                    onClick={ () => { setDocumento( row.ine ); setOpenDocumento( true );  } }
                                                    color='primary'
                                                >
                                                    <FileCopyIcon fontSize='large' />
                                                </IconButton>

                                            </TableCell>

                                            <TableCell sx={{ textAlign: 'center' }}>   
                                                
                                                <IconButton
                                                    onClick={ () => { setDocumento( row.curp ); setOpenDocumento( true );  } }
                                                    color='primary'
                                                >
                                                    <FileCopyIcon fontSize='large' />
                                                </IconButton>

                                            </TableCell>
                                            
                                            <TableCell sx={{ textAlign: 'center' }}>   
                                                
                                                {
                                                    row.cedula
                                                    &&
                                                    <IconButton
                                                        onClick={ () => { setDocumento( row.cedula ); setOpenDocumento( true );  } }
                                                        color='primary'
                                                    >
                                                        <FileCopyIcon fontSize='large' />
                                                    </IconButton>
                                                }                                                

                                            </TableCell>

                                            <TableCell sx={{ textAlign: 'center' }}>   
                                                
                                                <IconButton
                                                    onClick={ () => { setDocumento( row.video ); setOpenDocumento( true );  } }
                                                    color='primary'
                                                >
                                                    <FileCopyIcon fontSize='large' />
                                                </IconButton>

                                            </TableCell>

                                            <TableCell sx={{ textAlign: 'center' }}>   
                                                
                                                <Chip
                                                    color={ 
                                                        ( row.idEstatus === 6 && 'primary' ) ||
                                                        ( row.idEstatus === 7 && 'error' ) ||
                                                        ( row.idEstatus === 8 && 'warning' )
                                                    }
                                                    label={ row.estatus }
                                                />

                                                {
                                                    row.motivoCancelacion
                                                    &&
                                                    <>
                                                        <br /> <br />
                                                        { row.motivoCancelacion }
                                                    </>
                                                }

                                            </TableCell>

                                        </TableRow>

                                    ))
                                }

                                { 
                                    rows.length === 0 && (
                                        <TableRow style={{ height: 53 }}>

                                            <TableCell colSpan={9} align="center" sx={{ fontWeight: 'bold' }}> 
                                                {
                                                    loading
                                                    ?
                                                        <CircularProgress size={40} sx={{ mt: 2, mb: 2 }} />
                                                    :
                                                        ''
                                                }
                                                {
                                                    !loading && rows.length === 0
                                                    ?
                                                        'No se encontraron solicitudes'
                                                    :
                                                        ''
                                                }
                                            </TableCell>

                                        </TableRow>
                                    )
                                }
                            </TableBody>

                            <TableFooter>

                                <TableRow>

                                    <TableCell colSpan={3}>
                                        <Typography variant='body2' color='black' sx={{ fontWeight: 'bold' }}> Total de solicitudes: { rows.length } </Typography>
                                    </TableCell>

                                    <TablePagination
                                        rowsPerPageOptions={[ 5, 10, 15, 20 ]}
                                        colSpan={10}
                                        count={rows.length}                                        
                                        rowsPerPage={rowsPerPage}
                                        page={page}                                                                                
                                        onPageChange={ handleChangePage }
                                        onRowsPerPageChange={ handleChangeRowsPerPage }
                                        ActionsComponent={ TablePaginationActions }

                                        labelRowsPerPage="Filas por página"                                            
                                        labelDisplayedRows={
                                            ({ from, to, count }) => {
                                            return '' + from + '-' + to + ' de ' + count
                                            }
                                        }  
                                    />

                                </TableRow>

                            </TableFooter>

                        </Table>

                    </TableContainer>

                </Grid>
                
            </Grid>

            <Dialog open={ openDocumento } onClose={ () => {} } fullWidth={ true } maxWidth="md" >

                <DialogContent>
                    
                    <Grid container spacing={3} >                                                
                    
                            <Grid item md={12} xs={12} sx={{ display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
                                
                                <iframe 
                                    title='acuse' 
                                    src={ documento } 
                                    height="500px" 
                                    width="100%"
                                >                                    
                                </iframe>

                            </Grid>
                            
                        </Grid>
                    
                </DialogContent>

                <DialogActions>

                    <Button color="primary" onClick={ () => { setOpenDocumento( false ); setDocumento( '' ); } }>
                        Cerrar
                    </Button>

                </DialogActions>                

            </Dialog>  

            <Dialog open={ openDetalle } onClose={ () => { } } maxWidth='sm' fullWidth >

                <DialogTitle>
                    Detalle de la solicitud
                </DialogTitle>

                <DialogContent>
                    
                    <Table>                       

                        <TableBody>
                    
                            <TableRow>
                                <TableCell sx={{ fontSize: 14, fontWeight: 'bold', width: '40%', border: 'none' }}> Solicitante: </TableCell>  
                                <TableCell sx={{ border: 'none' }}> { detalle?.solicitante } </TableCell>               
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ fontSize: 14, fontWeight: 'bold', width: '40%', border: 'none' }}> CURP: </TableCell>  
                                <TableCell sx={{ border: 'none' }}> { detalle?.curpSolicitante } </TableCell>                             
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ fontSize: 14, fontWeight: 'bold', width: '40%', border: 'none' }}> Correo electrónico: </TableCell>  
                                <TableCell sx={{ border: 'none' }}> { detalle?.correoElectronico } </TableCell>       
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ fontSize: 14, fontWeight: 'bold', width: '40%', border: 'none' }}> Teléfono celular: </TableCell>  
                                <TableCell sx={{ border: 'none' }}> { detalle?.telefonoCelular } </TableCell>            
                            </TableRow>

                        </TableBody>

                    </Table>     

                </DialogContent>

                <DialogActions>
                    <Button onClick={ () => { setOpenDetalle( false ); setDetalle( {} ); } }>Cerrar</Button>                  
                </DialogActions>

            </Dialog>
          
        </> 

    )

}
