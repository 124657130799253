import React, { useEffect, useState } from 'react';

import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

import { LoadingButton } from '@mui/lab';

import { useDispatch } from 'react-redux';

import { Logout, ValidarSesion } from '../../actions/auth/authActions';

import { setAplicacionesOnline, setAvatar, setPermisos } from '../../store/slices/authSlice';

export const ModalTokenExpired = () => {

    const dispatch = useDispatch();

    const [open, setOpen] = useState( false );

    const [loading, setLoading] = useState( false );

    const IniciarSesion = () => {

        setLoading( true );

        setTimeout(() => {

            dispatch( Logout() );

        }, 1000);

    }

    useEffect(() => {
       
        async function Obtener(){

            await ValidarSesion().then( response => {

                if( response ){

                    const { success, idTipoUsuario, idUsuarioCitas, idJuzgado, idMateria, permisos, aplicacionesOnline, imagen } = response.data;

                    if( !success ){
                        setOpen(true);
                    }

                    const token = success ? 'authenticated' :'token expired';

                    dispatch( setPermisos( { token, permisos, idTipoUsuario, idJuzgado, idMateria, idUsuarioCitas } ) );
                    dispatch( setAplicacionesOnline( { aplicacionesOnline } ) );

                    dispatch( setAvatar( { imagenPerfil: imagen ?? '' }) );
                }
            });

        }

        Obtener();

    }, [ dispatch ] );

    return (
        <>
            <Dialog open={ open } onClose={ () => {} } >
                <DialogTitle>
                    El tiempo de sesión ha expirado
                </DialogTitle>

                <DialogContent>
                    <DialogContentText sx={{ color: 'black' }}>
                        Por seguridad es necesario volver a iniciar sesión
                    </DialogContentText>
                </DialogContent>

                <DialogActions>
                    <LoadingButton 
                        variant='contained' 
                        color='primary' 
                        onClick={ IniciarSesion } 
                        loading={ loading }
                    >
                        Aceptar
                    </LoadingButton>
                </DialogActions>
            </Dialog>  
        </>
    );
}