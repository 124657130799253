import React, { useState } from 'react';

import { useSelector } from 'react-redux';

import { Box, Chip, Tab, Tabs, Typography } from '@mui/material';

import { FactoresAutenticacionScreen } from './FactoresAutenticacionScreen';
import { MisEnviosScreen } from './MisEnviosScreen';
import { RealizarEnvioScreen } from './RealizarEnvioScreen';

import PropTypes from 'prop-types';

function TabPanel( props ){
    
    const { children, value, index, ...other } = props;
  
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};
  
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export const TabsUsuarioExternoScreen = () => {

    const { tokenUnicoActivado } = useSelector( state => state.auth );

    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', mt: 2 }}>
            
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" variant='scrollable' scrollButtons='auto' allowScrollButtonsMobile >
                    
                    <Tab label="Mis envios" {...a11yProps(0)} />
                    <Tab label="Realizar envió" {...a11yProps(1)} />

                    {
                        ( tokenUnicoActivado === false )
                        &&
                        <Tab label={
                                <Typography variant='subtitle2'>
                                    App token  
                                    <Chip size='small' color='error' label={ <Typography variant='subtitle2' sx={{ fontSize: 11 }}> Nuevo </Typography> } />
                                </Typography> 
                            } 
                                {...a11yProps(2)} 
                        />
                    }
                   
                </Tabs>

            </Box> 

            <TabPanel value={value} index={0}>
                
                <MisEnviosScreen />

            </TabPanel>

            <TabPanel value={value} index={1}>
                
                <RealizarEnvioScreen />

            </TabPanel>

            {
                ( tokenUnicoActivado === false )
                &&

                    <TabPanel value={value} index={2}>
                        
                        <FactoresAutenticacionScreen />

                    </TabPanel>
            }
        </>
    )

}
