import React, { useEffect, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { Box, Button, Card, CardContent, CircularProgress, Container, Dialog, DialogActions, DialogContent, DialogTitle, Grid, List, ListItem, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, Typography } from '@mui/material';

import UndoIcon from '@mui/icons-material/Undo';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import DownloadIcon from '@mui/icons-material/Download';
import TaskIcon from '@mui/icons-material/Task';

import moment from 'moment';

import { NavbarInicio } from '../ui/NavbarInicio';

import { usePages } from '../../hooks/usePages';

import { TablePaginationActions } from '../../helpers/TablePaginationActions';

import { VerCausaPenalDetalle, VerCausaPenalDocumentos, VerDocumentoPenal } from '../../actions/expediente/expedienteVirtualActions';

export const DetalleExpedientePenalScreen = () => {

    const navigate = useNavigate();

    const { Id } = useParams();

    const [open, setOpen] = useState( false );

    const [page, rowsPerPage, handleChangePage, handleChangeRowsPerPage] = usePages( 10 );

    const [loading, setLoading] = useState( true );
    const [loadingDocumentos, setLoadingDocumentos] = useState( true );

    const [data, setData] = useState( { } );
    const [documentos, setDocumentos] = useState( [] );
    const [documento, setDocumento] = useState( '' );

    const [loadingDocumento, setLoadingDocumento] = useState( false );

    const [recordsCount, setRecordsCount] = useState( 0 );

    const handleVerDocumento = async ( idDocumentoPenal, firmado ) => {

        setOpen( true );
        setLoadingDocumento( true );

        await VerDocumentoPenal({ idDocumentoPenal }).then( response => {

            if( response ){

                if( response.status === 200 ){

                    const { success, documento } = response.data;

                    if( success ){                        
                        
                        if( firmado ){
                            
                            setTimeout(() => {
                                
                                setDocumento( documento );
                                setLoadingDocumento( false );

                            }, 1000);
                            
                        }
                        else{

                            if( documento?.includes("http")){
                                setDocumento( documento );    
                            }
                            else{
                                setDocumento( 'data:application/pdf;base64,' + documento );    
                            }
                            setLoadingDocumento( false );
                            
                        }

                    }                 

                }

            }

        });

    }

    useEffect(() => {
      
        async function Obtener(){

            setLoading( true );

            await VerCausaPenalDetalle({ idCausaPenal: Id }).then( response => {

                if( response ){

                    if( response.status === 200 ){
                        
                        setTimeout(() => {
                            
                            setData( response.data );                            
                            setLoading( false );

                        }, 800);                        

                    }

                }

            });

        }

        Obtener();

    }, [ Id ])

    useEffect(() => {
      
        async function Obtener(){

            setDocumentos( [] );
            setLoadingDocumentos( true );

            await VerCausaPenalDocumentos({ idCausaPenal: Id, page, rowsPerPage }).then( response => {

                if( response ){

                    if( response.status === 200 ){
                        
                        setTimeout(() => {    

                            setDocumentos( response?.data?.records ?? [] );
                            setRecordsCount( response?.data?.recordsCount ?? 0 );
                            setLoadingDocumentos( false );

                        }, 800);                        

                    }

                }

            });

        }

        Obtener();

    }, [ Id, page, rowsPerPage ])

    return (
        <>
            <NavbarInicio />

            <Container maxWidth='xl' sx={{ mt: { md: '10em', xs: '8em' } }}>

                <Box sx={{ pb: '2em', pl: { md: 10, xs: 1 }, pr: { md: 10, xs: 1 }, pt: 2 }} >	
                    
                    <Grid container spacing={3}>

                        <Grid item md={12} xs={12} sx={{ textAlign: { xs: 'center', md: 'right'} }}>

                            <Button
                                variant='contained'
                                sx={{ backgroundColor: '#fff' }}
                                color='inherit'
                                startIcon={ <UndoIcon /> }
                                onClick={ () => navigate('/expedienteVirtual/') }
                            >
                                Mis expedientes
                            </Button>

                        </Grid>

                        <Grid item md={12} xs={12} >

                            <Card>
                            
                                <CardContent sx={{ p: 5 }}> 

                                    {
                                        loading 
                                        ?
                                            <Grid container spacing={3} sx={{ justifyContent: 'center' }}>
                                                <CircularProgress size={40} sx={{ mt: 15, mb: 15 }} />
                                            </Grid>
                                        :

                                            <Grid container spacing={3} >            

                                                <Grid item sm={2} xs={6} >
                                                    <Typography variant='body2' sx={{ fontWeight: 'bold' }}>
                                                        NUC
                                                    </Typography>
                                                </Grid>

                                                <Grid item sm={10} xs={6} >
                                                    <Typography variant='body2' sx={{ textTransform: '' }}>
                                                        { data?.nuc }
                                                    </Typography>
                                                </Grid>

                                                <Grid item sm={2} xs={6} >
                                                    <Typography variant='body2' sx={{ fontWeight: 'bold' }}>
                                                        NIC
                                                    </Typography>
                                                </Grid>

                                                <Grid item sm={4} xs={6} >
                                                    <Typography variant='body2' sx={{ textTransform: '' }}>
                                                        { data?.nic }
                                                    </Typography>
                                                </Grid>

                                                <Grid item sm={2} xs={6} >
                                                    <Typography variant='body2' sx={{ fontWeight: 'bold' }}>
                                                        Fecha de inicio
                                                    </Typography>
                                                </Grid>

                                                <Grid item sm={4} xs={6} >
                                                    <Typography variant='body2' sx={{ textTransform: '' }}>
                                                        { data?.fechaInicio ? moment( data?.fechaInicio ).format('DD-MM-YYYY') : '' }
                                                    </Typography>
                                                </Grid>

                                                <Grid item sm={2} xs={6} >
                                                    <Typography variant='body2' sx={{ fontWeight: 'bold' }}>
                                                        Juzgado
                                                    </Typography>
                                                </Grid>

                                                <Grid item sm={10} xs={6} >
                                                    <Typography variant='body2' sx={{ textTransform: '' }}>
                                                        { data?.juzgado }
                                                    </Typography>
                                                </Grid>

                                                <Grid item sm={2} xs={6} >
                                                    <Typography variant='body2' sx={{ fontWeight: 'bold' }}>
                                                        Imputado(s)
                                                    </Typography>
                                                </Grid>

                                                <Grid item sm={4} xs={6} >
                                                    <List disablePadding>
                                                        {
                                                            data?.imputado?.split(', ')?.map( ( elem, i ) => (
                                                                <ListItem key={ i } disablePadding sx={{ pb: 1 }}>
                                                                    <Typography variant='body2'>{ elem }</Typography>
                                                                </ListItem>
                                                            ))
                                                        } 
                                                    </List>
                                                </Grid>

                                                <Grid item sm={2} xs={6} >
                                                    <Typography variant='body2' sx={{ fontWeight: 'bold' }}>
                                                        Victima(s)/Ofendido(s)
                                                    </Typography>
                                                </Grid>

                                                <Grid item sm={4} xs={6} >
                                                    <List disablePadding>
                                                        {
                                                            data?.victima_Ofendido?.split(', ')?.map( ( elem, i ) => (
                                                                <ListItem key={ i } disablePadding sx={{ pb: 1 }}>
                                                                    <Typography variant='body2'>{ elem }</Typography>
                                                                </ListItem>
                                                            ))
                                                        } 
                                                    </List>
                                                </Grid>

                                                <Grid item sm={2} xs={6} >
                                                    <Typography variant='body2' sx={{ fontWeight: 'bold' }}>
                                                        Delito(s)
                                                    </Typography>
                                                </Grid>

                                                <Grid item sm={10} xs={6} >
                                                    <List disablePadding>
                                                        {
                                                            data?.delitos?.split(', Art.')?.map( ( elem, i ) => (
                                                                <ListItem key={ i } disablePadding sx={{ pb: 1, textAlign: 'justify' }}>
                                                                    <Typography variant='body2'>{ elem }</Typography>
                                                                </ListItem>
                                                            ))
                                                        } 
                                                    </List>
                                                </Grid>

                                            </Grid>
                                    }

                                </CardContent>
                            
                            </Card>

                        </Grid>

                        <Grid item md={12} xs={12} >

                            <Card>
                                                    
                                <CardContent> 

                                    {
                                        loading 
                                        ?
                                            <Grid container spacing={3} sx={{ justifyContent: 'center' }}>
                                                <CircularProgress size={40} sx={{ mt: 15, mb: 15 }} />
                                            </Grid>
                                        :

                                            <Grid container spacing={3} sx={{ p: 1 }}>

                                                <Grid item md={12} xs={12}>

                                                    <TableContainer>

                                                        <Table>
                                                            
                                                            <TableHead>

                                                                <TableRow>  
                                                                
                                                                    <TableCell style={{ width:'5%' }}> FECHA </TableCell>
                                                                    <TableCell style={{ width:'25%' }}> TIPO DE DOCUMENTO </TableCell>
                                                                    <TableCell style={{ width:'15%' }}> NOMBRE </TableCell>
                                                                    <TableCell style={{ width:'5%', textAlign: 'center' }}>  </TableCell>

                                                                </TableRow>

                                                            </TableHead>

                                                            <TableBody>

                                                                {
                                                                    documentos?.map( ( row, i ) => (

                                                                        <TableRow key={ i } >
                                                                            
                                                                            <TableCell> { row?.fecha ? moment( row?.fecha ).format('DD-MM-YYYY') : '' } </TableCell>
                                                                            <TableCell> { row.tipoDocumento } </TableCell>
                                                                            <TableCell> { row.nombre } </TableCell>
                                                                            
                                                                            <TableCell sx={{ textAlign: 'center' }}>   
                                                                                
                                                                                {
                                                                                    row?.extension === '.odt'
                                                                                    ?
                                                                                        <Button
                                                                                            href={ row?.documento ?? '' }
                                                                                            color='primary'
                                                                                            variant='contained'
                                                                                        >
                                                                                            <DownloadIcon />
                                                                                        </Button>
                                                                                    :                                                                                
                                                                                        <Button
                                                                                            onClick={ () => handleVerDocumento( row?.idDocumentoPenal, row?.firmado ) }
                                                                                            color='primary'
                                                                                            variant='contained'
                                                                                        >
                                                                                            {
                                                                                                row?.firmado
                                                                                                ?
                                                                                                    <TaskIcon />
                                                                                                :
                                                                                                    <FileCopyIcon />
                                                                                            }
                                                                                        </Button>                                                                                       
                                                                                }                                                                                

                                                                            </TableCell>

                                                                        </TableRow>

                                                                    ))
                                                                }

                                                                { 
                                                                    documentos.length === 0 && (
                                                                        <TableRow style={{ height: 53 }}>

                                                                            <TableCell colSpan={10} align="center" sx={{ fontWeight: 'bold' }}> 
                                                                                {
                                                                                    loadingDocumentos
                                                                                    ?
                                                                                        <CircularProgress size={40} sx={{ mt: 2, mb: 2 }} />
                                                                                    :
                                                                                        ''
                                                                                }
                                                                                {
                                                                                    !loadingDocumentos && documentos.length === 0
                                                                                    ?
                                                                                        'No se encontraron documentos'
                                                                                    :
                                                                                        ''
                                                                                }
                                                                            </TableCell>

                                                                        </TableRow>
                                                                    )
                                                                }
                                                            </TableBody>

                                                            <TableFooter>

                                                                <TableRow>

                                                                    <TableCell colSpan={2}>
                                                                        <Typography variant='body2' color='black' sx={{ fontWeight: 'bold' }}> Total de documentos: { recordsCount } </Typography>
                                                                    </TableCell>    
                                                        
                                                                    <TablePagination
                                                                        rowsPerPageOptions={[ 5, 10, 15, 20 ]}
                                                                        colSpan={5}
                                                                        count={ recordsCount }                                        
                                                                        rowsPerPage={ rowsPerPage }
                                                                        page={ page }                                                                                
                                                                        onPageChange={ handleChangePage }
                                                                        onRowsPerPageChange={ handleChangeRowsPerPage }
                                                                        ActionsComponent={ TablePaginationActions }

                                                                        labelRowsPerPage="Filas por página"                                            
                                                                        labelDisplayedRows={
                                                                            ({ from, to, count }) => {
                                                                            return '' + from + '-' + to + ' de ' + count
                                                                            }
                                                                        }  
                                                                    />

                                                                </TableRow>

                                                            </TableFooter>

                                                        </Table>

                                                    </TableContainer>

                                                </Grid>

                                            </Grid>

                                    }
                                
                                </CardContent>
                            
                            </Card>

                        </Grid>

                    </Grid>                    

                </Box>

            </Container>

            <Dialog open={ open } onClose={ () => {} } fullWidth={ true } maxWidth="md" >

                <DialogTitle>
                    Visualizar documento
                </DialogTitle>
               
               <DialogContent>
                   
                    {
                        loadingDocumento 
                        ?
                            <Grid container spacing={3} sx={{ justifyContent: 'center' }}>
                                <CircularProgress size={40} sx={{ mt: 15, mb: 15 }} />
                            </Grid>
                        :
                                                                
                        <Grid container spacing={3} >  

                            <Grid item md={12} xs={12} sx={{ display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
                                
                                <iframe title='acuse' src={ documento } height="500px" width="100%"></iframe>

                            </Grid>
                    
                        </Grid>
                    }
                  
               </DialogContent>

               <DialogActions>

                   <Button color="primary" onClick={ () => { setOpen( false ); setDocumento( '' ); } }>
                       Cerrar
                   </Button>

               </DialogActions>                

            </Dialog>  

        </>
    )
    
}
