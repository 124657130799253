import React, { useEffect, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { Box, Button, Card, CardContent, CircularProgress, Container, Dialog, DialogActions, DialogContent, DialogTitle, Grid, List, ListItem, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, TextField, Typography } from '@mui/material';

import { LoadingButton } from '@mui/lab';

import UndoIcon from '@mui/icons-material/Undo';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import DownloadIcon from '@mui/icons-material/Download';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';

import moment from 'moment';

import { NavbarInicio } from '../ui/NavbarInicio';

import { usePages } from '../../hooks/usePages';

import { TablePaginationActions } from '../../helpers/TablePaginationActions';

import { VerDocumento, VerExpedienteDetalle, VerExpedienteDocumentos, VerExpedienteDocumentosBuscar } from '../../actions/expediente/expedienteVirtualActions';

export const DetalleExpedienteScreen = () => {

    const navigate = useNavigate();

    const { Id } = useParams();

    const [open, setOpen] = useState( false );

    const [page, rowsPerPage, handleChangePage, handleChangeRowsPerPage, resetPage] = usePages( 10 );

    const [loading, setLoading] = useState( true );
    const [loadingDocumentos, setLoadingDocumentos] = useState( true );
    const [loadingSearch, setLoadingSearch] = useState( false );   

    const [data, setData] = useState( { } );
    const [documentos, setDocumentos] = useState( [] );
    const [documento, setDocumento] = useState( '' );

    const [recordsCount, setRecordsCount] = useState( 0 );

    const [loadingDocumento, setLoadingDocumento] = useState( false );

    const [filtroAplicado, setFiltroAplicado] = useState( false );    

    const [buscarTipoDocumento, setBuscarTipoDocumento] = useState( '' );
    const [buscarFecha, setBuscarFecha] = useState( '' );

    const handleVerDocumento = async ( idDocumento, isODT ) => {

        await VerDocumento({ idDocumento }).then( response => {

            if( response ){

                if( response.status === 200 ){

                    const { success, documento, extension } = response.data;

                    if( success ){    

                        if( extension === '.odt' ){
                            window.open( documento, '_blank');
                        }
                        else{
                            setDocumento( documento );
                            setOpen( true );
                            setLoadingDocumento( false );
                        }
                    }                 

                }

            }

        });

    }

    const handleClearFiltro = () => {

        setFiltroAplicado( false ); 
        setBuscarFecha( '' );      
        setBuscarTipoDocumento('');

        resetPage();
        setDocumentos( [] );
    }

    const handleBuscar = () => { 

        setLoadingSearch( true );
        setFiltroAplicado( true );

    }

    useEffect(() => {
      
        async function Obtener(){

            setLoading( true );

            await VerExpedienteDetalle({ idExpediente: Id }).then( response => {

                if( response ){

                    if( response.status === 200 ){
                        
                        setTimeout(() => {
                            
                            setData( response.data );                            
                            setLoading( false );

                        }, 800);                        

                    }

                }

            });

        }

        Obtener();

    }, [ Id ])

    useEffect(() => {
      
        async function Obtener(){

            setDocumentos( [] );
            setLoadingDocumentos( true );

            await VerExpedienteDocumentos({ idExpediente: Id, page, rowsPerPage }).then( response => {

                if( response ){

                    if( response.status === 200 ){
                        
                        setTimeout(() => {    

                            setDocumentos( response?.data?.records ?? [] );
                            setRecordsCount( response?.data?.recordsCount ?? 0 );
                            setLoadingDocumentos( false );

                        }, 800);                        

                    }

                }

            });

        }

        if( !filtroAplicado ){
            Obtener();
        }

    }, [ Id, filtroAplicado, page, rowsPerPage ])

    useEffect(() => {
      
        async function Obtener(){

            setDocumentos( [] );
            setLoadingDocumentos( true );

            await VerExpedienteDocumentosBuscar({ idExpediente: Id, page, rowsPerPage, tipoDocumento: buscarTipoDocumento, fecha: buscarFecha }).then( response => {

                if( response ){

                    if( response.status === 200 ){
                        
                        setTimeout(() => {    

                            setDocumentos( response?.data?.records ?? [] );
                            setRecordsCount( response?.data?.recordsCount ?? 0 );
                            setLoadingDocumentos( false );
                            setLoadingSearch( false );

                        }, 800);                        

                    }

                }

            });

        }

        if( filtroAplicado ){
            Obtener();
        }

    }, [ Id, filtroAplicado, buscarFecha, buscarTipoDocumento, page, rowsPerPage ])

    return (
        <>
            <NavbarInicio />

            <Container maxWidth='xl' sx={{ mt: { md: '10em', xs: '8em' } }}>

                <Box sx={{ pb: '2em', pl: { md: 10, xs: 1 }, pr: { md: 10, xs: 1 }, pt: 2 }} >	
                    
                    <Grid container spacing={3}>

                        <Grid item md={12} xs={12} sx={{ textAlign: { xs: 'center', md: 'right'} }}>

                            <Button
                                variant='contained'
                                sx={{ backgroundColor: '#fff' }}
                                color='inherit'
                                startIcon={ <UndoIcon /> }
                                onClick={ () => navigate('/expedienteVirtual/') }
                            >
                                Mis expedientes
                            </Button>

                        </Grid>

                        <Grid item md={12} xs={12} >

                            <Card>
                            
                                <CardContent sx={{ p: 5 }}> 

                                    {
                                        loading 
                                        ?
                                            <Grid container spacing={3} sx={{ justifyContent: 'center' }}>
                                                <CircularProgress size={40} sx={{ mt: 15, mb: 15 }} />
                                            </Grid>
                                        :

                                            <Grid container spacing={3} >            

                                                {
                                                    data?.folio
                                                    &&
                                                    <>
                                                        <Grid item sm={2} xs={6} >
                                                            <Typography variant='body2' sx={{ fontWeight: 'bold' }}>
                                                                Número de folio
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item sm={10} xs={6} >
                                                            <Typography variant='body2' sx={{ textTransform: '' }}>
                                                                { data?.folio !== '0' ? data?.folio : '' }
                                                            </Typography>
                                                        </Grid>
                                                    </>
                                                }

                                                <Grid item sm={2} xs={6} >
                                                    <Typography variant='body2' sx={{ fontWeight: 'bold' }}>
                                                        Número de expediente
                                                    </Typography>
                                                </Grid>

                                                <Grid item sm={4} xs={6} >
                                                    <Typography variant='body2' sx={{ textTransform: '' }}>
                                                        { data?.numeroExpediente }
                                                    </Typography>
                                                </Grid>

                                                <Grid item sm={2} xs={6} >
                                                    <Typography variant='body2' sx={{ fontWeight: 'bold' }}>
                                                        Fecha de radicación
                                                    </Typography>
                                                </Grid>

                                                <Grid item sm={4} xs={6} >
                                                    <Typography variant='body2' sx={{ textTransform: '' }}>
                                                        { data?.fechaRadicacion ? moment( data?.fechaRadicacion ).format('DD-MM-YYYY') : '' }
                                                    </Typography>
                                                </Grid>

                                                <Grid item sm={2} xs={6} >
                                                    <Typography variant='body2' sx={{ fontWeight: 'bold' }}>
                                                        Presentante
                                                    </Typography>
                                                </Grid>

                                                <Grid item sm={4} xs={6} >
                                                    <Typography variant='body2' sx={{ textTransform: '' }}>
                                                        { data?.presentante }
                                                    </Typography>
                                                </Grid>

                                                <Grid item sm={2} xs={6} >
                                                    <Typography variant='body2' sx={{ fontWeight: 'bold' }}>
                                                        Fecha de recepción
                                                    </Typography>
                                                </Grid>

                                                <Grid item sm={4} xs={6} >
                                                    <Typography variant='body2' sx={{ textTransform: '' }}>
                                                        { data?.fechaPresentacion ? moment( data?.fechaPresentacion ).format('DD-MM-YYYY') : '' }
                                                    </Typography>
                                                </Grid>

                                                <Grid item sm={2} xs={6} >
                                                    <Typography variant='body2' sx={{ fontWeight: 'bold' }}>
                                                        Juzgado
                                                    </Typography>
                                                </Grid>

                                                <Grid item sm={10} xs={6} >
                                                    <Typography variant='body2' sx={{ textTransform: '' }}>
                                                        { data?.juzgado }
                                                    </Typography>
                                                </Grid>

                                                <Grid item sm={2} xs={6} >
                                                    <Typography variant='body2' sx={{ fontWeight: 'bold' }}>
                                                        Actor(es)
                                                    </Typography>
                                                </Grid>

                                                <Grid item sm={4} xs={6} >
                                                    {
                                                        data?.actor?.split(', ')?.map( ( elem, i ) => (
                                                            <ListItem key={ i } disablePadding sx={{ pb: 1 }}>
                                                                <Typography variant='body2'>{ elem }</Typography>
                                                            </ListItem>
                                                        ))
                                                    }   
                                                </Grid>

                                                <Grid item sm={2} xs={6} >
                                                    <Typography variant='body2' sx={{ fontWeight: 'bold' }}>
                                                        Demandado(s)
                                                    </Typography>
                                                </Grid>

                                                <Grid item sm={4} xs={6} >
                                                    <List disablePadding>
                                                        {
                                                            data?.demandado?.split(', ')?.map( ( elem, i ) => (
                                                                <ListItem key={ i } disablePadding sx={{ pb: 1 }}>
                                                                    <Typography variant='body2'>{ elem }</Typography>
                                                                </ListItem>
                                                            ))
                                                        }    
                                                    </List>
                                                </Grid>

                                            </Grid>
                                    }

                                </CardContent>
                            
                            </Card>

                        </Grid>

                        <Grid item md={12} xs={12} >

                            <Card>
                                                    
                                <CardContent> 

                                    {
                                        loading 
                                        ?
                                            <Grid container spacing={3} sx={{ justifyContent: 'center' }}>
                                                <CircularProgress size={40} sx={{ mt: 15, mb: 15 }} />
                                            </Grid>
                                        :

                                            <Grid container spacing={3} sx={{ p: 1 }}>
                                              
                                                <Grid item md={12} xs={12} mt={1}>

                                                    <Grid container spacing={3}>   

                                                        <Grid item md={5} xs={12} >
                                                            <TextField
                                                                fullWidth
                                                                label="Tipo de documento"
                                                                name="tipoDocumento"
                                                                variant="standard" 
                                                                autoComplete='off'    
                                                                disabled={ filtroAplicado }       
                                                                InputLabelProps={{
                                                                    shrink: true
                                                                }}  
                                                                value={ buscarTipoDocumento }
                                                                onChange={ (e) => setBuscarTipoDocumento( e.target.value ) }
                                                            />
                                                        </Grid>

                                                        <Grid item md={5} xs={12} >
                                                            <TextField
                                                                fullWidth
                                                                label="Fecha"
                                                                name="fecha"
                                                                variant="standard" 
                                                                type='date'
                                                                autoComplete='off'  
                                                                disabled={ filtroAplicado }                
                                                                InputLabelProps={{
                                                                    shrink: true
                                                                }}  
                                                                value={ buscarFecha }
                                                                onChange={ (e) => setBuscarFecha( e.target.value ) }
                                                            />
                                                        </Grid>

                                                        <Grid item md={2} xs={12} >

                                                            <LoadingButton 
                                                                fullWidth 
                                                                type="button"                                                          
                                                                variant="contained"
                                                                startIcon={ filtroAplicado ? <ClearIcon /> : <SearchIcon /> }
                                                                disabled={ buscarTipoDocumento === ''  && buscarFecha === '' }
                                                                onClick={ () => { filtroAplicado ? handleClearFiltro() : handleBuscar() } }
                                                                loading={ loadingSearch }
                                                                color={ filtroAplicado ? 'inherit' : 'primary' }
                                                            > 
                                                                { filtroAplicado ? 'limpiar filtro' : 'Buscar'}
                                                            </LoadingButton> 

                                                        </Grid> 

                                                    </Grid>

                                                </Grid>                                                                                            

                                                <Grid item md={12} xs={12}>

                                                    <TableContainer>

                                                        <Table>
                                                            
                                                            <TableHead>

                                                                <TableRow>  
                                                                
                                                                    <TableCell style={{ width:'10%' }}> FECHA </TableCell>
                                                                    <TableCell style={{ width:'15%' }}> TIPO DE DOCUMENTO </TableCell>
                                                                    <TableCell style={{ width:'15%' }}> NOMBRE </TableCell>
                                                                    <TableCell style={{ width:'5%', textAlign: 'center' }}>  </TableCell>

                                                                </TableRow>

                                                            </TableHead>

                                                            <TableBody>

                                                                {
                                                                    documentos?.map( ( row, i ) => (

                                                                        <TableRow key={ i } >
                                                                            
                                                                            <TableCell> { row?.fecha ? moment( row?.fecha ).format('DD-MM-YYYY') : '' } </TableCell>
                                                                            <TableCell> { row.tipoDocumento } </TableCell>
                                                                            <TableCell> { row.nombre } </TableCell>
                                                                            
                                                                            <TableCell sx={{ textAlign: 'center' }}>   
                                                                                                                                                               
                                                                                <Button
                                                                                    onClick={ () => handleVerDocumento( row?.idDocumento, row?.extension === '.odt' ) }
                                                                                    color='primary'
                                                                                    variant='contained'
                                                                                >
                                                                                    {
                                                                                        row?.extension === '.odt'
                                                                                        ?
                                                                                            <DownloadIcon />
                                                                                        :
                                                                                            <FileCopyIcon />
                                                                                    }

                                                                                </Button>                                                                                

                                                                            </TableCell>

                                                                        </TableRow>

                                                                    ))
                                                                }

                                                                { 
                                                                    documentos.length === 0 && (
                                                                        <TableRow style={{ height: 53 }}>

                                                                            <TableCell colSpan={10} align="center" sx={{ fontWeight: 'bold' }}> 
                                                                                {
                                                                                    loadingDocumentos
                                                                                    ?
                                                                                        <CircularProgress size={40} sx={{ mt: 2, mb: 2 }} />
                                                                                    :
                                                                                        ''
                                                                                }
                                                                                {
                                                                                    !loadingDocumentos && documentos.length === 0
                                                                                    ?
                                                                                        'No se encontraron documentos'
                                                                                    :
                                                                                        ''
                                                                                }
                                                                            </TableCell>

                                                                        </TableRow>
                                                                    )
                                                                }
                                                            </TableBody>

                                                            <TableFooter>

                                                                <TableRow>

                                                                    <TableCell sx={{ display: { xs: 'none', md: 'flex' } }} colSpan={2}>
                                                                        <Typography variant='body2' color='black' sx={{ fontWeight: 'bold' }}> Total de documentos: { recordsCount } </Typography>
                                                                    </TableCell>    
                                                        
                                                                    <TablePagination
                                                                        rowsPerPageOptions={[ 5, 10, 15, 20 ]}
                                                                        colSpan={5}
                                                                        count={ recordsCount }                                        
                                                                        rowsPerPage={ rowsPerPage }
                                                                        page={ page }                                                                                
                                                                        onPageChange={ handleChangePage }
                                                                        onRowsPerPageChange={ handleChangeRowsPerPage }
                                                                        ActionsComponent={ TablePaginationActions }

                                                                        labelRowsPerPage="Filas por página"                                            
                                                                        labelDisplayedRows={
                                                                            ({ from, to, count }) => {
                                                                            return '' + from + '-' + to + ' de ' + count
                                                                            }
                                                                        }  
                                                                    />

                                                                </TableRow>

                                                            </TableFooter>

                                                        </Table>

                                                    </TableContainer>

                                                </Grid>

                                                {

                                                    documentos?.length !== 0
                                                    &&
                                                        <Grid item md={12} xs={12} textAlign='center'>
                                                            
                                                            <Button
                                                                variant='contained'                                                        
                                                                href={ process.env.REACT_APP_URL_BASE_DWH + '/Api/Expediente/DescargarDocumentosExpediente/' + Id }
                                                                target='_blank'
                                                                startIcon={ <DownloadIcon /> }
                                                                disabled={ documentos?.length === 0 }
                                                            >
                                                                Descargar documentos
                                                            </Button>

                                                        </Grid>
                                                }

                                            </Grid>

                                    }
                                
                                </CardContent>
                            
                            </Card>

                        </Grid>

                    </Grid>                    

                </Box>

            </Container>

            <Dialog open={ open } onClose={ () => {} } fullWidth={ true } maxWidth="md" >

                <DialogTitle>
                    Visualizar documento
                </DialogTitle>
               
               <DialogContent>

                    {
                        loadingDocumento 
                        ?
                            <Grid container spacing={3} sx={{ justifyContent: 'center' }}>
                                <CircularProgress size={40} sx={{ mt: 15, mb: 15 }} />
                            </Grid>
                        :
                  
                            <Grid container spacing={3} >                                                
                            
                                    <Grid item md={12} xs={12} sx={{ display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
                                        
                                        <iframe title='acuse' src={ documento } height="500px" width="100%"></iframe>

                                    </Grid>
                                
                            </Grid>

                    }
                  
               </DialogContent>

               <DialogActions>

                   <Button color="primary" onClick={ () => { setOpen( false ); setDocumento( '' ); } }>
                       Cerrar
                   </Button>

               </DialogActions>                

            </Dialog>  

        </>
    )
    
}
