import React, { useEffect, useState } from 'react';

import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, List, ListItem, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, TextField, Typography } from '@mui/material';

import { LoadingButton } from '@mui/lab';

import ClearIcon from '@mui/icons-material/Clear';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import InfoIcon from '@mui/icons-material/Info';
import SearchIcon from '@mui/icons-material/Search';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';

import moment from 'moment';

import { usePages } from '../../hooks/usePages';

import { TablePaginationActions } from '../../helpers/TablePaginationActions';

import { BuscarBoletasJuzgado, DetalleBoletas } from '../../actions/boleta/boletaActions';

export const BusquedaScreen = () => {  

    const [rows, setRows] = useState( [] );
    const [rowsPersonas, setRowsPersonas] = useState( [] );

    const [boleta, setBoleta] = useState( {} );
    
    const [numeroBoleta, setNumeroBoleta] = useState( '' );
    const [numeroExpediente, setNumeroExpediente] = useState( '' );
    const [solicitante, setSolicitante] = useState( '' );

    const [open, setOpen] = useState( false );
    const [openAcuse, setOpenAcuse] = useState( false );

    const [documento, setDocumento] = useState( '' );

    const [recordsCount, setRecordsCount] = useState( 0 );
    const [page, rowsPerPage, handleChangePage, handleChangeRowsPerPage, resetPage] = usePages( 10 );
    const [pagePersonas, rowsPerPagePersonas, handleChangePagePersonas, handleChangeRowsPerPagePersonas] = usePages( 5 ); 

    const [loading, setLoading] = useState( false );

    const [filtroAplicado, setFiltroAplicado] = useState( false );    
    const [loadingSearch, setLoadingSearch] = useState( false );    
    const [loadingDetalle, setLoadingDetalle] = useState( false );
    
    const handleDetalleBoleta = async ( id ) => {

        setOpen( true ); 
        setLoadingDetalle( true );

        await DetalleBoletas({ idBoleta: id }).then( response => {

            if( response ){

                if( response.data ){

                    setTimeout(() => {

                        setBoleta( response.data ?? {} );
                        
                        setRowsPersonas(response.data.personasNotificar ?? [] );    

                        setLoadingDetalle( false );

                    }, 1000);
                }
            }
        });              
        
    }

    const handleClearFiltro = () => {

        setFiltroAplicado( false ); 
        setSolicitante( '' );
        setNumeroBoleta( '' );        
        setNumeroExpediente( '' );

        setRecordsCount(0);

        resetPage();
        setRows( [] );
    }

    const handleBuscarBoletas = () => { 

        setLoadingSearch( true );
        setFiltroAplicado( true );

    }

    useEffect(() => {
      
        async function Obtener(){

            setRows( [] );
            setLoading( true );
           
            await BuscarBoletasJuzgado({ page, rowsPerPage, numeroBoleta, numeroExpediente, solicitante }).then( response => {

                if( response ){

                    if( response.data ){

                        setTimeout(() => {

                            setRows(response.data.records ?? [] );
                            setRecordsCount(response.data.recordsCount);

                            setLoading( false );
                            setLoadingSearch( false );

                        },1000);
                    }
                }
            });         
        }      

        if( filtroAplicado ) {
            Obtener();  
        }

    }, [ filtroAplicado, page, rowsPerPage, numeroBoleta, numeroExpediente, solicitante ])   

    return (
        <>
            <Grid container spacing={3}>

                <Grid item md={4} xs={12} >  

                    <TextField
                        name='numeroBoleta'
                        label='Número de boleta'                       
                        type='text'
                        autoComplete='off'                       
                        value= { numeroBoleta }                        
                        onChange={ (e) => setNumeroBoleta( e.target.value ) }
                        disabled={ filtroAplicado }
                        fullWidth
                    />

                </Grid>

                <Grid item md={4} xs={12} >  

                    <TextField
                        name='folioExpediente'
                        label='Número expediente'
                        type='text'
                        autoComplete='off'    
                        value={ numeroExpediente }
                        onChange={ (e) => setNumeroExpediente( e.target.value ) }                       
                        fullWidth
                        disabled={ filtroAplicado }
                    />

                </Grid>

                <Grid item md={4} xs={12} >  

                    <TextField
                        name='solicitante'
                        label='Solicitante'
                        autoComplete='off'    
                        value={ solicitante }
                        onChange={ (e) => setSolicitante( e.target.value ) }
                        fullWidth
                        disabled={ filtroAplicado }
                    />

                </Grid>

                <Grid item md={12} xs={12} >  

                    <Grid container spacing={3} display='flex' flexDirection='revert' justifyContent='end' >

                        <Grid item md={4} xs={12}>

                            <LoadingButton
                                variant='contained'
                                startIcon={ filtroAplicado ? <ClearIcon /> : <SearchIcon /> }
                                fullWidth
                                disabled={ solicitante === '' && numeroBoleta === '' && numeroExpediente === '' }
                                onClick={ () => { filtroAplicado ? handleClearFiltro() : handleBuscarBoletas() } }
                                loading={ loadingSearch }
                                color={ filtroAplicado ? 'inherit' : 'primary' }
                            >
                                { filtroAplicado ? 'limpiar filtro' : 'Buscar'}
                            </LoadingButton>

                        </Grid>

                    </Grid>

                </Grid>  

                <Grid item md={12} xs={12} >

                    <TableContainer>

                        <Table>
                            
                            <TableHead>

                                <TableRow>   
                                   
                                    <TableCell style={{ width:'15%' }}> FECHA </TableCell>
                                    <TableCell style={{ width:'15%' }}> BOLETA </TableCell>
                                    <TableCell style={{ width:'12%' }}> EXPEDIENTE </TableCell>
                                    <TableCell style={{ width:'15%' }}> TIPO DE DILIGENCIA </TableCell>
                                    <TableCell style={{ width:'20%' }}> SOLICITANTE </TableCell>
                                    <TableCell style={{ width:'15%', textAlign: 'center' }}> ESTATUS </TableCell>
                                    <TableCell style={{ width:'1%', textAlign: 'center' }}> DETALLE </TableCell>
                                    <TableCell style={{ width:'1%', textAlign: 'center' }}> ACUSE </TableCell>
                                    <TableCell style={{ width:'1%', textAlign: 'center' }}></TableCell>

                                </TableRow>

                            </TableHead>

                            <TableBody>

                                {
                                    rows.map( ( row ) => (

                                        <TableRow key={ row.id } >                                            
                                            
                                            <TableCell> { row.fechaSolicita ? moment(row?.fechaSolicita).format('DD/MM/YYYY hh:mma') :''  } </TableCell>                                            
                                            <TableCell sx={{ fontWeight: 'bold' }}> { row.numeroBoleta } </TableCell>
                                            <TableCell> { row.numeroExpediente } </TableCell>
                                            <TableCell> { row.tipoDiligencia } </TableCell>
                                            <TableCell> 
                                            { row.solicitanteNombre } { row.solicitanteApellido1 } { row.solicitanteApellido2 } <br />
                                                <strong> { row.solicitanteTelefonoCelular } </strong>
                                            </TableCell>

                                            <TableCell sx={{ textAlign: 'center' }} > 
                                                <strong> { row.estatus } </strong>
                                                {
                                                    row.estatus === 'Incompleta'
                                                    &&
                                                    <>
                                                        <br />
                                                        { ( row.motivo ? row.motivo : '' ).toUpperCase() } 
                                                    </>
                                                }
                                            </TableCell>
                                          
                                            <TableCell sx={{ textAlign: 'center' }}> 

                                                <Button
                                                    color='primary'   
                                                    size='small'
                                                    variant='contained'                                                       
                                                    onClick={ () => { handleDetalleBoleta( row.id ) } }
                                                >
                                                    <InfoIcon />
                                                </Button>  
                                
                                            </TableCell>

                                            <TableCell sx={{ textAlign: 'center' }}>  
                                                
                                                {
                                                    row.acuse
                                                    &&
                                                    <Button
                                                        onClick={ () => {  setDocumento( row.acuse ); setOpenAcuse( true );  } }
                                                        color='primary'   
                                                        size='small'
                                                        variant='contained'                                                      
                                                    >
                                                        <FileCopyIcon />
                                                    </Button>
                                                }  

                                            </TableCell>

                                            <TableCell> { row.app && <PhoneAndroidIcon /> } </TableCell>                                    

                                        </TableRow>

                                    ))
                                }

                                { 
                                    rows.length === 0 && (
                                        <TableRow style={{ height: 53 }}>

                                            <TableCell colSpan={10} align="center" sx={{ fontWeight: 'bold' }}> 
                                                {
                                                    loading
                                                    ?
                                                        <CircularProgress size={40} sx={{ mt: 2, mb: 2 }} />
                                                    :
                                                        ''
                                                }
                                                {
                                                    !loading && rows.length === 0
                                                    ?
                                                        'No se encontraron boletas'
                                                    :
                                                        ''
                                                }
                                            </TableCell>

                                        </TableRow>
                                    )
                                }
                            </TableBody>

                            <TableFooter>

                                <TableRow>

                                    <TableCell colSpan={2}>
                                        <Typography variant='body2' color='black' sx={{ fontWeight: 'bold' }}> Total de registros: { recordsCount } </Typography>
                                    </TableCell> 
                        
                                    <TablePagination
                                        rowsPerPageOptions={[ 5, 10, 15, 20 ]}
                                        colSpan={10}
                                        count={ recordsCount }                                        
                                        rowsPerPage={rowsPerPage}
                                        page={page}                                                                                
                                        onPageChange={ handleChangePage }
                                        onRowsPerPageChange={ handleChangeRowsPerPage }
                                        ActionsComponent={ TablePaginationActions }

                                        labelRowsPerPage="Filas por página"                                            
                                        labelDisplayedRows={
                                            ({ from, to, count }) => {
                                            return '' + from + '-' + to + ' de ' + count
                                            }
                                        }  
                                    />

                                </TableRow>

                            </TableFooter>

                        </Table>

                    </TableContainer>

                </Grid>

            </Grid>

            <Dialog
                open={open}
                onClose={ () => { } }
                maxWidth='md'
                fullWidth
            >
                <DialogTitle>
                </DialogTitle>
               
                <DialogContent>
                   
                    {
                        loadingDetalle
                        ?
                            <Grid container spacing={3} sx={{ justifyContent: 'center' }}>
                                <CircularProgress size={40} sx={{ mt: 5, mb: 5 }} />
                            </Grid>
                        :
                            <Grid container spacing={3}>

                                <Grid item sm={3} xs={6} >
                                    <Typography variant='h6' sx={{ fontWeight: 'bold' }}>
                                        Número de boleta
                                    </Typography>   

                                </Grid>  

                                <Grid item sm={9} xs={6} >
                                    <Typography variant='h6' sx={{ textTransform: '' }}>
                                        { boleta.numeroBoleta }
                                    </Typography>
                                </Grid>

                                <Grid item sm={3} xs={6} >
                                    <Typography variant='body2' sx={{ fontWeight: 'bold' }}>
                                        Solicitante 
                                    </Typography>
                                </Grid>

                                <Grid item sm={9} xs={6} >
                                    <Typography variant='body2' sx={{ textTransform: '' }}>
                                        { boleta.solicitante } <br />
                                        <strong> { boleta.telefonoCelularSolicitante } </strong>
                                    </Typography>
                                </Grid>

                                <Grid item sm={3} xs={6} >
                                    <Typography variant='body2' sx={{ fontWeight: 'bold' }}>
                                        Número de expediente
                                    </Typography>
                                </Grid>

                                <Grid item sm={9} xs={6} >
                                    <Typography variant='body2' sx={{ textTransform: '' }}>
                                        { boleta.numeroExpediente }
                                    </Typography>
                                </Grid>

                                <Grid item sm={3} xs={6} >
                                    <Typography variant='body2' sx={{ fontWeight: 'bold' }}>
                                        Juzgado
                                    </Typography>
                                </Grid>

                                <Grid item sm={9} xs={6} >
                                    <Typography variant='body2' sx={{ textTransform: '' }}>
                                        { boleta.juzgado }
                                    </Typography>
                                </Grid>

                                <Grid item sm={3} xs={6} >
                                    <Typography variant='body2' sx={{ fontWeight: 'bold' }}>
                                        Tipo de diligencia
                                    </Typography>
                                </Grid>

                                <Grid item sm={9} xs={6} >
                                    <Typography variant='body2' sx={{ textTransform: '' }}>
                                        { boleta.tipoDiligencia }
                                    </Typography>
                                </Grid>

                                {
                                    boleta.observaciones
                                    &&
                                    <>
                                        <Grid item sm={3} xs={6} >
                                            <Typography variant='body2' sx={{ fontWeight: 'bold' }}>
                                                Observaciones
                                            </Typography>
                                        </Grid>

                                        <Grid item sm={9} xs={6} >
                                            <Typography variant='body2' sx={{ textTransform: '' }}>
                                                { boleta.observaciones }
                                            </Typography>
                                        </Grid>
                                    </>
                                }

                                <Grid item sm={3} xs={6} >
                                    <Typography variant='body2' sx={{ fontWeight: 'bold' }}>
                                        Autos a notificar
                                    </Typography>
                                </Grid>

                                <Grid item sm={9} xs={6} >

                                    <List disablePadding>  

                                        { boleta?.acuerdos?.map( ( elem, i  ) => (
                                            <ListItem key={ i } disablePadding sx={{ pb: 1 }}>
                                                <Typography variant='body2'> <strong> { elem ? moment( elem ).format('DD-MM-YYYY') : '' } </strong></Typography>                                    
                                            </ListItem>
                                        ))}                               
                                        
                                    </List>

                                </Grid>

                                <Grid item xs={12} sx={{ mt: 1 }} >

                                    <Typography variant='h6'>Personas a notificar</Typography>

                                    <Divider sx={{ pb: 2 }} />

                                </Grid>

                                <Grid item md={12} xs={12} >

                                    <TableContainer>

                                        <Table>
                                            
                                            <TableHead>

                                                <TableRow>        

                                                    <TableCell style={{ width:'15%' }}> NOMBRE(S) </TableCell>
                                                    <TableCell style={{ width:'15%' }}> APELLIDO 1 </TableCell>
                                                    <TableCell style={{ width:'15%' }}> APELLIDO 2 </TableCell>
                                                    <TableCell style={{ width:'30%' }}> DOMICILIO </TableCell>

                                                </TableRow>

                                            </TableHead>

                                            <TableBody>

                                                {
                                                    ( rowsPerPagePersonas > 0
                                                        ? rowsPersonas.slice(pagePersonas * rowsPerPagePersonas, pagePersonas * rowsPerPagePersonas + rowsPerPagePersonas)
                                                        : rowsPersonas
                                                    ).map( ( row, i ) => (

                                                        <TableRow key={ i } >
                                                            
                                                            <TableCell> { row.nombre } </TableCell>
                                                            <TableCell> { row.apellido1 } </TableCell>
                                                            <TableCell> { row.apellido2 } </TableCell>
                                                            <TableCell> { row.direccion } </TableCell>

                                                        </TableRow>

                                                    ))
                                                }

                                                { 
                                                    rowsPersonas.length === 0 && (
                                                        <TableRow style={{ height: 53 }}>

                                                            <TableCell colSpan={10} align="center" sx={{ fontWeight: 'bold' }}> 
                                                                No se añadieron personas a notificar
                                                            </TableCell>

                                                        </TableRow>
                                                    )
                                                }
                                            </TableBody>

                                            <TableFooter>

                                                <TableRow>
                                        
                                                    <TablePagination
                                                        rowsPerPageOptions={[ 5, 10, 15, 20 ]}
                                                        colSpan={10}
                                                        count={rowsPersonas.length}                                        
                                                        rowsPerPage={rowsPerPagePersonas}
                                                        page={pagePersonas}                                                                                
                                                        onPageChange={ handleChangePagePersonas }
                                                        onRowsPerPageChange={ handleChangeRowsPerPagePersonas }
                                                        ActionsComponent={ TablePaginationActions }

                                                        labelRowsPerPage="Filas por página"                                            
                                                        labelDisplayedRows={
                                                            ({ from, to, count }) => {
                                                            return '' + from + '-' + to + ' de ' + count
                                                            }
                                                        }  
                                                    />

                                                </TableRow>

                                            </TableFooter>

                                        </Table>

                                    </TableContainer>

                                </Grid>
                            
                            </Grid>
                    }

                </DialogContent>

                <DialogActions>
                    <Button onClick={ () => { setOpen( false ) } }>Cancelar</Button>
                </DialogActions>

            </Dialog>

            <Dialog 
                open={ openAcuse } 
                onClose={ () => {} } 
                fullWidth={ true } 
                maxWidth="md" 
            >

                <DialogTitle>
                    Acuse
                </DialogTitle>
               
               <DialogContent>
                  
                   <Grid container spacing={3} >                                                
                      
                        <Grid item md={12} xs={12} sx={{ display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
                            
                           <iframe 
                                    title='acuse' 
                                    src={ documento } 
                                    height="500px" 
                                    width="100%"
                                >                                    
                            </iframe>

                        </Grid>

                   </Grid>
                  
               </DialogContent>

               <DialogActions>

                   <Button color="primary" onClick={ () => { setOpenAcuse( false ); } }>
                       Cerrar
                   </Button>

               </DialogActions>                

            </Dialog> 

        </>
    )

}
