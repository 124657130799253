import React, { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Link, useLocation, useNavigate } from 'react-router-dom';

import { AppBar, Box, Button, CircularProgress, Container, Divider, ListItemIcon, Menu, MenuItem, Toolbar, Typography } from '@mui/material';

import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import HelpIcon from '@mui/icons-material/Help';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import UndoIcon from '@mui/icons-material/Undo';
import ArticleIcon from '@mui/icons-material/Article';

import { Logout } from '../../actions/auth/authActions';

import { ModalTokenExpired } from '../modals/ModalTokenExpired';

export const NavbarInicio = () => {

    const { nombre, organoJurisdiccional, imagenPerfil } = useSelector( state => state.auth );

    const navigate = useNavigate();

    const dispatch = useDispatch();

    const { pathname } = useLocation();

    const [anchorElUser, setAnchorElUser] = useState(null);

    const [loading, setLoading] = useState( false );

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleLogout = () => {
        
        setLoading( true );

        setTimeout(() => {
            
            dispatch( Logout() );

        }, 1000);
        
    }

    const handleRegresarInicio = () => navigate('/inicio/')

    return (
        <>
            <ModalTokenExpired />

            <AppBar position="absolute" color='inherit' >

                <Container maxWidth="xl">

                    <Toolbar disableGutters>
                    
                        <Typography variant="h6" sx={{ mr: 2, flexGrow: 1 }} >
                                
                            <Box sx={{ ml: { sm: 10 } }}>
                                            
                                <Link to="/inicio/">

                                    <Box sx={{ display: { md: 'block', xs: 'none' } }}>

                                        <img style={{ width: 250, zIndex: 5 }}
                                            src={ process.env.PUBLIC_URL + '/assets/logo.png'}
                                            alt='logo-poder-en-linea'
                                        />

                                    </Box>

                                    <Box sx={{ display: { md: 'none', xs: 'block' } }}>

                                        <img style={{ width: 180, zIndex: 5 }}
                                            src={ process.env.PUBLIC_URL + '/assets/logo.png'}
                                            alt='logo-poder-en-linea'
                                        />
                                        
                                    </Box>
                                    
                                </Link>
                            
                            </Box>

                        </Typography>

                        <Box sx={{ flexGrow: 0, textAlign: 'right' }}>

                            {
                                (pathname && pathname !== '/inicio/' )
                                &&
                                <Button 
                                    color="inherit" 
                                    sx={{ textTransform: 'none', mr: 1 }}
                                    startIcon={ <UndoIcon /> }
                                    onClick={ handleRegresarInicio }
                                >
                                    Regresar al inicio
                                </Button>
                            }

                            <Button 
                                color="inherit" 
                                sx={{ textTransform: 'none' }}
                                endIcon={ <KeyboardArrowDownIcon /> }
                                onClick={ handleOpenUserMenu }       
                            >
                                <img 
                                    alt='imagen de perfil del usuario'
                                    style={{ width: 40, height: 40, borderRadius: 50, marginRight: 10 }} 
                                    src={ imagenPerfil ? imagenPerfil : require('../../assets/user.png') }                                     
                                />
                                
                                { nombre }

                                { organoJurisdiccional ? <br /> : '' } { organoJurisdiccional }

                            </Button>
                            
                            <Menu
                                sx={{ mt: '45px' }}
                                id="menu-appbar"
                                anchorEl={anchorElUser}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={ Boolean(anchorElUser) }
                                onClose={ handleCloseUserMenu }
                            >
                                
                                <MenuItem onClick={ () => { navigate('/perfil/'); handleCloseUserMenu(); } } >
                                    <ListItemIcon>    
                                        <ManageAccountsIcon color='primary' />  
                                    </ListItemIcon>
                                    <Typography variant='body1' sx={{ fontWeight: 'bold', color: '#252B50' }}>Perfil</Typography>
                                </MenuItem>

                                <Divider />

                                <MenuItem onClick={ () => { navigate('/ayuda/'); handleCloseUserMenu(); } }  >
                                    <ListItemIcon>    
                                        <HelpIcon color='primary' />  
                                    </ListItemIcon>
                                    <Typography variant='body1' sx={{ fontWeight: 'bold', color: '#252B50' }}>Ayuda</Typography>
                                </MenuItem>
                                
                                <Divider />

                                <MenuItem onClick={ () => { } } >
                                    <ListItemIcon>    
                                        <ArticleIcon color='primary' />  
                                    </ListItemIcon>
                                    <Link to='/aviso/' target='_blank' style={{ textDecoration: 'none', color: '#252B50' }}>
                                        <Typography variant='body1' sx={{ fontWeight: 'bold' }}>Aviso de privacidad</Typography>
                                    </Link>
                                </MenuItem>
                                
                                <Divider />
                              
                                <MenuItem onClick={ () => { } } >                                      
                                    <ListItemIcon>    
                                        <ArticleIcon color='primary' />  
                                    </ListItemIcon>                                       
                                    <Link to='/terminos/' target='_blank' style={{ textDecoration: 'none', color: '#252B50' }}>
                                        <Typography variant='body1' sx={{ fontWeight: 'bold' }}>Términos y condiciones de uso</Typography>
                                    </Link>
                                </MenuItem>
                                
                                <Divider />

                                <MenuItem onClick={ handleLogout } >
                                    <ListItemIcon>    
                                            { loading ? <CircularProgress size={20} /> : <ExitToAppIcon color='primary' /> }
                                    </ListItemIcon>
                                    <Typography variant='body1' sx={{ fontWeight: 'bold', color: '#252B50' }}>Salir</Typography>
                                </MenuItem>

                            </Menu>

                        </Box>

                    </Toolbar>

                </Container>

            </AppBar>   
        
        </>        
    )
}
