import React from 'react';

import { useNavigate } from 'react-router-dom';

import { Button, Card, CardActionArea, CardActions, CardContent, CardMedia, Grid, Typography } from '@mui/material';

import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

export const CardAplicacion = ({ image, title, desc, desc_button, path }) => {

    const navigate = useNavigate();   

    return (
        <Grid item md={3} sm={6} xs={12} >
           
            <Card sx={{ borderRadius: 3 }}>

                <CardActionArea
                    onClick={ () => navigate( path ) }
                >

                    <CardMedia
                        component='img'
                        height={150}
                        image={ process.env.PUBLIC_URL + `/assets/${ image }`}
                        alt='imagen card'
                    />
                    
                    <CardContent sx={{ height: 100 }}>
                    
                        <Typography gutterBottom variant='h5' component='div' sx={{ fontSize: 18 }}>
                            { title }
                        </Typography>
                    
                        <Typography variant='body2' color='text.secondary'>
                            { desc }
                        </Typography>

                    </CardContent>

                </CardActionArea>
                
                <CardActions>

                    <Button 
                        size='small'                             
                        color='primary'
                        fullWidth 
                        sx={{ textTransform: 'initial', fontSize: 14 }}
                        onClick={ () => navigate( path ) }
                        endIcon={ <KeyboardArrowRightIcon /> }
                    >
                        { desc_button }
                    </Button>

                </CardActions>
                
            </Card>  

        </Grid>
    )

}
