import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Link, useNavigate } from 'react-router-dom';

import { Container, Grid, Box, Typography, Stack, TextField, Paper, Button, Alert, Snackbar, FormControl, InputLabel, OutlinedInput, InputAdornment, IconButton } from '@mui/material';

import { LoadingButton } from '@mui/lab';

import LoginIcon from '@mui/icons-material/Login';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import KeyIcon from '@mui/icons-material/Key';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import '../../css/login.css';

import { Login } from '../../actions/auth/authActions';
import { useForm } from '../../hooks/useForm';
import { DownloadApp } from '../ui/DownloadApp';

export const LoginScreen = () => {

    const navigate = useNavigate();

    const dispatch = useDispatch();

    const { errorMessage } = useSelector( state => state.auth );

    const { correoElectronico, contrasena, onChange } = useForm({ correoElectronico: '', contrasena: '' });

    const [{ open, message, type }, setAlert] = useState({
        open: false,
        message: '',
        type: 'warning'
    });

    const [viewContrasena, setViewContrasena] = useState( false );

    const [loading, setLoading] = useState( false );

    const handleRegister = () => navigate("/auth/register/")

    const handleForgotPassowrd = () => navigate("/auth/password/")

    const handleChangeViewContrasena = () => {
        setViewContrasena( ( prev ) => !prev );
    }

    const validateFiels = () => {

        let valid = true;

        const validEmail = /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(correoElectronico);

        if(!correoElectronico){    
            valid = false;
            setAlert({
                open: true,
                message: 'Escribe el correo electrónico',
                type: 'warning'
            });
        }        
        else if(!validEmail){   
            valid = false;
            setAlert({
                open: true,
                message: 'El correo electrónico no es valido',
                type: 'warning'
            });
        }
        else if(!contrasena){
            valid = false;
            setAlert({
                open: true,
                message: 'Escribe la contraseña',
                type: 'warning'
            });
        }

        return valid;
    }

    const handleLogin = ( e ) => {

        e.preventDefault();

        const valid = validateFiels();

        if( !valid ){
            return false;
        }

        setLoading( true );
        
        dispatch( Login({ correoElectronico, contrasena }, setLoading) )

    }

    useEffect(() => {
      
        if( errorMessage ){
            setAlert({
                open: true,
                message: errorMessage,
                type: 'error'
            });
        }
        
    }, [ errorMessage ])

    return (
        <>
            <Snackbar open={ open } autoHideDuration={6000} onClose={ () => {} } anchorOrigin={{ vertical : 'bottom', horizontal: 'center' }} >
                <Alert onClose={ () => { setAlert({ open: false, message, type }) } } variant='filled' severity={ type } sx={{ width: '100%' }}>
                    { message }
                </Alert>
            </Snackbar>

            <Container maxWidth='lg' sx={{ height: '90vh' }} >
                
                <Grid container justifyContent='center' alignItems='center' sx={{ width: '100%', height: '100%' }} >
                
                    <Grid item sx={{ maxWidth: '60rem', width: '100%' }} >
                    
                        <Paper sx={{ backgroundColor: 'rgba(255, 255, 255, 1)', mt:{ xs: 5, md: 10 } }} >

                            <Grid container >
                                
                                <Grid item container justifyContent='space-between' >
                                    
                                    <Grid item xs={12} sm={12} md={6} >
                                    
                                        <Box display='flex' flexDirection='column' alignItems='center' sx={{ pr: '1em', pl: '1em', mt:{ md: '8em' } }} >
                                        
                                            <Box sx={{ display: { md: 'flex', xs: 'none' } }}>
                                                <img style={{ width: 400 }}
                                                    src={ process.env.PUBLIC_URL + '/assets/logo.png'}
                                                    alt='logo-poder-en-linea'
                                                />
                                            </Box>

                                            <Box sx={{ display: { md: 'none', xs: 'flex' } }}>
                                                <img style={{ width: 320 }}
                                                    src={ process.env.PUBLIC_URL + '/assets/logo.png'}
                                                    alt='logo-poder-en-linea'
                                                />
                                            </Box>

                                        </Box>

                                    </Grid>

                                    <Grid item xs={12} sm={12} md={6} >

                                        <Box display='flex' flexDirection='column' sx={{ pr: { md: '3em', xs: '1em' }, pl: { md: '3em', xs: '1em' }, mt:{ md: '3em' } }} >

                                            <Typography variant='h6' component='h1' sx={{ textAlign: 'center', mb: '1.5rem', textTransform: 'uppercase' }} >
                                                Ingresa con tu cuenta
                                            </Typography>
                                            
                                            <form>

                                                <Grid container spacing={3} >

                                                    <Grid item xs={12} sm={12} > 

                                                        <TextField
                                                            label='Usuario (Correo electrónico)'
                                                            type='email'                                                         
                                                            fullWidth
                                                            autoComplete='off'
                                                            value={ correoElectronico }
                                                            onChange={ ( e ) => onChange( e.target.value, 'correoElectronico' ) }
                                                        />

                                                    </Grid>

                                                    <Grid item xs={12} sm={12} > 

                                                        <FormControl variant="outlined" fullWidth >
                                                            <InputLabel>Contraseña</InputLabel>
                                                            <OutlinedInput
                                                                label="Contraseña"                                                             
                                                                type={ viewContrasena ? 'text' : 'password'}
                                                                value={ contrasena }                                
                                                                onChange={ ( e ) => onChange( e.target.value, 'contrasena' ) }
                                                                endAdornment={
                                                                    <InputAdornment position="end">
                                                                        <IconButton onClick={ handleChangeViewContrasena } edge="end" >
                                                                            {
                                                                                viewContrasena
                                                                                ?
                                                                                <VisibilityOffIcon />
                                                                                :
                                                                                <VisibilityIcon />
                                                                            }                                                    
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                                }
                                                            />
                                                        </FormControl>  

                                                    </Grid>
                                        
                                                    <Grid item xs={12} sm={12} > 

                                                        <LoadingButton 
                                                            variant='contained' 
                                                            type='submit'
                                                            loading={ loading } 
                                                            fullWidth
                                                            startIcon={ <LoginIcon /> }
                                                            onClick={ handleLogin }
                                                        >
                                                            Ingresar
                                                        </LoadingButton>
                                                    
                                                    </Grid>

                                                    <Grid item xs={12} sm={12} > 

                                                        <Button 
                                                            variant='contained' 
                                                            fullWidth
                                                            startIcon={ <HowToRegIcon /> }
                                                            color='secondary'
                                                            onClick={ handleRegister }
                                                        >
                                                            Crear una cuenta
                                                        </Button>
                                                    
                                                    </Grid>

                                                    <Grid item xs={12} sm={12} > 

                                                        <Button 
                                                            variant='text'                                                        
                                                            fullWidth
                                                            startIcon={ <KeyIcon /> }
                                                            color='primary'
                                                            onClick={ handleForgotPassowrd }
                                                        >
                                                            ¿Olvidaste tu contraseña?
                                                        </Button>
                                                    
                                                    </Grid>

                                                </Grid>   
                                            
                                            </form>

                                        </Box>

                                    </Grid>

                                </Grid>
                                
                                <Grid container justifyContent='center'>

                                    <Stack sx={{ mt: '2rem', mb: '1rem', textAlign: 'center' }}>

                                        <Grid container spacing={1}>

                                            <Grid item xs={12}>

                                                <Button variant='text'>
                                                    <Link to='/aviso/' target='_blank' style={{ textDecoration: 'none', color: '#252B50' }} >Aviso de privacidad</Link>
                                                </Button>
                                                
                                            </Grid>

                                            <Grid item xs={12}>

                                                <Button variant='text'>
                                                    <Link to='/terminos/' target='_blank' style={{ textDecoration: 'none', color: '#252B50' }} >Términos y condiciones de uso</Link>
                                                </Button>

                                            </Grid>

                                        </Grid>

                                    </Stack>

                                </Grid>

                            </Grid>

                        </Paper>

                       <DownloadApp />

                    </Grid>                    

                </Grid>

            </Container>
        </>
    );
}
