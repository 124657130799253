import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import {
    Alert, Box, Button, CircularProgress, Container, Dialog, DialogActions, DialogContent, FormControl, Grid, IconButton, InputLabel, LinearProgress, MenuItem, Paper, Select, Switch, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination,
    TableRow, Toolbar, Typography
} from '@mui/material';

import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import UndoIcon from '@mui/icons-material/Undo';

import { NavbarInicio } from '../ui/NavbarInicio';

import { usePages } from '../../hooks/usePages';

import { TablePaginationActions } from '../../helpers/TablePaginationActions';

import { Materias } from '../../actions/catalogos/materiaActions';
import { ObtenerOficialias } from '../../actions/catalogos/oficialiaActions';

import { ActualizarOficialiaMateria, AgregarOficialiaMateria, OficialiaMateria } from '../../actions/catalogos/oficialiaMateriaActions';

import { usePermisos } from '../../hooks/usePermisos';

import { NotFoundScreen } from '../auth/NotFoundScreen';
    
export const OficialiaMateriaScreen = ( ) => {

    const { tipoUsuario, loading } = usePermisos();

    const navigate = useNavigate();

    const [errors, setErrors] = useState({ });

    const [rows, setRows] = useState( [ ] );

    const [oficialia, setOficialia] = useState( 0 );
    const [oficialiaArray, setOficialiaArray] = useState( [ ] );

    const [materia, setMateria] = useState( 0 );
    const [materiaArray, setMateriaArray] = useState( [ ] );

    const [idReferencia, setIdReferencia] = useState( '' );
    const [opcion, setOpcion] = useState( '' );

    const [loadingTable, setLoadingTable] = useState( true );
    const [loadingSave, setLoadingSave] = useState( false );
    const [openModal, setOpenModal] = useState( false );
  
    const [page, rowsPerPage, handleChangePage, handleChangeRowsPerPage] = usePages();
  
    const handleChangeSwitch = ( id, value ) => {

        const newRows = rows.map( (row) => { if(row.idOficialiaMateria === id){ row.activo = value; return row; } else{ return row; } } );
        setRows( newRows );        

        const oficialiaMateria = rows.filter( function(row){ return row.idOficialiaMateria === id; } );
        cambiarEstatus( oficialiaMateria[0] );
    }

    const handleOpenModal = () => {
        setOpenModal(true);
    }

    const handleCloseModal = () => {

        setOficialia( 0 );
        setMateria( 0 );

        setOpenModal(false);
        setErrors( { } );
    }

    const cambiarEstatus = async ( { idOficialiaMateria, activo, oficialia, materia } ) => {

        const params = {
            idOficialiaMateria,
            idOficialia: oficialia.idOficialia,
            idMateria: materia.idMateria,
            activo,
        };

        await ActualizarOficialiaMateria( params );
    }

    const AgregarRegistro = () => {

        setOpcion( 'agregar' );
        setIdReferencia( 0 );

        handleOpenModal();
    }

    const EditarRegistro = ( id, idOficialia , idMateria ) => {
        
        setOficialia( idOficialia );
        setMateria( idMateria );

        setOpcion( 'editar' );
        setIdReferencia( id );

        handleOpenModal();
    }

    const Guardar = async () => {

        const valid = validateFiels();

        if( !valid ){
            return false;
        }

        setLoadingSave( true );

        const validate = validateDuplicidad();
        if( !validate ) return;

        if( opcion === 'agregar' ){
            const params = {
                idOficialiaMateria: 0,
                idOficialia: oficialia,
                idMateria: materia,
                activo: true,
            };

            await AgregarOficialiaMateria( params ).then( response => {
                if( response ){
                    if( response.status === 200){
                        if( response.data ){
                            
                            setTimeout(() => {
                                
                                setRows([ 
                                    ...rows,  
                                    {
                                        idOficialiaMateria: response.data.idOficialiaMateria,
                                        idOficialia: response.data.idOficialia,
                                        oficialia: response.data.oficialia,
                                        idMateria: response.data.idMateria,
                                        materia: response.data.materia,
                                        activo: true
                                    }
                                ]);  
    
                                handleCloseModal();

                                setLoadingSave( false );

                            }, 1000);                            
                            
                        } 
                    }
                }
            });
        }

        if( opcion === 'editar' ){
            const oficialiaMateria = rows.filter( function(row){ return row.idOficialiaMateria === idReferencia; } )[0];

            const params = {
                idOficialiaMateria: idReferencia,
                idOficialia: oficialia,
                idMateria: materia,
                activo: oficialiaMateria.activo,
            };
            
            await ActualizarOficialiaMateria( params ).then( response => {
                if( response ){
                    if( response.status === 200){

                        setTimeout(() => {
                                
                            const newRows = rows.map( (row) => { 
                                if(row.idOficialiaMateria === idReferencia){ 
                                    row.idOficialia = response.data.idOficialia;
                                    row.oficialia = response.data.oficialia;
                                    row.idMateria = response.data.idMateria;
                                    row.materia = response.data.materia;
                                    return row; 
                                } 
                                else{ 
                                    return row; 
                                } 
                            });
    
                            setRows( newRows ); 
                            
                            handleCloseModal();

                            setLoadingSave( false );

                        }, 1000);                        
                        
                    }
                }                
            });
        }
    }

    const validateDuplicidad = () => {

        const even = (e) => e.idOficialia === oficialia && e.idMateria === materia;
        const validateAdd = rows.some( even );

        if( validateAdd ){

            const oficialiasItems = oficialiaArray.find( (e) => e.idOficialia === oficialia );
            const materiaItems = materiaArray.find( (e) => e.idMateria === materia );

            setErrors({ add: 'La oficialía ' + oficialiasItems?.descripcion + ' y la Matería ' + materiaItems?.descripcion + ' ya estan agregados' });
            
            setLoadingSave( false );

            return false;
        }

        return true;
    }

    const validateFiels = () => {
        
        setErrors( { } );

        let valid = true;
        let errores = {};

        if( oficialia === 0 ){
            valid = false;
            errores.oficialia = 'Debes seleccionar una Oficialía';
        }    
        
        if( materia === 0 ){
            valid = false;
            errores.materia = 'Debes seleccionar una Materia';
        }  

        setErrors( errores );
        return valid;
    } 

    useEffect(() => {
        
        async function Obtener(){
            
            await OficialiaMateria().then(response => { 
                
                if( response ){
                    if( response.status === 200 ){
                       
                        setTimeout(() => {
                        
                            setLoadingTable( false );
                            setRows( response.data );

                        }, 1000);

                    }
                }
                
            });

        }

        Obtener();

    }, [ ]);

    useEffect(() => {
        
        async function Obtener(){
            
            await ObtenerOficialias().then(response => {
                
                if( response ){
                    if( response.status === 200 ){
                        setOficialiaArray( response.data );
                    }
                }
                
            });

        }

        Obtener();

    }, [ ]);

    useEffect(() => {
        
        async function Obtener(){
            
            await Materias().then(response => {
                
                if( response ){
                    if( response.status === 200 ){
                        setMateriaArray( response.data );
                    }
                }
                
            });

        }

        Obtener();

    }, [ ]);

    return (
        <>
            
            <NavbarInicio />

            {
                ( !loading && tipoUsuario !== 3 )
                &&
                <NotFoundScreen />
            }

            {
                ( !loading && tipoUsuario === 3  )
                &&   
                <>

                    <Container maxWidth='xl' sx={{ mt: { md: '13em', xs: '11em' } }}>

                        <Box sx={{ pb: '2em', pl: { md: 10, xs: 1 }, pr: { md: 10, xs: 1 }, pt: 2 }} component={ Paper } >	

                            <Container maxWidth="lg">   

                                <Grid container spacing={3} sx={{ mt: 1 }} >  
                                        
                                    <Grid item md={6} xs={6} sx={{ textAlign: 'left' }}> 
                                        <Button type="button" startIcon={ <UndoIcon /> } variant='contained' color='inherit' sx={{ mt: 1 }} onClick={ () => navigate('/administracion/') }> 
                                            Regresar a los catálogos
                                        </Button> 
                                    </Grid>

                                    <Grid item md={6} xs={6} sx={{ textAlign: 'right' }}> 
                                        <Button type="button" startIcon={ <AddCircleOutlineRoundedIcon /> } variant="contained" sx={{ mt: 1 }} onClick={ AgregarRegistro }> 
                                            Agregar
                                        </Button> 
                                    </Grid>

                                </Grid>

                                <Grid container spacing={3} sx={{ mt: 1 }} >  
                                    
                                    <Grid item xs={12} md={12}> 

                                        <Toolbar sx={{ pl: { sm: 2 }, pr: { xs: 1, sm: 1 } }} >
                                            <Typography sx={{ flex: '1 1 100%' }} variant="h6" component="div" >
                                                Oficialía - Matería
                                            </Typography>
                                        </Toolbar>

                                        <TableContainer>

                                            <Table sx={{ minWidth: 500 }} >
                                                <TableHead>
                                                    <TableRow>        
                                                        <TableCell style={{ width:'10%' }}> ID </TableCell>
                                                        <TableCell style={{ width:'30%' }}> OFICIALIA</TableCell>
                                                        <TableCell style={{ width:'30%' }}> MATERIA </TableCell>
                                                        <TableCell style={{ width:'10%' }}> HABILITADO </TableCell>
                                                        <TableCell style={{ width:'10%' }}> EDITAR </TableCell>
                                                    </TableRow>
                                                </TableHead>

                                                <TableBody>
                                                {
                                                (rowsPerPage > 0
                                                    ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                    : rows
                                                ).map((row) => (
                                                    <TableRow key={ row.idOficialiaMateria} >
                                                    
                                                        <TableCell style={{ fontWeight: 'bold' }}>
                                                            { row.idOficialiaMateria }
                                                        </TableCell>

                                                        <TableCell>
                                                            { row.oficialia.descripcion }
                                                        </TableCell>

                                                        <TableCell>
                                                            { row.materia.descripcion }
                                                        </TableCell>
                                                        
                                                        <TableCell>
                                                            {
                                                                row.activo 
                                                                ? 
                                                                (
                                                                    <Switch 
                                                                        inputProps={ { 'aria-label': 'Switch demo'} } 
                                                                        checked={ row.activo } 
                                                                        onChange={ ( e ) => handleChangeSwitch( row.idOficialiaMateria, e.target.checked ) }
                                                                    />
                                                                ) 
                                                                :                                           
                                                                ( 
                                                                    <Switch 
                                                                        inputProps={ { 'aria-label': 'Switch demo'} } 
                                                                        checked={ row.activo } 
                                                                        onChange={ ( e ) => handleChangeSwitch( row.idOficialiaMateria, e.target.checked ) }
                                                                    />
                                                                )
                                                            }
                                                        </TableCell>

                                                        <TableCell>
                                                            <IconButton 
                                                                    color="primary" 
                                                                    aria-label="edit" 
                                                                    component="span" 
                                                                    onClick={ () =>{ EditarRegistro( row.idOficialiaMateria,  row.oficialia.idOficialia, row.materia.idMateria )}  }
                                                                >                                                        
                                                                <EditIcon />
                                                            </IconButton>  
                                                        </TableCell>

                                                    </TableRow>
                                                ))}

                                                { 
                                                    rows.length === 0 && (
                                                        <TableRow style={{ height: 53 }}>

                                                            <TableCell colSpan={10} align="center"> 
                                                                {
                                                                    loadingTable
                                                                    ?
                                                                        <CircularProgress size={40} sx={{ mt: 2, mb: 2 }} />
                                                                    :
                                                                        ''
                                                                }
                                                                {
                                                                    !loadingTable && rows.length === 0
                                                                    ?
                                                                        'No se encontraron usuarios'
                                                                    :
                                                                        ''
                                                                }
                                                            </TableCell>

                                                        </TableRow>
                                                    )
                                                }

                                                </TableBody>

                                                <TableFooter>
                                                    <TableRow>
                                                        <TablePagination
                                                            rowsPerPageOptions={[ ]}
                                                            colSpan={6}
                                                            count={rows.length}                                        
                                                            rowsPerPage={rowsPerPage}
                                                            page={page}                                                                                
                                                            onPageChange={ handleChangePage }
                                                            onRowsPerPageChange={ handleChangeRowsPerPage }
                                                            ActionsComponent={ TablePaginationActions }

                                                            labelRowsPerPage="Filas por página"                                            
                                                            labelDisplayedRows={
                                                                ({ from, to, count }) => {
                                                                return '' + from + '-' + to + ' de ' + count
                                                                }
                                                            }  
                                                        />
                                                    </TableRow>
                                                </TableFooter>
                                            </Table>
                                        </TableContainer>
                                
                                </Grid>

                            </Grid>

                        </Container>

                    </Box>             

                    </Container>

                    <Dialog open={ openModal } maxWidth='sm' fullWidth={true}>       
                    
                        <DialogContent>

                            <FormControl fullWidth sx={{ mt: 3 }} >
                                <InputLabel id="select-oficialia-label">Oficialía</InputLabel>
                            
                                <Select
                                    labelId="select-oficialia-label"
                                    id="select-oficialia"                                            
                                    label="Oficialia"
                                    name="oficialia"
                                    value={ oficialia }                                
                                    onChange={ ( e ) => { setOficialia( e.target.value ) } }
                                >
                                    <MenuItem key={0} value={0}>Seleccione una opción</MenuItem>
                                    {
                                        oficialiaArray.map( ( elem ) => (
                                            <MenuItem key={ elem.idOficialia } value={ elem.idOficialia }> { elem.descripcion } </MenuItem>
                                        ))
                                    }
                                </Select>

                            </FormControl>

                            {   
                                errors.oficialia  && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.oficialia } </Alert> )
                            }

                            <FormControl fullWidth sx={{ mt: 3 }} >
                                <InputLabel id="select-materia-label">Materia</InputLabel>
                            
                                <Select
                                    labelId="select-materia-label"
                                    id="select-materia"                                            
                                    label="Materia"
                                    name="materia"
                                    value={ materia }                                
                                    onChange={ ( e ) => { setMateria( e.target.value ) } }
                                >
                                    <MenuItem key={0} value={0}>Seleccione una opción</MenuItem>
                                    {
                                        materiaArray.map( ( elem ) => (
                                            <MenuItem key={ elem.idMateria } value={ elem.idMateria }> { elem.descripcion } </MenuItem>
                                        ))
                                    }
                                </Select>

                            </FormControl>

                            {   
                                errors.materia  && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.materia } </Alert> )
                            }

                            {   
                                errors.add  && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.add } </Alert> )
                            }

                        </DialogContent>

                        <DialogActions>
                            <Button onClick={ handleCloseModal }> Cancelar </Button>
                            <Button onClick={ Guardar } variant='contained' startIcon={ <SaveIcon /> } > Guardar </Button>
                        </DialogActions>

                        <LinearProgress sx={{ display: loadingSave ? 'flex' : 'none' }} />

                    </Dialog>

                </>
            }

        </>
    )
}
