import React, { useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { Alert, Box, Button, Container, FormControl, FormControlLabel, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, Paper, Radio, RadioGroup, TextField, Typography } from '@mui/material';

import { LoadingButton } from '@mui/lab';

import CheckIcon from '@mui/icons-material/Check';
import SendIcon from '@mui/icons-material/Send';
import UndoIcon from '@mui/icons-material/Undo';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import { Navbar } from '../ui/Navbar';

import { useForm } from '../../hooks/useForm';

import { CambiarContrasena, EnviarNipMetodoRecuperacion, RecuperarContrasena, ValidarNipRecuperacion } from '../../actions/auth/authActions';

export const ForgotPasswordScreen = () => {

    const navigate = useNavigate();

    const [activeStep, setActiveStep] = useState( 0 );

    const [viewContrasena, setViewContrasena] = useState( false );
    const [viewConfirmarContrasena, setViewConfirmarContrasena] = useState( false );

    const { 
        correoElectronicoTelefonoCelular, 
        codigoVerificacion, 
        contrasena, 
        confirmarContrasena, 
        onChange 
    } = useForm({ 
        correoElectronicoTelefonoCelular: '', 
        codigoVerificacion: '', 
        contrasena: '', 
        confirmarContrasena: '', 
    });

    const [tipoRecuperacion, setTipoRecuperacion] = useState( 0 );

    const [loadingNext, setLoadingNext] = useState( false );

    const [responseData, setResponseData] = useState( { correoElectronico: '', telefonoCelular: '', token: '', } );

    const [{ open, message, type }, setAlert] = useState({
        open: false,
        message: '',
        type: 'warning'
    });

    const handleChangeViewContrasena = () => setViewContrasena( (prev) => !prev );
    const handleChangeViewConfirmarContrasena = () => setViewConfirmarContrasena( (prev) => !prev );

    const validateFields = () => {

        let valid = true;

        setAlert({
            open: false,
            message,
            type,
        })

        const isEmail = correoElectronicoTelefonoCelular.includes('@');
        const notValidPhoneNumber = isNaN( correoElectronicoTelefonoCelular );

        if( activeStep === 0 ){
       
            if( correoElectronicoTelefonoCelular === '' ){
                setAlert({
                    open: true,
                    message: 'Escribe el correo electrónico ó teléfono celular',
                    type: 'warning'
                });
                valid = false;
            }
            else if( isEmail ){
                const validEmail = /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test( correoElectronicoTelefonoCelular );

                if( !validEmail ){                   
                    setAlert({
                        open: true,
                        message: 'El correo electrónico no es valido',
                        type: 'warning'
                    });
                    valid = false;
                }    
            }
            else if( !notValidPhoneNumber ){            

                if( correoElectronicoTelefonoCelular.length !== 10 ){                   
                    setAlert({
                        open: true,
                        message: 'El teléfono celular debe contener 10 digitos',
                        type: 'warning'
                    });
                    valid = false;
                }    
            }
            else{
                setAlert({
                    open: true,
                    message: 'El correo electrónico ó teléfono celular no es valido',
                    type: 'warning'
                });
                valid = false;
            }

        }
        else if( activeStep === 1 ){

            if( tipoRecuperacion === 0 ){
                setAlert({
                    open: true,
                    message: 'Seleccione un metodo de recuperación',
                    type: 'warning'
                });
                valid = false;
            }

        }
        else if( activeStep === 2 ){

            if( codigoVerificacion === '' ){
                setAlert({
                    open: true,
                    message: 'Escriba el código de verificación enviado a su correo electrónico ó teléfono celular',
                    type: 'warning'
                });
                valid = false;
            }

        }
        else if( activeStep === 3 ){

            if( contrasena === '' ){
                setAlert({
                    open: true,
                    message: 'Escribe la nueva contraseña',
                    type: 'warning'
                });
                valid = false;
            }
            else if( confirmarContrasena === '' ){
                setAlert({
                    open: true,
                    message: 'Confirma la contraseña',
                    type: 'warning'
                });
                valid = false;
            }
            else if( contrasena !== confirmarContrasena ){
                setAlert({
                    open: true,
                    message: 'Las contraseñas no coinciden',
                    type: 'warning'
                });
                valid = false;
            }

        }

        return valid;
    }

    const handleValidarCorreoCelular = async () => {

        const valid = validateFields();

        if( !valid ){
            return false;
        }

        const isEmail = correoElectronicoTelefonoCelular.includes('@');

        const params = {
            correoElectronico: isEmail ? correoElectronicoTelefonoCelular : '',
            telefonoCelular: !isEmail ? (
                '(' + correoElectronicoTelefonoCelular.substring( 0, 3) + ') ' + correoElectronicoTelefonoCelular.substring(3, 6) + '-' + correoElectronicoTelefonoCelular.substring(6)
            ) : ''
        }

        setLoadingNext( true );

        await RecuperarContrasena( params ).then( response => {
            
            if( response ){

                if( response.status === 200 ){

                    const { success, token, correoElectronico, telefonoCelular, } = response.data;

                    if( success ){
                         setTimeout(() => {

                            setResponseData({ token: token, correoElectronico: correoElectronico, telefonoCelular: telefonoCelular });
            
                            setLoadingNext( false );
                            setActiveStep( (prevStep) => prevStep + 1);

                        }, 1200);
                    }
                    else {
                        setTimeout(() => {

                            setAlert({
                                open: true,
                                message: 'No existe una cuenta asociada con el ' + ( isEmail ? 'correo electrónico ' + correoElectronicoTelefonoCelular :  'teléfono celular ' + correoElectronicoTelefonoCelular ),
                                type: 'error'
                            });
            
                            setLoadingNext( false );
                
                        }, 1200);
                    }

                }

            }

        });
    }

    const handleEnviarNipMetodoRecuperacion = () => {

        const valid = validateFields();

        if( !valid ){
            return false;
        }

        const params = {
            idTipoRecuperacion: tipoRecuperacion,
            token: responseData.token,
        }

        setLoadingNext( true );

        EnviarNipMetodoRecuperacion( params ).then( response => {

            if( response ){

                if( response.status === 200 ){

                    const { success } = response.data;

                    if( success ){
                        setTimeout(() => {
           
                           setLoadingNext( false );
                           setActiveStep( (prevStep) => prevStep + 1);
        
                       }, 1200);
                   }
                   else {
                       setTimeout(() => {
        
                           setAlert({
                               open: true,
                               message: 'No se pudo enviar el codigo de verificación, intentelo mas tarde.',
                               type: 'error'
                           });
           
                           setLoadingNext( false );
               
                       }, 1200);
                   }

                }

            }

        })
    }

    const handleValidarNipRecuperacion = () => {

        const valid = validateFields();

        if( !valid ){
            return false;
        }

        const params = {
            nip: codigoVerificacion,
            token: responseData.token,
        }

        setLoadingNext( true );

        ValidarNipRecuperacion( params ).then( response => {

            if( response ){

                if( response.status === 200 ){

                    const { success, message } = response.data;

                    if( success ){
                        setTimeout(() => {
           
                           setLoadingNext( false );
                           setActiveStep( (prevStep) => prevStep + 1);
        
                       }, 1200);
                   }
                   else {
                       setTimeout(() => {
        
                           setAlert({
                               open: true,
                               message,
                               type: 'error'
                           });
           
                           setLoadingNext( false );
               
                       }, 1200);
                   }

                }

            }

        })
    }

    const handleCambiarContrasena = async () => {
       
        const valid = validateFields();

        if( !valid ){
            return false;
        }

        setLoadingNext( true );

        await CambiarContrasena( { contrasena, token: responseData.token } ).then( response => {

            if( response ){

                if( response.status === 200 ){

                    const { success, message } = response.data;

                    if( success ){
                        setTimeout(() => {
           
                           setLoadingNext( false );
                           setActiveStep( (prevStep) => prevStep + 1);
        
                       }, 1200);
                   }
                   else {
                       setTimeout(() => {
        
                           setAlert({
                               open: true,
                               message,
                               type: 'error'
                           });
           
                           setLoadingNext( false );
               
                       }, 1200);
                   }

                }

            }

        })
    }

    return (
        <>

            <Navbar />

            <Container maxWidth='xl' sx={{ mt: '10em' }}>
                
                <Box sx={{ pb: '2em' }} component={ Paper } >		

                    <Grid container spacing={3} >

                        <Grid item md={12} xs={12} sx={{ textAlign: 'right', pr: { xs: '1em', md: '2em' }, pl: { xs: '1em', md: '1em' } }}>
                            <Button startIcon={ <UndoIcon /> } onClick={ () => navigate('/') } >
                                Regresar al inicio
                            </Button>
                        </Grid>

                    </Grid>

                    <Grid container spacing={3} sx={{ mt: 1, pr: { xs: '1em', md: '1em' }, pl: { xs: '1em', md: '1em' }} }  display='flex' flexDirection='column' alignContent='center' alignItems='stretch' > 

                        <Grid item md={12} xs={12} sx={{ textAlign: 'center' }}>

                            <Typography variant='h6' sx={{ fontSize: 22 }}>Recuperar contraseña</Typography>

                        </Grid>

                        {
                            activeStep === 0
                            ?
                            (
                                <>
                                    <Grid item md={12} xs={12} sx={{ textAlign: 'center' }}>

                                        <Typography variant='h6' sx={{ fontSize: 16 }}>Ingresa el número de teléfono o correo electrónico</Typography>

                                    </Grid>

                                    <Grid item md={6} xs={12}>

                                        <TextField 
                                            name='correoElectronicoTelefonoCelular'
                                            label='Correo electrónico ó teléfono celular'
                                            type='text'
                                            fullWidth
                                            value={ correoElectronicoTelefonoCelular }
                                            onChange={ ( e ) => onChange( e.target.value, 'correoElectronicoTelefonoCelular' ) }
                                        />
                                      
                                    </Grid>

                                    {
                                        ( open )
                                        &&
                                        <Grid item md={12} xs={12}>

                                            <Alert variant='filled' severity={ type } sx={{ width: 350 }}>
                                                { message }
                                            </Alert>                                        

                                        </Grid>
                                    }

                                    <Grid item md={12} xs={12} sx={{ textAlign: 'center' }}>

                                        <LoadingButton
                                            variant='contained'
                                            loading={ loadingNext }
                                            onClick={ handleValidarCorreoCelular }
                                            startIcon={ <CheckIcon /> }
                                        >
                                            Validar información
                                        </LoadingButton>

                                    </Grid>
                                </>
                            )
                            :
                            activeStep === 1
                            ?
                            (
                                <>
                                    <Grid item md={12} xs={12} sx={{ textAlign: 'center' }}>

                                        <Typography variant='h6' sx={{ fontSize: 16, fontWeight: 'bold' }}>
                                            Seleccione algun método que aparece a continuación:
                                        </Typography>

                                    </Grid>

                                    <Grid item md={12} xs={12} >

                                        <FormControl>

                                            <RadioGroup>

                                                <FormControlLabel control={<Radio size='small' checked={ tipoRecuperacion === 1 } onChange={ () => setTipoRecuperacion( 1 ) } /> } label={ 
                                                    <Typography variant='body2'> 
                                                        Enviar código de verificación al correo electrónico <strong> { responseData.correoElectronico } </strong>
                                                    </Typography> 
                                                } />

                                                {
                                                    ( responseData.telefonoCelular )
                                                    &&                                                
                                                    <FormControlLabel sx={{ mt: 1 }}  control={<Radio size='small' checked={ tipoRecuperacion === 2 } onChange={ () => setTipoRecuperacion( 2 ) } />} label={ 
                                                        <Typography variant='body2'> 
                                                            Enviar código de verificación vía mensaje de texto al número celular <strong> { responseData.telefonoCelular } </strong>
                                                        </Typography> 
                                                    } />
                                                }
                                                
                                            </RadioGroup>

                                        </FormControl>

                                    </Grid>

                                    {
                                        ( open )
                                        &&
                                        <Grid item md={12} xs={12}>

                                            <Alert variant='filled' severity={ type } sx={{ width: 350 }}>
                                                { message }
                                            </Alert>                                        

                                        </Grid>
                                    }

                                    <Grid item md={12} xs={12} sx={{ textAlign: 'center' }}>

                                        <LoadingButton
                                            variant='contained'
                                            loading={ loadingNext }
                                            onClick={ handleEnviarNipMetodoRecuperacion }
                                            startIcon={ <SendIcon /> }
                                        >
                                            Enviar código de verificación
                                        </LoadingButton>

                                    </Grid>
                                </>
                            )
                            :
                            activeStep === 2
                            ?
                            (
                                <>
                                    <Grid item md={12} xs={12} sx={{ textAlign: 'center' }}>

                                        <Typography variant='h6' sx={{ fontSize: 16, fontWeight: 'normal' }}>
                                            Ingrese el codigo de verificación
                                        </Typography>

                                    </Grid>

                                    <Grid item md={12} xs={12} display='flex' flexDirection='column' alignItems='center'>

                                        <TextField 
                                            name='codigoVerificacion'
                                            label=''
                                            type='text'
                                            value={ codigoVerificacion }
                                            onChange={ ( e ) => onChange( e.target.value, 'codigoVerificacion' ) }
                                            inputProps={{ style: { textAlign: 'center', fontSize: '1.5em' }}}
                                        />

                                    </Grid>

                                    {
                                        ( open )
                                        &&
                                        <Grid item md={12} xs={12}>

                                            <Alert variant='filled' severity={ type } sx={{ width: 350 }}>
                                                { message }
                                            </Alert>                                        

                                        </Grid>
                                    }

                                    <Grid item md={12} xs={12} sx={{ textAlign: 'center' }}>

                                        <LoadingButton
                                            variant='contained'
                                            loading={ loadingNext }
                                            onClick={ handleValidarNipRecuperacion }
                                            startIcon={ <CheckIcon /> }
                                        >
                                            Validar código de verificación
                                        </LoadingButton>

                                    </Grid>
                                </>
                            )
                            :
                            activeStep === 3
                            ?
                            (
                                <>
                                    <Grid item md={12} xs={12} sx={{ textAlign: 'center' }}>

                                        <Typography variant='h6' sx={{ fontSize: 16, fontWeight: 'normal' }}>
                                            Ingrese la nueva contraseña
                                        </Typography>

                                    </Grid>

                                    <Grid item xs={12} sm={12} > 

                                        <FormControl variant="outlined" fullWidth >
                                            <InputLabel>Contraseña</InputLabel>
                                            <OutlinedInput
                                                label="Contraseña"
                                                name="contrasena"
                                                type={ viewContrasena ? 'text' : 'password'}
                                                value={ contrasena }
                                                onChange={ ( e ) => onChange( e.target.value, 'contrasena' ) }
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton onClick={ handleChangeViewContrasena } edge="end" >
                                                            {
                                                                viewContrasena
                                                                ?
                                                                <VisibilityOffIcon />
                                                                :
                                                                <VisibilityIcon />
                                                            }                                                    
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>  

                                    </Grid>                  

                                    <Grid item md={6} xs={12} >

                                        <FormControl variant="outlined" fullWidth>
                                            <InputLabel>Confirmar contraseña</InputLabel>
                                            <OutlinedInput
                                                label="Confirmar contraseña"
                                                name="confirmarContrasena"
                                                type={ viewConfirmarContrasena ? 'text' : 'password'}
                                                value={ confirmarContrasena }
                                                onChange={ ( e ) => onChange( e.target.value, 'confirmarContrasena' ) }
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton onClick={ handleChangeViewConfirmarContrasena } edge="end" >
                                                            {
                                                                viewConfirmarContrasena
                                                                ?
                                                                    <VisibilityOffIcon />
                                                                :
                                                                    <VisibilityIcon />
                                                            }
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>

                                    </Grid>

                                    {
                                        ( open )
                                        &&
                                        <Grid item md={12} xs={12}>

                                            <Alert variant='filled' severity={ type } sx={{ width: 250 }}>
                                                { message }
                                            </Alert>                                        

                                        </Grid>
                                    }

                                    <Grid item md={12} xs={12} sx={{ textAlign: 'center' }}>

                                        <LoadingButton
                                            variant='contained'
                                            loading={ loadingNext }
                                            onClick={ handleCambiarContrasena }
                                            startIcon={ <CheckIcon /> }
                                        >
                                            Guardar nueva contraseña
                                        </LoadingButton>

                                    </Grid>
                                </>
                            )
                            :
                                <>
                                    <Grid container spacing={1} sx={{ mt: '1em' }} >

                                        <Grid item md={12} xs={12} sx={{ textAlign: 'center' }}>

                                            <CheckIcon sx={{ fontSize: '5em' }} color='success' />

                                        </Grid>

                                        <Grid item md={12} xs={12} sx={{ textAlign: 'center' }}>

                                            <Typography variant='body2' sx={{ fontSize: 16, fontWeight: 'bold' }}>
                                                Se actualizó correctamente la contraseña
                                            </Typography>

                                        </Grid>

                                    </Grid>
                                </>
                        }
                        

                    </Grid>

                </Box>

            </Container>

        </>
    );

};