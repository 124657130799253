import React, { useEffect, useState } from 'react';

import { Button, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, Typography } from '@mui/material';

import GroupIcon from '@mui/icons-material/Group';

import moment from 'moment';

import { usePermisos } from '../../hooks/usePermisos';

import { usePages } from '../../hooks/usePages';

import { TablePaginationActions } from '../../helpers/TablePaginationActions';

import { ObtenerCausasPenalesJuzgadoExpedienteVirtual, ObtenerExpedientesJuzgadoExpedienteVirtual, ObtenerSolicitantesCausaVirtual, ObtenerSolicitantesExpedienteVirtual } from '../../actions/expediente/expedienteVirtualActions';

export const ExpedientesAutorizadosScreen = () => {

    const { idMateria } = usePermisos();

    const [rows, setRows] = useState( [] );
    const [rowsUsuarios, setRowsUsuarios] = useState( [] );

    const [open, setOpen] = useState( false );

    const [page, rowsPerPage, handleChangePage, handleChangeRowsPerPage] = usePages( 10 );
    const [pageUsuarios, rowsPerPageUsuarios, handleChangePageUsuarios, handleChangeRowsPerPageUsuarios] = usePages( 5 );

    const [recordsCount, setRecordsCount ] = useState( 0 );

    const [loading, setLoading] = useState( true );
    const [loadingUsuarios, setLoadingUsuarios] = useState( true );

    const handleObtenerSolicitantes = async ( id ) => {
      
        setRowsUsuarios( [] );
        setLoadingUsuarios( true );
        setOpen( true );

        await ObtenerSolicitantesExpedienteVirtual({ idExpediente: id }).then(response => {

            if ( response ) {

                if (response.status === 200) {

                    if ( response.data ) {

                        setTimeout(() => {
                        
                            setRowsUsuarios( response?.data ?? [] );
                            setLoadingUsuarios( false );

                        }, 1000);

                    }
                    else {
                        setTimeout(() => {

                            setLoadingUsuarios( false );

                        }, 1000);

                    }

                }

            }

        });

    }

    const handleObtenerSolicitantesPenal = async ( id ) => {
      
        setRowsUsuarios( [] );
        setLoadingUsuarios( true );
        setOpen( true );

        await ObtenerSolicitantesCausaVirtual({ idCausaPenal: id }).then(response => {

            if ( response ) {

                if (response.status === 200) {

                    if ( response.data ) {

                        setTimeout(() => {
                        
                            setRowsUsuarios( response?.data ?? [] );
                            setLoadingUsuarios( false );

                        }, 1000);

                    }
                    else {
                        setTimeout(() => {

                            setLoadingUsuarios( false );

                        }, 1000);

                    }

                }

            }

        });

    }

    useEffect(() => {

        async function Obtener() {

            setLoading( true );
            setRows( [] );

            await ObtenerExpedientesJuzgadoExpedienteVirtual({ page, rowsPerPage }).then(response => {

                if ( response ) {

                    if (response.status === 200) {

                        if ( response.data ) {

                            setTimeout(() => {
                            
                                setRows( response?.data?.records ?? [] );

                                setRecordsCount( response?.data?.recordsCount ?? 0 );
                               
                                setLoading( false );
    
                            }, 1000);
    
                        }
                        else {
                            setTimeout(() => {

                                setLoading(false);

                            }, 1000);

                        }

                    }

                }

            });

        }
       
        if( idMateria !== 6 ){
            Obtener();       
        }

    }, [ page, rowsPerPage, idMateria ])

    useEffect(() => {

        async function Obtener() {

            setLoading( true );
            setRows( [] );

            await ObtenerCausasPenalesJuzgadoExpedienteVirtual({ page, rowsPerPage }).then(response => {

                if ( response ) {

                    if (response.status === 200) {

                        if ( response.data ) {

                            setTimeout(() => {
                            
                                setRows( response?.data?.records ?? [] );

                                setRecordsCount( response?.data?.recordsCount ?? 0 );
                               
                                setLoading( false );
    
                            }, 1000);
    
                        }
                        else {
                            setTimeout(() => {

                                setLoading(false);

                            }, 1000);

                        }

                    }

                }

            });

        }
       
        if( idMateria === 6 ){
            Obtener();       
        }

    }, [ page, rowsPerPage, idMateria ])
    
    return (
        <>

            <Grid container spacing={3} >
                  
                <Grid item md={12} xs={12} >

                    {
                        idMateria === 6
                        ?
                            <TableContainer>

                                <Table>
                                    
                                    <TableHead>

                                        <TableRow>        

                                            <TableCell style={{ width: '5%' }}> CARPETA DE INVESTIGACIÓN </TableCell>
                                            <TableCell style={{ width: '10%' }}> NÚMERO DE CAUSA </TableCell>
                                            <TableCell style={{ width: '10%' }}> FECHA DE INICIO </TableCell>
                                            <TableCell style={{ width: '10%' }}> IMPUTADO(S) </TableCell>
                                            <TableCell style={{ width: '10%' }}> VICTIMA(S)/OFENDIDO(S) </TableCell>
                                            <TableCell style={{ width:'5%', textAlign: 'center' }}> SOLICITANTES </TableCell>

                                        </TableRow>

                                    </TableHead>

                                    <TableBody>

                                        {
                                            rows.map( ( row ) => (

                                                <TableRow key={ row.idCausaPenal } >

                                                    <TableCell> { row.nuc } </TableCell>
                                                    <TableCell> { row.nic } </TableCell>
                                                    <TableCell> { row.fechaInicio ? moment (row.fechaInicio ).format('DD-MM-YYYY') : ''} </TableCell>
                                                    <TableCell> { row.imputado } </TableCell>
                                                    <TableCell> { row.victima_Ofendido } </TableCell>
                                                    
                                                    <TableCell sx={{ textAlign: 'center' }}>   

                                                        <Button
                                                            variant='contained'
                                                            color='primary'
                                                            startIcon={ <GroupIcon /> }
                                                            onClick={ () => { handleObtenerSolicitantesPenal( row.idCausaPenal ); } }
                                                        >
                                                            Ver ({ row.numeroAsignaciones })
                                                        </Button>
                                        
                                                    </TableCell>

                                                </TableRow>

                                            ))
                                        }

                                        { 
                                            rows.length === 0 && (
                                                <TableRow style={{ height: 53 }}>

                                                    <TableCell colSpan={10} align="center"> 
                                                        {
                                                            loading
                                                            ?
                                                                <CircularProgress size={40} sx={{ mt: 2, mb: 2 }} />
                                                            :
                                                                ''
                                                        }
                                                        {
                                                            !loading && rows.length === 0
                                                            ?
                                                                'No se encontraron expedientes'
                                                            :
                                                                ''
                                                        }
                                                    </TableCell>

                                                </TableRow>
                                            )
                                        }
                                    </TableBody>

                                    <TableFooter>

                                        <TableRow>

                                            <TableCell colSpan={3}>
                                                <Typography variant='body2' color='black' sx={{ fontWeight: 'bold' }}> Total de registros: { recordsCount } </Typography>
                                            </TableCell>
                                
                                            <TablePagination
                                                rowsPerPageOptions={[ 5, 10, 15, 20 ]}
                                                colSpan={ 10 }
                                                count={ recordsCount }                                        
                                                rowsPerPage={ rowsPerPage }
                                                page={ page }                                                                                
                                                onPageChange={ handleChangePage }
                                                onRowsPerPageChange={ handleChangeRowsPerPage }
                                                ActionsComponent={ TablePaginationActions }

                                                labelRowsPerPage="Filas por página"                                            
                                                labelDisplayedRows={
                                                    ({ from, to, count }) => {
                                                    return '' + from + '-' + to + ' de ' + count
                                                    }
                                                }  
                                            />

                                        </TableRow>

                                    </TableFooter>

                                </Table>

                            </TableContainer>
                        :                    
                            <TableContainer>

                                <Table>
                                    
                                    <TableHead>

                                        <TableRow>        

                                            <TableCell style={{ width:'5%' }}> FOLIO </TableCell>
                                            <TableCell style={{ width:'5%' }}> EXPEDIENTE </TableCell>
                                            <TableCell style={{ width:'5%' }}> FECHA RADICACIÓN </TableCell>
                                            <TableCell style={{ width:'10%' }}> ACTOR(ES) </TableCell>
                                            <TableCell style={{ width:'10%' }}> DEMANDADO(S) </TableCell>
                                            <TableCell style={{ width:'5%', textAlign: 'center' }}> SOLICITANTES </TableCell>

                                        </TableRow>

                                    </TableHead>

                                    <TableBody>

                                        {
                                            rows.map( ( row, i ) => (

                                                <TableRow key={ i } >
                                                    
                                                    <TableCell> { row.folio !== '0' ? row.folio : '' } </TableCell>
                                                    <TableCell> { row.numeroExpediente } </TableCell>
                                                    <TableCell> { row.fechaRadicacion ? moment( row.fechaRadicacion ).format('DD-MM-YYYY') : '' } </TableCell>
                                                    <TableCell> { row.actor } </TableCell>
                                                    <TableCell> { row.demandado } </TableCell>
                                                    
                                                    <TableCell sx={{ textAlign: 'center' }}>   

                                                        <Button
                                                            variant='contained'
                                                            color='primary'
                                                            startIcon={ <GroupIcon /> }
                                                            onClick={ () => { handleObtenerSolicitantes( row.idExpediente ); } }
                                                        >
                                                            Ver ({ row.numeroAsignaciones })
                                                        </Button>
                                        
                                                    </TableCell>

                                                </TableRow>

                                            ))
                                        }

                                        { 
                                            rows.length === 0 && (
                                                <TableRow style={{ height: 53 }}>

                                                    <TableCell colSpan={10} align="center"> 
                                                        {
                                                            loading
                                                            ?
                                                                <CircularProgress size={40} sx={{ mt: 2, mb: 2 }} />
                                                            :
                                                                ''
                                                        }
                                                        {
                                                            !loading && rows.length === 0
                                                            ?
                                                                'No se encontraron expedientes'
                                                            :
                                                                ''
                                                        }
                                                    </TableCell>

                                                </TableRow>
                                            )
                                        }
                                    </TableBody>

                                    <TableFooter>

                                        <TableRow>

                                            <TableCell colSpan={3}>
                                                <Typography variant='body2' color='black' sx={{ fontWeight: 'bold' }}> Total de registros: { recordsCount } </Typography>
                                            </TableCell>
                                
                                            <TablePagination
                                                rowsPerPageOptions={[ 5, 10, 15, 20 ]}
                                                colSpan={ 10 }
                                                count={ recordsCount }                                        
                                                rowsPerPage={ rowsPerPage }
                                                page={ page }                                                                                
                                                onPageChange={ handleChangePage }
                                                onRowsPerPageChange={ handleChangeRowsPerPage }
                                                ActionsComponent={ TablePaginationActions }

                                                labelRowsPerPage="Filas por página"                                            
                                                labelDisplayedRows={
                                                    ({ from, to, count }) => {
                                                    return '' + from + '-' + to + ' de ' + count
                                                    }
                                                }  
                                            />

                                        </TableRow>

                                    </TableFooter>

                                </Table>

                            </TableContainer>
                    }

                </Grid>

            </Grid>

            <Dialog
                open={open}
                onClose={ () => { } }
                maxWidth='md'
                fullWidth
            >
                <DialogTitle>
                    Solicitantes
                </DialogTitle>
               
                <DialogContent>

                    <Grid container spacing={3} >

                        <Grid item md={12} xs={12}>

                            <TableContainer>

                                <Table>
                                    
                                    <TableHead>

                                        <TableRow>        

                                            <TableCell style={{ width:'15%' }}> SOLICITANTE </TableCell>
                                            <TableCell style={{ width:'10%' }}> CORREO ELECTRÓNICO </TableCell>
                                            <TableCell style={{ width:'10%' }}> TELÉFONO CELULAR </TableCell>
                                            <TableCell style={{ width:'5%', textAlign: 'center' }}> ESTATUS </TableCell>

                                        </TableRow>

                                    </TableHead>

                                    <TableBody>

                                        {
                                            ( rowsPerPageUsuarios > 0
                                                ? rowsUsuarios.slice(pageUsuarios * rowsPerPageUsuarios, pageUsuarios * rowsPerPageUsuarios + rowsPerPageUsuarios)
                                                : rowsUsuarios
                                            ).map( ( row, i ) => (

                                                <TableRow key={ i } >
                                                    
                                                    <TableCell> { row.solicitante } </TableCell>
                                                    <TableCell> { row.correoElectronico } </TableCell>
                                                    <TableCell> { row.telefonoCelular } </TableCell>
                                                    <TableCell sx={{ textAlign: 'center' }}> 
                                                    
                                                        <Chip 
                                                            label={ row.estatus }
                                                            color={
                                                                row.idEstatus === 2 ? 'error' :
                                                                    row.idEstatus === 5 ? 'warning' : 
                                                                'primary'
                                                            }
                                                        />
                                                    
                                                    </TableCell>

                                                </TableRow>

                                            ))
                                        }

                                        { 
                                            rowsUsuarios.length === 0 && (
                                                <TableRow style={{ height: 53 }}>

                                                    <TableCell colSpan={10} align="center"> 
                                                        {
                                                            loadingUsuarios
                                                            ?
                                                                <CircularProgress size={40} sx={{ mt: 2, mb: 2 }} />
                                                            :
                                                                ''
                                                        }
                                                        {
                                                            !loadingUsuarios && rowsUsuarios.length === 0
                                                            ?
                                                                'No se encontraron solicitantes'
                                                            :
                                                                ''
                                                        }
                                                    </TableCell>

                                                </TableRow>
                                            )
                                        }
                                    </TableBody>

                                    <TableFooter>

                                        <TableRow>

                                            <TablePagination
                                                rowsPerPageOptions={[ 5, 10, 15, 20 ]}
                                                colSpan={10}
                                                count={rowsUsuarios.length}                                        
                                                rowsPerPage={rowsPerPageUsuarios}
                                                page={pageUsuarios}                                                                                
                                                onPageChange={ handleChangePageUsuarios }
                                                onRowsPerPageChange={ handleChangeRowsPerPageUsuarios }
                                                ActionsComponent={ TablePaginationActions }

                                                labelRowsPerPage="Filas por página"                                            
                                                labelDisplayedRows={
                                                    ({ from, to, count }) => {
                                                    return '' + from + '-' + to + ' de ' + count
                                                    }
                                                }  
                                            />

                                        </TableRow>

                                    </TableFooter>

                                </Table>

                            </TableContainer>

                        </Grid>

                    </Grid>

                </DialogContent>

                <DialogActions>
                    <Button onClick={ () => { setOpen( false ) } }>Cancelar</Button>
                </DialogActions>

            </Dialog>

        </>
    )

}
