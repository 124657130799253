import React, { useState, useEffect } from 'react';

import { Alert, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, IconButton, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, TextField } from '@mui/material';

import { LoadingButton } from '@mui/lab';

import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import { ClearFormatPhoneNumber, FormatPhoneNumber } from '../../helpers/FormatPhoneNumber';

import { ActualizarUsuarioAdmin, AgregarUsuarioAdmin, DetalleUsuario } from '../../actions/admin/usuarioActions';

import { ObtenerTipoUsuarios } from '../../actions/catalogos/tipoUsuarioActions';
import { ObtenerJuzgadosDistrito } from '../../actions/catalogos/juzgadoActions';
import { ObtenerOficialias } from '../../actions/catalogos/oficialiaActions';
import { ObtenerDistritosJudiciales } from '../../actions/catalogos/distritoJudicialActions';

export const ModalAdminDetalle = ({ open, setOpen, referencia, setReferencia, usuarios, setUsuarios, setReload }) => {

    const [tipoUsuario, setTipoUsuario] = useState( 0 );
    const [tipoUsuarioArray, setTipoUsuarioArray] = useState( [] );

    const [loading, setLoading] = useState( false );

    const [data, setData] = useState( { } );

    const [nombre, setNombre] = useState( '' );
    const [apellido1, setApellido1] = useState( '' );
    const [apellido2, setApellido2] = useState( '' );
    const [curp, setCurp] = useState( '' );
    const [cedulaProfesional, setCedulaProfesional] = useState( '' );
    const [telefonoCelular, setTelefonoCelular] = useState( '' );
    const [telefonoFijo, setTelefonoFijo] = useState( '' );
    const [correoElectronico, setCorreoElectronico] = useState( '' );

    const [distritoJudicial, setDistritoJudicial] = useState( 0 );
    const [distritoJudicialArray, setDistritoJudicialArray] = useState( [] );

    const [juzgado, setJuzgado] = useState( 0 );
    const [juzgadoArray, setJuzgadoArray] = useState( [] );

    const [oficialia, setOficialia] = useState( 0 );
    const [oficialiaArray, setOficialiaArray] = useState( [] );

    const [idFirma, setIdFirma] = useState( 0 );
    const [contrasenaFirma, setContrasenaFirma] = useState( '' );
    const [viewContrasenaFirma, setViewContrasenaFirma] = useState( false );

    const [loadingSave, setLoadingSave] = useState( false );

    const [errors, setErrors] = useState( {} );

    const validateFields = () => {

        let valid = true;
        let errores = {};

        const validEmail = /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test( correoElectronico );

        if ( tipoUsuario === 0 ) {
            valid = false
            errores.tipoUsuario = 'Selecciona el tipo de usuario';
        }

        if ( nombre === '' ) {
            valid = false
            errores.nombre = 'Escribe el nombre';
        }

        if ( apellido1 === '' ) {
            valid = false
            errores.apellido1 = 'Escribe el apellido';
        }

        if( tipoUsuario !== 1 && tipoUsuario !== 3 && tipoUsuario !== 4 && tipoUsuario !== 5 && tipoUsuario !== 13 ){

            if ( curp === '' ) {
                valid = false
                errores.curp = 'Escribe el CURP';
            }
            else if( curp !== ''  && curp.length < 18){
                valid = false;
                errores.curp = 'El CURP debe contener 18 caracteres';
            }

        }

        if ( telefonoCelular === '' ) {
            valid = false
            errores.telefonoCelular = 'Escribe la teléfono celular';
        }
        else if ( telefonoCelular.length !== 10 ) {
            valid = false
            errores.telefonoCelular = 'El teléfono celular debe contener 10 digitos';
        }

        if ( telefonoFijo !== '' && telefonoFijo.length !== 10 ) {
            valid = false
            errores.telefonoFijo = 'El teléfono fijo debe contener 10 digitos';
        }

        if ( correoElectronico === '' ) {
            valid = false
            errores.correoElectronico = 'Debe escribir el correo electrónico';
        }
        else if ( !validEmail ) {
            valid = false
            errores.correoElectronico = 'El correo electrónico no es valido';
        }

        if ( tipoUsuario === 1 ) {

            if( distritoJudicial === 0 ){
                valid = false
                errores.distritoJudicial = 'Seleccione el distrito judicial';
            }
            else if( juzgado === 0 ){
                valid = false
                errores.juzgado = 'Seleccione el juzgado';
            }

            if( (idFirma !== 0 && idFirma !== '') && contrasenaFirma === ''  ){
                valid = false;
                errores.contrasenaFirma = 'Debes escribir la contraseña de la firma electronica';
            }
            else if( contrasenaFirma !== '' && (idFirma === 0 || idFirma === '')  ){
                valid = false;
                errores.idFirmaElectronica = 'Debes escribir el ID de la firma electronica';
            }
            
        }

        if ( tipoUsuario === 5 ) {

            if( oficialia === 0 ){
                valid = false
                errores.oficialia = 'Seleccione la oficialía';
            }
            
        }
   
        setErrors( errores );

        return valid;
    }

    const handleClose = () => {

        setOpen( false );

        setTipoUsuario( 0 );

        clearFields();    

    }

    const clearFields = () => {        
        
        setJuzgado( 0 );
        setDistritoJudicial( 0 );
        setOficialia( 0 );

        setReferencia( '' );
        setNombre( '' );
        setApellido1( '' );
        setApellido2(  '' );
        setCurp( '' );
        setCedulaProfesional( '' );
        setTelefonoCelular( '' );
        setTelefonoFijo( '' );
        setCorreoElectronico( '' ); 
        setIdFirma( 0 );
        setContrasenaFirma( '' );

        setViewContrasenaFirma( false );

        setData( {} );
          
        setErrors( {} );
    }

    const handleGuardarDetalle = async() => {

        const valid = validateFields();

        if( !valid ){
            return false;
        }

        setLoadingSave( true );

        const params = {
            id: referencia,
            curp: curp,    
            nombre: nombre,    
            apellido1: apellido1,    
            apellido2: apellido2,    
            correoElectronico: correoElectronico,       
            telefonoFijo: FormatPhoneNumber(telefonoFijo),    
            telefonoCelular: FormatPhoneNumber(telefonoCelular),    
            cedulaProfesional: cedulaProfesional,    
            idJuzgado: juzgado,         
            idOficialia: oficialia,         
            idTipoUsuario: tipoUsuario,     
            idUsuarioFirmaElectronica: idFirma ? idFirma : 0,
            contrasenaFirmaElectronica: contrasenaFirma,
        };

        if( referencia ){

            await ActualizarUsuarioAdmin( params ).then( response => {

                if( response ){

                    if( response.data ){

                        const { success, data, message } = response.data;

                        setTimeout(() => {    

                            if( success ){

                                setUsuarios( usuarios.map( ( element ) => {

                                    if( element.id === referencia ){
                                        element = {
                                            ...data
                                        };
                                    }
                                    return element;
                        
                                })); 
                        
                                setOpen( false );

                            }      
                            else{

                                setErrors( { response: message } );

                            }   

                            setLoadingSave( false );
                            
                        }, 500);

                    }

                }

            });

        }
        else{

            await AgregarUsuarioAdmin( params ).then( response => {

                if( response ){

                    if( response.data ){

                        const { success, message } = response.data;

                        setTimeout(() => {    

                            if( success ){

                                setReload( true );
                        
                                setOpen( false );

                            }      
                            else{

                                setErrors( { response: message } );

                            }   

                            setLoadingSave( false );
                            
                        }, 500);

                    }

                }

            });

        }

    }

    useEffect(() => {

        async function Obtener() {

            await ObtenerTipoUsuarios().then( response => {

                if( response ){

                    if ( response.status === 200 ) {
                    
                        if ( response.data ) {
                            setTipoUsuarioArray( response.data );
                        }

                    }

                }

            });

        }

        Obtener();

    }, [])

    useEffect(() => {

        async function Obtener() {

            await ObtenerDistritosJudiciales().then( response => {

                if( response ){

                    if ( response.status === 200 ) {
                    
                        if ( response.data ) {
                            setDistritoJudicialArray( response.data );
                        }

                    }

                }

            });

        }

        Obtener();

    }, [])

    useEffect(() => {

        async function Obtener() {

            setJuzgado( 0 );

            await ObtenerJuzgadosDistrito( distritoJudicial ).then( response => {

                if( response ){

                    if ( response.status === 200 ) {
                    
                        if ( response.data ) {
                            setJuzgadoArray( response.data );
                        }

                    }

                }

            });

        }

        Obtener();

    }, [ distritoJudicial ])

    useEffect(() => {

        async function Obtener() {

            await ObtenerOficialias().then( response => {

                if( response ){

                    if ( response.status === 200 ) {
                    
                        if ( response.data ) {
                            setOficialiaArray( response.data );
                        }

                    }

                }

            });

        }

        Obtener();

    }, [])

    useEffect(() => {

        async function Obtener(){

            setLoading( true );

            await DetalleUsuario( referencia ).then( response => {

                if( response ){

                    if( response.status === 200 ){

                        setTimeout(() => {                          
                        
                            setTipoUsuario( response.data.idTipoUsuario ?? 0 );
                            setNombre( response.data.nombre ?? '' );
                            setApellido1( response.data.apellido1 ?? '' );
                            setApellido2( response.data.apellido2 ?? '' );
                            setCurp( response.data.curp ?? '' );
                            setCedulaProfesional( response.data.cedulaProfesional ?? '' );
                            setTelefonoCelular( ClearFormatPhoneNumber( response.data.telefonoCelular ?? '' ) );
                            setTelefonoFijo( ClearFormatPhoneNumber( response.data.telefonoFijo ?? '' ) );
                            setCorreoElectronico( response.data.correoElectronico ?? '' );    
                            setIdFirma( response.data.idUsuarioFirmaElectronica ?? 0 );
                            setContrasenaFirma( response.data.contrasenaFirmaElectronica ?? '' );

                            setData( response.data ?? {} );
                            
                            setLoading( false );

                        }, 700);                       

                    }

                }

            });

        }

        if( open && referencia ){
            Obtener();
        }
      
    }, [ open, referencia ])    

    useEffect(() => {

        if( Object.keys( data ).length !== 0 && open ){

            setDistritoJudicial( data.idDistritoJudicial ?? 0 );
            setOficialia(data.idOficialia ?? 0 );
        }

    }, [ open, data ]); 

    useEffect(() => {
        
        if( Object.keys( data ).length !== 0  && open ){
        
            setJuzgado( 0 );

            if( juzgadoArray.length > 0 && data.idDistritoJudicial === distritoJudicial){
                setJuzgado( data.idJuzgado ?? 0 );
            }

        }

    }, [ open, data, distritoJudicial, juzgadoArray ]);

    return (
        <Dialog
            open={ open }
            onClose={ () => { } }
            maxWidth='md'
            fullWidth
        >
            <DialogTitle>
            
                { referencia ? 'Modificar usuario' : 'Agregar usuario' }
                
            </DialogTitle>
        
            <DialogContent>       

                {
                    loading
                    ?   
                        <Grid container spacing={3} sx={{ justifyContent: 'center' }}>
                            <CircularProgress size={40} sx={{ mt: 5, mb: 5 }} />
                        </Grid>
                    :
                        <Grid container spacing={3} sx={{ pt: 1 }}>

                            <Grid item md={12} xs={12} >   

                                <FormControl fullWidth>

                                    <InputLabel id="select-tipoUsuario-label">Tipo de usuario</InputLabel>

                                    <Select
                                        labelId="select-tipoUsuario-label"
                                        id="select-tipoUsuario"                                            
                                        label="Tipo de usuario"
                                        name="tipoUsuario"
                                        value={ tipoUsuario }                                
                                        onChange={ ( e ) => { setTipoUsuario( e.target.value ); } }
                                    >
                                        <MenuItem key={0} value={0}>Seleccione una opción</MenuItem>
                                        {
                                            tipoUsuarioArray.map( ( element ) => (
                                                <MenuItem key={ element.idTipoUsuario } value={ element.idTipoUsuario }> { element.descripcion } </MenuItem>
                                            ))
                                        }
                                    </Select>

                                </FormControl>

                                {
                                    errors.tipoUsuario
                                    &&                                   
                                    <Alert severity='warning' variant='filled' sx={{ mt: 2 }}>
                                        { errors.tipoUsuario }
                                    </Alert>
                                }

                            </Grid>  

                            {
                                tipoUsuario === 1
                                &&
                                <>
                                    <Grid item md={6} xs={12} >   

                                        <FormControl fullWidth>

                                            <InputLabel id="select-distrito-label">Distrito judicial</InputLabel>

                                            <Select
                                                labelId="select-distrito-label"
                                                id="select-distrito"                                            
                                                label="Distrito judicial"
                                                name="distrito"
                                                value={ distritoJudicial }                                
                                                onChange={ ( e ) => { setDistritoJudicial( e.target.value ); } }
                                            >
                                                <MenuItem key={0} value={0}>Seleccione una opción</MenuItem>
                                                {
                                                    distritoJudicialArray.map( ( element ) => (
                                                        <MenuItem key={ element.idDistritoJudicial } value={ element.idDistritoJudicial }> { element.descripcion } </MenuItem>
                                                    ))
                                                }
                                            </Select>

                                        </FormControl>

                                        {
                                            errors.distritoJudicial
                                            &&                                   
                                            <Alert severity='warning' variant='filled' sx={{ mt: 2 }}>
                                                { errors.distritoJudicial }
                                            </Alert>
                                        }

                                    </Grid>  

                                    <Grid item md={6} xs={12} >   

                                        <FormControl fullWidth>

                                            <InputLabel id="select-juzgado-label">Juzgado</InputLabel>

                                            <Select
                                                labelId="select-juzgado-label"
                                                id="select-juzgado"                                            
                                                label="Juzgado"
                                                name="juzgado"
                                                value={ juzgado }                                
                                                onChange={ ( e ) => { setJuzgado( e.target.value ); } }
                                                disabled={ distritoJudicial === 0 }
                                            >
                                                <MenuItem key={0} value={0}>Seleccione una opción</MenuItem>
                                                {
                                                    juzgadoArray.map( ( element ) => (
                                                        <MenuItem key={ element.idJuzgado } value={ element.idJuzgado }> { element.descripcion } </MenuItem>
                                                    ))
                                                }
                                            </Select>

                                            {
                                                errors.juzgado
                                                &&                                   
                                                <Alert severity='warning' variant='filled' sx={{ mt: 2 }}>
                                                    { errors.juzgado }
                                                </Alert>
                                            }

                                        </FormControl>

                                        {
                                            errors.tipoUsuario
                                            &&                                   
                                            <Alert severity='warning' variant='filled' sx={{ mt: 2 }}>
                                                { errors.tipoUsuario }
                                            </Alert>
                                        }

                                    </Grid>  
                                </>
                            }

                            {
                                tipoUsuario === 5
                                &&
                                <Grid item md={12} xs={12} >   

                                    <FormControl fullWidth>

                                        <InputLabel id="select-oficialia-label">Oficialía</InputLabel>

                                        <Select
                                            labelId="select-oficialia-label"
                                            id="select-oficialia"                                            
                                            label="Oficialía"
                                            name="oficialia"
                                            value={ oficialia }                                
                                            onChange={ ( e ) => { setOficialia( e.target.value ); } }
                                        >
                                            <MenuItem key={0} value={0}>Seleccione una opción</MenuItem>
                                            {
                                                oficialiaArray.map( ( element ) => (
                                                    <MenuItem key={ element.idOficialia } value={ element.idOficialia }> { element.descripcion } </MenuItem>
                                                ))
                                            }
                                        </Select>

                                    </FormControl>

                                    {
                                        errors.oficialia
                                        &&                                   
                                        <Alert severity='warning' variant='filled' sx={{ mt: 2 }}>
                                            { errors.oficialia }
                                        </Alert>
                                    }

                                </Grid>  
                            }

                            <Grid item md={4} xs={12} >
                                
                                <TextField
                                    fullWidth
                                    label="Nombres(s)"
                                    name="nombres"
                                    variant="outlined"
                                    autoComplete='off'
                                    value={ nombre }                                
                                    onChange={ ( e ) => setNombre( e.target.value ) }
                                    required
                                    disabled={ tipoUsuario === 0 }
                                />

                                {
                                    errors.nombre
                                    &&                                   
                                    <Alert severity='warning' variant='filled' sx={{ mt: 2 }}>
                                        { errors.nombre }
                                    </Alert>
                                }

                            </Grid>

                            <Grid item md={4} xs={12} >
                        
                                <TextField
                                    fullWidth
                                    label="Apellido 1"
                                    name="apellido1"
                                    variant="outlined"
                                    autoComplete='off'
                                    value={ apellido1 }                                
                                    onChange={ ( e ) => setApellido1( e.target.value ) }
                                    required
                                    disabled={ tipoUsuario === 0 }
                                />

                                {
                                    errors.apellido1
                                    &&                                   
                                    <Alert severity='warning' variant='filled' sx={{ mt: 2 }}>
                                        { errors.apellido1 }
                                    </Alert>
                                }

                            </Grid>

                            <Grid item md={4} xs={12} >
                        
                                <TextField
                                    fullWidth
                                    label="Apellido 2"
                                    name="apellido2"
                                    variant="outlined"
                                    autoComplete='off'
                                    value={ apellido2 }                                
                                    onChange={ ( e ) => setApellido2( e.target.value ) }
                                    disabled={ tipoUsuario === 0 }
                                />

                            </Grid>

                            {
                                tipoUsuario !== 1 && tipoUsuario !== 3 && tipoUsuario !== 4 && tipoUsuario !== 5 && tipoUsuario !== 13
                                &&
                                <>                               
                                
                                    <Grid item md={6} xs={12} >
                                
                                        <TextField
                                            fullWidth
                                            label="CURP"
                                            name="curp"
                                            variant="outlined"
                                            autoComplete='off'
                                            value={ curp }                                
                                            onChange={ ( e ) => setCurp( e.target.value ) }
                                            required
                                            disabled={ tipoUsuario === 0 }
                                            inputProps={{ maxLength: 18 }}
                                        />

                                        {
                                            errors.curp
                                            &&                                   
                                            <Alert severity='warning' variant='filled' sx={{ mt: 2 }}>
                                                { errors.curp }
                                            </Alert>
                                        }

                                    </Grid>

                                    <Grid item md={6} xs={12} >
                                        
                                        <TextField
                                            fullWidth
                                            label="Cédula Profesional"
                                            name="cedulaProfesional"
                                            variant="outlined"
                                            autoComplete='off'
                                            value={ cedulaProfesional }                                
                                            onChange={ ( e ) => setCedulaProfesional( e.target.value ) }
                                            disabled={ tipoUsuario === 0 }
                                        />

                                        {
                                            errors.cedulaProfesional
                                            &&                                   
                                            <Alert severity='warning' variant='filled' sx={{ mt: 2 }}>
                                                { errors.cedulaProfesional }
                                            </Alert>
                                        }

                                    </Grid>

                                </>
                            }

                            <Grid item md={6} xs={12} >
                                
                                <TextField
                                    fullWidth
                                    label="Teléfono Celular"
                                    name="telefonoCelular"
                                    variant="outlined" 
                                    autoComplete='off'
                                    type='number'
                                    value={ telefonoCelular }                                
                                    onChange={ ( e ) => setTelefonoCelular( e.target.value ) }                                    
                                    disabled={ tipoUsuario === 0 }
                                    onInput= { (e) => {
                                        e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0, 10)
                                    }}
                                    required
                                />

                                {
                                    errors.telefonoCelular
                                    &&                                   
                                    <Alert severity='warning' variant='filled' sx={{ mt: 2 }}>
                                        { errors.telefonoCelular }
                                    </Alert>
                                }

                            </Grid>

                            {
                                tipoUsuario !== 1 && tipoUsuario !== 3 && tipoUsuario !== 4 && tipoUsuario !== 5 && tipoUsuario !== 13
                                &&
                                <Grid item md={6} xs={12} >
                                
                                    <TextField
                                        fullWidth
                                        label="Teléfono Fijo"
                                        name="telefonoFijo"
                                        variant="outlined"
                                        autoComplete='off'
                                        type='number'
                                        value={ telefonoFijo }                                
                                        onChange={ ( e ) => setTelefonoFijo( e.target.value ) }
                                        disabled={ tipoUsuario === 0 }
                                        onInput = { (e) => {
                                            e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0, 10)
                                        }}
                                    />

                                    {
                                        errors.telefonoFijo
                                        &&                                   
                                        <Alert severity='warning' variant='filled' sx={{ mt: 2 }}>
                                            { errors.telefonoFijo }
                                        </Alert>
                                    }

                                </Grid>
                            }                            

                            <Grid item md={12} xs={12} >
                                
                                <TextField
                                    fullWidth
                                    label="Correo electrónico"
                                    name="correoElectronico"
                                    variant="outlined"
                                    autoComplete='off'
                                    value={ correoElectronico }                                
                                    onChange={ ( e ) => setCorreoElectronico( e.target.value ) }
                                    disabled={ tipoUsuario === 0 }
                                />

                                {
                                    errors.correoElectronico
                                    &&                                   
                                    <Alert severity='warning' variant='filled' sx={{ mt: 2 }}>
                                        { errors.correoElectronico }
                                    </Alert>
                                }

                            </Grid>

                            {
                                tipoUsuario === 1
                                &&
                                <>
                                    <Grid item md={6} xs={12} >
                                        <TextField
                                            fullWidth
                                            label="ID Usuario de la Firma Electrónica"
                                            name="usuarioFirma"                                
                                            type="number"
                                            variant="outlined"
                                            value={ idFirma }                                
                                            onChange={ (e) => setIdFirma( e.target.value) }                           
                                        />

                                        {
                                            errors.idFirmaElectronica && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.idFirmaElectronica } </Alert> )
                                        }
                                    </Grid>                                      

                                    <Grid item md={6} xs={12} >
                                        <FormControl variant="outlined" fullWidth>
                                            <InputLabel>Contraseña de la Firma Electrónica</InputLabel>
                                            <OutlinedInput
                                                label="Contraseña de la Firma Electrónica"
                                                name="contrasenaFirma"
                                                type={ viewContrasenaFirma ? 'text' : 'password'}
                                                value={ contrasenaFirma }                                
                                                onChange={ (e) => setContrasenaFirma( e.target.value) }
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton onClick={ () => setViewContrasenaFirma( ( prev ) => !prev ) } edge="end" >
                                                            {
                                                                viewContrasenaFirma 
                                                                ?
                                                                <VisibilityOffIcon />
                                                                :
                                                                <VisibilityIcon />
                                                            }                                                    
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>

                                        {   
                                            errors.contrasenaFirma && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.contrasenaFirma } </Alert> )
                                        }
                                    </Grid>
                                </>
                            }

                            {
                                errors.response
                                &&      
                                <Grid item md={12} xs={12} >                             
                                    <Alert severity='error' variant='filled'>
                                        { errors.response }
                                    </Alert>
                                </Grid>
                            }

                        </Grid>                        
                }     

            </DialogContent>

            <DialogActions>
                <Button onClick={ handleClose }>Cancelar</Button>
                <LoadingButton 
                    variant='contained' 
                    color='primary' 
                    onClick={ handleGuardarDetalle } 
                    startIcon={ referencia ? <SaveIcon /> : <AddIcon /> } 
                    loading={ loadingSave }
                >
                    { referencia ? 'Modificar' : 'Agregar' }
                </LoadingButton>
            </DialogActions>

        </Dialog>
    )

}
