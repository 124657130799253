import httpClientCitasApiKey from '../../services/httpClientCitasApiKey'


export const GetCitCitas = () => {
    return new Promise((resolve, eject) => {
       
        httpClientCitasApiKey.get(`/v2/cit_citas`)
        .then(response => {
            resolve(response)
        })
        .catch(error => {
            resolve(error.response)
        })
    
    })
}

export const GetCitMisCitas = (idUsuarioCitas) => {
    return new Promise((resolve, eject) => {
       
        httpClientCitasApiKey.get(`/v2/cit_citas/mis_citas?cit_cliente_id=${idUsuarioCitas}`)
        .then(response => {
            resolve(response)
        })
        .catch(error => {
            resolve(error.response)
        })
    
    })
}

export const GetCitCitasDisponibles = (idUsuarioCitas) => {
    return new Promise((resolve, eject) => {
        
        httpClientCitasApiKey.get(`/v2/cit_citas/disponibles?cit_cliente_id=${idUsuarioCitas}`)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                resolve(error.response)
            })
        
    })
}


export const DeleteCitas = (params) => {
    return new Promise((resolve, reject) => {
        
        httpClientCitasApiKey.post(`/v2/cit_citas/cancelar`, params)
        .then(response => {
            resolve(response)         
        })
        .catch((error) => {
            resolve(error.response)
        })
        
    })
}

export const GetDistritos = () => {
    return new Promise((resolve, reject) => {
        
        httpClientCitasApiKey.get('/v2/distritos')
        .then(response => {
            resolve(response)
        })
        .catch((error) => {
            resolve(error.response)
        })
        
    })
}


export const GetOficinas = (distrito_id) => {
    return new Promise((resolve, reject) => {
        
        let ruta = '/v2/oficinas'
        //if(distrito_id !== null){ 
        ruta = ruta + `?distrito_id=${distrito_id}`
        //}
        httpClientCitasApiKey.get(ruta)
        .then( response => {
            resolve(response)
        })
        .catch((error) => {
            resolve(error.response)
        });
    });
}


export const GetOficinaServicio = (oficina_id) => {
    return new Promise((resolve, reject) => {
        
        let ruta = '/v2/cit_oficinas_servicios'
        //if(oficina_id !== null){
        ruta = ruta + `?oficina_id=${oficina_id}`
       // }
        httpClientCitasApiKey.get(ruta)
        .then(response => {
            resolve(response)
        })
        .catch((error) => {
            resolve(error.response)
        })
        
    })
}


export const GetCitDiasDisponibles = () => {
    return new Promise((resolve, reject) => {
        
        httpClientCitasApiKey.get('/v2/cit_dias_disponibles')
        .then(response => {
            resolve(response)
        })
        .catch((error) => {
            resolve(error.response)
        })
    
    })
}

export const GetHorasDisponibles = (filtros) => {
    return new Promise((resolve, reject) => {
       
        const ruta = `/v2/cit_horas_disponibles?oficina_id=${filtros.oficina_id}&cit_servicio_id=${filtros.cit_servicio_id}&fecha=${filtros.fecha}`
        
        httpClientCitasApiKey.get(ruta)
        .then(response => {
            resolve(response)
        })
        .catch((error) => {
            resolve(error.reponse)
        })
    })
}


export const NewCita = (params) => {
    return new Promise((resolve, reject) => {
       
        const ruta = '/v2/cit_citas/nueva'
        httpClientCitasApiKey.post(ruta, params )
        .then(response => {
            if (response.status === 200) {
                resolve(response)
            }
        })
        .catch((error) => {
            resolve(error.response)
        })
        
    })
}

export const GetPollPendiente = (idUsuarioCitas) => {
    return new Promise((resolve, eject) => {
        
        httpClientCitasApiKey.get(`/v2/enc_servicios/pendiente?cit_cliente_id=${idUsuarioCitas}`)
        .then(response => {
            resolve(response)
        })
        .catch(error => {
            resolve(error.response)
        })
        
    })
}
