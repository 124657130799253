import React, { useState, useEffect } from 'react'

import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField, Typography } from '@mui/material'

import { LoadingButton } from '@mui/lab';

import SaveIcon from '@mui/icons-material/Save';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import { ActualizarDatosPersonales } from '../../actions/admin/usuarioActions';

import { ClearFormatPhoneNumber, FormatPhoneNumber } from '../../helpers/FormatPhoneNumber';

export const ModalActualizarDatosPersonales = ({ open, setOpen, setModal, usuario, setUsuario }) => {

    const [telefonoFijo, setTelefonoFijo] = useState( '' );
    const [telefonoCelular, setTelefonoCelular] = useState( '' )
    const [cedulaProfesional, setCedulaProfesional] = useState( '' )

    const [errores, setErrors] = useState( {} );
    const [openLoading, setOpenLoading] = useState( false );

    const [viewContrasena, setViewContrasena] = useState( false );
    const [viewConfirmarContrasena, setViewConfirmarContrasena] = useState( false );
    const [contrasena, setContrasena] = useState( '' );
    const [confirmarContrasena, setConfirmarContrasena] = useState( '' );

    const handleChangeViewContrasena = () => setViewContrasena( (prev) => !prev );
    const handleChangeViewConfirmarContrasena = () => setViewConfirmarContrasena( (prev) => !prev );

    const validateFields = () => {

        let valid = true;
        let errores = {};

        if ( telefonoCelular === '' ) {
            valid = false;
            errores.telefonoCelular = 'Debes escribir el teléfono celular';
        }
        else if ( telefonoCelular.length !== 10 ) {
            valid = false;
            errores.telefonoCelular = 'El teléfono celular debe tener 10 digitos';
        }

        if ( telefonoFijo !== '' && telefonoFijo.length !== 10 ) {
            valid = false;
            errores.telefonoFijo = 'El teléfono fijo debe tener 10 digitos';
        }

        if ( ( confirmarContrasena !== '' || contrasena !== '' ) && ( contrasena !== confirmarContrasena )) {
            valid = false
            errores.contrasena = 'las contraseñas no coinciden'
        }
   
        setErrors( errores );

        return valid;
    }

    const handleClose = () => {

        setErrors( {} );
        setTelefonoCelular( '' );
        setTelefonoFijo( '' );
        setCedulaProfesional( '' );
        setContrasena( '' );
        setConfirmarContrasena( '' );

        setOpen( false );
    }

    const handleActualizarDatosPersonales = async () => {

        const valid = validateFields();

        if ( !valid ) {            
            return false;
        }

        setOpenLoading( true );

        const params = {
            telefonoCelular: FormatPhoneNumber( telefonoCelular ),
            telefonoFijo: telefonoFijo ? FormatPhoneNumber( telefonoFijo ) : '',
            CedulaProfesional: cedulaProfesional,
            contrasena
        }

        await ActualizarDatosPersonales( params ).then( response => {

            if( response ){

                if (response.status === 200) {

                    const { success, message } = response.data;

                    setTimeout(() => {

                        if ( success ) {
        
                            setModal({
                                Open: true,
                                msg: message,
                                type: 'success'
                            });

                            setUsuario({
                                ...usuario,
                                telefonoCelular,
                                telefonoFijo,
                                cedulaProfesional,
                            });
        
                            setOpenLoading( false );
        
                            handleClose();
        
                        } 
                        else {
        
                            setModal({
                                Open: true,
                                msg: message,
                                type: 'error'
                            });
        
                            setOpenLoading( false );
                        }
                    
                    }, 1000);

                }

            }    

        })
    }

    useEffect(() => {

        if( usuario?.telefonoCelular ){           
            setTelefonoCelular( ClearFormatPhoneNumber( usuario?.telefonoCelular ) ); 
        }

        if( usuario?.telefonoFijo ){
            setTelefonoFijo( ClearFormatPhoneNumber( usuario?.telefonoFijo ) ); 
        }

        setCedulaProfesional( usuario?.cedulaProfesional ?? '')
        
    }, [ open, usuario ])

    return (
        <Dialog open={open} onClose={() => { }} maxWidth='md' >

            <DialogTitle>
                Actualizar datos personales
            </DialogTitle>

            <DialogContent>

                <Grid container spacing={3} sx={{ mt: 1 }}>

                    <Grid item md={4} xs={12} >

                        <TextField
                            fullWidth
                            label="Teléfono celular"
                            name="telefonoCelular"
                            variant="outlined"
                            autoComplete='off'
                            type='number'
                            value={ telefonoCelular }
                            onChange={ (e) => { setTelefonoCelular( e.target.value ) } }
                            onInput= { (e) => {
                                e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0, 10)
                            }}
                            required
                        />

                        {
                            errores.telefonoCelular
                            &&
                            <Alert severity='warning' variant='filled' sx={{ mt: 1 }}>
                                { errores.telefonoCelular }
                            </Alert>
                        }

                    </Grid>

                    <Grid item md={4} xs={12} >

                        <TextField
                            fullWidth
                            label="Teléfono fijo"
                            name="telefonoFijo"
                            variant="outlined"
                            autoComplete='off'
                            type='number'
                            value={ telefonoFijo }
                            onChange={ (e) => { setTelefonoFijo( e.target.value ) }}
                            onInput= { (e) => {
                                e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0, 10)
                            }}
                        />

                        {
                            errores.telefonoFijo
                            &&
                            <Alert severity='warning' variant='filled' sx={{ mt: 1 }}>
                                { errores.telefonoFijo }
                            </Alert>
                        }

                    </Grid>

                    <Grid item md={4} xs={12} >

                        <TextField
                            fullWidth
                            label="Cédula profesional"
                            name="cedulaProfesional"
                            variant="outlined"
                            autoComplete='off'
                            value={cedulaProfesional}
                            onChange={(e) => { setCedulaProfesional(e.target.value) }}
                        />

                    </Grid>

                    <Grid item md={12} xs={12} sx={{ mt: 1 }}>
                        <Typography variant='body2' sx={{ fontWeight: 'bold' }}>
                            Para cambiar el teléfono celular, teléfono fijo o la cédula profesional es necesario ingresar su contraseña actual.
                        </Typography>
                    </Grid>

                    <Grid item md={6} xs={12} >

                        <FormControl variant="outlined" fullWidth>
                            <InputLabel>Contraseña</InputLabel>
                            <OutlinedInput
                                label="Contraseña"
                                name="contrasena"
                                type={ viewContrasena ? 'text' : 'password'}
                                value={ contrasena }
                                onChange={ (e) => { setContrasena( e.target.value ) }}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton onClick={ handleChangeViewContrasena } edge="end" >
                                            {
                                                viewContrasena
                                                ?
                                                    <VisibilityOffIcon />
                                                :
                                                    <VisibilityIcon />
                                            }
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>

                    </Grid>

                    <Grid item md={6} xs={12} >

                        <FormControl variant="outlined" fullWidth>
                            <InputLabel>Confirmar contraseña</InputLabel>
                            <OutlinedInput
                                label="Confirmar contraseña"
                                name="confirmarContrasena"
                                type={ viewConfirmarContrasena ? 'text' : 'password'}
                                value={ confirmarContrasena }
                                onChange={ (e) => { setConfirmarContrasena( e.target.value) } }
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton onClick={ handleChangeViewConfirmarContrasena } edge="end" >
                                            {
                                                viewConfirmarContrasena
                                                ?
                                                    <VisibilityOffIcon />
                                                :
                                                    <VisibilityIcon />
                                            }
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>

                    </Grid>

                    {
                        errores.contrasena
                        &&
                        <Grid item md={12} xs={12} >
                            <Alert severity='warning' variant='filled'>
                                { errores.contrasena }
                            </Alert>
                        </Grid>
                    }

                </Grid>

            </DialogContent>

            <DialogActions>
                <Button onClick={ handleClose }> Cancelar </Button>
                <LoadingButton 
                    onClick={ handleActualizarDatosPersonales } 
                    variant='contained' 
                    startIcon={ <SaveIcon />} 
                    loading={ openLoading } 
                    disabled={ contrasena === '' || confirmarContrasena === '' }
                > 
                    Guardar 
                </LoadingButton>
            </DialogActions>

        </Dialog>
    )

}
