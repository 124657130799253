
import { createSlice } from '@reduxjs/toolkit'

export const counterSlice = createSlice({
    name: 'auth',
    initialState: {
        status: 'not-authenticated', 
        token: null,
        nombre: null,
        apellido: null,
        organoJurisdiccional: null,
        errorMessage: null,
        idTipoUsuario: 0,
        idJuzgado: 0,
        idMateria: 0,
        idUsuarioCitas: 0,
        imagenPerfil: null,
        esPenal: false,
        tokenUnicoActivado: false,
        permisos: { },
        aplicacionesOnline: {},
    },
    reducers: {
        login: ( state, { payload } ) => {
            state.status = 'authenticated';
            state.token = payload.token;
            state.nombre = payload.nombre;
            state.apellido = payload.apellido;
            state.organoJurisdiccional = payload.organoJurisdiccional;
            state.imagenPerfil = payload.imagenPerfil;
            state.esPenal = payload.esPenal;
            state.tokenUnicoActivado = payload.tokenUnicoActivado;
            state.errorMessage = null;        
        },
        logout: ( state ) => {            
            state.status = 'not-authenticated';
            state.token = null;
            state.nombre = null;
            state.apellido = null;
            state.organoJurisdiccional = null;
            state.errorMessage = null;
            state.imagenPerfil = null;
            state.esPenal = false;
            state.tokenUnicoActivado = false;
            state.permisos = { };
            state.idTipoUsuario = 0;
            state.idUsuarioCitas = 0;
        },
        authError: ( state, { payload } ) => {  
            state.status= 'error';
            state.errorMessage = payload;
        },
        setPermisos: ( state, { payload } ) => {  
            state.status = payload.status;
            state.permisos = payload.permisos;
            state.idTipoUsuario = payload.idTipoUsuario;
            state.idUsuarioCitas = payload.idUsuarioCitas;
            state.idJuzgado = payload.idJuzgado;
            state.idMateria = payload.idMateria;
        },
        setAplicacionesOnline: ( state, { payload } ) => {
            state.aplicacionesOnline = payload.aplicacionesOnline;
        },
        setAvatar: ( state, { payload } ) => {
            state.imagenPerfil = payload.imagenPerfil;
        }
    },
})

export const { login, logout, authError, setPermisos, setAvatar, setAplicacionesOnline } = counterSlice.actions

export default counterSlice.reducer