
export const arrayOptionsJuzgados = [
    {
        value: 9,
        idJuzgado: 54,
        descripcion: 'Primero Familiar',
    },
    {
        value: 10,
        idJuzgado: 54,
        descripcion: 'Segundo Familiar',
    },
    {
        value: 11,
        idJuzgado: 54,
        descripcion: 'Tercero Familiar',
    },
    {
        value: 9,
        idJuzgado: 10,
        descripcion: 'Primero Familiar',
    },
    {
        value: 10,
        idJuzgado: 10,
        descripcion: 'Segundo Familiar',
    },
    {
        value: 11,
        idJuzgado: 10,
        descripcion: 'Tercero Familiar',
    },
    {
        value: 20,
        idJuzgado: 21,
        descripcion: 'Primero Familiar',
    },
    {
        value: 21,
        idJuzgado: 21,
        descripcion: 'Segundo Familiar',
    },
    {
        value: 22,
        idJuzgado: 21,
        descripcion: 'Tercero Familiar',
    },
    {
        value: 26,
        idJuzgado: 26,
        descripcion: 'Primero Familiar',
    },
    {
        value: 27,
        idJuzgado: 26,
        descripcion: 'Segundo Familiar',
    },
    {
        value: 28,
        idJuzgado: 28,
        descripcion: 'Primero Civil',
    },
    {
        value: 30,
        idJuzgado: 28,
        descripcion: 'Primero Familiar',
    },
    {
        value: 29,
        idJuzgado: 29,
        descripcion: 'Segundo Civil',
    },
    {
        value: 30,
        idJuzgado: 29,
        descripcion: 'Primero Familiar',
    },
]
