import React, { useEffect, useState } from 'react';

import { Avatar, Button, CircularProgress, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, Switch, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, TextField, Typography } from '@mui/material';

import { LoadingButton } from '@mui/lab';

import InfoIcon from '@mui/icons-material/Info'
import KeyIcon from '@mui/icons-material/Key';
import LaptopIcon from '@mui/icons-material/Laptop';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import MapIcon from '@mui/icons-material/Map';
import ClearIcon from '@mui/icons-material/Clear';

import { ModalAdminDetalle } from '../modals/ModalAdminDetalle';
import { ModalAdminDomicilio } from '../modals/ModalAdminDomicilio';
import { ModalAdminContrasena } from '../modals/ModalAdminContrasena';
import { ModalAdminAplicaciones } from '../modals/ModalAdminAplicaciones';

import { usePages } from '../../hooks/usePages';

import { TablePaginationActions } from '../../helpers/TablePaginationActions';

import { ActualizarUsuarioEstatus, ObtenerUsuarios, ObtenerUsuariosFiltro } from '../../actions/admin/usuarioActions';
import { TiposUsuarios } from '../../actions/catalogos/tipoUsuarioActions';

export const UsuariosScreen = () => {

    const [rows, setRows] = useState( [] );
    
    const [openDetalle, setOpenDetalle] = useState( false );
    const [openDomicilio, setOpenDomicilio] = useState( false );
    const [openContrasena, setOpenContrasena] = useState( false );
    const [openAplicaciones, setOpenAplicaciones] = useState( false );

    const [recordsCount, setRecordsCount] = useState( 0 );
    const [page, rowsPerPage, handleChangePage, handleChangeRowsPerPage, resetPage] = usePages( 10 );

    const [loading, setLoading] = useState( true );    

    const [referencia, setReferencia] = useState( '' );
    const [usuario, setUsuario] = useState( '' );
    const [correoElectronico, setCorreoElectronico] = useState( '' );

    const [loadingSearch, setLoadingSearch] = useState( false );
    const [filtroAplicado, setFiltroAplicado] = useState( false );    
    const [reload, setReload] = useState( false );

    const [tipoUsuario, setTipoUsuario] = useState( 0 );
    const [tipoUsuarioArray, setTipoUsuarioArray] = useState( [] );

    const handleClearFiltro = () => {

        setFiltroAplicado( false ); 
        setUsuario( '' );
        setCorreoElectronico( '' );
        setTipoUsuario( 0 );
        resetPage();
    }
    
    const handleBuscarUsuario = async () => {

        setFiltroAplicado( true );
        setRows( [ ] );    
        setLoading( true );      
        setLoadingSearch( true );

        await ObtenerUsuariosFiltro({ usuario, correoElectronico, idTipoUsuario: tipoUsuario, page, rowsPerPage }).then( response => {

            if( response ){

                if( response.status === 200 ){                       

                    setTimeout(() => {
                        
                        setRecordsCount( response.data.recordsCount ?? 0 );
                        setRows( response.data.records ?? [] );                
                        setLoading( false );
                        setLoadingSearch( false );
            
                    }, 700);
                    
                }
                else{
                    setLoading( false );
                }
            }
            else{
                setLoading( false );
            }

        })

    }

    const handleChangeCuentaActiva = async ( value, id ) => {

        await ActualizarUsuarioEstatus({ id, activar: value }).then( response => {

            if( response ){

                if( response.status === 200 ){                      
                           
                    const newArray = rows.map( ( elem ) => {
                        if( elem.id === id ){
                            elem.cuentaActiva = value;
                        }
                        return elem;
                    });
            
                    setRows( newArray );
            
                }
            }

        })

    }

    useEffect(() => {

        async function Obtener() {

            await TiposUsuarios().then( response => {

                if( response ){

                    if ( response.status === 200 ) {
                    
                        if ( response.data ) {
                            setTipoUsuarioArray( response.data );
                        }

                    }

                }

            });

        }

        Obtener();

    }, [])

    useEffect(() => {

        async function Obtener(){
            
            setRows( [ ] );    
            setLoading( true );            

            await ObtenerUsuarios( { page, rowsPerPage } ).then( response => {

                if( response ){

                    if( response.status === 200 ){                       

                        setTimeout(() => {
                            
                            setRecordsCount( response.data.recordsCount ?? 0 );
                            setRows( response.data.records ?? [] );                
                            setLoading( false );
                            setReload( false );
                
                        }, 700);
                        
                    }
                    else{
                        setLoading( false );
                    }
                }
                else{
                    setLoading( false );
                }

            })

        }
         
        if( !filtroAplicado ){
            Obtener();  
        }
        
    }, [ page, rowsPerPage, filtroAplicado ])

    useEffect(() => {

        async function Obtener(){
            
            setRows( [ ] );    
            setLoading( true );            

            await ObtenerUsuariosFiltro({ usuario, correoElectronico, idTipoUsuario: tipoUsuario, page, rowsPerPage }).then( response => {

                if( response ){

                    if( response.status === 200 ){                       

                        setTimeout(() => {
                            
                            setRecordsCount( response.data.recordsCount ?? 0 );
                            setRows( response.data.records ?? [] );                
                            setLoading( false );
                
                        }, 700);
                        
                    }
                    else{
                        setLoading( false );
                    }
                }
                else{
                    setLoading( false );
                }

            })

        }
         
        if( filtroAplicado ){
            Obtener();  
        }
      
    }, [  page, rowsPerPage, filtroAplicado, usuario, correoElectronico, tipoUsuario ])
    
    useEffect(() => {

        async function Obtener(){
            
            setRows( [ ] );    
            setLoading( true );            

            await ObtenerUsuarios( { page, rowsPerPage } ).then( response => {

                if( response ){

                    if( response.status === 200 ){                       

                        setTimeout(() => {
                            
                            setRecordsCount( response.data.recordsCount ?? 0 );
                            setRows( response.data.records ?? [] );                
                            setLoading( false );
                            setReload( false );
                
                        }, 700);
                        
                    }
                    else{
                        setLoading( false );
                    }
                }
                else{
                    setLoading( false );
                }

            })

        }
         
        if( reload ){
            Obtener();  
        }
        
    }, [ page, rowsPerPage, reload ])

    return (
        <>
            <Grid container spacing={3} sx={{ mt: 1 }}>

                <Grid item md={4} xs={12} >  

                    <TextField
                        name='usuario'
                        label='Usuario'
                        value={ usuario }
                        onChange={ ( e ) => { setUsuario( e.target.value ) } }
                        fullWidth
                        variant='outlined'
                        autoComplete='off'
                        disabled={ filtroAplicado }
                    />

                </Grid>

                <Grid item md={4} xs={12} >  

                    <TextField
                        name='correoElectronico'
                        label='Correo electrónico'
                        value={ correoElectronico }
                        onChange={ ( e ) => { setCorreoElectronico( e.target.value ) } }
                        fullWidth
                        variant='outlined'
                        autoComplete='off' 
                        disabled={ filtroAplicado }       
                    />

                </Grid>

                <Grid item md={4} xs={12} >  

                    <FormControl fullWidth>

                        <InputLabel id="select-tipoUsuario-label">Tipo de usuario</InputLabel>

                        <Select
                            labelId="select-tipoUsuario-label"
                            id="select-tipoUsuario"                                            
                            label="Tipo de usuario"
                            name="tipoUsuario"
                            value={ tipoUsuario }                                
                            onChange={ ( e ) => { setTipoUsuario( e.target.value ); } }
                            disabled={ filtroAplicado }
                        >
                            <MenuItem key={0} value={0}>Seleccione una opción</MenuItem>
                            {
                                tipoUsuarioArray.map( ( element ) => (
                                    <MenuItem key={ element.idTipoUsuario } value={ element.idTipoUsuario }> { element.descripcion } </MenuItem>
                                ))
                            }
                        </Select>

                    </FormControl>

                </Grid>

                <Grid item md={12} xs={12} >  

                    <Grid container spacing={3} display='flex' flexDirection='revert' justifyContent='end' >

                        <Grid item md={4} xs={12}>

                            <LoadingButton
                                variant='contained'
                                startIcon={ filtroAplicado ? <ClearIcon /> : <SearchIcon /> }
                                fullWidth
                                disabled={ usuario === '' && correoElectronico === '' && tipoUsuario === 0 }
                                onClick={ () => { filtroAplicado ? handleClearFiltro() : handleBuscarUsuario() } }
                                loading={ loadingSearch }
                                color={ filtroAplicado ? 'inherit' : 'primary' }
                            >
                                { filtroAplicado ? 'limpiar filtro' : 'Buscar'}
                            </LoadingButton>

                        </Grid>

                    </Grid>

                </Grid>  

                <Grid item md={12} xs={12} sx={{ mt: 1 }} >

                    <TableContainer>

                        <Table>
                            
                            <TableHead>

                                <TableRow>     
                                   
                                    <TableCell style={{ width:'1%' }}> </TableCell>
                                    <TableCell style={{ width:'20%' }}> USUARIO </TableCell>
                                    <TableCell style={{ width:'5%' }}> TIPO DE USUARIO </TableCell>
                                    <TableCell style={{ width:'10%' }}> CORREO ELECTRÓNICO </TableCell>
                                    <TableCell style={{ width:'15%', textAlign: 'center' }}> OPCIONES </TableCell>
                                    <TableCell style={{ width:'5%', textAlign: 'center' }}> ACTIVO </TableCell>
                                    <TableCell style={{ width:'5%', textAlign: 'center' }}> </TableCell>

                                </TableRow>

                            </TableHead>

                            <TableBody>

                                {
                                    rows.map( ( row ) => (

                                        <TableRow key={ row.id} >

                                            <TableCell sx={{ textAlign: 'center' }}> 

                                                <Avatar 
                                                    src={ row.imagenPerfil ?? '' }
                                                    style={{
                                                        width: 45,
                                                        height: 45
                                                    }}
                                                />
                                                
                                            </TableCell>
                                            
                                            <TableCell>  

                                                <Typography variant='subtitle2' sx={{ fontWeight: 'bold', pb: 1 }}>
                                                    { row.nombreCompleto } ({ row.referencia })
                                                </Typography>
                                                
                                                {
                                                    row.organoJurisdiccional
                                                    &&
                                                    <Typography variant='caption'>
                                                        { row.organoJurisdiccional } 
                                                    </Typography>
                                                }
                                                
                                            </TableCell>

                                            <TableCell> { row.tipoUsuario } </TableCell>
                                            <TableCell> { row.correoElectronico } </TableCell>
                        
                                            <TableCell sx={{ textAlign: 'center' }}> 

                                                <IconButton
                                                    color='primary'
                                                    onClick={ () => { setOpenDetalle( true ); setReferencia( row.id ); } }
                                                >
                                                    <InfoIcon />
                                                </IconButton>    

                                                <IconButton
                                                    color='primary'
                                                    onClick={ () => {  setOpenDomicilio( true ); setReferencia( row.id ); } }
                                                >
                                                    <MapIcon />
                                                </IconButton>  

                                                <IconButton
                                                    color='primary'
                                                    onClick={ () => {  setOpenContrasena( true ); setReferencia( row.id ); } }
                                                >
                                                    <KeyIcon />
                                                </IconButton>                                  
                                        
                                                <IconButton
                                                    color='primary'
                                                    onClick={ () => { setOpenAplicaciones( true ); setReferencia( row.id ); } }
                                                >
                                                    <LaptopIcon />
                                                </IconButton>  
                                
                                            </TableCell>

                                            <TableCell sx={{ textAlign: 'center' }}> 

                                                <Switch 
                                                    checked={ row.cuentaActiva }
                                                    onChange={ (e) => handleChangeCuentaActiva( e.target.checked, row.id ) }
                                                />
                                
                                            </TableCell>

                                            <TableCell align='center'>                                                 
                                               

                                                {
                                                    (row.appToken && !row.appPoderEnLinea)
                                                    && 
                                                        <Avatar 
                                                            variant='rounded'
                                                            sx={{ width: 30, height: 30 }}                                           
                                                            src={ require('../../assets/app-token.png') }    
                                                        />
                                                }

                                                {
                                                    ( row.appPoderEnLinea )
                                                    && 
                                                        <Avatar 
                                                            variant='rounded'
                                                            sx={{ width: 30, height: 30 }}                                           
                                                            src={ require('../../assets/poder-en-linea-app.png') }                                                    
                                                        />
                                                }
                                                                            
                                            </TableCell>

                                        </TableRow>

                                    ))
                                }

                                { 
                                    rows.length === 0 && (
                                        <TableRow style={{ height: 53 }}>

                                            <TableCell colSpan={10} align="center" sx={{ fontWeight: 'bold' }}> 
                                                {
                                                    loading
                                                    ?
                                                        <CircularProgress size={40} sx={{ mt: 2, mb: 2 }} />
                                                    :
                                                        ''
                                                }
                                                {
                                                    !loading && rows.length === 0
                                                    ?
                                                        'No se encontraron usuarios'
                                                    :
                                                        ''
                                                }
                                            </TableCell>

                                        </TableRow>
                                    )
                                }
                            </TableBody>

                            <TableFooter>

                                <TableRow>

                                    <TableCell colSpan={2}>
                                        <Typography variant='body2' color='black' sx={{ fontWeight: 'bold' }}> Total de usuarios: { recordsCount } </Typography>
                                    </TableCell> 
                        
                                    <TablePagination
                                        rowsPerPageOptions={[ 5, 10, 15, 20 ]}
                                        colSpan={ 10 }
                                        count={ recordsCount }                                        
                                        rowsPerPage={rowsPerPage}
                                        page={ page }                                                                                
                                        onPageChange={ handleChangePage }
                                        onRowsPerPageChange={ handleChangeRowsPerPage }
                                        ActionsComponent={ TablePaginationActions }

                                        labelRowsPerPage="Filas por página"                                            
                                        labelDisplayedRows={
                                            ({ from, to, count }) => {
                                            return '' + from + '-' + to + ' de ' + count
                                            }
                                        }  
                                    />

                                </TableRow>

                            </TableFooter>

                        </Table>

                    </TableContainer>

                </Grid>

                <Grid item md={12} xs={12} >  

                    <Grid container spacing={3} display='flex' flexDirection='revert' justifyContent='center' >

                        <Grid item md={4} xs={12}>

                            <Button
                                variant='contained'
                                onClick={ () => { setOpenDetalle( true ); } }
                                color='primary'
                                startIcon={ <AddIcon /> }
                                fullWidth
                            >
                                Agregar usuario
                            </Button>

                        </Grid>

                    </Grid>

                </Grid>  

            </Grid>

            <ModalAdminDetalle
                open={ openDetalle }
                setOpen={ setOpenDetalle }  
                referencia={ referencia }
                setReferencia={ setReferencia }
                usuarios={ rows }
                setUsuarios={ setRows }
                setReload={ setReload }
            />

            <ModalAdminDomicilio
                open={ openDomicilio }
                setOpen={ setOpenDomicilio }  
                referencia={ referencia }
                setReferencia={ setReferencia }
            />

            <ModalAdminContrasena
                open={ openContrasena }
                setOpen={ setOpenContrasena }  
                referencia={ referencia }
                setReferencia={ setReferencia }
            />

            <ModalAdminAplicaciones
                open={ openAplicaciones }
                setOpen={ setOpenAplicaciones }  
                referencia={ referencia }
                setReferencia={ setReferencia }
            />
            
        </>
    )
}
