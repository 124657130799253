import React, { useEffect, useRef, useState } from 'react';

import { Alert, Button, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, Grid, InputLabel, List, ListItem, MenuItem, Select, Snackbar, Switch, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, TextField, Typography } from '@mui/material';

import { LoadingButton } from '@mui/lab';

import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';

import moment from 'moment';

import { usePermisos } from '../../hooks/usePermisos';

import { usePages } from '../../hooks/usePages';

import { TablePaginationActions } from '../../helpers/TablePaginationActions';

import { FormatPhoneNumber } from '../../helpers/FormatPhoneNumber';

import { arrayOptionsJuzgados } from '../../helpers/JuzgadosExpedienteVirtual';

import { ObtenerUsuarioExpedeinteVirtual, HabilitarDesabilitarExpediente, ObtenerExpedientesExpedienteVirtual, BuscarExpediente, AsignarExpedienteVirtual, ObtenerUsuarioCausainteVirtual, ObtenerCausasExpedienteVirtual, HabilitarDesabilitarCausa, BuscarCausaPenal, AsignarCausaVirtual } from '../../actions/expediente/expedienteVirtualActions';

export const AutorizarExpedienteScreen = () => {

    const { idJuzgado, idMateria } = usePermisos();

    const refId = useRef('');

    const [rows, setRows] = useState([]);

    const [open, setOpen] = useState(false);
    const [openCausa, setOpenCausa] = useState(false);

    const [loading, setLoading] = useState(false);
    const [loadingSearch, setLoadingSearch] = useState(false);
    const [loadingSearchExpediente, setLoadingSearchExpediente] = useState(false);
    const [loadingAsignarExpediente, setLoadingAsignarExpediente] = useState(false)

    const [correoElectronicoTelefonoCelular, setCorreoElectronicoTelefonoCelular] = useState('');

    const [data, setData] = useState({});
    const [expediente, setExpediente] = useState({ success : false });

    const [errores, setErrors] = useState({});

    const [recordsCount, setRecordsCount] = useState(0);

    const [filtroAplicado, setFiltroAplicado] = useState(false);
    const [filtroAplicadoSearch, setFiltroAplicadoSearch] = useState(false);

    const [{ openModal, messageModal, typeModal }, setModal] = useState({ openModal: false, messageModal: '', typeModal: 'warning' });

    const [page, rowsPerPage, handleChangePage, handleChangeRowsPerPage, resetPage] = usePages(10);

    const [juzgado, setJuzgado] = useState(0);
    const [numeroExpediente, setNumeroExpediente] = useState('');

    const [numeroCausa, setNumeroCausa] = useState('');

    const [reload, setReload] = useState(false);

    const handleCloseModal = (event, reason) => {

        if (reason === 'clickaway') {
            return;
        }

        setModal({ messageModal, typeModal, openModal: false });
    };

    const handleClose = () => {

        setOpen( false );
        setOpenCausa( false );

        setNumeroExpediente( '' );
        setNumeroCausa( '' );
        setExpediente( { success: false } );

        setFiltroAplicadoSearch( false );

    }

    const handleCambiarEstado = async ( id, isChecked ) => {

        await HabilitarDesabilitarExpediente({ idExpedienteAsignado: id, activar: isChecked }).then(response => {

            if (response) {

                if (response.status === 200) {

                    const { success } = response.data;

                    if (success) {

                        setRows(rows.map((elem) => {
                            if (elem.idExpedienteAsignado === id) {
                                elem.checked = isChecked;
                            }
                            return elem;
                        }));

                    }

                }

            }

        });

    }

    const handleCambiarEstadoCausa = async ( id, isChecked ) => {

        await HabilitarDesabilitarCausa({ idCausaPenalAsignada: id, activar: isChecked }).then(response => {

            if (response) {

                if (response.status === 200) {

                    const { success } = response.data;

                    if (success) {

                        setRows( rows.map( ( elem ) => {
                            if ( elem.idCausaPenalAsignada === id ) {
                                elem.checked = isChecked;
                            }
                            return elem;
                        }));

                    }

                }

            }

        });

    }

    const validateFields = () => {

        let valid = true;
        let errores = {};

        const isEmail = correoElectronicoTelefonoCelular.includes('@');
        const notValidPhoneNumber = isNaN(correoElectronicoTelefonoCelular);

        if (correoElectronicoTelefonoCelular === '') {
            valid = false
            errores.correoElectronicoTelefonoCelular = 'Debe escribir el correo electrónico ó teléfono celular';
        }
        else if (isEmail) {
            const validEmail = /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(correoElectronicoTelefonoCelular);

            if (!validEmail) {
                valid = false;
                errores.correoElectronicoTelefonoCelular = 'El correo electrónico no es valido';
            }
        }
        else if (!notValidPhoneNumber) {

            if (correoElectronicoTelefonoCelular.length !== 10) {
                valid = false;
                errores.correoElectronicoTelefonoCelular = 'El teléfono celular debe contener 10 digitos';
            }
        }
        else {
            valid = false;
            errores.correoElectronicoTelefonoCelular = 'El correo electrónico ó teléfono celular no es valido';
        }

        
        setErrors(errores);

        return valid;
    }

    const handleLimpiar = () => {

        setCorreoElectronicoTelefonoCelular('');
        setData({});
        setRows([]);

        refId.current = '';

        setFiltroAplicado(false);
        resetPage(0);
    }

    const handleLimpiarSearch = () => {

        setNumeroExpediente( '' );
        setNumeroCausa( '' );
        setExpediente( {} );
        setJuzgado( 0 );
        setFiltroAplicadoSearch(false);

    }

    const handleBusqueda = () => {

        if( idMateria === 6 ){
            handleBuscarUsuarioCausaPenal();
        }
        else{
            handleBuscarUsuarioExpediente();
        }
    }

    const handleBuscarUsuarioExpediente = async () => {

        const valid = validateFields();

        if (!valid) {
            return false;
        }

        setLoading(true);
        setLoadingSearch(true);

        const isEmail = correoElectronicoTelefonoCelular.includes('@');

        const params = {
            correoElectronico: isEmail ? correoElectronicoTelefonoCelular : '',
            telefonoCelular: !isEmail ? FormatPhoneNumber(correoElectronicoTelefonoCelular) : '',
        };

        await ObtenerUsuarioExpedeinteVirtual(params).then(response => {

            if (response) {

                if (response.status === 200) {

                    const { success, message } = response.data;

                    if (success) {

                        const { id, solicitante, correoElectronico, telefonoCelular, expedienteVirtual } = response.data;

                        setTimeout(() => {

                            refId.current = id;
                            setData({ id, solicitante, correoElectronico, telefonoCelular });

                            setRows(expedienteVirtual?.expedientes?.map((elem) => { return { ...elem, checked: elem.idEstatus === 1 } }) ?? []);
                            setRecordsCount(expedienteVirtual?.recordsCount ?? 0);

                            setLoading(false);
                            setLoadingSearch(false);

                            setFiltroAplicado(true);

                        }, 1000);

                    }
                    else {
                        setTimeout(() => {

                            setData({});

                            setModal({
                                openModal: true,
                                messageModal: message,
                                typeModal: 'warning'
                            })

                            setLoading(false);
                            setLoadingSearch(false);

                        }, 1000);

                    }

                }
                else {
                    setTimeout(() => {

                        setData({});

                        setModal({
                            openModal: true,
                            messageModal: 'Ocurrio un problema, contacte con el administrador del sistema',
                            typeModal: 'error'
                        })

                        setLoading(false);
                        setLoadingSearch(false);

                    }, 1000);

                }

            }

        });

    }

    const handleBuscarUsuarioCausaPenal = async () => {

        const valid = validateFields();

        if (!valid) {
            return false;
        }

        setLoading(true);
        setLoadingSearch(true);

        const isEmail = correoElectronicoTelefonoCelular.includes('@');

        const params = {
            correoElectronico: isEmail ? correoElectronicoTelefonoCelular : '',
            telefonoCelular: !isEmail ? FormatPhoneNumber(correoElectronicoTelefonoCelular) : '',
        };

        await ObtenerUsuarioCausainteVirtual(params).then(response => {

            if (response) {

                if (response.status === 200) {

                    const { success, message } = response.data;

                    if (success) {

                        const { id, solicitante, correoElectronico, telefonoCelular, expedienteVirtual } = response.data;

                        setTimeout(() => {

                            refId.current = id;
                            setData({ id, solicitante, correoElectronico, telefonoCelular });

                            setRows(expedienteVirtual?.causas?.map((elem) => { return { ...elem, checked: elem.idEstatus === 1 } }) ?? []);
                            setRecordsCount(expedienteVirtual?.recordsCount ?? 0);

                            setLoading(false);
                            setLoadingSearch(false);

                            setFiltroAplicado(true);

                        }, 1000);

                    }
                    else {
                        setTimeout(() => {

                            setData({});

                            setModal({
                                openModal: true,
                                messageModal: message,
                                typeModal: 'warning'
                            })

                            setLoading(false);
                            setLoadingSearch(false);

                        }, 1000);

                    }

                }
                else {
                    setTimeout(() => {

                        setData({});

                        setModal({
                            openModal: true,
                            messageModal: 'Ocurrio un problema, contacte con el administrador del sistema',
                            typeModal: 'error'
                        })

                        setLoading(false);
                        setLoadingSearch(false);

                    }, 1000);

                }

            }

        });

    }

    const handleBuscarExpediente = async () => {

        const params = {
            idJuzgado: idJuzgado,
            idOrigen: juzgado,
            numeroExpediente: numeroExpediente,
        }

        setLoadingSearchExpediente(true);
        setFiltroAplicadoSearch( true );

        await BuscarExpediente(params).then(response => {

            if (response) {

                if (response.status === 200) {

                    if (response.data) {

                        const { success } = response.data;

                        if (success) {
                            
                            setTimeout(() => {
    
                                setExpediente( response.data );

                                setLoadingSearchExpediente( false );
    
                            }, 1000);

                        }
                        else {

                            setTimeout(() => {

                                setExpediente( { success: false } );

                                setModal({
                                    openModal: true,
                                    messageModal: 'No se encontro el expediente',
                                    typeModal: 'warning'
                                });

                                setLoadingSearchExpediente(false);
                                setFiltroAplicadoSearch( false );

                            }, 1000);

                        }

                    }
                    else {

                        setTimeout(() => {

                            setExpediente( { success: false } );

                            setModal({
                                openModal: true,
                                messageModal: 'Ocurrio un problema, contacte con el administrador del sistema',
                                typeModal: 'error'
                            })
                            
                            setLoadingSearchExpediente(false);

                        }, 1000);

                    }

                }

            }

        });


    }

    const handleAgregarExpediente = async () => {

        const params = {

            idUsuario: refId.current,
            idJuzgado : idJuzgado,
            idOrigen: juzgado,
            folio: expediente?.folio,
            numeroExpediente: numeroExpediente,
            fechaRadicacion: expediente?.fechaRadicacion,
            tipoJuicio: expediente?.tipoJuicio,
            presentante: expediente?.presentante,
            fechaPresentacion: expediente?.fechaPresentacion,
            actorPromovente: expediente?.actorPromovente,
            demandado:  expediente?.demandado,
            sintesis: expediente?.sintesis,
        }

        setLoadingAsignarExpediente(true);

        await AsignarExpedienteVirtual(params).then(response => {

            if ( response ) {

                if ( response.status === 200 ) {

                    if ( response.data ) {

                        const { success, message } = response.data;

                        if (success) {
                            
                            setTimeout(() => {
                                
                                setModal({
                                    openModal: true,
                                    messageModal: message,
                                    typeModal: 'success'
                                });

                                setLoadingAsignarExpediente(false);
                               
                                handleClose();
                                
                                setReload(true);
    
                            }, 1000);

                        }
                        else {

                            setTimeout(() => {

                                setModal({
                                    openModal: true,
                                    messageModal: message,
                                    typeModal: 'warning'
                                });

                                setLoadingAsignarExpediente(false);

                            }, 1000);

                        }

                    }
                    else {

                        setTimeout(() => {                          

                            setModal({
                                openModal: true,
                                messageModal: 'Ocurrio un problema, contacte con el administrador del sistema',
                                typeModal: 'error'
                            })
                            
                            setLoadingAsignarExpediente(false);

                        }, 1000);

                    }

                }

            }

        });
    
    }

    const handleBuscarCausa = async () => {

        const params = {
            idJuzgado: idJuzgado,
            numeroCausaPenal: numeroCausa,
        }

        setLoadingSearchExpediente(true);
        setFiltroAplicadoSearch( true );

        await BuscarCausaPenal(params).then(response => {

            if (response) {

                if (response.status === 200) {

                    if (response.data) {

                        const { success } = response.data;

                        if (success) {
                            
                            setTimeout(() => {
    
                                setExpediente( response.data );

                                setLoadingSearchExpediente( false );
    
                            }, 1000);

                        }
                        else {

                            setTimeout(() => {

                                setExpediente( { success: false } );

                                setModal({
                                    openModal: true,
                                    messageModal: 'No se encontro la causa penal',
                                    typeModal: 'warning'
                                });

                                setLoadingSearchExpediente(false);
                                setFiltroAplicadoSearch( false );

                            }, 1000);

                        }

                    }
                    else {

                        setTimeout(() => {

                            setExpediente( { success: false } );

                            setModal({
                                openModal: true,
                                messageModal: 'Ocurrio un problema, contacte con el administrador del sistema',
                                typeModal: 'error'
                            })
                            
                            setLoadingSearchExpediente(false);

                        }, 1000);

                    }

                }

            }

        });


    }

    const handleAgregarCausa = async () => {

        const params = {

            idUsuario: refId.current,
            idJuzgado : idJuzgado,
            nuc: expediente?.nuc,
            nic: expediente?.nic,
            fechaInicio: expediente?.fechaInicio,
            imputado: expediente?.imputado,
            victima_Ofendido: expediente?.victima_Ofendido,
            delitos: expediente?.delitos,
        }

        setLoadingAsignarExpediente(true);

        await AsignarCausaVirtual(params).then(response => {

            if ( response ) {

                if ( response.status === 200 ) {

                    if ( response.data ) {

                        const { success, message } = response.data;

                        if (success) {
                            
                            setTimeout(() => {
                                
                                setModal({
                                    openModal: true,
                                    messageModal: message,
                                    typeModal: 'success'
                                });

                                setLoadingAsignarExpediente(false);
                               
                                handleClose();
                                
                                setReload(true);
    
                            }, 1000);

                        }
                        else {

                            setTimeout(() => {

                                setModal({
                                    openModal: true,
                                    messageModal: message,
                                    typeModal: 'warning'
                                });

                                setLoadingAsignarExpediente(false);

                            }, 1000);

                        }

                    }
                    else {

                        setTimeout(() => {                          

                            setModal({
                                openModal: true,
                                messageModal: 'Ocurrio un problema, contacte con el administrador del sistema',
                                typeModal: 'error'
                            })
                            
                            setLoadingAsignarExpediente(false);

                        }, 1000);

                    }

                }

            }

        });
    
    }

    useEffect(() => {

        async function Obtener() {

            setLoading(true);
            setRows([]);

            await ObtenerExpedientesExpedienteVirtual({ id: refId.current, page, rowsPerPage }).then(response => {

                if (response) {

                    if (response.status === 200) {

                        if (response.data) {

                            setTimeout(() => {

                                setRows(response?.data?.expedientes?.map((elem) => { return { ...elem, checked: elem.idEstatus === 1 } }) ?? []);

                                setRecordsCount(response?.data?.recordsCount ?? 0);

                                setLoading(false);

                            }, 1000);

                        }
                        else {
                            setTimeout(() => {

                                setLoading(false);

                            }, 1000);

                        }

                    }

                }

            });

        }

        if ( refId.current && idMateria !== 6 ) {
            Obtener();
        }

    }, [page, rowsPerPage, idMateria])

    useEffect(() => {

        async function Obtener() {

            setLoading(true);
            setRows([]);

            await ObtenerExpedientesExpedienteVirtual({ id: refId.current, page, rowsPerPage }).then(response => {

                if (response) {

                    if (response.status === 200) {

                        if (response.data) {

                            setTimeout(() => {

                                setRows(response?.data?.expedientes?.map((elem) => { return { ...elem, checked: elem.idEstatus === 1 } }) ?? []);

                                setRecordsCount(response?.data?.recordsCount ?? 0);

                                setLoading(false);
                                setReload(false);

                            }, 1000);

                        }
                        else {
                            setTimeout(() => {

                                setLoading(false);

                            }, 1000);

                        }

                    }

                }

            });

        }

        if (refId.current && idMateria !== 6 && reload) {
            Obtener();
        }

    }, [page, rowsPerPage, reload, idMateria])

    useEffect(() => {

        async function Obtener() {

            setLoading(true);
            setRows([]);

            await ObtenerCausasExpedienteVirtual({ id: refId.current, page, rowsPerPage }).then(response => {

                if (response) {

                    if (response.status === 200) {

                        if (response.data) {

                            setTimeout(() => {

                                setRows(response?.data?.causas?.map((elem) => { return { ...elem, checked: elem.idEstatus === 1 } }) ?? []);

                                setRecordsCount(response?.data?.recordsCount ?? 0);

                                setLoading(false);

                            }, 1000);

                        }
                        else {
                            setTimeout(() => {

                                setLoading(false);

                            }, 1000);

                        }

                    }

                }

            });

        }

        if ( refId.current && idMateria === 6 ) {
            Obtener();
        }

    }, [page, rowsPerPage, idMateria])

    useEffect(() => {

        async function Obtener() {

            setLoading(true);
            setRows([]);

            await ObtenerCausasExpedienteVirtual({ id: refId.current, page, rowsPerPage }).then(response => {

                if (response) {

                    if (response.status === 200) {

                        if (response.data) {

                            setTimeout(() => {

                                setRows(response?.data?.causas?.map((elem) => { return { ...elem, checked: elem.idEstatus === 1 } }) ?? []);

                                setRecordsCount(response?.data?.recordsCount ?? 0);

                                setLoading(false);
                                setReload(false);

                            }, 1000);

                        }
                        else {
                            setTimeout(() => {

                                setLoading(false);

                            }, 1000);

                        }

                    }

                }

            });

        }

        if (refId.current && idMateria === 6 && reload) {
            Obtener();
        }

    }, [page, rowsPerPage, reload, idMateria])

    return (
        <>
            <Snackbar open={openModal} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} autoHideDuration={2000} onClose={handleCloseModal}>
                <Alert onClose={handleCloseModal} variant="filled" severity={typeModal} sx={{ width: '100%' }}>
                    {messageModal}
                </Alert>
            </Snackbar>

            <Grid container spacing={3} sx={{ mt: 1 }} >

                <Grid item md={10} xs={12}>

                    <TextField
                        name='solicitante'
                        label='Buscar solicitante (correo electrónico o teléfono celular )'
                        autoComplete='off'
                        fullWidth
                        value={correoElectronicoTelefonoCelular}
                        onChange={(e) => setCorreoElectronicoTelefonoCelular(e.target.value)}
                        disabled={filtroAplicado}
                    />

                    {
                        errores.correoElectronicoTelefonoCelular
                        &&
                        <Alert severity='warning' variant='filled' sx={{ mt: 1 }}>
                            {errores.correoElectronicoTelefonoCelular}
                        </Alert>

                    }

                </Grid>

                <Grid item md={2} xs={12}>

                    <LoadingButton
                        variant='contained'
                        color={filtroAplicado ? 'inherit' : 'primary'}
                        fullWidth
                        startIcon={filtroAplicado ? <ClearIcon /> : <SearchIcon />}
                        onClick={ () => filtroAplicado ? handleLimpiar() : handleBusqueda()}
                        loading={loadingSearch}
                        disabled={correoElectronicoTelefonoCelular === ''}
                    >
                        {filtroAplicado ? 'Limpiar' : 'Buscar'}
                    </LoadingButton>

                </Grid>

                <Grid item md={4} xs={12}>
                    <Typography variant='body2'>
                        <strong>Solicitante: </strong> <br />
                        {data?.solicitante}
                    </Typography>
                </Grid>

                <Grid item md={4} xs={12}>
                    <Typography variant='body2'>
                        <strong>Correo electrónico: </strong> <br />
                        {data?.correoElectronico}
                    </Typography>
                </Grid>

                <Grid item md={4} xs={12}>
                    <Typography variant='body2'>
                        <strong>Teléfono celular: </strong> <br />
                        {data?.telefonoCelular}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Divider />
                </Grid>

                <Grid item md={12} xs={12} sx={{ textAlign: 'right', mt: 1 }}>

                    <Button
                        variant='contained'
                        color='primary'
                        startIcon={<AddIcon />}
                        onClick={ () => { idMateria === 6 ? setOpenCausa(true) : setOpen(true) }}
                        disabled={ refId.current === '' }
                    >
                        Asignar expediente
                    </Button>

                </Grid>

                {
                    idMateria === 6
                    ?
                        <Grid item md={12} xs={12} >

                            <TableContainer>

                                <Table>

                                    <TableHead>

                                        <TableRow>

                                            <TableCell style={{ width: '5%' }}> CARPETA DE INVESTIGACIÓN </TableCell>
                                            <TableCell style={{ width: '10%' }}> NÚMERO DE CAUSA </TableCell>
                                            <TableCell style={{ width: '10%' }}> FECHA DE INICIO </TableCell>
                                            <TableCell style={{ width: '10%' }}> IMPUTADO(S) </TableCell>
                                            <TableCell style={{ width: '10%' }}> VICTIMA(S)/OFENDIDO(S) </TableCell>
                                            <TableCell style={{ width: '5%', textAlign: 'center' }}> ACTIVAR </TableCell>

                                        </TableRow>

                                    </TableHead>

                                    <TableBody>

                                        {
                                            rows.map((row) => (

                                                <TableRow key={ row.idCausaPenalAsignada } >

                                                    <TableCell> { row.nuc } </TableCell>
                                                    <TableCell> { row.nic } </TableCell>
                                                    <TableCell> { row.fechaInicio ? moment (row.fechaInicio ).format('DD-MM-YYYY') : ''} </TableCell>
                                                    <TableCell> { row.imputado } </TableCell>
                                                    <TableCell> { row.victima_Ofendido } </TableCell>

                                                    <TableCell sx={{ textAlign: 'center' }}>

                                                        {
                                                            row.idEstatus === 5
                                                                ?
                                                                <Chip
                                                                    label='Archivado por el usuario'
                                                                    color='warning'
                                                                />
                                                                :
                                                                <Switch
                                                                    checked={row.checked}
                                                                    onChange={(e) => handleCambiarEstadoCausa(row.idCausaPenalAsignada, e.target.checked)}
                                                                />
                                                        }

                                                    </TableCell>

                                                </TableRow>

                                            ))
                                        }

                                        {
                                            rows.length === 0 && (
                                                <TableRow style={{ height: 53 }}>

                                                    <TableCell colSpan={10} align="center" sx={{ fontWeight: 'bold' }}>
                                                        {
                                                            loading
                                                                ?
                                                                    <CircularProgress size={40} sx={{ mt: 2, mb: 2 }} />
                                                                :
                                                                    ''
                                                        }
                                                        {
                                                            !loading && rows.length === 0
                                                                ?
                                                                'No se encontraron causas asignadas'
                                                                :
                                                                ''
                                                        }
                                                    </TableCell>

                                                </TableRow>
                                            )
                                        }
                                    </TableBody>

                                    <TableFooter>

                                        <TableRow>

                                            <TableCell colSpan={3}>
                                                <Typography variant='body2' color='black' sx={{ fontWeight: 'bold' }}> Total de registros: {recordsCount} </Typography>
                                            </TableCell>

                                            <TablePagination
                                                rowsPerPageOptions={[5, 10, 15, 20]}
                                                colSpan={10}
                                                count={recordsCount}
                                                rowsPerPage={rowsPerPage}
                                                page={page}
                                                onPageChange={handleChangePage}
                                                onRowsPerPageChange={handleChangeRowsPerPage}
                                                ActionsComponent={TablePaginationActions}

                                                labelRowsPerPage="Filas por página"
                                                labelDisplayedRows={
                                                    ({ from, to, count }) => {
                                                        return '' + from + '-' + to + ' de ' + count
                                                    }
                                                }
                                            />

                                        </TableRow>

                                    </TableFooter>

                                </Table>

                            </TableContainer>

                        </Grid>
                    :
                        <Grid item md={12} xs={12} >

                            <TableContainer>

                                <Table>

                                    <TableHead>

                                        <TableRow>

                                            <TableCell style={{ width: '5%' }}> FOLIO </TableCell>
                                            <TableCell style={{ width: '5%' }}> EXPEDIENTE </TableCell>
                                            <TableCell style={{ width: '5%' }}> FECHA RADICACIÓN </TableCell>
                                            <TableCell style={{ width: '10%' }}> ACTOR(ES) </TableCell>
                                            <TableCell style={{ width: '10%' }}> DEMANDADO(S) </TableCell>
                                            <TableCell style={{ width: '5%', textAlign: 'center' }}> ACTIVAR </TableCell>

                                        </TableRow>

                                    </TableHead>

                                    <TableBody>

                                        {
                                            rows.map((row) => (

                                                <TableRow key={row.idExpedienteAsignado} >

                                                    <TableCell> {row.folio} </TableCell>
                                                    <TableCell> {row.numeroExpediente} </TableCell>
                                                    <TableCell> {row.fechaRadicacion ? moment(row.fechaRadicacion).format('DD-MM-YYYY') : ''} </TableCell>
                                                    <TableCell> {row.actor} </TableCell>
                                                    <TableCell> {row.demandado} </TableCell>

                                                    <TableCell sx={{ textAlign: 'center' }}>

                                                        {
                                                            row.idEstatus === 5
                                                                ?
                                                                <Chip
                                                                    label='Archivado por el usuario'
                                                                    color='warning'
                                                                />
                                                                :
                                                                <Switch
                                                                    checked={row.checked}
                                                                    onChange={(e) => handleCambiarEstado(row.idExpedienteAsignado, e.target.checked)}
                                                                />
                                                        }

                                                    </TableCell>

                                                </TableRow>

                                            ))
                                        }

                                        {
                                            rows.length === 0 && (
                                                <TableRow style={{ height: 53 }}>

                                                    <TableCell colSpan={10} align="center" sx={{ fontWeight: 'bold' }}>
                                                        {
                                                            loading
                                                                ?
                                                                <CircularProgress size={40} sx={{ mt: 2, mb: 2 }} />
                                                                :
                                                                ''
                                                        }
                                                        {
                                                            !loading && rows.length === 0
                                                                ?
                                                                'No se encontraron expedientes asignados'
                                                                :
                                                                ''
                                                        }
                                                    </TableCell>

                                                </TableRow>
                                            )
                                        }
                                    </TableBody>

                                    <TableFooter>

                                        <TableRow>

                                            <TableCell colSpan={3}>
                                                <Typography variant='body2' color='black' sx={{ fontWeight: 'bold' }}> Total de registros: {recordsCount} </Typography>
                                            </TableCell>

                                            <TablePagination
                                                rowsPerPageOptions={[5, 10, 15, 20]}
                                                colSpan={10}
                                                count={recordsCount}
                                                rowsPerPage={rowsPerPage}
                                                page={page}
                                                onPageChange={handleChangePage}
                                                onRowsPerPageChange={handleChangeRowsPerPage}
                                                ActionsComponent={TablePaginationActions}

                                                labelRowsPerPage="Filas por página"
                                                labelDisplayedRows={
                                                    ({ from, to, count }) => {
                                                        return '' + from + '-' + to + ' de ' + count
                                                    }
                                                }
                                            />

                                        </TableRow>

                                    </TableFooter>

                                </Table>

                            </TableContainer>

                        </Grid>
                }

            </Grid>

            <Dialog
                open={open}
                onClose={() => { }}
                maxWidth='sm'
                fullWidth
            >
                <DialogTitle>
                    Asignar expediente
                </DialogTitle>

                <DialogContent>

                    <Grid container spacing={3} sx={{ mt: 0.1 }}>

                        {
                            (idJuzgado === 54 || idJuzgado === 10 || idJuzgado === 21 || idJuzgado === 26 || idJuzgado === 28 || idJuzgado === 29)
                            &&
                            <Grid item md={12} xs={12} >

                                <FormControl fullWidth>

                                    <InputLabel id='select-juzgado-label'>Juzgado</InputLabel>

                                    <Select
                                        labelId='select-juzgado-label'
                                        id='select-juzgado'
                                        label='Juzgado'
                                        name='juzgado'
                                        value={juzgado}
                                        onChange={(e) => { setJuzgado(e.target.value); }}
                                        disabled={ filtroAplicadoSearch }
                                    >
                                        <MenuItem key={0} value={0}>Seleccione una opción</MenuItem>
                                        {
                                            arrayOptionsJuzgados
                                                .filter((elem) => elem.idJuzgado === idJuzgado)
                                                .map((elem) => (
                                                    <MenuItem key={elem.value} value={elem.value}>{elem.descripcion}</MenuItem>
                                                ))
                                        }
                                    </Select>

                                </FormControl>

                            </Grid>
                        }

                        <Grid item md={8} xs={12} >

                            <TextField
                                name='numeroExpediente'
                                label='Número de expediente'
                                autoComplete='off'
                                placeholder='0000/0000'
                                fullWidth
                                value={numeroExpediente}
                                onChange={(e) => setNumeroExpediente(e.target.value)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={ filtroAplicadoSearch }
                            />

                        </Grid>

                        <Grid item md={4} xs={12}>

                            <LoadingButton
                                variant='contained'
                                color={ filtroAplicadoSearch ? 'inherit' : 'primary' }
                                fullWidth
                                startIcon={ filtroAplicadoSearch ? <ClearIcon /> : <SearchIcon />}
                                onClick={ () => filtroAplicadoSearch ? handleLimpiarSearch() : handleBuscarExpediente() }
                                loading={ loadingSearchExpediente }                                
                                disabled={
                                    (idJuzgado === 54 || idJuzgado === 10 || idJuzgado === 21 || idJuzgado === 26 || idJuzgado === 28 || idJuzgado === 29)
                                    ?
                                        numeroExpediente === '' || 
                                        !numeroExpediente.includes('/') ||
                                        juzgado === 0
                                    :
                                    numeroExpediente === '' || 
                                    !numeroExpediente.includes('/')
                                }
                            >
                                { filtroAplicadoSearch ? 'Limpiar' : 'Buscar' }
                            </LoadingButton>

                        </Grid>

                        {                            
                            <>
                                <Grid item sm={3} xs={6} >
                                    <Typography variant='body2' sx={{ fontWeight: 'bold' }}>
                                        Número de folio
                                    </Typography>
                                </Grid>

                                <Grid item sm={9} xs={6} >
                                    <Typography variant='body2' sx={{ textTransform: '' }}>
                                        { expediente?.folio }
                                    </Typography>
                                </Grid>

                                <Grid item sm={3} xs={6} >
                                    <Typography variant='body2' sx={{ fontWeight: 'bold' }}>
                                        Número de expediente
                                    </Typography>
                                </Grid>

                                <Grid item sm={9} xs={6} >
                                    <Typography variant='body2' sx={{ textTransform: '' }}>
                                        { expediente?.numeroExpediente }
                                    </Typography>
                                </Grid>

                                <Grid item sm={3} xs={6} >
                                    <Typography variant='body2' sx={{ fontWeight: 'bold' }}>
                                        Fecha de radicación
                                    </Typography>
                                </Grid>

                                <Grid item sm={9} xs={6} >
                                    <Typography variant='body2' sx={{ textTransform: '' }}>
                                    { expediente?.fechaRadicacion ? moment(expediente?.fechaRadicacion).format('DD-MM-YYYY') : ''}
                                    </Typography>
                                </Grid>

                                <Grid item sm={3} xs={6} >
                                    <Typography variant='body2' sx={{ fontWeight: 'bold' }}>
                                        Tipo de juicio
                                    </Typography>
                                </Grid>

                                <Grid item sm={9} xs={6} >
                                    <Typography variant='body2' sx={{ textTransform: '' }}>
                                        { expediente?.tipoJuicio }
                                    </Typography>
                                </Grid>

                                <Grid item sm={3} xs={6} >
                                    <Typography variant='body2' sx={{ fontWeight: 'bold' }}>
                                        Presentante
                                    </Typography>
                                </Grid>

                                <Grid item sm={9} xs={6} >
                                    <Typography variant='body2' sx={{ textTransform: '' }}>
                                        { expediente?.presentante }
                                    </Typography>
                                </Grid>

                                <Grid item sm={3} xs={6} >
                                    <Typography variant='body2' sx={{ fontWeight: 'bold' }}>
                                        Fecha de recepción
                                    </Typography>
                                </Grid>

                                <Grid item sm={9} xs={6} >
                                    <Typography variant='body2' sx={{ textTransform: '' }}>
                                    { expediente.fechaPresentacion ? moment(expediente.fechaPresentacion).format('DD-MM-YYYY') : ''}
                                    </Typography>
                                </Grid>

                                <Grid item sm={3} xs={6} >
                                    <Typography variant='body2' sx={{ fontWeight: 'bold' }}>
                                        Actor(es)
                                    </Typography>
                                </Grid>

                                <Grid item sm={9} xs={6} >
                                    <List disablePadding>
                                        <ListItem disablePadding sx={{ pb: 1 }}>
                                            <Typography variant='body2'>{ expediente.actorPromovente }</Typography>
                                        </ListItem>
                                    </List>
                                </Grid>

                                <Grid item sm={3} xs={6} >
                                    <Typography variant='body2' sx={{ fontWeight: 'bold' }}>
                                        Demandado(s)
                                    </Typography>
                                </Grid>

                                <Grid item sm={9} xs={6} >
                                    <List disablePadding>
                                        <ListItem disablePadding sx={{ pb: 1 }}>
                                            <Typography variant='body2'> { expediente?.demandado } </Typography>
                                        </ListItem>
                                    </List>
                                </Grid>

                                <Grid item sm={3} xs={6} >
                                    <Typography variant='body2' sx={{ fontWeight: 'bold' }}>
                                        Síntesis
                                    </Typography>
                                </Grid>

                                <Grid item sm={9} xs={6} >
                                    <List disablePadding>
                                        <ListItem disablePadding sx={{ pb: 1 }}>
                                            <Typography variant='body2'> { expediente?.sintesis } </Typography>
                                        </ListItem>
                                    </List>
                                </Grid>

                            </>
                        }       

                    </Grid>

                </DialogContent>

                <DialogActions>
                    <Button onClick={ handleClose }>Cancelar</Button>
                    <LoadingButton 
                        variant='contained' 
                        color='primary' 
                        onClick={ handleAgregarExpediente } 
                        loading={loadingAsignarExpediente} 
                        startIcon={<AddIcon />}
                        disabled={ 
                            (idJuzgado === 54 || idJuzgado === 10 || idJuzgado === 21 || idJuzgado === 26 || idJuzgado === 28 || idJuzgado === 29)
                            ?
                                !expediente?.success ||
                                juzgado === 0
                            :
                                !expediente?.success 
                        } 
                    >
                        Agregar
                    </LoadingButton>
                </DialogActions>

            </Dialog>

            <Dialog
                open={ openCausa }
                onClose={() => { }}
                maxWidth='sm'
                fullWidth
            >
                <DialogTitle>
                    Asignar causa penal
                </DialogTitle>

                <DialogContent>

                    <Grid container spacing={3} sx={{ mt: 0.1 }}>

                        <Grid item md={8} xs={12} >

                            <TextField
                                name='numeroCausaPenal'
                                label='Número de causa'
                                autoComplete='off'
                                placeholder='0000/0000'
                                fullWidth
                                value={ numeroCausa }
                                onChange={ (e) => setNumeroCausa(e.target.value) }
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={ filtroAplicadoSearch }
                            />

                        </Grid>

                        <Grid item md={4} xs={12}>

                            <LoadingButton
                                variant='contained'
                                color={ filtroAplicadoSearch ? 'inherit' : 'primary' }
                                fullWidth
                                startIcon={ filtroAplicadoSearch ? <ClearIcon /> : <SearchIcon />}
                                disabled={ numeroCausa === '' || !numeroCausa.includes('/') }
                                onClick={ () => filtroAplicadoSearch ? handleLimpiarSearch() : handleBuscarCausa() }
                                loading={ loadingSearchExpediente }
                            >
                                { filtroAplicadoSearch ? 'Limpiar' : 'Buscar' }
                            </LoadingButton>

                        </Grid>                       

                        {                            
                            <>
                                <Grid item sm={4} xs={6} >
                                    <Typography variant='body2' sx={{ fontWeight: 'bold' }}>
                                        Número de carpeta de investigación
                                    </Typography>
                                </Grid>

                                <Grid item sm={8} xs={6} >
                                    <Typography variant='body2' sx={{ textTransform: '' }}>
                                        { expediente?.nuc }
                                    </Typography>
                                </Grid>

                                <Grid item sm={4} xs={6} >
                                    <Typography variant='body2' sx={{ fontWeight: 'bold' }}>
                                        Número de causa penal
                                    </Typography>
                                </Grid>

                                <Grid item sm={8} xs={6} >
                                    <Typography variant='body2' sx={{ textTransform: '' }}>
                                        { expediente?.nic }
                                    </Typography>
                                </Grid>

                                <Grid item sm={4} xs={6} >
                                    <Typography variant='body2' sx={{ fontWeight: 'bold' }}>
                                        Fecha de inicio
                                    </Typography>
                                </Grid>

                                <Grid item sm={8} xs={6} >
                                    <Typography variant='body2' sx={{ textTransform: '' }}>
                                    { expediente?.fechaInicio ? moment(expediente?.fechaInicio).format('DD-MM-YYYY') : ''}
                                    </Typography>
                                </Grid>
                         
                                <Grid item sm={4} xs={6} >
                                    <Typography variant='body2' sx={{ fontWeight: 'bold' }}>
                                        Imputado(s)
                                    </Typography>
                                </Grid>

                                <Grid item sm={8} xs={6} >
                                    <Typography variant='body2' sx={{ textTransform: '' }}>
                                        { expediente?.imputado }
                                    </Typography>
                                </Grid>                               

                                <Grid item sm={4} xs={6} >
                                    <Typography variant='body2' sx={{ fontWeight: 'bold' }}>
                                        Victima(s)/Ofendido(s)
                                    </Typography>
                                </Grid>

                                <Grid item sm={8} xs={6} >
                                    <List disablePadding>
                                        <ListItem disablePadding sx={{ pb: 1 }}>
                                            <Typography variant='body2'>{ expediente?.victima_Ofendido }</Typography>
                                        </ListItem>
                                    </List>
                                </Grid>

                                <Grid item sm={4} xs={6} >
                                    <Typography variant='body2' sx={{ fontWeight: 'bold' }}>
                                        Delitos(s)
                                    </Typography>
                                </Grid>

                                <Grid item sm={8} xs={6} >
                                    <List disablePadding>
                                        <ListItem disablePadding sx={{ pb: 1 }}>
                                            <Typography variant='body2'> { expediente?.delitos } </Typography>
                                        </ListItem>
                                    </List>
                                </Grid>

                            </>
                        }       

                    </Grid>

                </DialogContent>

                <DialogActions>
                    <Button onClick={ handleClose }>Cancelar</Button>
                    <LoadingButton variant='contained' color='primary' disabled={ !expediente?.success } onClick={ handleAgregarCausa } loading={ loadingAsignarExpediente } startIcon={<AddIcon />}>
                        Agregar
                    </LoadingButton>
                </DialogActions>

            </Dialog>

        </>
    )

}
