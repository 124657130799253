import React from 'react';

import { AppBar, Box, Container, Toolbar, Typography } from '@mui/material';

import { Link } from 'react-router-dom';

export const Navbar = () => {

    return (

        <AppBar position="fixed" sx={{ backgroundColor: '#fff' }}>

            <Container maxWidth="xl">

                <Toolbar disableGutters>

                    <Typography variant="h5" noWrap sx={{ flexGrow: 1 }}>
                        
                        <Box display='flex' flexDirection='column' alignItems='center' >
                                
                            <Link to="/">
                                <img style={{ width: 200 }}
                                    src={ process.env.PUBLIC_URL + '/assets/logo.png'}
                                    alt='logo-poder-en-linea'
                                />
                            </Link>
                        
                        </Box>

                    </Typography>

                </Toolbar>

            </Container>

        </AppBar>
        
    )

}
