
export const FormatPhoneNumber = ( number ) => {

    let format = '';

    if( number ){

        format = '(' + number.substring(0, 3) + ') ' + number.substring(3, 6) + '-' + number.substring(6);
    }

    return format;
}

export const ClearFormatPhoneNumber = ( number ) => {

    let format = '';

    if( number ){

        format = number.replace(/[&#,+()$~%.'":*?<>{}-]/g, '').replace(' ', '');
    }

    return format;
}
