import React, { useState, useEffect } from 'react';

import { Avatar, Divider, Link, List, ListItem, ListItemAvatar, ListItemText, Paper, Typography } from '@mui/material';

import { obtenerNoticiasEventos } from '../../actions/ui/noticiasEventosActions';

export const NoticiasEventos = () => {

    const [data, setData] = useState( [] );
    
    useEffect(() => {
      
        async function Obtener(){

            await obtenerNoticiasEventos().then( response => {

                if( response.status === 200 ){

                    if( response.data ){

                        const noticias = response.data?.data ?? [];
                        setData(noticias);

                    }
                }

            });
        }

        Obtener();

    }, [ ])
    
    return (
        <Paper>
            <Typography fontSize={22} variant='h5' sx={{ ml: 2, pt: 2 }}>
                Noticias y eventos
            </Typography>
            
            <List key={0} sx={{ width: '100%', borderRadius: 3 }}>
            {
                data
                .slice(0, 5)
                .map( ( { title, summary, preview, url }, i ) => (

                    <div key={ i }>

                        <ListItem>
                            <ListItemAvatar sx={{ p: 1 }} >
                                <img style={{ width: 125, borderRadius: 15 }} src={ preview }  />
                            </ListItemAvatar>
                            <ListItemText 
                                primary={ 
                                    <Link href={ url } target='_blank' sx={{ textDecoration: 'none' }} >
                                        <Typography variant='body2' sx={{ fontSize: 14, fontWeight: 'bold' }}> 
                                            { title } 
                                        </Typography> 
                                    </Link>
                                } 
                                secondary={ <Typography variant='body2' sx={{ fontSize: 14, mt: 1 }}> { summary } </Typography> } 
                            />
                        </ListItem>

                        <Divider />

                    </div>

                ))
            }
            </List>        
        </Paper>
    )
}
