import React, { useEffect, useState } from 'react';

import { Box, CircularProgress, Container, Grid } from '@mui/material';

import { NavbarInicio } from './ui/NavbarInicio';

import { UltimosAcuerdos } from './ui/UltimosAcuerdos';

import { usePermisos } from '../hooks/usePermisos';

import { CardAplicacion } from './ui/CardAplicacion';
import { NoticiasEventos } from './ui/NoticiasEventos';

export const PoderEnLineaScreen = () => {

    const { tipoUsuario, banderaCitas, banderaBuzon, banderaExpedienteVirtual, banderaBoletas } = usePermisos();

    const [loading, setLoading] = useState( true );

    useEffect(() => {

        if( tipoUsuario !== 0 ){

            setTimeout(() => {
            
                setLoading( false );
    
            }, 500);

        }     

    }, [ tipoUsuario ])    
    
    return (
        
        <>

            <NavbarInicio />

            <Container maxWidth='lg' sx={{ mt: { md: '10em', xs: '8em' }, mb: 5 }}>

                {
                    ( loading )
                    ?
                        <Grid container spacing={3} >
                            
                            <Grid item xs={12} sx={{ textAlign: 'center' }} >
                                <CircularProgress size={50} sx={{ color: '#fff', mt: 10 }} />
                            </Grid>

                        </Grid>                    
                    :
                        <Grid container spacing={3}>

                            {
                                tipoUsuario === 3
                                &&                        
                                <>
                                    <CardAplicacion 
                                        image='expediente-virtual.png' 
                                        title='Administración'  
                                        desc='Permitirá administrar usuarios, catálogos y Api key´s utilizados en la plataforma.'  
                                        desc_button='ir a panel de control'  
                                        path='/administracion/' 
                                    />

                                    <CardAplicacion 
                                        image='buzon.png' 
                                        title='Sistema de identificación (SID)'  
                                        desc=' Permitirá registrar a los usuarios que podran acceder a la plataforma poder en línea'  
                                        desc_button='Ver solicitudes'  
                                        path='/solicitudes/' 
                                    />                        
                                </>
                            }
                            
                            {
                                ( tipoUsuario === 1 || tipoUsuario === 2 || tipoUsuario === 5 || tipoUsuario === 14 )
                                &&                    
                                <Grid item md={12} sm={12} xs={12} >
                                
                                    <Grid container spacing={3}>
                                    
                                        {
                                            ( banderaCitas && tipoUsuario === 2 )
                                            &&
                                            <CardAplicacion 
                                                image='citas.png' 
                                                title='Sistema de Citas'  
                                                desc='Permitirá agendar tu visita a nuestros órganos jurisdiccionales mediante una sencilla herramienta en línea.'  
                                                desc_button='Agendar una cita'  
                                                path='/citas/' 
                                            />   
                                        }

                                        {
                                            banderaBuzon
                                            &&
                                            <CardAplicacion 
                                                image='buzon.png' 
                                                title='Buzón electrónico'  
                                                desc='Presentación electrónica de demandas y promociones que vayan dirigidas a los juzgados.'  
                                                desc_button={ ( tipoUsuario === 2 || tipoUsuario === 14 ) ? 'Enviar demanda ó promoción' : 'Ver demandas y/o promociones' }
                                                path='/buzon/' 
                                            />                                                  
                                        }
                                        
                                        {
                                            ( banderaExpedienteVirtual && ( tipoUsuario === 1 || tipoUsuario === 2 || tipoUsuario === 14 ) )
                                            &&                                         
                                            <CardAplicacion 
                                                image='expediente-virtual.png' 
                                                title='Expediente virtual'  
                                                desc='Permite visualizar las resoluciones judiciales que conforman un expediente o carpeta administrativa.'  
                                                desc_button={ 
                                                    ( tipoUsuario === 1 ? 'Autorizar expediente' : '' ) ||
                                                    ( ( tipoUsuario === 2 || tipoUsuario === 14 ) ? 'Mis expedientes' : '' )
                                                }
                                                path='/expedienteVirtual/' 
                                            />     
                                        }

                                        {
                                            ( banderaBoletas && ( tipoUsuario === 1 || tipoUsuario === 2 ) )
                                            &&
                                            <CardAplicacion 
                                                image='boletas.png' 
                                                title='Boletas de notificación'  
                                                desc='Permite solicitar diligencias actuariales sin la necesidad de trasladarse al juzgado respectivo.'  
                                                desc_button={ 
                                                    ( tipoUsuario === 1 ? 'Ver boletas' : '' ) ||
                                                    ( tipoUsuario === 2 ? 'Crear solicitud' : '' )
                                                }
                                                path='/boletas/' 
                                            />    
                                        }
                
                                    </Grid>                                
                                
                                </Grid>
                            }
                        
                            <Grid item md={12} sm={12} xs={12} >

                                <Grid container spacing={3}>

                                    <Grid item md={6} xs={12}>
                                       
                                        <NoticiasEventos />

                                    </Grid>

                                    <Grid item md={6} xs={12}>                                        
                                       
                                        <Box sx={{ height: 725, borderRadius: 3 }} >

                                            <UltimosAcuerdos /> 

                                        </Box> 

                                    </Grid>

                                </Grid>
                            
                            </Grid>

                        </Grid>
                }

            </Container>

        </>
        
    );
};