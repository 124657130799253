import React from 'react';

import { Box, Container, Paper } from '@mui/material';

import { NavbarInicio } from '../ui/NavbarInicio';

import { TabsUsuarioExternoScreen } from './TabsUsuarioExternoScreen';
import { TabsUsuarioInternoScreen } from './TabsUsuarioInternoScreen';
import { NotFoundScreen } from '../auth/NotFoundScreen';

import { CardMantenimiento } from '../ui/CardMantenimiento';

import { usePermisos } from '../../hooks/usePermisos';

export const BoletasScreen = () => {

    const { tipoUsuario, banderaBoletas, loading, banderaOnlineBoletas } = usePermisos();

    return (
        <>

            <NavbarInicio />

            {
                ( !loading && !banderaBoletas )
                &&
                <NotFoundScreen />
            }

            {
                ( !loading && banderaBoletas )
                &&     

                <Container maxWidth='xl' sx={{ mt: { md: '10em', xs: '8em' } }}>

                    {
                        banderaOnlineBoletas
                        ?
                            <CardMantenimiento />
                        :
                        <>  

                            <Box sx={{ pb: '2em', pl: { md: 10, xs: 1 }, pr: { md: 10, xs: 1 }, pt: 2 }} component={ Paper } >	

                                {
                                    ( tipoUsuario === 1 )
                                    &&
                                    <TabsUsuarioInternoScreen />
                                }

                                {
                                    ( tipoUsuario === 2 )
                                    &&
                                    <TabsUsuarioExternoScreen />
                                }

                            </Box>

                        </>
                    }
            
                </Container>

            }
        
        </>
    )

}
