import React from 'react';

import { Button, Grid } from '@mui/material';

import FileDownloadIcon from '@mui/icons-material/FileDownload';

export const FactoresAutenticacionScreen = () => {

    return (    
        <Grid container spacing={3} >

            <Grid item sm={12} xs={12} display='flex' flexDirection='column' alignItems='center' sx={{ mt: 3 }}>

                <img alt='app-token' src={ process.env.PUBLIC_URL + '/assets/indicaciones_app_token.png' } style={{ width: '100%' }} />

            </Grid>     

            <Grid item sm={12} xs={12} display='flex' flexDirection='column' alignItems='center'>

                <Grid container spacing={3} >

                    <Grid item sm={6} xs={12} display='flex' flexDirection='column' alignItems='center'>

                        <a href='https://play.google.com/store/apps/details?id=com.poderlineatokenapp' target='_blank' rel='noreferrer' >
                            <img alt='googleplay' src={ process.env.PUBLIC_URL + '/assets/googleplay.png' } style={{ height: 55, marginTop: 35 }} />
                        </a>

                        <Button
                            variant='text'
                            href='https://storage.googleapis.com/pjecz-gob-mx/Consultas/Poder%20en%20Linea/Manual_descarga_configuracion_google_play.pdf'
                            target='_blank'
                            startIcon={ <FileDownloadIcon /> }
                        >
                            Manual de descarga y configuración
                        </Button>

                    </Grid>

                    <Grid item sm={6} xs={12} display='flex' flexDirection='column' alignItems='center'>
                        
                        <a href='https://apps.apple.com/mx/app/app-token/id1669177678' target='_blank' rel='noreferrer'>
                            <img alt='appstore' src={ process.env.PUBLIC_URL + '/assets/appstore.png' } style={{ height: 55, marginTop: 35 }} />
                        </a>

                        <Button
                            variant='text'
                            href='https://storage.googleapis.com/pjecz-gob-mx/Consultas/Poder%20en%20Linea/Manual_descarga_configuracion_app_store.pdf'
                            target='_blank'
                            startIcon={ <FileDownloadIcon /> }
                        >
                            Manual de descarga y configuración
                        </Button>

                    </Grid>

                </Grid> 

            </Grid>           

        </Grid>
    );
}