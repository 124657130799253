import React, { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';

import { Box, Card, CardHeader, CircularProgress, Grid, TextField, Typography } from '@mui/material';

import FolderIcon from '@mui/icons-material/Folder';
import EditIcon from '@mui/icons-material/Edit';
import SearchIcon from '@mui/icons-material/Search';

import { catalogosData } from '../../data/catalogosData';

export const CatalogosScreen = () => {

    const [catalogos, setCatalogos] = useState([ ]);
    const [loading, setLoading] = useState( true );

    const filter = (e) => {
        if( e.target.value !== ""){
            const catalogosFilter = getCatalogFilter( catalogosData, e.target.value );
            setCatalogos( catalogosFilter );
        }
        else{
            setCatalogos( catalogosData );
        }
    }

    const getCatalogFilter = ( catalogos, value ) => {

        const catalogosFilter = catalogos.filter(function ({ descripcion }) { 
    
            const descripcionFilter = descripcion.toLowerCase().indexOf(value.toLowerCase() );
            
            return descripcionFilter !== -1;
        });
    
        return catalogosFilter;
    }

    useEffect(() => {

        setLoading( true );

        setTimeout(() => {

            setLoading( false );

            setCatalogos(
                catalogosData.sort((a, b) => {
                    let fa = a.descripcion.toLowerCase(),
                        fb = b.descripcion.toLowerCase();
                
                    if (fa < fb) {
                        return -1;
                    }
                    if (fa > fb) {
                        return 1;
                    }
                    return 0;
                })
            );
            
        }, 500);

    }, [ ]);
  
    return (
        <>
            <Grid container spacing={3}>

               <Grid item xs={12} md={12}> 

                    <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>

                        <SearchIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />

                        <TextField 
                            label="Buscar catálogo" 
                            variant="standard" 
                            style={{ width: '100%' }} 
                            onKeyUp={ filter } 
                            autoComplete="off"
                        />

                    </Box>

                </Grid>

                <Grid sx={{ mt: 5 }} container spacing={3} >   

                    { catalogos.map( ({ id, descripcion, path }) => (
                        
                        <Grid key={ id } item xs={6} sm={4} md={3} >

                            <Card onClick={ () => {} } >
                                <CardHeader avatar={ <FolderIcon color='primary' /> }
                                    action={  
                                       <Link to={ path } > <EditIcon color='primary' /> </Link>
                                    }
                                    title={ <Typography variant='body2' sx={{ fontWeight: 'bold', color: '#252b50' }} > { descripcion } </Typography> }
                                />          
                            </Card>

                        </Grid>

                    ))}   

                </Grid>

                <Grid sx={{ marginTop: 2, textAlign: 'center' }} container spacing={3} >
                    {
                        catalogos.length === 0 && (
                            <Grid item md={12} >

                                {
                                    loading
                                    ?
                                        <CircularProgress size={40} sx={{ mt: 2, mb: 2 }} />
                                    :
                                        ''
                                }
                                {
                                    !loading && catalogos.length === 0
                                    ?
                                        <Typography variant="h6" sx={{ mt: 1, mb: 1, color: '#999595', fontSize: 18 }}>
                                            No se encontraron catálogos
                                        </Typography>
                                    :
                                        ''
                                }                                
                            </Grid>
                        )
                    }
                </Grid>

            </Grid>
        </>
    )

}
