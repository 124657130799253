import React from 'react';

import { useSelector } from 'react-redux';

import { Box, Button, Card, CardActionArea, CardActions, CardContent, CardMedia, Container, Grid, Typography } from '@mui/material';

import { NavbarInicio } from '../ui/NavbarInicio';

import { usePermisos } from '../../hooks/usePermisos';

export const AyudaScreen = () => {

    const { tokenUnicoActivado } = useSelector( state => state.auth );

    const { tipoUsuario, banderaBoletas } = usePermisos();

    return (
        <>

            <NavbarInicio />

            <Container maxWidth='lg' sx={{ mt: { md: '13em', xs: '11em' }, mb: 5 }}>

                <Grid container spacing={3}>

                    <Grid item md={ 4 } xs={12}>

                        <Card>

                            <CardActionArea>

                                <CardMedia
                                    component="img"
                                    image={ process.env.PUBLIC_URL + '/assets/manual-usuario.jpeg'}
                                    height={250}
                                    alt="green iguana"
                                />
                                
                                <CardContent>
                                
                                    <Typography gutterBottom variant="h5" component="div" sx={{ fontSize: 18 }}>
                                        Poder en Línea Web
                                    </Typography>
                                
                                    <Typography variant="body2" color="text.secondary">
                                        Manual de usuario
                                    </Typography>

                                </CardContent>

                            </CardActionArea>

                            <CardActions>

                                <Button 
                                    size="small" 
                                    fullWidth 
                                    color="primary"
                                    href={
                                        ( tipoUsuario === 1 && banderaBoletas )
                                        ?
                                            'https://storage.googleapis.com/pjecz-gob-mx/Consultas/Poder%20en%20Linea/Manual_de_usuario_actuario.pdf'
                                        :
                                            tipoUsuario === 1
                                        ?
                                            'https://storage.googleapis.com/pjecz-gob-mx/Consultas/Poder%20en%20Linea/Manual_de_usuario_juzgado.pdf'
                                        :
                                            tipoUsuario === 14
                                        ?
                                            'https://storage.googleapis.com/pjecz-gob-mx/Consultas/Poder%20en%20Linea/Manual_de_usuario_psicosocial.pdf'
                                        :
                                            tipoUsuario === 5
                                        ?
                                            'https://storage.googleapis.com/pjecz-gob-mx/Consultas/Poder%20en%20Linea/Manual_de_ususario_oficialia.pdf'
                                        :
                                            'https://storage.googleapis.com/pjecz-gob-mx/Consultas/Poder%20en%20Linea/Manual_de_usuario.pdf'                                         
                                    }
                                    target='_blank'
                                >
                                    Descargar
                                </Button>

                            </CardActions>
                            
                        </Card>

                    </Grid>

                    {/*
                        <Grid item md={ 4 } xs={12}>

                            <Card>

                                <CardActionArea>
                                
                                    <CardMedia
                                        component="img"
                                        height={250}
                                        image={ process.env.PUBLIC_URL + '/assets/solicitud-acceso.png'}
                                        alt="green iguana"
                                    />

                                    <CardContent>
                                        
                                        <Typography gutterBottom variant="h5" component="div" sx={{ fontSize: 18 }}>
                                            Buzón electrónico
                                        </Typography>

                                        <Typography variant="body2" color="text.secondary">
                                            Solicitud de accesso
                                        </Typography>

                                    </CardContent>

                                </CardActionArea>
                                
                                <CardActions>
                                
                                    <Button 
                                        size="small" 
                                        fullWidth 
                                        color="primary"
                                        href='https://storage.googleapis.com/pjecz-gob-mx/Consultas/Buz%C3%B3n%20Electr%C3%B3nico/Solicitud%20de%20Acceso.pdf'
                                        target='_blank'
                                    >
                                        Descargar
                                    </Button>

                                </CardActions>
                                
                            </Card>

                        </Grid>
                    */}

                    <Grid item md={ 4 } xs={12}>

                        <Card>

                            <CardActionArea>

                                <CardMedia
                                    component="img"
                                    height={250}
                                    image={ process.env.PUBLIC_URL + '/assets/autorizacion-expediente-virtual.png'}
                                    alt="green iguana"
                                />

                                <CardContent>

                                    <Typography gutterBottom variant="h5" component="div" sx={{ fontSize: 18 }}>
                                        Expediente virtual
                                    </Typography>
                                    
                                    <Typography variant="body2" color="text.secondary">
                                        Solicitud de autorización
                                    </Typography>

                                </CardContent>

                            </CardActionArea>

                            <CardActions>
                            
                                <Button 
                                    size="small" 
                                    fullWidth 
                                    color="primary"
                                    href='https://storage.googleapis.com/pjecz-gob-mx/Consultas/Expediente%20Virtual/formato-solicitud-expedientes.pdf'
                                    target='_blank'
                                >
                                    Descargar
                                </Button>

                            </CardActions>

                        </Card>

                    </Grid>

                    {
                        tokenUnicoActivado
                        &&
                            <Grid item md={ 4 } xs={12}>

                                <Card>

                                    <CardActionArea>

                                        <CardMedia
                                            component="img"
                                            image={ process.env.PUBLIC_URL + '/assets/manual-usuario.jpeg'}
                                            height={250}
                                            alt="green iguana"
                                        />
                                        
                                        <CardContent>

                                            <Grid container>

                                                <Grid item xs={8}>
                                                    <Typography gutterBottom variant="h5" component="div" sx={{ fontSize: 18 }}>
                                                        Poder en Líne@ App
                                                    </Typography>
                                                    <Typography variant="body2" color="text.secondary">
                                                        Manual de usuario
                                                    </Typography>
                                                </Grid>

                                                <Grid item xs={4}>
                                                    <Box display='flex' flexDirection='column' alignItems='center'> 
                                                        <img 
                                                            alt='logo app poder en linea'
                                                            src={ process.env.PUBLIC_URL + '/assets/app-logo.png'}
                                                            style={{ width: 45, borderRadius: 10 }}
                                                        />
                                                    </Box>
                                                </Grid>

                                            </Grid> 

                                        </CardContent>

                                    </CardActionArea>

                                    <CardActions>

                                        <Button 
                                            size="small" 
                                            fullWidth 
                                            color="primary"
                                            href={ 'https://storage.googleapis.com/pjecz-gob-mx/Consultas/Poder%20en%20Linea/Manual_Poder_en_Linea_App.pdf' }
                                            target='_blank'
                                        >
                                            Descargar
                                        </Button>

                                    </CardActions>
                                    
                                </Card>

                            </Grid>
                    }

                    <Grid item md={12} xs={12}>

                        <Card>

                            <CardActionArea>

                                <CardMedia
                                    component="img"
                                    image={ process.env.PUBLIC_URL + '/assets/whatsapp-banner.png'}
                                    height={250}
                                    alt="green iguana"
                                />

                                <CardContent>

                                    <Typography gutterBottom variant="h5" component="div" sx={{ fontSize: 18 }}>
                                        Soporte técnico
                                    </Typography>
                                    
                                    <Typography variant="body2" color="text.secondary">
                                        Whatsapp: 844 506 0075
                                    </Typography>

                                </CardContent>

                            </CardActionArea>

                        </Card>

                    </Grid>

                </Grid>

            </Container> 

        </>
    )

}
