import React, { useEffect, useState } from 'react';

import { Alert, Button, ButtonGroup, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, TextField, Typography } from '@mui/material'

import { LoadingButton } from '@mui/lab';

import FileCopyIcon from '@mui/icons-material/FileCopy';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';

import moment from 'moment';

import { usePages } from '../../hooks/usePages';

import { TablePaginationActions } from '../../helpers/TablePaginationActions';

import { AceptarSolicitud, CancelarSolicitud, CuentaExistente, ObtenerSolicitudesPendientes } from '../../actions/sid/solicitudActions';
import { ObtenerMotivosCancelacion } from '../../actions/sid/motivoCancelacionActions';

export const SolicitudesPendientesScreen = () => {
    
    const [rows, setRows] = useState( [] );

    const [page, rowsPerPage, handleChangePage, handleChangeRowsPerPage] = usePages( 5 );

    const [loading, setLoading] = useState( true );
    const [loadingAceptar, setLoadingAceptar] = useState( false );
    const [loadingCancelar, setLoadingCancelar] = useState( false );
    const [loadingCuentaExistente, setLoadingCuentaExistente] = useState( false );
    const [reload, setReload] = useState( false );
    
    const [documento, setDocumento] = useState( '' );
    const [motivoCancelacion, setMotivoCancelacion] = useState( '' );
    const [referencia, setReferencia] = useState( '' );

    const [openDocumento, setOpenDocumento] = useState( false );
    const [openAutorizar, setOpenAutorizar] = useState( false );
    const [openCancelacion, setOpenCancelacion] = useState( false );

    const [cuentaExistente, setCuentaExistente] = useState( {} );
    const [openCuentaExistente, setOpenCuentaExistente] = useState( false );

    const [detalle, setDetalle] = useState( false );
    const [openDetalle, setOpenDetalle] = useState( false );

    const [errores, setErrores] = useState( {} );

    const [motivosCancelacion, setMotivosCancelacion] = useState( [] );

    const validateFields = () => {

        let valid = true;
        let errores = {};

        if ( motivoCancelacion === '' ) {
            valid = false;
            errores.motivoCancelacion = 'Escribe el motivo de cancelación';
        }

        setErrores( errores );

        return valid;
    }

    const handleClose = () => {

        setReferencia( '' );
        setMotivoCancelacion( '' );
        setErrores( {} );
        setCuentaExistente( {} );
        setOpenCancelacion( false );
        setOpenCuentaExistente( false );
        setOpenAutorizar( false );
    }

    const handleAceptarSolicitud = async () => {

        setLoadingAceptar( true );
        
        await AceptarSolicitud({ id: referencia }).then( response => {

            if( response ){

                if( response.status === 200 ){

                    const { success, message } = response.data;

                    if( success ){

                        setTimeout(() => {

                            setLoadingAceptar( false );
                            setReload( true );
                            handleClose();
                            
                        }, 700);

                    }
                    else{
                        setErrores({ error: message });
                    }

                }   

            }

        });

    }

    const handleCancelarSolicitud = async () => {

        const valid = validateFields();

        if( !valid ){
            return false;
        }

        const params = {
            id: referencia,
            motivoCancelacion,
        };

        setLoadingCancelar( true );
        
        await CancelarSolicitud( params ).then( response => {

            if( response ){

                if( response.status === 200 ){

                    const { success, message } = response.data;

                    if( success ){

                        setTimeout(() => {

                            setRows(rows.filter( ( elem ) => elem.id !== referencia ));

                            setLoadingCancelar( false );
                            handleClose();
                            
                        }, 700);

                    }
                    else{
                        setErrores({ error: message });
                    }

                }   

            }

        });

    }

    const handleCuentaExistente = async () => {

        setLoadingCuentaExistente( true );
        
        await CuentaExistente({ id: referencia }).then( response => {

            if( response ){

                if( response.status === 200 ){

                    const { success, message } = response.data;

                    if( success ){

                        setTimeout(() => {

                            setRows(rows.filter( ( elem ) => elem.id !== referencia ));

                            setLoadingCuentaExistente( false );
                            handleClose();
                            
                        }, 700);

                    }
                    else{
                        setErrores({ error: message });
                    }

                }   

            }

        });

    }
    
    useEffect(() => {

        async function Obtener(){
            
            setRows( [ ] );    
            setLoading( true );            

            await ObtenerSolicitudesPendientes().then( response => {

                if( response ){

                    if( response.status === 200 ){      
                        
                        setTimeout(() => {
                            
                            setRows( response.data ?? [] );                
                            setLoading( false );
                
                        }, 700);
                        
                    }
                    else{
                        setLoading( false );
                    }
                }
                else{
                    setLoading( false );
                }

            })

        }         
      
        Obtener();          
        
    }, [ ])    

    useEffect(() => {

        async function Obtener(){
            
            setMotivosCancelacion( [ ] );    

            await ObtenerMotivosCancelacion().then( response => {

                if( response ){

                    if( response.status === 200 ){      
                        
                        setTimeout(() => {
                            
                            setMotivosCancelacion( response.data ?? [] );        
                
                        }, 700);
                        
                    }                 
                }

            })

        }         
      
        Obtener();          
        
    }, [ ])    
    
    useEffect(() => {

        async function Obtener(){
            
            setRows( [ ] );    
            setLoading( true );            

            await ObtenerSolicitudesPendientes().then( response => {

                if( response ){

                    if( response.status === 200 ){      
                        
                        setTimeout(() => {
                            
                            setRows( response.data ?? [] );                
                            setLoading( false );
                            setReload( false );

                        }, 700);
                        
                    }
                    else{
                        setLoading( false );
                    }
                }
                else{
                    setLoading( false );
                }

            })

        }         
      
        if( reload ){
            Obtener();          
        }
        
    }, [ reload ])    

    return (
        
        <>
            <Grid container spacing={3} >

                <Grid item xs={12} md={12}> 
                
                    <TableContainer>

                        <Table>
                            
                            <TableHead>

                                <TableRow>        

                                    <TableCell style={{ width:'0.5%' }}></TableCell>
                                    <TableCell style={{ width:'15%' }}> FECHA </TableCell>
                                    <TableCell style={{ width:'25%' }}> SOLICITANTE </TableCell>
                                    <TableCell style={{ width:'10%' }}> PALABRA CLAVE </TableCell>
                                    <TableCell style={{ width:'5%' }}> IDENTIFICACIÓN </TableCell>
                                    <TableCell style={{ width:'5%' }}> CURP </TableCell>
                                    <TableCell style={{ width:'5%' }}> CÉDULA PROFESIONAL </TableCell>
                                    <TableCell style={{ width:'5%' }}> VIDEO </TableCell>
                                    <TableCell style={{ width:'10%', textAlign: 'center' }}>  </TableCell>

                                </TableRow>

                            </TableHead>

                            <TableBody>

                                {
                                    ( rowsPerPage > 0
                                        ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        : rows
                                    ).map( ( row, i) => (

                                        <TableRow key={ row.id } >

                                            <TableCell> { row.app && <PhoneAndroidIcon /> } </TableCell>
                                            
                                            <TableCell> { row.fecha ? moment( row.fecha ).format('DD-MM-YYYY hh:mm a') : '' } </TableCell>
                                            
                                            <TableCell sx={{ fontWeight: 'bold' }}> 
                                                <Button
                                                    variant='text'
                                                    onClick={ () => { setDetalle( row ); setOpenDetalle( true ); }}
                                                >
                                                    { row.solicitante } 
                                                </Button>
                                            </TableCell>
                                           
                                            <TableCell> { row.palabraClave } </TableCell>
                                            
                                            <TableCell sx={{ textAlign: 'center' }}>  

                                                <IconButton
                                                    onClick={ () => { setDocumento( row.ine ); setOpenDocumento( true );  } }
                                                    color='primary'
                                                >
                                                    <FileCopyIcon fontSize='large' />
                                                </IconButton>

                                            </TableCell>

                                            <TableCell sx={{ textAlign: 'center' }}>   
                                                
                                                <IconButton
                                                    onClick={ () => { setDocumento( row.curp ); setOpenDocumento( true );  } }
                                                    color='primary'
                                                >
                                                    <FileCopyIcon fontSize='large' />
                                                </IconButton>

                                            </TableCell>
                                            
                                            <TableCell sx={{ textAlign: 'center' }}>   
                                                
                                                {
                                                    row.cedula
                                                    &&
                                                    <IconButton
                                                        onClick={ () => { setDocumento( row.cedula ); setOpenDocumento( true );  } }
                                                        color='primary'
                                                    >
                                                        <FileCopyIcon fontSize='large' />
                                                    </IconButton>
                                                }                                                

                                            </TableCell>

                                            <TableCell sx={{ textAlign: 'center' }}>   
                                                
                                                <IconButton
                                                    onClick={ () => { setDocumento( row.video ); setOpenDocumento( true );  } }
                                                    color='primary'
                                                >
                                                    <FileCopyIcon fontSize='large' />
                                                </IconButton>

                                            </TableCell>

                                            <TableCell>  
                                                
                                                <ButtonGroup variant="contained" size='small'>  
                                                    {
                                                        row.cuentaExistente
                                                        ?
                                                        <>
                                                            <Button color='warning' onClick={ () => { setCuentaExistente( row ); setReferencia( row.id ); setOpenCuentaExistente( true ); } } >
                                                                < SupervisorAccountIcon />
                                                            </Button>

                                                            <Button  onClick={ () => { setOpenCancelacion( true ); setReferencia( row.id ); } } color='error'>
                                                                <ClearIcon />
                                                            </Button>
                                                        </>
                                                        :
                                                        <>
                                                            <Button color='success' onClick={ () => { setOpenAutorizar( true ); setReferencia( row.id ); } } >
                                                                <CheckIcon />
                                                            </Button>

                                                            <Button  onClick={ () => { setOpenCancelacion( true ); setReferencia( row.id ); } } color='error'>
                                                                <ClearIcon />
                                                            </Button>
                                                        </>   
                                                                                                
                                                    }                                                
                                                </ButtonGroup>  

                                            </TableCell>

                                        </TableRow>

                                    ))
                                }

                                { 
                                    rows.length === 0 && (
                                        <TableRow style={{ height: 53 }}>

                                            <TableCell colSpan={9} align="center" sx={{ fontWeight: 'bold' }}> 
                                                {
                                                    loading
                                                    ?
                                                        <CircularProgress size={40} sx={{ mt: 2, mb: 2 }} />
                                                    :
                                                        ''
                                                }
                                                {
                                                    !loading && rows.length === 0
                                                    ?
                                                        'No se encontraron solicitudes'
                                                    :
                                                        ''
                                                }
                                            </TableCell>

                                        </TableRow>
                                    )
                                }
                            </TableBody>

                            <TableFooter>

                                <TableRow>

                                    <TableCell colSpan={3}>
                                        <Typography variant='body2' sx={{ fontWeight: 'bold' }} color='black'> Total de solicitudes: { rows.length } </Typography>
                                    </TableCell>

                                    <TablePagination
                                        rowsPerPageOptions={[ 5, 10, 15, 20 ]}
                                        colSpan={9}
                                        count={rows.length}                                        
                                        rowsPerPage={rowsPerPage}
                                        page={page}                                                                                
                                        onPageChange={ handleChangePage }
                                        onRowsPerPageChange={ handleChangeRowsPerPage }
                                        ActionsComponent={ TablePaginationActions }

                                        labelRowsPerPage="Filas por página"                                            
                                        labelDisplayedRows={
                                            ({ from, to, count }) => {
                                            return '' + from + '-' + to + ' de ' + count
                                            }
                                        }  
                                    />

                                </TableRow>

                            </TableFooter>

                        </Table>

                    </TableContainer>

                </Grid>
                
            </Grid>

            <Dialog open={ openDocumento } onClose={ () => {} } fullWidth={ true } maxWidth="lg" >

                <DialogContent>
                    
                    <Grid container spacing={3} >                                                
                    
                            <Grid item md={12} xs={12} sx={{ display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
                                
                                <iframe 
                                    title='acuse' 
                                    src={ documento } 
                                    height="500px" 
                                    width="100%"
                                >                                    
                                </iframe>

                            </Grid>
                            
                        </Grid>
                    
                </DialogContent>

                <DialogActions>

                    <Button color="primary" onClick={ () => { setOpenDocumento( false ); setDocumento( '' ); } }>
                        Cerrar
                    </Button>

                </DialogActions>                

            </Dialog>  

            <Dialog open={ openAutorizar } onClose={ () => { } } >

                <DialogTitle>
                    Confirmación
                </DialogTitle>
               
                <DialogContent>

                    <DialogContentText sx={{ color: 'black' }}>
                        ¿Desea autorizar la solicitud seleccionada?
                    </DialogContentText>

                </DialogContent>

                <DialogActions>
                    <Button onClick={ handleClose }> Cancelar </Button>
                    <LoadingButton variant='contained' color='primary' onClick={ handleAceptarSolicitud } loading={ loadingAceptar } >
                        Aceptar
                    </LoadingButton>
                </DialogActions>

            </Dialog>

            <Dialog open={ openCancelacion } onClose={ () => { } } maxWidth='sm' fullWidth >

                <DialogTitle>
                    Escribe el motivo de cancelación
                </DialogTitle>
               
                <DialogContent>

                    <Grid container spacing={3} sx={{ pt: 1 }}>                      

                        <Grid item md={12} xs={12}>

                            <TextField 
                                name='motivoCancelacion'
                                label='Motivo de cancelación'
                                multiline
                                rows={5}
                                fullWidth
                                value={ motivoCancelacion }
                                onChange={ (e) => { setMotivoCancelacion( e.target.value ); } }
                            />

                            {
                                errores.motivoCancelacion
                                &&                          
                                <Alert severity='warning' variant='filled' sx={{ mt: 1 }}>
                                    { errores.motivoCancelacion }
                                </Alert>                          
                            }

                        </Grid>

                        {
                            motivosCancelacion?.map( ( elem, index ) => (
                                <Grid key={ index } item md={12} xs={12}>
                                    <Chip label={ elem } color='default' variant='filled' onClick={ () => setMotivoCancelacion( elem ) } />
                                </Grid>
                            ))
                        }

                    </Grid>

                </DialogContent>

                <DialogActions>
                    <Button onClick={ handleClose }>Cancelar</Button>
                    <LoadingButton startIcon={ <ClearIcon /> } variant='contained' color='error' onClick={ handleCancelarSolicitud } loading={ loadingCancelar }>
                        Cancelar solicitud
                    </LoadingButton>
                </DialogActions>

            </Dialog>

            <Dialog open={ openCuentaExistente } onClose={ () => { } } maxWidth='md' fullWidth >

                <DialogTitle>
                    Detalle de la cuenta existente
                </DialogTitle>
               
                <DialogContent>
                    
                    <Table sx={{ mt: 1 }}>

                        <TableHead>

                            <TableRow>
                                <TableCell> </TableCell>  
                                <TableCell sx={{ fontSize: 14, fontWeight: 'bold' }}> Información del Solicitante </TableCell>                             
                                <TableCell sx={{ fontSize: 14, fontWeight: 'bold' }}> Información de la cuenta existente </TableCell>
                                <TableCell> </TableCell>
                            </TableRow>

                        </TableHead>

                        <TableBody>
                    
                            <TableRow>
                                <TableCell sx={{ fontSize: 14, fontWeight: 'bold' }}> Cuenta: </TableCell>  
                                <TableCell> { cuentaExistente?.solicitante } </TableCell>                             
                                <TableCell> { cuentaExistente?.nombreCuentaExistente } </TableCell>
                                <TableCell> { cuentaExistente?.solicitante === cuentaExistente?.nombreCuentaExistente && <ClearIcon color='error' /> } </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ fontSize: 14, fontWeight: 'bold' }}> CURP: </TableCell>  
                                <TableCell> { cuentaExistente?.curpSolicitante } </TableCell>                             
                                <TableCell> { cuentaExistente?.curpCuentaExistente } </TableCell>
                                <TableCell> { cuentaExistente?.curpSolicitante === cuentaExistente?.curpCuentaExistente && <ClearIcon color='error' /> } </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ fontSize: 14, fontWeight: 'bold' }}> Correo electrónico: </TableCell>  
                                <TableCell> { cuentaExistente?.correoElectronicoSolicitante } </TableCell>                             
                                <TableCell> { cuentaExistente?.correoElectronicoCuentaExistente } </TableCell>
                                <TableCell> { cuentaExistente?.correoElectronicoSolicitante === cuentaExistente?.correoElectronicoCuentaExistente && <ClearIcon color='error' /> } </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ fontSize: 14, fontWeight: 'bold' }}> Teléfono celular: </TableCell>  
                                <TableCell> { cuentaExistente?.telefonoCelularSolicitante } </TableCell>                             
                                <TableCell> { cuentaExistente?.telefonoCelularCuentaExistente } </TableCell>
                                <TableCell> { cuentaExistente?.telefonoCelularSolicitante === cuentaExistente?.telefonoCelularCuentaExistente && <ClearIcon color='error' /> } </TableCell>
                            </TableRow>

                        </TableBody>

                    </Table>     

                </DialogContent>

                <DialogActions>
                    <Button onClick={ () => { setOpenCuentaExistente( false ); setCuentaExistente( {} ); } }>Cerrar</Button>
                    <LoadingButton 
                        startIcon={ <SupervisorAccountIcon /> } 
                        variant='contained' 
                        color='warning' 
                        onClick={ handleCuentaExistente }
                        loading={ loadingCuentaExistente }
                    >
                        Terminar solicitud
                    </LoadingButton>
                </DialogActions>

            </Dialog>

            <Dialog open={ openDetalle } onClose={ () => { } } maxWidth='sm' fullWidth >

                <DialogTitle>
                    Detalle de la solicitud
                </DialogTitle>
               
                <DialogContent>
                    
                    <Table>                       

                        <TableBody>
                    
                            <TableRow>
                                <TableCell sx={{ fontSize: 14, fontWeight: 'bold', width: '40%', border: 'none' }}> Solicitante: </TableCell>  
                                <TableCell sx={{ border: 'none' }}> { detalle?.solicitante } </TableCell>               
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ fontSize: 14, fontWeight: 'bold', width: '40%', border: 'none' }}> CURP: </TableCell>  
                                <TableCell sx={{ border: 'none' }}> { detalle?.curpSolicitante } </TableCell>                             
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ fontSize: 14, fontWeight: 'bold', width: '40%', border: 'none' }}> Correo electrónico: </TableCell>  
                                <TableCell sx={{ border: 'none' }}> { detalle?.correoElectronicoSolicitante } </TableCell>       
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ fontSize: 14, fontWeight: 'bold', width: '40%', border: 'none' }}> Teléfono celular: </TableCell>  
                                <TableCell sx={{ border: 'none' }}> { detalle?.telefonoCelularSolicitante } </TableCell>            
                            </TableRow>

                        </TableBody>

                    </Table>     

                </DialogContent>

                <DialogActions>
                    <Button onClick={ () => { setOpenDetalle( false ); setDetalle( {} ); } }>Cerrar</Button>                  
                </DialogActions>

            </Dialog>
        
        </> 

    )

}
