import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export const usePermisos = () => {

    const { idTipoUsuario, idUsuarioCitas, idJuzgado, idMateria, permisos, aplicacionesOnline } = useSelector( state => state.auth );

    const [loading, setLoading] = useState( true );
    const [tipoUsuario, setTipoUsuario] = useState( 0 );
    const [banderaCitas, setBanderaCitas] = useState( false );
    const [banderaBuzon, setBanderaBuzon] = useState( false );
    const [banderaExpedienteVirtual, setBanderaExpedienteVirtual] = useState( false );
    const [banderaBoletas, setBanderaBoletas] = useState( false );    

    const [banderaOnlineCitas, setBanderaOnlineCitas] = useState( false );
    const [banderaOnlineBuzon, setBanderaOnlineBuzon] = useState( false );
    const [banderaOnlineExpedienteVirtual, setBanderaOnlineExpedienteVirtual] = useState( false );
    const [banderaOnlineBoletas, setBanderaOnlineBoletas] = useState( false );    

    useEffect(() => {
      
        if( permisos?.CITAS && idTipoUsuario === 2 ){ setBanderaCitas( true ); }
        if( permisos?.BEDP && ( idTipoUsuario === 1 || idTipoUsuario === 2 || idTipoUsuario === 5 || idTipoUsuario === 14 ) ){ setBanderaBuzon( true ); }
        if( permisos?.['EV2.0'] && ( idTipoUsuario === 1 || idTipoUsuario === 2 || idTipoUsuario === 14 ) ){ setBanderaExpedienteVirtual( true ); }
        if( permisos?.BEGA && ( idTipoUsuario === 1 || idTipoUsuario === 2 ) ){ setBanderaBoletas( true ); }
        
    }, [ permisos, idTipoUsuario ])

    useEffect(() => {
      
        if( aplicacionesOnline?.CITAS === false ){ setBanderaOnlineCitas( true ); }
        if( aplicacionesOnline?.BEDP === false ){ setBanderaOnlineBuzon( true ); }
        if( aplicacionesOnline?.['EV2.0'] === false ){ setBanderaOnlineExpedienteVirtual( true ); }
        if( aplicacionesOnline?.BEGA === false ){ setBanderaOnlineBoletas( true ); }
        
    }, [ aplicacionesOnline ])

    useEffect(() => {
              
        setTipoUsuario( idTipoUsuario );        
        
        if( idTipoUsuario !== 0){
            setLoading( false );
        }
        
    }, [ idTipoUsuario ])

    return {
        loading,
        idUsuarioCitas,
        tipoUsuario,
        idJuzgado,
        idMateria,
        banderaCitas,
        banderaBuzon, 
        banderaExpedienteVirtual,
        banderaBoletas,
        banderaOnlineCitas,
        banderaOnlineBuzon, 
        banderaOnlineExpedienteVirtual,
        banderaOnlineBoletas,
    }

}