import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { Box, Button, CircularProgress, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, Typography } from '@mui/material';

import { LoadingButton } from '@mui/lab';

import BookmarkIcon from '@mui/icons-material/Bookmark';
import UndoIcon from '@mui/icons-material/Undo';

import { NavbarInicio } from '../ui/NavbarInicio';

import { usePages } from '../../hooks/usePages';

import { TablePaginationActions } from '../../helpers/TablePaginationActions';

import { ArchivarCausaPenal, ArchivarExpediente, VerMisCausasPenalesArchivadas, VerMisExpedientesArchivados } from '../../actions/expediente/expedienteVirtualActions';

export const ArchivadosScreen = () => {

    const navigate = useNavigate();

    const [rowsExpedientes, setRowsExpedientes] = useState( [] );
    const [rowsCausas, setRowsCausas] = useState( [] );

    const [pageExpedientes, rowsPerPageExpedientes, handleChangePageExpedientes, handleChangeRowsPerPageExpedientes] = usePages( 5 );
    const [pageCausas, rowsPerPageCausas, handleChangePageCausas, handleChangeRowsPerPageCausas] = usePages( 5 );

    const [loadingExpediente, setLoadingExpediente] = useState( true );
    const [loadingCausas, setLoadingCausas] = useState( true );

    const [openExpediente, setOpenExpediente] = useState( false );
    const [openCausa, setOpenCausa] = useState( false );

    const [recordsCountExpediente, setRecordsCountExpediente] = useState( 0 );
    const [recordsCountCausas, setRecordsCountCausas] = useState( 0 );

    const [refId, setRefId] = useState( '' );
    
    const [reloadExpediente, setReloadExpediente] = useState(false);
    const [reloadCausaPenal, setReloadCausaPenal] = useState(false);

    const [loading, setLoading] = useState(false);

    const handleArchivarExpediente = async () => {

        setLoading( true );

        await ArchivarExpediente({ idExpedienteAsignado : refId, archivar: false }).then( response => {

            if( response ){

                if( response.status === 200 ){

                    const { success } = response.data;

                    if( success ){

                        setTimeout(() => {
                            
                            setReloadExpediente( true );
                            setOpenExpediente( false );

                            setLoading( false );

                        }, 1000);                        
                        
                    }

                }

            }

        });

    }
    
    const handleArchivarCausaPenal = async () => {

        setLoading( true );

        await ArchivarCausaPenal({ idCausaPenalAsignada : refId, archivar: false }).then( response => {

            if( response ){

                if( response.status === 200 ){

                    const { success } = response.data;

                    if( success ){

                        setTimeout(() => {
                            
                            setReloadCausaPenal( true );
                            setOpenCausa( false );

                            setLoading( false );

                        }, 1000);    

                    }

                }

            }

        });
        
    }

    useEffect(() => {
      
        async function Obtener(){

            setRowsExpedientes( [] );
            setLoadingExpediente( true );

            await VerMisExpedientesArchivados({ page: pageExpedientes, rowsPerPage: rowsPerPageExpedientes }).then( response => {

                if( response ){

                    if( response.status === 200 ){
                        
                        setTimeout(() => {    

                            setRowsExpedientes( response?.data?.records ?? [] );
                            setRecordsCountExpediente( response?.data?.recordsCount ?? 0 );
                            setLoadingExpediente( false );

                        }, 800);                        

                    }

                }

            });

        }

        Obtener();

    }, [ pageExpedientes, rowsPerPageExpedientes ])

    useEffect(() => {
      
        async function Obtener(){

            setRowsCausas( [] );
            setLoadingCausas( true );

            await VerMisCausasPenalesArchivadas({ page: pageCausas, rowsPerPage: rowsPerPageCausas }).then( response => {

                if( response ){

                    if( response.status === 200 ){
                        
                        setTimeout(() => {    

                            setRowsCausas( response?.data?.records ?? [] );
                            setRecordsCountCausas( response?.data?.recordsCount ?? 0 );
                            setLoadingCausas( false );

                        }, 800);                        

                    }

                }

            });

        }

        Obtener();

    }, [ pageCausas, rowsPerPageCausas ])

    useEffect(() => {
      
        async function Obtener(){

            setRowsExpedientes( [] );
            setLoadingExpediente( true );

            await VerMisExpedientesArchivados({ page: pageExpedientes, rowsPerPage: rowsPerPageExpedientes }).then( response => {

                if( response ){

                    if( response.status === 200 ){
                        
                        setTimeout(() => {    

                            setRowsExpedientes( response?.data?.records ?? [] );
                            setRecordsCountExpediente( response?.data?.recordsCount ?? 0 );
                            setLoadingExpediente( false );
                            setReloadExpediente( false );

                        }, 800);                        

                    }

                }

            });

        }

        if( reloadExpediente ){
            Obtener();
        }

    }, [ pageExpedientes, rowsPerPageExpedientes, reloadExpediente ])

    useEffect(() => {
      
        async function Obtener(){

            setRowsCausas( [] );
            setLoadingCausas( true );

            await VerMisCausasPenalesArchivadas({ page: pageCausas, rowsPerPage: rowsPerPageCausas }).then( response => {

                if( response ){

                    if( response.status === 200 ){
                        
                        setTimeout(() => {    

                            setRowsCausas( response?.data?.records ?? [] );
                            setRecordsCountCausas( response?.data?.recordsCount ?? 0 );
                            setLoadingCausas( false );
                            setReloadCausaPenal( false );

                        }, 800);                        

                    }

                }

            });

        }

        if( reloadCausaPenal ){
            Obtener();
        }

    }, [ pageCausas, rowsPerPageCausas, reloadCausaPenal ])
    
    return (
        <>

            <NavbarInicio />

            <Container maxWidth='xl' sx={{ mt: { md: '10em', xs: '8em' } }}>

                <Grid container spacing={3} sx={{ mt: 1 }} >

                    <Grid item md={12} xs={12} sx={{ textAlign: { xs: 'center', md: 'right'} }}>

                        <Button
                            variant='contained'
                            sx={{ backgroundColor: '#fff' }}
                            color='inherit'
                            startIcon={ <UndoIcon /> }
                            onClick={ () => navigate('/expedienteVirtual') }
                        >
                            Mis expedientes
                        </Button>

                    </Grid>

                </Grid>

                <Box sx={{ mt: 2, pb: '2em', pl: { md: 10, xs: 1 }, pr: { md: 10, xs: 1 }, pt: 2 }} component={ Paper } >	

                    <Grid container spacing={3} sx={{ mt: 1 }} >

                        <Grid item md={12} xs={12}>
                            <Typography variant='h6'>
                                Expedientes archivados
                            </Typography>
                        </Grid>

                        <Grid item md={12} xs={12}>

                            <TableContainer>

                                <Table>
                                    
                                    <TableHead>

                                        <TableRow>        

                                            <TableCell style={{ width:'5%' }}> FOLIO </TableCell>
                                            <TableCell style={{ width:'5%' }}> EXPEDIENTE </TableCell>
                                            <TableCell style={{ width:'15%' }}> ACTOR(ES) </TableCell>
                                            <TableCell style={{ width:'15%' }}> DEMANDADO(S) </TableCell>
                                            <TableCell style={{ width:'20%' }}> JUZGADO </TableCell>
                                            <TableCell style={{ width:'5%', textAlign: 'center' }}>  </TableCell>

                                        </TableRow>

                                    </TableHead>

                                    <TableBody>

                                        {
                                            rowsExpedientes.map( ( row, i ) => (

                                                <TableRow key={ i } >
                                                    
                                                    <TableCell> { row.folio } </TableCell>
                                                    <TableCell> { row.numeroExpediente } </TableCell>
                                                    <TableCell> { row.actor } </TableCell>
                                                    <TableCell> { row.demandado } </TableCell>
                                                    <TableCell> { row.juzgado } </TableCell>
                                                    
                                                    <TableCell sx={{ textAlign: 'center' }}>   
                                                        
                                                        <IconButton
                                                            onClick={ () => { setRefId( row?.idExpedienteAsignado ); setOpenExpediente( true ); } }
                                                            color='primary'
                                                        >
                                                            <BookmarkIcon />
                                                        </IconButton>

                                                    </TableCell>

                                                </TableRow>

                                            ))
                                        }

                                        { 
                                            rowsExpedientes.length === 0 && (
                                                <TableRow style={{ height: 53 }}>

                                                    <TableCell colSpan={10} align="center" sx={{ fontWeight: 'bold' }}> 
                                                        {
                                                            loadingExpediente
                                                            ?
                                                                <CircularProgress size={40} sx={{ mt: 2, mb: 2 }} />
                                                            :
                                                                ''
                                                        }
                                                        {
                                                            !loadingExpediente && rowsExpedientes.length === 0
                                                            ?
                                                                'No se encontraron expedientes'
                                                            :
                                                                ''
                                                        }
                                                    </TableCell>

                                                </TableRow>
                                            )
                                        }
                                    </TableBody>

                                    <TableFooter>

                                        <TableRow>

                                            <TableCell colSpan={2}>
                                                <Typography variant='body2' color='black' sx={{ fontWeight: 'bold' }}> Total de expedientes: { recordsCountExpediente } </Typography>
                                            </TableCell>  
                                
                                            <TablePagination
                                                rowsPerPageOptions={[ 5, 10, 15, 20 ]}
                                                colSpan={ 10 }
                                                count={ recordsCountExpediente }                                        
                                                rowsPerPage={ rowsPerPageExpedientes }
                                                page={ pageExpedientes }                                                                                
                                                onPageChange={ handleChangePageExpedientes }
                                                onRowsPerPageChange={ handleChangeRowsPerPageExpedientes }
                                                ActionsComponent={ TablePaginationActions }

                                                labelRowsPerPage="Filas por página"                                            
                                                labelDisplayedRows={
                                                    ({ from, to, count }) => {
                                                    return '' + from + '-' + to + ' de ' + count
                                                    }
                                                }  
                                            />

                                        </TableRow>

                                    </TableFooter>

                                </Table>

                            </TableContainer>

                        </Grid>

                    </Grid>

                    <Grid container spacing={3} sx={{ mt: 1 }} >

                        <Grid item md={12} xs={12}>
                            <Typography variant='h6'>
                                Causas archivadas
                            </Typography>
                        </Grid>

                        <Grid item md={12} xs={12}>

                            <TableContainer>

                                <Table>
                                    
                                    <TableHead>

                                        <TableRow>        

                                            <TableCell style={{ width: '5%' }}> CARPETA DE INVESTIGACIÓN </TableCell>
                                            <TableCell style={{ width: '10%' }}> NÚMERO DE CAUSA </TableCell>
                                            <TableCell style={{ width: '10%' }}> IMPUTADO(S) </TableCell>
                                            <TableCell style={{ width: '10%' }}> VICTIMA(S)/OFENDIDO(S) </TableCell>
                                            <TableCell style={{ width:'20%' }}> JUZGADO </TableCell>
                                            <TableCell style={{ width:'5%', textAlign: 'center' }}>  </TableCell>

                                        </TableRow>

                                    </TableHead>

                                    <TableBody>

                                        {
                                            rowsCausas.map( ( row, i ) => (

                                                <TableRow key={ i } >
                                                    
                                                    <TableCell> { row.nuc } </TableCell>
                                                    <TableCell> { row.nic } </TableCell>
                                                    <TableCell> { row.imputado } </TableCell>
                                                    <TableCell> { row.victima_Ofendido } </TableCell>
                                                    <TableCell> { row.juzgado } </TableCell>
                                                    
                                                    <TableCell sx={{ textAlign: 'center' }}>   
                                                        
                                                        <IconButton
                                                            onClick={ () => { setRefId( row?.idCausaPenalAsignada ); setOpenCausa( true ); } }
                                                            color='primary'
                                                        >
                                                            <BookmarkIcon />
                                                        </IconButton>

                                                    </TableCell>

                                                </TableRow>

                                            ))
                                        }

                                        { 
                                            rowsCausas.length === 0 && (
                                                <TableRow style={{ height: 53 }}>

                                                    <TableCell colSpan={10} align="center" sx={{ fontWeight: 'bold' }}> 
                                                        {
                                                            loadingCausas
                                                            ?
                                                                <CircularProgress size={40} sx={{ mt: 2, mb: 2 }} />
                                                            :
                                                                ''
                                                        }
                                                        {
                                                            !loadingCausas && rowsCausas.length === 0
                                                            ?
                                                                'No se encontraron causas'
                                                            :
                                                                ''
                                                        }
                                                    </TableCell>

                                                </TableRow>
                                            )
                                        }
                                    </TableBody>

                                    <TableFooter>

                                        <TableRow>
                                            
                                            <TableCell colSpan={2}>
                                                <Typography variant='body2' color='black' sx={{ fontWeight: 'bold' }}> Total de causas: { recordsCountCausas } </Typography>
                                            </TableCell>  
                                
                                            <TablePagination
                                                rowsPerPageOptions={[ 5, 10, 15, 20 ]}
                                                colSpan={10}
                                                count={ recordsCountCausas }                                        
                                                rowsPerPage={ rowsPerPageCausas}
                                                page={ pageCausas }                                                                                
                                                onPageChange={ handleChangePageCausas }
                                                onRowsPerPageChange={ handleChangeRowsPerPageCausas }
                                                ActionsComponent={ TablePaginationActions }

                                                labelRowsPerPage="Filas por página"                                            
                                                labelDisplayedRows={
                                                    ({ from, to, count }) => {
                                                    return '' + from + '-' + to + ' de ' + count
                                                    }
                                                }  
                                            />

                                        </TableRow>

                                    </TableFooter>

                                </Table>

                            </TableContainer>

                        </Grid>

                    </Grid>

                </Box>

            </Container>

            <Dialog
                open={ openExpediente }
                onClose={ () => { } }
            >
                <DialogTitle>
                    Confirmación
                </DialogTitle>
               
                <DialogContent>

                    <DialogContentText sx={{ color: 'black' }}>
                        ¿Desea desarchivar el expediente seleccionado?
                    </DialogContentText>

                </DialogContent>

                <DialogActions>
                    <Button onClick={ () => { setOpenExpediente( false ) } }>Cancelar</Button>
                    <LoadingButton variant='contained' color='primary' onClick={ handleArchivarExpediente } loading={ loading } >
                        Aceptar
                    </LoadingButton>
                </DialogActions>

            </Dialog>

            <Dialog
                open={ openCausa }
                onClose={ () => { } }
            >
                <DialogTitle>
                    Confirmación
                </DialogTitle>
               
                <DialogContent>

                    <DialogContentText sx={{ color: 'black' }}>
                        ¿Desea desarchivar la causa seleccionada?
                    </DialogContentText>

                </DialogContent>

                <DialogActions>
                    <Button onClick={ () => { setOpenCausa( false ) } }>Cancelar</Button>
                    <LoadingButton variant='contained' color='primary' onClick={ handleArchivarCausaPenal } loading={ loading } >
                        Aceptar
                    </LoadingButton>
                </DialogActions>

            </Dialog>

        </>
    )
}
