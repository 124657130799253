import React, { useEffect, useState } from 'react';

import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, InputLabel, List, ListItemButton, ListItemIcon, ListItemText, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, TextField, Typography } from '@mui/material';

import { LoadingButton } from '@mui/lab';

import ClearIcon from '@mui/icons-material/Clear';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import InfoIcon from '@mui/icons-material/Info';
import SearchIcon from '@mui/icons-material/Search';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';

import moment from 'moment/moment';

import { usePages } from '../../hooks/usePages';

import { TablePaginationActions } from '../../helpers/TablePaginationActions';

import { ObtenerMateria } from '../../actions/catalogos/materiaActions';

import { BuscarMisEnvios, DetallePromocionDemanda, MisEnvios } from '../../actions/buzon/promocionActions';

export const MisEnviosScreen = () => {
    
    const onlyNumbers = (e) => { e.target.value = e.target.value.replace(/[^0-9]/g, '') }
    
    const [rows, setRows] = useState( [] );

    const [page, rowsPerPage, handleChangePage, handleChangeRowsPerPage, resetPage] = usePages( 5 );

    const [loading, setLoading] = useState( true );

    const [openDetalle, setOpenDetalle] = useState( false );
    const [openAcuseGestion, setOpenAcuseGestion] = useState( false );
    const [openAcuseEnvio, setOpenAcuseEnvio] = useState( false );
    const [openEscrito, setOpenEscrito] = useState( false );
    const [openAnexos, setOpenAnexos] = useState( false );  
    const [openArchivoAnexo, setOpenArchivoAnexo] = useState( false );  
    
    const [folio , setFolio] = useState('') ;
    const [materia , setMateria] = useState(0);
    const [fecha, setFecha] =useState('');

    const [materiaArray, setMateriaArray] = useState( [ ] );

    const [loadingDetalle, setLoadingDetalle] = useState( false );   
    const [detalle , setDetalle] = useState( {} );

    const [recordsCount, setRecordsCount] = useState( 0 );

    const [escrito, setEscrito] = useState( '' );
    const [acuseGestion, setAcuseGestion] = useState( '' );
    const [acuseEnvio, setAcuseEnvio] = useState( '' );
    const [anexos, setAnexos] = useState( [] );
    const [archivoAnexo, setArchivoAnexo] = useState( '' );
    
    const [filtroAplicado, setFiltroAplicado] = useState( false );    
    const [loadingSearch, setLoadingSearch] = useState( false );   

    const handleClearFiltro = () => {

        setFiltroAplicado( false ); 
        setFolio( '' );
        setMateria( 0 );        
        setFecha( '' );

        resetPage();
        setRows( [] );
    }

    const handleBuscarPromociones = () => { 

        setLoadingSearch( true );
        setFiltroAplicado( true );

    }

    const handleDetallePromocion = async ( id ) => {

        setOpenDetalle( true ); 
        setLoadingDetalle( true );

        await DetallePromocionDemanda({ idPromocion: id }).then( response => {

            if( response ){

                if( response.data ){

                    setTimeout(() => {

                        setDetalle( response.data ?? {} );
                        setLoadingDetalle( false );

                    },1000);

                }

            }

        });           
        
    }

    useEffect(() => {
        
        async function Obtener(){
            
            await ObtenerMateria().then(response => {
                
                if( response ){

                    if( response.status === 200 ){

                        setMateriaArray( response.data );
                    }

                }
                
            });

        }

        Obtener();

    }, [  ]);
     
    useEffect(() => {

        async function Obtener(){
            
            setRows( [ ] );    
            setLoading( true );           

            await MisEnvios({ page, rowsPerPage }).then( response => {

                if( response ){

                    if( response.status === 200 ){      
                        
                        setTimeout(() => {
                            
                            setRecordsCount( response.data.recordsCount ?? 0 );
                            setRows( response.data.records ?? [] );             
                            setLoading( false );
                
                        }, 700);
                        
                    }
                    else{
                        setLoading( false );
                    }
                }
                else{
                    setLoading( false );
                }
            })
        }         
      
        if( !filtroAplicado ){
            Obtener();          
        }
        
    }, [ filtroAplicado, page, rowsPerPage ])  
    
    useEffect(() => {

        async function Obtener(){
            
            setRows( [ ] );    
            setLoading( true );           

            await BuscarMisEnvios({ folio: folio === '' ? 0 : folio, idMateria: materia, fecha, page, rowsPerPage }).then( response => {
                
                if( response ){

                    if( response.status === 200 ){      
                        
                        setTimeout(() => {
                            
                            setRecordsCount( response.data.recordsCount ?? 0 );
                            setRows( response.data.records ?? [] );             
                            setLoading( false );
                            setLoadingSearch( false );
                
                        }, 700);
                        
                    }
                    else{
                        setLoading( false );
                        setLoadingSearch( false );
                    }
                }
                else{
                    setLoading( false );
                    setLoadingSearch( false );
                }
            })
        }         
      
        if( filtroAplicado ){
            Obtener();
        }         
        
    }, [ filtroAplicado, folio, materia, fecha, page, rowsPerPage ])  

    return (
        <>

            <Grid container spacing={3} sx={{ mt: 1 }}>

                <Grid item md={4} xs={12} >  

                    <TextField
                        name='folio'
                        label='Folio Buzón Electrónico'
                        autoComplete='off'
                        value={ folio }
                        onChange={ ( e ) => { setFolio(e.target.value) } }               
                        onInput= {(e) => onlyNumbers(e)}
                        fullWidth
                        disabled={ filtroAplicado }
                    />

                </Grid>

                <Grid item md={4} xs={12} >   

                    <FormControl fullWidth>

                        <InputLabel id='select-materia-label'>Materia</InputLabel>
                    
                        <Select
                            labelId='select-materia-label'
                            id='select-materia'                                            
                            label='Materia'
                            name='materia'
                            value={ materia }                                           
                            onChange={ ( e ) => { setMateria(e.target.value) } }
                            disabled={ filtroAplicado }
                        >
                            <MenuItem value={0}>Seleccione una opción</MenuItem>
                            {
                                materiaArray.map( (elem) => (
                                    <MenuItem key={ elem.idMateria} value={ elem.idMateria}> {elem.descripcion} </MenuItem>
                                ))
                            }
                            
                        </Select>               

                    </FormControl>

                </Grid>  

                <Grid item md={4} xs={12} >  

                    <TextField                              
                        label='Fecha de recepción'
                        type='date'              
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}   
                        value={ fecha }
                        onChange={ ( e ) => { setFecha(e.target.value) } } 
                        disabled={ filtroAplicado }                            
                    />

                </Grid>

                <Grid item md={12} xs={12} >   

                    <Grid container spacing={3} justifyContent='right' >
                            
                        <Grid item xs={12} sm={3}>

                            <LoadingButton
                                variant='contained'
                                startIcon={ filtroAplicado ? <ClearIcon /> : <SearchIcon /> }
                                fullWidth
                                disabled={ folio === '' && materia === 0 && fecha === '' }
                                onClick={ () => { filtroAplicado ? handleClearFiltro() : handleBuscarPromociones() } }
                                loading={ loadingSearch }
                                color={ filtroAplicado ? 'inherit' : 'primary' }
                            >
                                { filtroAplicado ? 'limpiar filtro' : 'Buscar'}
                            </LoadingButton>
                            
                        </Grid>
                        
                    </Grid>

                </Grid>

                <Grid item xs={12} md={12} sx={{ mt: 1 }}> 
                
                    <TableContainer>

                        <Table>
                            
                            <TableHead>

                                <TableRow>   
                                   
                                    <TableCell style={{ width:'15%' }}> TIPO DE TRÁMITE </TableCell>
                                    <TableCell style={{ width:'10%' }}> FOLIO BÚZON ELECTRÓNICO </TableCell>
                                    <TableCell style={{ width:'15%' }}> REFERENCIA </TableCell>
                                    <TableCell style={{ width:'15%' }}> FECHA DE RECEPCIÓN </TableCell>
                                    <TableCell style={{ width:'10%' }}> ESTATUS </TableCell>
                                    <TableCell style={{ width:'25%' }}> ÓRGANO JURISDICCIONAL </TableCell>
                                    <TableCell style={{ width:'2%', textAlign: 'center' }}> DETALLE </TableCell>
                                    <TableCell style={{ width:'2%', textAlign: 'center' }}> ESCRITO </TableCell>
                                    <TableCell style={{ width:'1%', textAlign: 'center' }}></TableCell>

                                </TableRow>

                            </TableHead>

                            <TableBody>

                                {
                                    rows.map( ( row, i ) => (

                                        <TableRow key={ i } >   

                                            <TableCell> { row.tipoTramite } </TableCell>
                                           
                                            <TableCell sx={{ fontWeight: 'bold' }}> { row.folio } </TableCell>
                                           
                                            <TableCell> { row.referencia } </TableCell>
                                           
                                            <TableCell> { row.fechaHora ? moment( row.fechaHora ).format('DD-MM-YYYY hh:mm a') : '' } </TableCell>
                                           
                                            <TableCell> { row.estatus } </TableCell>
                                           
                                            <TableCell> 
                                                
                                                <Typography variant='subtitle2' sx={{ fontWeight: 'bold', pb: 1 }}>
                                                    { row.oficialia } 
                                                </Typography>
                                                
                                                {
                                                    row.juzgado
                                                    &&
                                                    <Typography variant='caption'>
                                                        { row.juzgado } 
                                                    </Typography>
                                                }
                                            
                                            </TableCell>

                                            <TableCell sx={{ textAlign: 'center' }}>  

                                                <Button
                                                    onClick={ () => { handleDetallePromocion( row.idPromocion ) } }
                                                    color='primary'   
                                                    size='small'
                                                    variant='contained'     
                                                >
                                                    <InfoIcon />
                                                </Button>

                                            </TableCell>

                                            
                                            <TableCell sx={{ textAlign: 'center' }}>  
                                                
                                                <Button
                                                    onClick={ () => { setEscrito( row.escrito ); setOpenEscrito( true ); }}
                                                    color='primary'   
                                                    size='small'
                                                    variant='contained'     
                                                >   
                                                    <FileCopyIcon />
                                                </Button>

                                            </TableCell>

                                            <TableCell> { row.app && <PhoneAndroidIcon /> } </TableCell>

                                        </TableRow>

                                    ))
                                }

                                { 
                                    rows.length === 0 && (
                                        <TableRow style={{ height: 53 }}>

                                            <TableCell colSpan={10} align="center" sx={{ fontWeight: 'bold' }}> 
                                                {
                                                    loading
                                                    ?
                                                        <CircularProgress size={40} sx={{ mt: 2, mb: 2 }} />
                                                    :
                                                        ''
                                                }
                                                {
                                                    !loading && rows.length === 0
                                                    ?
                                                        'No se encontraron registros'
                                                    :
                                                        ''
                                                }
                                            </TableCell>

                                        </TableRow>
                                    )
                                }
                            </TableBody>

                            <TableFooter>

                                <TableRow>

                                    <TableCell colSpan={3}>
                                        <Typography variant='body2' color='black' sx={{ fontWeight: 'bold' }}> Total de registros: { recordsCount } </Typography>
                                    </TableCell>

                                    <TablePagination
                                        rowsPerPageOptions={[ 5, 10, 15, 20 ]}
                                        colSpan={10}
                                        count={ recordsCount }                                        
                                        rowsPerPage={ rowsPerPage }
                                        page={ page}                                                                                 
                                        onPageChange={ handleChangePage }
                                        onRowsPerPageChange={ handleChangeRowsPerPage }
                                        ActionsComponent={ TablePaginationActions }

                                        labelRowsPerPage="Filas por página"                                            
                                        labelDisplayedRows={
                                            ({ from, to, count }) => {
                                            return '' + from + '-' + to + ' de ' + count
                                            }
                                        }  
                                    />

                                </TableRow>

                            </TableFooter>

                        </Table>

                    </TableContainer>

                </Grid>
                
            </Grid>

            <Dialog 
                open={ openDetalle } 
                onClose={ () => {} } 
                fullWidth
                maxWidth="md" 
            >

                <DialogTitle variant='h4'>
                    { detalle.folio ? "F-" + ( '00000000' + detalle.folio.toString() ).substring(detalle.folio.toString().length) : '' }
                </DialogTitle>
               
                <DialogContent>
                  
                    {
                        loadingDetalle
                        ?
                            <Grid container spacing={3} sx={{ justifyContent: 'center' }}>
                                <CircularProgress size={40} sx={{ mt: 5, mb: 5 }} />
                            </Grid>
                        :
                            <Grid container spacing={3} sx={{ mt: 1 }}>                                                
                            
                                <Grid item sm={4} xs={4} >
                                    <Typography variant='body2' sx={{ fontWeight: 'bold' }}>
                                        Presentante
                                    </Typography>
                                </Grid>

                                <Grid item sm={8} xs={8} >
                                    <Typography variant='body2' sx={{ textTransform: '' }}>
                                        <strong>{detalle.presentante }</strong> <br />
                                        { detalle.correoElectronico }<br />
                                        {  detalle.telefonoCelular }
                                    </Typography>
                                </Grid>
                            
                                <Grid item sm={4} xs={4} >
                                    <Typography variant='body2' sx={{ fontWeight: 'bold' }}>
                                        Tipo de trámite	
                                    </Typography>
                                </Grid>

                                <Grid item sm={8} xs={8} >
                                    <Typography variant='body2' sx={{ textTransform: '' }}>
                                        {detalle.tipoTramite } <br />
                                        {detalle.tipoJuicio}
                                    </Typography>
                                </Grid>

                                {
                                    detalle.referencia
                                    &&
                                    <>
                                        <Grid item sm={4} xs={4} >
                                            <Typography variant='body2' sx={{ fontWeight: 'bold' }}>
                                                Número de expediento o folio
                                            </Typography>
                                        </Grid>

                                        <Grid item sm={8} xs={8} >
                                            <Typography variant='body2' sx={{ textTransform: '' }}>
                                                { detalle.referencia }
                                            </Typography>
                                        </Grid>
                                    </>
                                }

                                <Grid item sm={4} xs={4} >
                                    <Typography variant='body2' sx={{ fontWeight: 'bold' }}>
                                        Órgano jurisdiccional
                                    </Typography>
                                </Grid>

                                <Grid item sm={8} xs={8} >
                                    <Typography variant='body2' sx={{ textTransform: '' }}>
                                        <b>{ detalle.oficialia }</b> <br />
                                        { detalle.juzgado }
                                    </Typography>
                                </Grid>

                                <Grid item sm={4} xs={4} >
                                    <Typography variant='body2' sx={{ fontWeight: 'bold' }}>
                                        Estatus
                                    </Typography>
                                </Grid>

                                <Grid item sm={8} xs={8} >
                                    <Typography variant='body2' sx={{ textTransform: '' }}>
                                        { detalle.estatus }
                                    </Typography>
                                </Grid>      

                                {
                                    detalle?.anexos?.length !== 0
                                    &&
                                    <>
                                        <Grid item sm={4} xs={4} >
                                            <Typography variant='body2' sx={{ fontWeight: 'bold' }}>
                                                Anexos { '(' + detalle?.anexos?.length + ')' }
                                            </Typography>
                                        </Grid>

                                        <Grid item sm={8} xs={8}>

                                            <List disablePadding>

                                                {
                                                    detalle?.anexos?.map( ( elem, index ) => (
                                                        
                                                        <ListItemButton disableGutters key={ index } onClick={ () => { setArchivoAnexo( elem.url ); setOpenArchivoAnexo( true ); } }>
                                                            <ListItemIcon>
                                                                <FileCopyIcon color='primary' />
                                                            </ListItemIcon>
                                                            <ListItemText primary={ elem.archivo } />
                                                        </ListItemButton>

                                                    ))
                                                }

                                            </List>

                                        </Grid>   
                                    </>
                                }

                                {
                                    detalle.acuse !== ''
                                    &&
                                    <>
                                        <Grid item sm={4} xs={4} >
                                            <Typography variant='body2' sx={{ fontWeight: 'bold' }}>
                                                Acuse de envío 
                                            </Typography>
                                        </Grid>

                                        <Grid item sm={8} xs={8}>
                                            <List disablePadding>
                                                <ListItemButton disableGutters onClick={ () => { setAcuseEnvio( detalle.acuse ); setOpenAcuseEnvio( true ); } }>
                                                    <ListItemIcon>
                                                        <FileCopyIcon color='primary' />
                                                    </ListItemIcon>
                                                    <ListItemText primary="Acuse de envío" />
                                                </ListItemButton>

                                            </List>
                                        </Grid> 
                                    </>
                                }

                                {
                                    detalle.acuseSistemaGestion !== ''
                                    && 
                                    <>
                                        <Grid item sm={4} xs={4} >
                                            <Typography variant='body2' sx={{ fontWeight: 'bold' }}>
                                                Acuse de registro
                                            </Typography>
                                        </Grid>

                                        <Grid item sm={8} xs={8}>

                                            <List disablePadding>
                                                <ListItemButton disableGutters onClick={ () => { setOpenAcuseGestion( true ); setAcuseGestion( detalle.acuseSistemaGestion  ); } }>
                                                    <ListItemIcon>
                                                        <FileCopyIcon color='primary' />
                                                    </ListItemIcon>
                                                    <ListItemText primary='Acuse de registro.pdf' />
                                                </ListItemButton>
                                            </List>

                                        </Grid>   
                                    </>
                                }

                            </Grid>
                    }
                  
                </DialogContent>

               <DialogActions>

                   <Button color="primary" onClick={ () => { setOpenDetalle( false ); setDetalle( {} ); } }>
                       Cerrar
                   </Button>

               </DialogActions>                

            </Dialog> 

            <Dialog 
                open={ openAcuseGestion } 
                onClose={ () => {} } 
                fullWidth
                maxWidth="md" 
            >

                <DialogTitle>
                    Acuse de recepción
                </DialogTitle>
               
               <DialogContent>
                  
                   <Grid container spacing={3} >                                                
                   
                        <Grid item md={12} xs={12} sx={{ display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
                            
                            <iframe title='acuse' src={ acuseGestion } height="500px" width="100%"></iframe>

                        </Grid>
                      
                   </Grid>
                  
               </DialogContent>

               <DialogActions>

                   <Button color="primary" onClick={ () => { setOpenAcuseGestion( false ); setAcuseGestion( '' ); } }>
                       Cerrar
                   </Button>

               </DialogActions>                

            </Dialog> 

            <Dialog 
                open={ openEscrito } 
                onClose={ () => {} } 
                fullWidth 
                maxWidth="md" 
            >

                <DialogTitle>
                    Escrito
                </DialogTitle>
                
                <DialogContent>
                    
                    <Grid container spacing={3} >                                                
                    
                            <Grid item md={12} xs={12} sx={{ display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
                                
                                <iframe title='escrito' src={ escrito } height="500px" width="100%"></iframe>

                            </Grid>
                            
                        </Grid>
                    
                </DialogContent>

                <DialogActions>

                    <Button color="primary" onClick={ () => { setOpenEscrito( false ); setEscrito( '' ); } }>
                        Cerrar
                    </Button>

                </DialogActions>                

            </Dialog>  
            
            <Dialog 
                open={ openAnexos } 
                onClose={ () => {} } 
                fullWidth
                maxWidth="sm" 
            >

                <DialogTitle>
                    Anexos
                </DialogTitle>
                
                <DialogContent>
                    
                    <List>

                    {
                        anexos.map( ( elem, index ) => (
                            
                            <ListItemButton key={ index } onClick={ () => { setArchivoAnexo( elem.url ); setOpenArchivoAnexo( true ); } }>
                                <ListItemIcon>
                                    <FileCopyIcon color='primary' />
                                </ListItemIcon>
                                <ListItemText primary={ elem.archivo } />
                          </ListItemButton>

                        ))
                    }

                    </List>
                    
                </DialogContent>

                <DialogActions>

                    <Button color="primary" onClick={ () => { setOpenAnexos( false ); setAnexos( [] ); } }>
                        Cerrar
                    </Button>

                </DialogActions>                

            </Dialog>  

            <Dialog 
                open={ openArchivoAnexo } 
                onClose={ () => {} } 
                fullWidth
                maxWidth="md" 
            >

                <DialogTitle>
                    Anexo
                </DialogTitle>
               
               <DialogContent>
                  
                   <Grid container spacing={3} >                                                
                   
                        <Grid item md={12} xs={12} sx={{ display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
                            
                            <iframe title='acuse' src={ archivoAnexo } height="500px" width="100%"></iframe>

                        </Grid>
                      
                   </Grid>
                  
               </DialogContent>

               <DialogActions>

                   <Button color="primary" onClick={ () => { setOpenArchivoAnexo( false ); setArchivoAnexo( '' ); } }>
                       Cerrar
                   </Button>

               </DialogActions>                

            </Dialog> 

            <Dialog 
                open={ openAcuseEnvio } 
                onClose={ () => {} } 
                fullWidth
                maxWidth="md" 
            >

                <DialogTitle>
                    Acuse de envío
                </DialogTitle>
               
               <DialogContent>
                  
                   <Grid container spacing={3} >                                                
                   
                        <Grid item md={12} xs={12} sx={{ display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
                            
                            <iframe title='acuse' src={ acuseEnvio } height="500px" width="100%"></iframe>

                        </Grid>
                      
                   </Grid>
                  
               </DialogContent>

               <DialogActions>

                   <Button color="primary" onClick={ () => { setOpenAcuseEnvio( false ); setAcuseEnvio( '' ); } }>
                       Cerrar
                   </Button>

               </DialogActions>                

            </Dialog>

        </> 

    )

}
