import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Alert, Avatar, Box, Button, Card, CardActions, CardContent, CardHeader, CircularProgress, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Grid, IconButton, List, ListItem, ListItemAvatar, ListItemText, Snackbar, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material';

import { LoadingButton } from '@mui/lab';

import styled from '@emotion/styled';

import EditIcon from '@mui/icons-material/Edit';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import CloseIcon from '@mui/icons-material/Close';
import NotificationAddIcon from '@mui/icons-material/NotificationAdd';

import { NavbarInicio } from '../ui/NavbarInicio';

import { ModalActualizarDomicilio } from '../modals/ModalActualizarDomicilio';
import { ModalActualizarDatosPersonales } from '../modals/ModalActualizarDatosPersonales';
import { ModalActualizarDatosCuenta } from '../modals/ModalActualizarDatosCuenta';
import { ModalActualizarContrasena } from '../modals/ModalActualizarContrasena';

import { ActualizarImagenPerfil, DesvincularDispositivo, HabilitarNotificacion, ObtenerPerfil } from '../../actions/admin/usuarioActions';

import { usePermisos } from '../../hooks/usePermisos';

import { setAvatar } from '../../store/slices/authSlice';

const Input = styled('input')({ display: 'none' });

export const PerfilScreen = () => {

    const state = useSelector( state => state.auth );

    const dispatch = useDispatch();

    const { tipoUsuario, loading: loadingPermisos } = usePermisos();

    const [openActualizarDatosPersonales, setOpenActualizarDatosPersonales] = useState( false );
    const [openActualizarDomicilio, setOpenActualizarDomicilio] = useState( false );
    const [openActualizarDatosCuenta, setOpenActualizarDatosCuenta] = useState( false );
    const [openActualizarContrasena, setOpenActualizarContrasena] = useState( false );

    const [openConfirmacion, setOpenConfirmacion] = useState(false);
    const [referencia, setReferencia] = useState('');

    const [imagenPerfil, setImagenPerfil] = useState('');

    const [usuario, setUsuario] = useState( {} );
    const [dispositivosVinculados, setDispositivosVinculados] = useState( [] );

    const [openLoading, setOpenLoading] = useState( false );
    const [loadingDesvincular, setLoadingDesvincular] = useState( false );
    const [loadingHabilitar, setLoadingHabilitar] = useState( false );
    
    const [openConfirmacionNotificaciones, setOpenConfirmacionNotificaciones] = useState(false);
    const [habilitarNotificacion, setHabilitarNotificacion] = useState( false );

    const [loading, setLoading] = useState( true );
    const [validSize, setValidSize] = useState( true );

    const [ { Open, msg, type }, setModal ] = useState({ Open: false, msg: '', type: 'error' });

    const handleCloseModal = (event, reason) => {

        if (reason === 'clickaway') {
            return;
        }

        setModal({ open: false, type, msg, });
    };

    const subirImagen = async ({ currentTarget }) => {

        setValidSize( true );

        const { files } = currentTarget;

        if ( files.length > 0 ) {

            const file = files[0];

            if( file.size > 300000 ){
                
                setValidSize( false );
                return false;
            }
            
            setOpenLoading( true );

            if (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg') {

                let formData = new FormData();
                    formData.append('imagenPerfil', file);  

                await ActualizarImagenPerfil( formData ).then( response => {

                    if( response ){

                        if ( response.status === 200 ) {

                            const { success, message, imagen } = response.data;

                            setTimeout(() => {                                                         
                            
                                if ( success ) {
                                    
                                    setTimeout(() => {
                                     
                                        window.localStorage.setItem("data", JSON.stringify(
                                            {
                                                ...state,
                                                imagenPerfil: imagen,
                                            }
                                        ));

                                        setModal({
                                            Open: true,
                                            msg: message,
                                            type: 'success'
                                        });

                                        dispatch( setAvatar( imagen ) );

                                        setImagenPerfil( imagen );

                                        setOpenLoading( false );

                                    }, 700);

                                    handleCloseModal();

                                }
                                else{

                                    setTimeout(() => {

                                        setModal({
                                            Open: true,
                                            msg: message,
                                            type: 'error'
                                        });

                                        setOpenLoading( false );

                                    }, 250);

                                }
                            
                            }, 500);

                        }

                    }

                })

            }
        }
    }

    const handleDisvincularDispositivo = async () => {

        setLoadingDesvincular( true );

        await DesvincularDispositivo({ id: referencia }).then( resp => {

            if( resp ){

                const { success } = resp.data;

                if( success ){

                    setTimeout(() => {

                        const dispositivos = dispositivosVinculados.filter( ( item ) => item.id !== referencia );

                        setDispositivosVinculados( dispositivos );
                        setOpenConfirmacion( false );
                        setLoadingDesvincular( false );
                        
                    }, 1000);              

                }
                else {
                    setTimeout(() => {
                        setLoadingDesvincular( false );                        
                    }, 1000);  
                }
            }
            else {
                setTimeout(() => {
                    setLoadingDesvincular( false );                        
                }, 1000);  
            }

        });

    }

    const handleHabilitarNotificaciones = async () => {

        setLoadingHabilitar( true );

        const permitir = !habilitarNotificacion;

        await HabilitarNotificacion({ id: referencia, permitir: permitir }).then( resp => {

            if( resp ){

                const { success } = resp.data;

                if( success ){

                    setTimeout(() => {

                        const dispositivos = dispositivosVinculados.map( ( item ) => {

                            if( item.id === referencia ){
                                item.permitirNotificacion = permitir;
                            }
                            return item;
                        });

                        setDispositivosVinculados( dispositivos );
                        setOpenConfirmacionNotificaciones( false );
                        setLoadingHabilitar( false );
                        
                    }, 1000);              

                }
                else {
                    setTimeout(() => {
                        setLoadingHabilitar( false );                        
                    }, 1000);  
                }
            }
            else {
                setTimeout(() => {
                    setLoadingHabilitar( false );                        
                }, 1000);  
            }

        });

    }

    useEffect(() => {

        async function Obtener() {

            await ObtenerPerfil().then(response => {

                if (response) {

                    if (response.status === 200) {

                        setTimeout(() => {

                            const imagen = response?.data?.imagenPerfil ?? '';

                            setUsuario( response.data );
                            setImagenPerfil( imagen );
                            setDispositivosVinculados( response?.data?.dispositivos ?? [] );

                            const data = JSON.parse( window.localStorage.getItem("data") );
                            window.localStorage.setItem("data", JSON.stringify( { ...data, imagenPerfil: imagen, } ));

                            setLoading(false);

                        }, 1000);

                    }

                }

            });

        }

        Obtener();

    }, [ ]);

    return (
        <>
            <NavbarInicio />

            {
                (!loadingPermisos && tipoUsuario !== 0)
                &&
                <>
                    <Snackbar open={ Open } anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} autoHideDuration={ 2000 } onClose={ handleCloseModal }>
                        <Alert onClose={ handleCloseModal } variant="filled" severity={ type } sx={{ width: '100%' }}>
                            { msg }
                        </Alert>
                    </Snackbar>

                    <Container maxWidth='lg' sx={{ mt: { md: '10em', xs: '8em' }, mb: 5 }}>                        

                        <Grid container spacing={3}>

                            <Grid item md={4} xs={12}>

                                {
                                    loading
                                        ?
                                        <Grid container spacing={3} >

                                            <Grid item xs={12} sx={{ textAlign: 'center' }} >
                                                <CircularProgress size={50} sx={{ color: '#fff', mt: 10 }} />
                                            </Grid>

                                        </Grid>
                                        :
                                        <Card>

                                            <CardContent>

                                                <Box sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }} >

                                                    <Box sx={{ p: 1 }} >
                                                        <img
                                                            alt='Avatar del usuario'
                                                            src={ imagenPerfil ? imagenPerfil : require('../../assets/user.png') }
                                                            style={{ width: '100%', height: 315, borderRadius: 500 }}                                                        
                                                        />
                                                    </Box>

                                                    <Typography
                                                        variant="h6"
                                                        sx={{ mt: 2, textAlign: 'center', fontWeight: '500' }}
                                                    >
                                                        { usuario.nombreCompleto }
                                                    </Typography>

                                                    <Typography
                                                        variant="body2"
                                                        sx={{ mt: 2, textAlign: 'center', fontSize: 14 }}
                                                    >
                                                        { usuario.correoElectronico }
                                                    </Typography>

                                                    {
                                                        usuario.telefonoCelular?
                                                            <Typography
                                                                variant="body2"
                                                                sx={{ mt: 2, textAlign: 'center', fontSize: 14 }}
                                                            >
                                                                { usuario.telefonoCelular }
                                                            </Typography>
                                                        : null
                                                    }

                                                    {
                                                        !validSize
                                                        &&
                                                        <Alert severity='warning' color='warning' sx={{ mt: 2 }}>
                                                            El tamaño de imagen no es permitido
                                                        </Alert>
                                                    }

                                                </Box>

                                            </CardContent>

                                            <Divider />

                                            <CardActions>

                                                <label htmlFor="contained-button-file" style={{ width: '100%', textAlign: 'center' }}>
                                                    <Input accept=".jpg, .png, .jpeg" id="contained-button-file" type="file" onChange={ subirImagen } />

                                                    <LoadingButton 
                                                        variant="contained" 
                                                        fullWidth 
                                                        component="span" 
                                                        startIcon={ <PhotoCameraIcon />} 
                                                        loading={ openLoading } 
                                                        sx={{ mb: 1 }}
                                                    >
                                                        Subir imagen
                                                    </LoadingButton>

                                                    <Typography variant='caption'>
                                                        Tamaño de imagen permitido 300KB.
                                                    </Typography>
                                                </label>

                                            </CardActions>

                                        </Card>
                                }

                            </Grid>

                            <Grid item md={8} xs={12}>

                                {
                                    loading
                                        ?
                                        <Grid container spacing={3} >

                                            <Grid item xs={12} sx={{ textAlign: 'center' }} >
                                                <CircularProgress size={50} sx={{ color: '#fff', mt: 10 }} />
                                            </Grid>

                                        </Grid>
                                        :
                                        <Card>

                                            <CardHeader title={<Typography variant='subtitle1' sx={{ textAlign: 'center', fontWeight: '500', fontSize: 25 }}> Información del perfil </Typography>} />

                                            <CardContent>

                                                <TableContainer>

                                                    <Table>

                                                        <TableBody>
                                                            {
                                                                usuario.curp 
                                                                ?
                                                                    <TableRow>
                                                                        <TableCell sx={{ fontWeight: 'bold', fontSize: 14 }}>
                                                                            CURP:
                                                                        </TableCell>

                                                                        <TableCell sx={{ fontSize: 14 }}>
                                                                            { usuario.curp }
                                                                        </TableCell>
                                                                    </TableRow>
                                                                :
                                                                    null
                                                            }

                                                            <TableRow>
                                                                <TableCell sx={{ fontWeight: 'bold', fontSize: 14 }}>
                                                                    Nombre completo:
                                                                </TableCell>

                                                                <TableCell sx={{ fontSize: 14 }}>
                                                                    { usuario.nombreCompleto }
                                                                </TableCell>
                                                            </TableRow>

                                                            <TableRow>
                                                                <TableCell sx={{ fontWeight: 'bold', fontSize: 14 }}>
                                                                    Correo electrónico:
                                                                </TableCell>

                                                                <TableCell>
                                                                    <ListItem
                                                                        disablePadding
                                                                        secondaryAction={
                                                                            <IconButton edge="end" color='primary' onClick={ () => setOpenActualizarDatosCuenta( true ) } >
                                                                                <EditIcon />
                                                                            </IconButton>
                                                                        }
                                                                    >
                                                                        <ListItemText primary={<Typography sx={{ fontSize: 14 }} variant='body2'> { usuario.correoElectronico } </Typography>} />
                                                                    </ListItem>
                                                                </TableCell>
                                                            </TableRow>

                                                            <TableRow>
                                                                <TableCell sx={{ fontWeight: 'bold', fontSize: 14 }}>
                                                                    Contraseña:
                                                                </TableCell>

                                                                <TableCell>
                                                                    <ListItem
                                                                        disablePadding
                                                                        secondaryAction={
                                                                            <IconButton edge="end" color='primary' onClick={ () => setOpenActualizarContrasena( true ) } >
                                                                                <EditIcon />
                                                                            </IconButton>
                                                                        }
                                                                    >
                                                                        <ListItemText primary={<Typography sx={{ fontSize: 14 }} variant='body2'> ************ </Typography>} />
                                                                    </ListItem>
                                                                </TableCell>
                                                            </TableRow>

                                                            {
                                                                usuario.curp 
                                                                ?
                                                                    <TableRow>
                                                                        <TableCell sx={{ fontWeight: 'bold', fontSize: 14 }}>
                                                                            Teléfono celular:
                                                                        </TableCell>

                                                                        <TableCell>
                                                                            <ListItem
                                                                                disablePadding
                                                                                secondaryAction={
                                                                                    <IconButton edge="end" color='primary' onClick={ () => setOpenActualizarDatosPersonales( true ) } >
                                                                                        <EditIcon />
                                                                                    </IconButton>
                                                                                }
                                                                            >
                                                                                <ListItemText primary={<Typography sx={{ fontSize: 14 }} variant='body2'> { usuario.telefonoCelular }</Typography>} />
                                                                            </ListItem>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                : 
                                                                    null
                                                            }
                                                            
                                                            {
                                                                usuario.curp 
                                                                ?
                                                                    <TableRow>
                                                                        <TableCell sx={{ fontWeight: 'bold', fontSize: 14 }}>
                                                                            Teléfono fijo:
                                                                        </TableCell>

                                                                        <TableCell>
                                                                            <ListItem
                                                                                disablePadding
                                                                                secondaryAction={
                                                                                    <IconButton edge="end" color='primary' onClick={ () => setOpenActualizarDatosPersonales( true ) }>
                                                                                        <EditIcon />
                                                                                    </IconButton>
                                                                                }
                                                                            >
                                                                                <ListItemText primary={<Typography sx={{ fontSize: 14 }} variant='body2'> { usuario.telefonoFijo } </Typography>} />
                                                                            </ListItem>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                : 
                                                                    null
                                                            }
                                                            
                                                            {
                                                                usuario.curp 
                                                                ?
                                                                    <TableRow>
                                                                        <TableCell sx={{ fontWeight: 'bold', fontSize: 14 }}>
                                                                            Cédula profesional:
                                                                        </TableCell>

                                                                        <TableCell>
                                                                            <ListItem
                                                                                disablePadding
                                                                                secondaryAction={
                                                                                    <IconButton edge="end" color='primary' onClick={ () => setOpenActualizarDatosPersonales( true ) }>
                                                                                        <EditIcon />
                                                                                    </IconButton>
                                                                                }
                                                                            >
                                                                                <ListItemText primary={<Typography sx={{ fontSize: 14 }} variant='body2'> { usuario.cedulaProfesional } </Typography>} />
                                                                            </ListItem>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                : 
                                                                    null
                                                            }

                                                            {
                                                                usuario.curp 
                                                                ?
                                                                    <TableRow>
                                                                        <TableCell sx={{ fontWeight: 'bold', fontSize: 14 }}>
                                                                            Dirección:
                                                                        </TableCell>

                                                                        <TableCell >
                                                                            <ListItem
                                                                                disablePadding
                                                                                secondaryAction={
                                                                                    <IconButton edge="end" color='primary' onClick={() => setOpenActualizarDomicilio(true)}>
                                                                                        <EditIcon />
                                                                                    </IconButton>
                                                                                }
                                                                            >
                                                                                <ListItemText primary={
                                                                                        <Typography sx={{ fontSize: 14, width: '90%' }} variant='body2'> 
                                                                                            {
                                                                                                usuario.idAsentamiento !== 0
                                                                                                &&
                                                                                                (
                                                                                                    usuario.calleVialidad + ", No." + usuario.numero + ", C.P: " + 
                                                                                                    usuario.codigoPostal + ", " + usuario.tipoAsentamiento + " " + usuario.asentamiento + ", " + 
                                                                                                    usuario.municipio + ", " + usuario.estado + ", "
                                                                                                )
                                                                                            } 
                                                                                        </Typography>
                                                                                    } 
                                                                                />
                                                                            </ListItem>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                :
                                                                    null
                                                            }

                                                        </TableBody>

                                                    </Table>                                                    

                                                </TableContainer>

                                            </CardContent>

                                        </Card>
                                }

                            </Grid>

                            <Grid item md={12} xs={12}>

                                {
                                    loading
                                        ?
                                            <Grid container spacing={3} >

                                                <Grid item xs={12} sx={{ textAlign: 'center' }} >
                                                    <CircularProgress size={50} sx={{ color: '#fff', mt: 10 }} />
                                                </Grid>

                                            </Grid>
                                        :
                                            <Card>

                                                <CardHeader title={
                                                        <Typography variant='subtitle1' sx={{ fontWeight: '500', fontSize: 22 }}> 
                                                            Dispositivos vinculados
                                                        </Typography>
                                                    } 
                                                />

                                                <CardContent>

                                                    <List sx={{ width: '95%', bgcolor: 'background.paper' }}>

                                                        {
                                                            dispositivosVinculados?.map( ( item, index ) => (

                                                                <ListItem                                                                                                                                    
                                                                    key={ index }
                                                                    secondaryAction={
                                                                        <>
                                                                            {
                                                                                ( item.permitirNotificacion !== null )
                                                                                &&
                                                                                <Button
                                                                                    variant='contained'
                                                                                    size='small'
                                                                                    sx={{ mr: 5 }}
                                                                                    color={ item.permitirNotificacion ? 'inherit' : 'primary' }
                                                                                    startIcon={ <NotificationAddIcon /> }
                                                                                    onClick={ () => {
                                                                                        setHabilitarNotificacion( item.permitirNotificacion );
                                                                                        setReferencia( item.id );
                                                                                        setOpenConfirmacionNotificaciones( true );
                                                                                    }}
                                                                                >
                                                                                    { item.permitirNotificacion ? 'Desahabilitar notificaciones' : 'Habilitar notificaciones' }
                                                                                </Button>
                                                                            }                                                                            

                                                                            <IconButton edge="end" aria-label="delete" onClick={ () => { setReferencia( item.id ); setOpenConfirmacion( true ); } }>
                                                                                <CloseIcon />
                                                                            </IconButton>                                                                     
                                                                        </>
                                                                    }
                                                                >
                                                                    <ListItemAvatar>
                                                                        <Avatar
                                                                            src={ 
                                                                                item.tipo === 'Android'
                                                                                ?
                                                                                    require('../../assets/android.png') 
                                                                                :
                                                                                    item.tipo === 'IOS'
                                                                                    ?
                                                                                        require('../../assets/ios.png')
                                                                                    :
                                                                                        ''
                                                                            }
                                                                        />
                                                                    </ListItemAvatar>
                                                                  
                                                                    <ListItemText 
                                                                        primary={ item.nombre }
                                                                        secondary={ item.uuid }
                                                                    />

                                                                </ListItem>      

                                                            ))
                                                        }             

                                                        {
                                                            dispositivosVinculados.length === 0
                                                            &&
                                                            <ListItem
                                                               disablePadding
                                                               disableGutters
                                                               sx={{ textAlign: 'center', fontWeight: 'bold' }}
                                                            >                                                                   
                                                                <ListItemText primary='No se encontraron dispositivos vinculados' />
                                                            </ListItem>      
                                                        }                          

                                                    </List>

                                                </CardContent>

                                            </Card>
                                }

                            </Grid>

                        </Grid>

                    </Container>

                    <Dialog open={ openConfirmacion } onClose={ () => {} } >
                
                        <DialogTitle>
                            Confirmación
                        </DialogTitle>
                        
                        <DialogContent>
                        
                            <DialogContentText sx={{ color: 'rgba(0, 0, 0, 1)' }}>
                                ¿Desea desvincular el dispositivo seleccionado?
                            </DialogContentText>
                        
                        </DialogContent>

                        <DialogActions>
                            <Button onClick={ () => setOpenConfirmacion( false ) }> Cancelar </Button>
                            <LoadingButton onClick={ handleDisvincularDispositivo } variant='contained' loading={ loadingDesvincular } > Aceptar </LoadingButton>
                        </DialogActions>
                    </Dialog>

                    <Dialog open={ openConfirmacionNotificaciones } onClose={ () => {} } >
                
                        <DialogTitle>
                            Confirmación
                        </DialogTitle>
                        
                        <DialogContent>
                        
                            <DialogContentText sx={{ color: 'rgba(0, 0, 0, 1)' }}>
                                ¿Desea { habilitarNotificacion ? 'desahabilitar' : 'habilitar' } las notificaciones de este dispositivo?
                            </DialogContentText>
                        
                        </DialogContent>

                        <DialogActions>
                            <Button onClick={ () => setOpenConfirmacionNotificaciones( false ) }> Cancelar </Button>
                            <LoadingButton onClick={ handleHabilitarNotificaciones } variant='contained' loading={ loadingHabilitar } > Aceptar </LoadingButton>
                        </DialogActions>
                    </Dialog>

                    <ModalActualizarDatosPersonales
                        open={ openActualizarDatosPersonales }
                        setOpen={ setOpenActualizarDatosPersonales }                        
                        setModal={ setModal }
                        usuario = { usuario }
                        setUsuario={ setUsuario }
                    />

                    <ModalActualizarDomicilio
                        open={ openActualizarDomicilio }
                        setOpen={ setOpenActualizarDomicilio }  
                        setModal={ setModal }
                        usuario = { usuario }
                        setUsuario={ setUsuario }
                    />

                    <ModalActualizarDatosCuenta
                        open={ openActualizarDatosCuenta }
                        setOpen={ setOpenActualizarDatosCuenta }  
                        setModal={ setModal }
                        usuario={ usuario }
                        setUsuario={ setUsuario }
                    />

                    <ModalActualizarContrasena
                        open={ openActualizarContrasena }                        
                        setOpen={ setOpenActualizarContrasena }    
                        setModal={ setModal }                     
                        usuario={ usuario }
                    />
                </>
            }

        </>
    )
}
